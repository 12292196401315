import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {Auth, ESign, ESocAuthType} from 'components';
import {getToken, ifCond} from 'utils';
import { useStoreActions, useStoreState } from 'hooks';
import {FIELD, ROUTE, URL_PARAM} from 'const';
import axios from "axios";
import {FACEBOOK_SUCCESS_LOGIN} from "../../../const/routes";

export interface ILoginPage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const LoginPage = ((props: ILoginPage) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const matchParams = useStoreState(state => state.router.params);
  const homePath = useStoreState(state => state.homePath);
  const push = useStoreActions(actions => actions.router.push);
  const doLogin = useStoreActions(actions => actions.login);
  const doConfirm = useStoreActions(actions => actions.confirm);
  const doResend = useStoreActions(actions => actions.resend);
  const setToken = useStoreActions(actions => actions.setTid);
  const clearMessagesByField = useStoreActions(actions => actions.clearMessagesByField);
  const clearFieldMessages = useStoreActions(actions => actions.clearFieldMessages);
  const switchToHome = useStoreActions(actions => actions.switchToHome);
  const consumeParams = useStoreActions(actions => actions.consumeUrlParams);
  const confirmToken = matchParams[URL_PARAM.TOKEN];
  const emailParam = matchParams[URL_PARAM.EMAIL];
  const [mode, setMode] = React.useState(null);
  const [values, setValues] = React.useState({[FIELD.EMAIL]: null});
  const url = window.location.href;
  const code = url?.split('code=')[1]?.split('&')[0];

  React.useEffect(() => {
    const token = getToken();
    (async () => {
      if (token) {
        push({to: "/history"})
      } else {
        if (code) {
          const result = await axios.get(FACEBOOK_SUCCESS_LOGIN(code));
          if (result.status === 200) {
            await setToken(result.data['access_token']);
            switchToHome(true);
            push({to: '/history'});
          }
        }
      }
    })()
  }, [])
  //!!confirmToken ? null : ESign.IN
  const preprocess = React.useCallback(async () => {
    if (mode == null) {
      if (emailParam) {
        setValues({...values, [FIELD.EMAIL]: emailParam, [FIELD.LOGIN]: emailParam});
        await consumeParams({params: [URL_PARAM.EMAIL]});
        return;
      } else if (confirmToken) {
        const token = await doConfirm(confirmToken);
        await switchToHome(true);
        if (token !== false) {
          await setToken(token);
          await switchToHome(true);
        } else {
          setMode(ESign.UP_WRONG);
          await consumeParams({params: [URL_PARAM.TOKEN]});
        }
      } else {
        setMode(ESign.IN);
      }
    }
  }, [confirmToken, emailParam, switchToHome, setToken, mode, setMode]);
  const handleChange = React.useCallback((e, fld, val, setVal) => {
    const value = e.target.value;
    if (val[fld]!==value) {
      setValues({...values, ...val, [fld]: value});
    }
  }, [values,, setValues]);
  const handleSubmit = React.useCallback(async (e, values, setValues) => {
    clearFieldMessages();
    switch (mode) {
      case ESign.UP_WRONG:
        await doResend(values[FIELD.EMAIL]);
        setMode(ESign.UP_SENT);
        break;
      case ESign.UP_SENT:
        push({to: ROUTE.AUTH});
        break;
      case ESign.IN:
        const token = await doLogin([ values[FIELD.LOGIN], values[FIELD.PWD] ]);
        if (token) {
          await setToken(token);
          switchToHome(true);
          push({to: '/history'});
        }
        break;
    }

  }, [mode, doLogin, switchToHome]);
  const handleSignUpClick = (e) => { push({to: ROUTE.REG}); };
  const handleSignInClick = (e) => { push({to: ROUTE.AUTH}); };
  const handleForgotPwdClick = (e) => { push({to: ROUTE.RESET}); };
  const handleSocAuthClick = (e, soc: ESocAuthType) => {
    if (soc === 'facebook') {
      push({to: ROUTE.FACEBOOK_LOGIN(window.location.origin)});
    }
  };

  React.useEffect(() => {
    if (mode == null) {
      preprocess();
    }
  }, [mode, preprocess]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWLoginPage darkMode={darkMode} {...rest}>
      <Auth mode={mode} values={values} errors={fieldErrors} onChange={handleChange} onClearError={clearMessagesByField} onSubmit={handleSubmit} onSignUpClick={handleSignUpClick} onSignInClick={handleSignInClick} onSocAuthClick={handleSocAuthClick} onForgotPwdClick={handleForgotPwdClick} />
    </TWLoginPage>
  );
});

const TWLoginPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative w-full h-full flex flex-col items-center justify-center`,
  tw`mobile:bg-white mobile:h-full mobile:flex mobile:flex-col mobile:justify-center mobile:mt-0`,
  css`min-height: 600px`
]);
