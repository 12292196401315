import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox } from 'components';
import { cssSize, ifCond } from 'utils';

export interface IPlug extends TThemed {
  vertical?: boolean; // false
  weight?: 1 | 2 | 3 | 4; // 1
  text?: string;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Plug = (props: IPlug) => {
  const { vertical=false, darkMode=false, if:_if=true, weight=1, text, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return <TWPlug/>
};

const TWPlug = styled.div(() => [ tw``, css`display: none`]);
