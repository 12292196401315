import * as ROUTE from "./routes";
import {CODE} from "./commands";
import * as PORTAL from "./domNodePool";

const BRAND = "Joxi";
const A_RECORD = "185.165.123.36";
const PUBLIC_ID = 'pk_44ae40e86a0da68c93c5f178e731d';

const IS_PROD = process.env.NODE_ENV === 'production';
const LOCAL_PARAM = {
  JWT: `${BRAND}_tid`,
  CID: `${BRAND}_cid`,
  LANG: `${BRAND}_lang`,
};
const STORE_PARAM = {
  ROUTER_KEY: "router",
};
const URL_PARAM = {
  CID: "cid",
  TOKEN: "confirm",
  EMAIL: "email",
  REASON: "reason",
  RESULT: "result",
  PRODUCT: "product",
  SHOT: 'shot',
  SCREENCAST: 'screencast',
  FILE: 'file',
  SEARCH: 'search',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  TAGS: 'tags',
};

const PRODUCTS = {
  FREE: 'plan:free',
  PRO: 'plan:pro',
  TEAM: 'plan:team',
  SLOT: 'team_slot',
  VERIFICATION: 'payment_method_verification',
};

const SETTINGS = {
  inputDelay: 400
};
const FIELD = {
  COMMON: "common",
  ERROR: "error",
  TOKEN: "token",
  CID: "cid",
  LOGIN: "login",
  USER: "username",
  EMAIL: "email",
  PWD: "password",
  HOLDER_NAME: "holder_name",
  CARD: "card_number",
  EXPIRES: "expires",
  CVC: "cvc",
  ZIP: "zip",
  LINK: "link",
  INVITE: "invite",
  LANG: "language",
  FROM: "from",
  TO: "to",
  OLD_PWD: "old_password",
  NEW_PWD: "new_password",
  PROTECT: "protect",
  CUSTOM_DOMAIN: "domain",
  CREATED_ON: "created_date",
  START_DATE: "start_date",
  END_DATE: "end_date",
  TAG_NAME: "tag_name",
  TAG_COLOR: "tag_color",
  AMOUNT: "amount",
  COMMENT: "comment",
};
const EventPrefix = {
  PSE_RADIO_ONCHANGE: "PubSub_Element_Radio_onChange_",
};
const EIcon = {
  "Add":"Add", "AddCircle":"AddCircle", "AddCircleGray":"AddCircleGray", "AddGray":"AddGray",
  "AuthOK":"AuthOK", "AuthTwitter":"AuthTwitter", "AuthVK":"AuthVK", "Calendar":"Calendar", "CalendarGray":"CalendarGray", "Check":"Check", "CheckWhite":"CheckWhite", "ChevronGray":"ChevronGray",
  "ChevronLeftGray":"ChevronLeftGray", "ChevronRightGray":"ChevronRightGray", "Chrome":"Chrome",
  "CloseCircle":"CloseCircle", "CloseCircleGray":"CloseCircleGray", "CloseGray":"CloseGray", "Coin":"Coin", "Diamond":"Diamond",
  "Download":"Download", "DownloadGray":"DownloadGray", "DownloadRounded":"DownloadRounded", "Edit":"Edit",
  "EditGray":"EditGray", "EllipsisWhite":"EllipsisWhite", "Enter":"Enter",
  "EyeClosedGray":"EyeClosedGray",
  "EyeGray":"EyeGray",
  "FileWhite":"FileWhite", "Firefox":"Firefox",
  "Group":"Group", "GroupGray":"GroupGray", 'GlobeGray':'GlobeGray', "Guest":"Guest", "History":"History", "Joxi":"Joxi", "Link":"Link",
  "LinkGray":"LinkGray", "LoaderGradientCircle":"LoaderGradientCircle", "Lock":"Lock", "LockGray":"LockGray", "LogIn":"LogIn", "Logo":"Logo", "LogOut":"LogOut",
  "Opera":"Opera", "Yandex":"Yandex", "PlayWhite":"PlayWhite", "Pro":"Pro", "SearchGray":"SearchGray", "Share":"Share", "ShareGray":"ShareGray",
  "SocFacebook":"SocFacebook", "SocGoogle":"SocGoogle", "SocInstagram":"SocInstagram", "SocPinterest":"SocPinterest",
  "SocVimeo":"SocVimeo", "SocVK":"SocVK", "SocYoutube":"SocYoutube", "Sun":"Sun", "Tag":"Tag", "TagGray":"TagGray",
  "Team":"Team", "TeamGrayed":"TeamGrayed", "Trash":"Trash", "TrashGray":"TrashGray", "TrashRed":"TrashRed", "Unlock":"Unlock",
  "UnlockGray":"UnlockGray", "User":"User",
};
const OnePixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const URLS = {
  SOCIAL_IMAGE: {
    "SocInstagram": "%%1%%",//"https://instagram.com/",
    "SocFacebook": "https://www.facebook.com/sharer/sharer.php?u=%%1%%",
    "SocPinterest": "https://pinterest.com/pin/create/button/?url=%%1%%",
    "SocVK": "https://vk.com/share.php?url=%%1%%",
  },
  SOCIAL_VIDEO: {
    "SocYoutube": "%%1%%",//"https://youtube.com/",
    "SocVimeo": "%%1%%",//"https://vimeo.com/",
    "SocVK": "https://vk.com/share.php?url=%%1%%",
    "SocInstagram": "%%1%%",//"https://instagram.com/",
    "SocFacebook": "https://www.facebook.com/sharer/sharer.php?u=%%1%%",
  },
  SOCKET: ""+process.env.REACT_APP_SOCKET_URL,
  FILE_SOCKET: ""+process.env.REACT_APP_FILE_SOCKET_URL,
  API: ""+process.env.REACT_APP_API_URL+"/api/",
  API_AUTH: ""+process.env.REACT_APP_API_URL+"/api/v2/auth/",
  API_BILLING: ""+process.env.REACT_APP_API_URL+"/api/v2/billing/",
  LOCALIZATION: "",
  CUSTOM_DOMAIN_INSTRUCTIONS: "",
  TERMS: "",
};
const PUBSUB = {
  HISTORY: {
    FILTER_CHANGE: "onHistoryFilterChange"
  }
};
const RX = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const LNG = {
  en: { unique: 'en', name: "English" },
  ru: { unique: 'ru', name: "Русский" }
} as any;

LNG._default = LNG.en;
LNG._current = LNG._default;

const MSG = {
  
  DATE: {
    HISTORY: {
      CREATED_ON: "MMMM D, YYYY [at] h:mm A",
      CALENDAR_LABEL: "DD.MM.YYYY"
    },
    CALENDAR: {
      MONTH_HDR: "MMMM YYYY"
    },
    BILLING: {
      HISTORY: "MMM D, YYYY"
    }
  },
  ERROR: {
    
    LOGIN: {
      REQ: "Login/E-mail is required"
    },
    PWD: {
      REQ: "Password is required",
      INCORRECT: "Password must be at least 4 non-space characters."
    },
    EMAIL: {
      REQ: "E-mail is required",
      INCORRECT: "E-mail is incorrect",
    },
    TAG_NAME: {
      REQ: "Tag name is required",
      INCORRECT: "Tag name is incorrect",
    },
    TAG_COLOR: {
      REQ: "Pick a tag color"
    },
    DONATION_AMOUNT: {
      REQ: "Enter an amount of donation"
    },
    API: {
      postInvoice: {
        ReferToCardIssuer: 'Refusal of the issuer to conduct an online transaction',
        InvalidMerchant: 'Refusal of the issuer to conduct an online transaction',
        PickUpCard: 'Lost card',
        DoNotHonor: 'Refusal of the issuer without explanation',
        Error: 'Network refusal to carry out the operation or incorrect CVV code',
        PickUpCardSpecialConditions: 'Lost card',
        InvalidTransaction: 'The card is not intended for online payments',
        AmountError: 'Too small or too large transaction amount',
        InvalidCardNumber: 'Incorrect card number',
        TransactionError: 'Issuer not found',
        FormatError: 'Refusal of the issuer without explanation',
        BankNotSupportedBySwitch: 'Error on the acquirers side - the transaction was incorrectly formed',
        ExpiredCardPickup: 'Lost card has expired',
        SuspectedFraud: 'Issuer refusal - suspicion of fraud',
        RestrictedCard: 'The card is not intended for payments',
        LostCard: 'Lost card',
        StolenCard: 'The card is stolen',
        InsufficientFunds: 'Insufficient funds',
        ExpiredCard: 'The card is expired or the expiration date is incorrect',
        TransactionNotPermitted: 'Restriction on the map',
        RestrictedCard2: 'The card is not intended for payments',
        SecurityViolation: 'Card blocked due to security breaches',
        ExceedWithdrawalFrequency: 'The limit of card transactions has been exceeded',
        IncorrectCVV: 'Invalid CVV code',
        Timeout: 'Issuer unavailable',
        CannotReachNetwork: 'Issuer unavailable',
        SystemError: 'Acquiring bank or network error',
        UnableToProcess: 'The transaction cannot be processed for other reasons',
        Authenticationfailed: '3-D Secure authorization failed',
        Authenticationunavailable: '3-D Secure authorization is not available',
        AntiFraud: 'Acquiring limits for transactions',
      },
      login: {},
      logout: {},
      forgot: {
        user_not_found: "User not found",
      },
      validate: {
        token_expired: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or try to reset the password again.",
        incorrect_token: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or try to reset the password again.",
      },
      change: {
        token_expired: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or try to reset the password again.",
        incorrect_token: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or try to reset the password again.",
      },
      register: {},
      confirm: {
        token_expired: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or request the confirmation again.",
        incorrect_token: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or request the confirmation again.",
      },
      resend: {
        user_not_found: "User not found",
      }
    },
    FIELD: {
      [FIELD.LOGIN]: "",
      [FIELD.EMAIL]: "",
      [FIELD.PWD]: "",
      [FIELD.USER]: "",
      [FIELD.LANG]: "",
      [FIELD.NEW_PWD]: "",
      [FIELD.OLD_PWD]: "",

    }
  },
  BRAND: BRAND,
  BRAND_: `${BRAND} %%1%%`,
  OR: "or",
  ICON: BRAND,
  NO_DATA: "No data",
  NO_TAGS: "No tags found...",
  ACTIONS: {
    CANCEL: "Cancel",
    DELETE: "Delete",
    SAVE: "Save",
    OK: "OK",
    CREATE: "Create",
    UNSUB: "Unsubscribe",
    SEND: "Send",
    LOCK: "Lock",
    UNLOCK: "Unlock",
    COPY_LINK: "Copy link",
    DOWNLOAD: "Download",
    UNBLOCK_SCREEN: 'Unblock',
    SHARE: "Share",
    TAGS: "Tags",
    DESELECT: "Deselect",
  },
  MESSAGE: {
    COPY_LINK: "Link is copied",
    LOCK: "Locked",
    UNLOCK: "Unlocked",
    DELETE: "Deleted",
  },
  YEARLY: "Yearly",
  MONTHLY: "Monthly",
  MONTH: "month",
  MONTH_BRIEF: "mo",//мес.
  YEAR: "year",
  TODAY: "Today",
  ONCE: "Once",
  EVERY_MONTH: "Every month",
  CHOOSE_PERIOD: "Choose period",
  CREDIT_CARD: "Credit card",
  DONATION_AMOUNT: "Donation amount",
  COMMENT: "Comment",
  PAYPAL: "PayPal",
  PAYMENT: {
    CARD_NUMBER: "Card No",
    EXPIRES: "Expiry Date",
    CVC: "CVC / CVV",
    ZIP: "ZIP",
    EMBEDDED_NAME: "First Name & Last Name"
  },
  HEADER: {
    SLOGAN: `${BRAND} — the best screenshot app`,
  },
  HISTORY: {
    CLEAR: "Clear history",
    TO_BE_REMOVED: ["objects to be removed", "objects to be removed", "objects to be removed"],
    TO_BE_FREED: "of disk space to be freed",
    CONFIRM_DELETE: "Are you sure you want to delete selected object(s)?",
    UNRESTORABLE: "Deleted objects cannot be restored.",
    SEARCH: "Search",
    SCREENSHOT: "Screenshot",
    SCREENCAST: "Screencast",
    FILE: "File",
    WHOLE_TIME: "Whole time",
    CHOOSE_PERIOD_HDR: "Choose period",
    RESET_FILTER: "Reset filter"
  },
  AUTH: {
    SIGN_IN: "Sign In",
    SIGN_UP: "Sign Up",
    SIGN_OUT: "Sign Out",
    SIGN_IN_GOOGLE: "Sign in with Google",
    SIGN_IN_FACEBOOK: "Sign in with Facebook",
    SIGN_OUT_GOOGLE: "Sign Out",
    RESET_HDR: "Password reset",
    RESET_BTN: "Reset password",
    WRONG_HDR: "Confirmation error",
    RESEND_BTN: "Send again",
    RECONFIRM_BTN: "Confirm again",
    NEW_PWD_HDR: "Enter your new password",
    INSTRUCTIONS_SENT_RESET: "We've sent password recovery instructions to %%1%%.",
    INSTRUCTIONS_SENT_REG: "We've sent registration completion instructions to %%1%%.",
    WRONG_TOKEN: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or request the confirmation again.",
    WRONG_TOKEN_RESET: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or try to reset the password again.",
    WRONG_TOKEN_REG: "Your confirmation token is wrong, incomplete or expired. Please double-check the link we sent to you by e-mail or request the confirmation again.",
    HAVE_ACCOUNT: "Already have an account? ",
    AGREE_TO_TERMS: "By signing up you're agreeing to %%1%%",
    HAVE_NO_ACCOUNT: "Don't have an account? ",
    TERMS: `${BRAND} Terms`,
    UNAUTHORIZED: "Unauthorized user",
    UNKNOWN: "Unknown user",
    GUEST: "Guest"
  },
  PROFILE: {
    HEADER: "Profile",
    PHOTO: "Photo",
    CHANGE_PHOTO: "Change photo",
    REMOVE_PHOTO: "Remove current photo",
    NAME: "Name",
    CHANGE_NAME: "Change name",
    LOGIN: "User name / E-mail",
    EMAIL: "E-mail",
    CHANGE_EMAIL: "Change e-mail",
    PWD: "Password",
    CHANGE_PWD: "Change password",
    FORGOT_PWD: "Forgot password?",
    NEW_PWD: "New password",
    CURRENT_PWD: "Current password",
    LANG: "Language",
    HELP_TRANSLATE: "Add your language",
    CHANGE_LANG: "Change language",
    CLEAR_HISTORY: "Clear history",
    ITEMS: "items",
    SPACE_USED: "%%1%% of %%2%% used",
    GOOGLE_LOGIN: "Connecting with Google allows you to login to your account using one-click on any device.",
    DELETE_FILES: "All of your files will be permanently deleted.",
    USAGE: "Usage",
    SUB_PLAN: "Subscription Plan",
    CURRENT_PLAN: "Current plan",
    PRICE_PER_MONTH: "%%1%% per %%2%%",
    PER_MONTH: "month",
    PER_USER_MONTH: "user/month",
    COMPARE_PLANS: "Compare plans",
    DELETE_ACCOUNT: "Delete account",
    TO_DELETE_ACCOUNT: "Delete account",
  },
  TEAM: {
    NEED_TO_PAY: "You need to pay for the license",
    LOGO_DESC: `Upload your logo to promote your brand. Instead of the ${BRAND} logo, visitors will see your custom logo on public share links.`,
    USE_PNG: "For the best results, use a transparent PNG.",
    CUSTOM_DOMAIN: "Custom domain",
    SET_DOMAIN: "Set domain"
  },
  BILL: {
    HEADER: "Billing",
    PAYMENT_METHOD: "Payment method",
    NEXT_BILL: "Next bill",
    HISTORY: "Billing history",
    PAY_NOW: "Pay now",
    PAID: "Paid",
    UNPAID: "Unpaid",
    CHANGE_BILLING_METHOD: "Change payment method", //payment method maybe?
    CANCEL_SUB: "Unsubscribe",
  },
  UPGRADE_PLAN: {
    HEADER: `Go to your Pro or Team account to access the full functionality of ${BRAND}`,
    UPGRADE_TO_BRAND: `Upgrade to ${BRAND} %%1%%`,
    UPGRADE_TO: "Upgrade to %%1%%",
    UPGRADE_NOW: "Upgrade now",
    DONATE_BRAND: `Donate to ${BRAND}`,
    CHOOSE_BILLING_CYCLE: "Choose Billing Cycle",
    CHOOSE_NUMBER_USERS: "Choose number of users",
    CHOOSE_PAYMENT_METHOD: "Choose a payment method",
    CHOOSE_DONATION_METHOD: "Choose a donation method",
    get PAYPAL_NOTE() {return `After clicking «${MSG.UPGRADE_PLAN.UPGRADE_NOW}», you will be redirected to PayPal to login and pay securely.`},
    YEARLY_BENEFIT: "Save %%1%% (%%2%%% off). It’s like getting %%3%% months free.",
    BENEFIT: "You'll save %%1%% (%%2%%%)",
    PER_USER: "per user",
    X_USERS: "users",
    PRO_FEATS: [
      'Video & Gif no time limit',
      'Password-protected sharing',
      'See & show no ads',
      'Structuring with tags',
      'Filter by period in the history',
      '5 Gb of disk space',
      'GoogleDrive & Dropbox',
      'Automatic OCR scan',
      'Direct link',
      'Prioryty support',
    ],
    TEAM_FEATS: [
      'The same as in Pro, as well:',
      'White Label',
      'Private team-only sharing',
      'Easy multi-user management',
      'Custom team domain',
      '5 Gb of disk space for each user',
      'One simple bill per team',
    ]
  },
  CONFIRM: {
    CANCEL_SUB_HDR: "Unsubscribe",
    CANCEL_SUB_ASK: "Are you sure you want to end your subscription?",
    CANCEL_SUB_WARN: "Your subscription ends on %%1%%",
    DELETE_ITEM_HDR: "Delete item",
    DELETE_ITEM_ASK: "Are you sure you want to delete this item?",
    DELETE_ITEM_WARN: "The item will be deleted permanently.",
    DELETE_ACCOUNT_HDR: "Delete account",
    DELETE_ACCOUNT_ASK: "Are you sure you want to delete your account?",
    DELETE_ACCOUNT_WARN: "Your account data and stored files will be completely removed.",
    DELETE_USER_HDR: "Delete user",
    DELETE_USER_ASK: "Are you sure you want to delete this user?",
    UNPROTECT_ITEM_HDR: "Remove password protection",
    UNPROTECT_ITEM_ASK: "Are you sure you want to remove password protection from this item?",
    DELETE_AVATAR_HDR: "Delete Avatar",
    DELETE_AVATAR_ASK: "Are you sure you want to delete your avatar?",
    
  },
  INFO: {
    HELP_TRANS_HDR: `Help us translate ${BRAND} to language`,
    HELP_TRANS_WARN: "We would appreciate if you could help us to translate our website! Website translation process is a bit different from translating applications of other kind but still is easy as pie.",
    HELP_TRANS_LOC_LINK: "Download English localization files",
    UPGRADED_PRO_SUCCESS: `You have successfully subscribed to ${BRAND} Pro`,
    UPGRADED_TEAM_SUCCESS: `You have successfully subscribed to ${BRAND} Team`,
    DONATE_SUCCESS: `Thanks for the support and for all your help to make ${BRAND} even better!`
  },
  ACTION: {
    CHANGE_PAYMENT_METHOD_HDR: "Change payment method",
    CHANGE_NAME_HDR: "Change name",
    CHANGE_EMAIL_HDR: "Change e-mail",
    CHANGE_PWD_HDR: "Change password",
    CHANGE_LANG_HDR: "Change language",
    CUSTOM_DOMAIN_HDR: "Custom domain",
    CUSTOM_DOMAIN_HINT: "Custom domain allows you to share files using your own domain name, e.g., example.com/xxxx or sub.example.com/xxxx.",
    CUSTOM_DOMAIN_ENTER: "Enter your domain",
    CUSTOM_DOMAIN_DESC1: "At the domain control panel at the registrar, register dns records for the domain:",
    CUSTOM_DOMAIN_DESC2: `Type-A record pointing to IP ${A_RECORD} and Type-A entry for a WWW-subdomain pointing to IP ${A_RECORD}.`,
    CUSTOM_DOMAIN_INSTRUCTIONS: "Detailed instructions",
    INVITE_USERS_HDR: "Invite users",
    INVITE_USERS_LINK: "Invite link",
    INVITE_USERS_LINK_COPY: "Copy link",
    INVITE_USERS_SEND_LINK: "Send this link to your team",
    INVITE_USERS_EMAIL_HDR: "Send invitations by e-mail",
    INVITE_USERS_ENTER: "Enter user email (each user from a new line)",
    IP_LIMIT_HDR: "IP limit",
    IP_LIMIT_FROM: "From",
    IP_LIMIT_TO: "To",
    SET_TAGS_HDR: "Set tags",
    SET_TAGS_NEW: "New tag",
    UNBLOCK_SCREEN: "Unblock",
    SET_TAGS_SEARCH: "Search tag",

    SET_TAGS_CREATE_HDR: "Create tag",//New tag maybe?
    SET_TAGS_ENTER_A_NAME: "Enter a new tag name",

    SET_TAGS_EDIT_HDR: "Edit tag",
    SET_TAGS_ENTER_THE_NAME: "Enter the tag name",

    SET_TAGS_NAME: "Tag name",


    PROTECT_ENTER_A_PASSWORD: "Enter a password",
    PROTECT_ENTER_THE_PASSWORD: "Enter the password",
    PROTECT_PWD_PROTECT_HDR: "Set password protection",
    PROTECT_PWD_ACCESS_HDR: "This page is password protected",

    SHARE_HDR: "Share",
    SHARE_COPY_BB_CODE: "Copy BB code",
    SHARE_COPY_PREVIEW_CODE: "Copy preview code"
  },
  PLAN: {
    FREE: "Free",
    PRO: "Pro",
    TEAM: "Team",
  },
  MENU: {
    ABOUT: `About ${BRAND}`,
    HISTORY: "Screenshot history",
    SIGN_UP_FREE: "Sign up for free",
    ADD_TO: "Add to %%1%%",
    SETTINGS: "Settings",
    DOWNLOAD_APP: "Download app",
    DOWNLOAD_BRAND: `Download ${BRAND}`,
    UPGRADE_BRAND: `Upgrade ${BRAND}`,
  }
};

const DEFAULT_CURRENCY = "USD";
const PLAN = {
  NONE: {unique: "unauthorized", name: MSG.PLAN.FREE, icon: null, size: 40},
  FREE: {unique: "free", name: MSG.PLAN.FREE, monthly: { currency: DEFAULT_CURRENCY, amount: 0 },
    yearly: { currency: DEFAULT_CURRENCY, amount: 0 }, iconActive: null, icon: null, size: 40
  },
  PRO: {unique: "pro", name: MSG.PLAN.PRO, monthly: { currency: DEFAULT_CURRENCY, amount: 2.99 },
    yearly: { currency: DEFAULT_CURRENCY, amount: 1.99 },
    benefit: { currency: DEFAULT_CURRENCY, amount: 12, percent: 30, months: 4 }, iconActive: EIcon.Pro, icon: EIcon.Pro, size: 30, offsetY: 0
  },
  TEAM: {unique: "team", name: MSG.PLAN.TEAM, monthly: { currency: DEFAULT_CURRENCY, amount: 3.99 },
    yearly: { currency: DEFAULT_CURRENCY, amount: 2.99, perUser: 2.99 },
    benefit: { currency: DEFAULT_CURRENCY, amount: 12, percent: 25, months: 3 }, iconActive: EIcon.Team, icon: EIcon.TeamGrayed, size: 40, offsetY: -5,
  }
};
const ROLE = {
  ADMIN: "admin",
  MEMBER: "member",
  GUEST: "guest",
  OWNER: "owner",
  NONE: "unauthorized"
};

const CURRENCY = { USD: "$", RUR: "₽", EUR: "€" };

const MIME_CATEGORIES = {
  video: [
    "video/vnd.joxi.screencast",
    "video/mpeg",
    "video/mp4",
    "video/ogg",
    "video/webm"
  ],
  image: [
    "image/vnd.joxi.screenshot",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
    "image/vnd.joxi.audioscreen",
  ],
  audio: [
    "image/vnd.joxi.audioscreen",
  ]
};

const DATE = {
  C: {
    MS_IN_DAY: 86400000, //1000*60*60*24
    MS_DAY_START: (ms) => Math.floor(ms/DATE.C.MS_IN_DAY)*DATE.C.MS_IN_DAY,
    MS_DAY_END: (ms) => DATE.C.MS_DAY_START(ms) + DATE.C.MS_IN_DAY - 1
  }
};

const CONTEXT_MENU = {
  CARD: "context-menu-card"
};

export { PORTAL, FIELD, IS_PROD, PLAN, MSG, DATE, ROLE, CURRENCY, ROUTE,
  EventPrefix, URLS, PUBSUB, OnePixel, MIME_CATEGORIES, EIcon, RX, LNG,
  LOCAL_PARAM, STORE_PARAM, URL_PARAM, CONTEXT_MENU, SETTINGS, PUBLIC_ID, PRODUCTS};
export * from './commands';
