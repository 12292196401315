import {action, Action, ActionOn, actionOn, computed, Computed, thunk, Thunk} from "easy-peasy";
import {IStoreModel} from 'store';
import {EIcon, FIELD, LOCAL_PARAM, ROUTE, URL_PARAM} from 'const';
import {
  concatSearchParams,
  MessageList,
  extractRouterParams,
  ValidationError,
  getFieldErrorsOnly,
  hasFieldErrors,
  getToken, Tip
} from 'utils';
import api from 'api';
import {ICloudpaymentsAsc, IInvoice, IMSG, IPaymentMethod, IPutAvatar, ISendPaymentMethod} from "../../types";

export interface IApiModel {
  //state
  //computed
  isGuest?: Computed<IStoreModel, boolean>;
  isLoggedIn?: Computed<IStoreModel, boolean>;
  //actions
  //thunks
  login?: Thunk<IStoreModel, string[]>;
  tempAuth: Thunk<IStoreModel, string>
  logout?: Thunk<IStoreModel>;
  getCountryLang?: Thunk<IStoreModel>;
  logout2?: Thunk<IStoreModel>;
  forgot?: Thunk<IStoreModel, string>;
  validate?: Thunk<IStoreModel, string>;
  change?: Thunk<IStoreModel, string[]>;
  register?: Thunk<IStoreModel, string[]>;
  confirm?: Thunk<IStoreModel, string>;
  resend?: Thunk<IStoreModel, string>;
  putAvatar?: Thunk<IStoreModel, IPutAvatar>;
  sendInvoice?: Thunk<IStoreModel, IInvoice>;
  getInvoice?: Thunk<IStoreModel>;
  sendPaymentMethod?: Thunk<IStoreModel, ISendPaymentMethod>;
  removePaymentMethod?: Thunk<IStoreModel>;
  getPaymentMethod?: Thunk<IStoreModel>;
  //listeners
}
export const apiModel: IApiModel = {
  //state
  //computed
  isGuest: computed(state => {
    return !getToken() && !state.cid;
  }),
  isLoggedIn: computed(state => {
    const loggedIn = !!getToken();
    return loggedIn;
  }),
  //actions

  //thunks
  login: thunk(async (actions, payload: [string?, string?], {getState}) => {
    const state = getState();
    if (state.isLoggedIn) {
      await actions.switchToHome(true);
    }
    const [login, password] = payload;
    const res = await api.login.send(login, password);
    if (res instanceof MessageList) {
      await actions.setMessages(res);
      return false;
    } else {
      return res.result;
    }
  }),
  tempAuth: thunk(async (actions, payload, {getState}) => {
    const res = await api.token.send(payload);
    if (res instanceof MessageList) {
      await actions.setMessages(res);
      return false;
    } else {
      return res.result;
    }
  }),
  logout: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    console.log({actions, state})
    if (!state.isLoggedIn) return;
    await actions.screenshots.reset();
    const res = await api.logout.send();
    if (res instanceof MessageList) {
      await actions.setMessages(res);
      return false;
    } else {
      localStorage.setItem(LOCAL_PARAM.CID, "")
      await actions.setTid("");
      await actions.resetApp();
      await actions.switchToHome(true);
      return true;
    }
  }),
  getCountryLang: thunk(async (actions, payload, {getState}) => {
    const geoData = await api.getCountry.send();
    if (geoData) {
      if (geoData.country === 'RU') {
        actions.setLanguage({unique: geoData.country.toLowerCase()})
      } else {
        actions.setLanguage({unique: 'en'})
      }
    } else {
      actions.setLanguage({unique: 'en'})
      console.log('get country error')
    }
  }),
  logout2: thunk(async (actions, payload) => actions.logout(payload)),
  forgot: thunk(async (actions, email: string="", {getState}) => {
    const state = getState();
    const res = await api.forgot.send(email);
    if (res instanceof MessageList) {
      await actions.setMessages((res));
      return false;
    } else {
      return true;
    }
  }),
  validate: thunk(async (actions, token: string="") => {
    const res = await api.validate.send(token);
    if (res instanceof MessageList) {
      //await actions.setMessages(res);
      return false;
    } else {
      return true;
    }
  }),
  change: thunk(async (actions, payload: [string?, string?]) => {
    const [token, password] = payload;
    const res = await api.change.send(token, password);
    if (res instanceof MessageList) {
      if (hasFieldErrors(res)) {
        await actions.setMessages(getFieldErrorsOnly(res));
        return false;
      } else return null;
    } else {
      return true;
    }
  }),
  register: thunk(async (actions, payload: [string?, string?]) => {
    const [email, password] = payload;
    const res = await api.register.send(email, password);
    if (res instanceof MessageList) {
      await actions.setMessages(res);
      return false;
    } else {
      return true;
    }
  }),
  confirm: thunk(async (actions, token="") => {
    const res = await api.confirm.send(token);
    if (res instanceof MessageList) {
      //await actions.setMessages(res);
      return false;
    } else {
      //await actions.setTid(res.result);
      return res.result;
    }
  }),
  resend: thunk(async (actions, email="") => {
    const res = await api.resend.send(email);
    if (res instanceof MessageList) {
      //await actions.setMessages(res);
      return false;
    } else {
      return true;
    }
  }),
  putAvatar: thunk(async (actions, {link, image, MSG}) => {
    const res = await api.putAvatar.send({link, image});
    if (res instanceof MessageList) {
      //await actions.setMessages(res);
      actions.pushCommonMessage(
        new Tip(null, MSG.SOCKET[64].description, EIcon.CloseCircle)
      );
      return false;
    } else {
      actions.pushCommonMessage(
        new Tip(null, MSG.SOCKET[65].description, EIcon.Check)
      );
      await actions.getProfile();
      return true;
    }
  }),
  sendInvoice: thunk(async (actions, {item_name, quantity, cryptogram, cardholder_name}, {getState}) => {
    const token = getToken()
    const state = getState();
    // amount: 400
    // created_at: "2021-08-11T10:22:08.104777Z"
    // currency: "USD"
    // error_reason: null
    // id: 7
    // item: "plan:pro"
    // payed_at: null
    // quantity: 1
    // secure3d_data: {transaction_id: 799111645, pa_req: "+/eyJNZXJjaGFudE5hbWUiOm51bGwsIkZpcnN0U2l4IjoiNDI0…tZXJOYW1lIjpudWxsLCJDdWx0dXJlTmFtZSI6InJ1LVJVIn0=", acs_url: "https://demo.cloudpayments.ru/acs", term_url: "http://api-beta.joxi.ru/api/v2/billing/invoice/7/finish_3ds/"}
    // status: "created"
    // type: "normal"
    const res = await api.postInvoice.send(item_name, quantity, cryptogram, cardholder_name, token);
    const root = state.router.root
    const path = state.router.path
    const replace = actions.router.replace
    const currentPage = `${(root === 'http://localhost' ? 'http://localhost:3000' : root) + path}`
    if (res instanceof MessageList) {
      console.log(res, 'error')
      return false;
    } else {
      if (res?.error) {
        const params = `?${URL_PARAM.REASON}=${res.error}&${URL_PARAM.RESULT}=error`
        replace({to: `${currentPage}${params}`})
      }
      if (res?.status === 'created') {
        const setCloudpaymentsAsc = actions.setCloudpaymentsAsc
        setCloudpaymentsAsc({...res.secure3d_data, product: item_name})
      }
      if (res?.status === 'payed') {
        const params = res.error_reason ? `?${URL_PARAM.REASON}=${res.error_reason}&${URL_PARAM.RESULT}=error&product=${res.item}` : `?${URL_PARAM.RESULT}=success&product=${res.item}`
        replace({to: `${currentPage}${params}`})
      }
      return true;
    }
    
  }),
  
  getInvoice: thunk(async (actions) => {
    
    const token = getToken()
    const res = await api.getInvoice.send(token);
   
    if (res instanceof MessageList) {
      return false;
    } else {
      actions.setInvoiceList(res)
      return true;
    }
  }),
  sendPaymentMethod: thunk(async (actions, {cryptogram, cardholder_name}) => {
    const token = getToken()
    const res = await api.postPaymentMethod.send(cryptogram, cardholder_name, token);
  }),
  removePaymentMethod: thunk(async (actions) => {
    const token = getToken()
    const res = await api.removePaymentMethod.send(token);
    if (res instanceof MessageList) {
      return false;
    } else {
      actions.setPaymentMethod(null)
      return true;
    }
  }),
  getPaymentMethod: thunk(async (actions) => {
    const token = getToken()
    const res = await api.getPaymentMethod.send(token);
    if (res instanceof MessageList) {
      
      return false;
    } else {
      actions.setPaymentMethod(res)
      return true;
    }
  }),
  //listeners
};
