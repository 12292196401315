import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  EIconSize,
  ETextAlign,
  ETextColor,
  ETextSize,
  Icon,
  ITextBoxProps,
  SpaceBox,
  TextBox,
  EIcon,
  ETextWeight
} from 'components';
import {cssSize, extractTextProps, ifCond} from 'utils';

export interface IPanel extends TThemed {
  caption?: ITextBoxProps;
  captionKind?: EPanelCaptionKind;
  icon?: keyof typeof EIcon;
  iconSize?: EIconSize | number;
  iconOffsetY?: number;
  width?: EPanelWidth | string | number;
  height?: string | number;
  padding?: boolean | number | string;
  align?: ETextAlign;
  noShadow?: boolean;
  noRoundBorders?: boolean;
  mobileCenter?: boolean;
  onClose?: Function;
  extraAction?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum EPanelWidth {
  SM = 375, AUTO = "auto", MOB = 345
}

export const Panel = React.forwardRef((props: IPanel, ref) => {
  
  const { caption, captionKind=EPanelCaptionKind.LG, icon, iconSize=20, iconOffsetY=0,
    align=ETextAlign.CENTER, padding=false, width=375, onClose, noShadow=false, noRoundBorders=false, mobileCenter,
    darkMode=false, demo=false, if:_if=true, extraAction, children=null, ...rest } = props;
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWPanel ref={ref} noRounded={noRoundBorders} darkMode={darkMode} padding={padding} align={align} width={width} noShadow={noShadow} {...rest}>
      <div tw="flex w-full h-auto">
        {icon &&
        <TWPanelIcon offsetY={iconOffsetY}>
          <Icon icon={icon} size={iconSize}/>
        </TWPanelIcon>}
        <TWHeader mobileCenter={mobileCenter}>
          <PanelHeader text={caption} kind={captionKind}>
            <SpaceBox if={!!extraAction} inline width="stretch"/>
            {extraAction}
            <SpaceBox if={!!extraAction} inline width={22}/>
          </PanelHeader>
        </TWHeader>
        {onClose &&
        <TWCloseButton noRounded={noRoundBorders} onClick={onClose} captionKind={captionKind}>
          <Icon icon="CloseGray" size={12}/>
        </TWCloseButton>}
      </div>
      {children}
    </TWPanel>
  );
});

const TWHeader = styled("div")(({mobileCenter}) => [
  tw`w-full flex justify-between`,
  mobileCenter && tw`mobile:text-center mobile:block `,
  css`` ]);
const TWPanel = styled("div")(({ theme, darkMode, width, padding, align, height, noShadow, noRounded }) => [
  tw`relative bg-white p-30p`,
  !noRounded && tw`rounded-md w-full`,
  noRounded && tw`desktop:rounded-md tablet:rounded-md`,
  !height ? tw`h-auto` : css`${cssSize(height, "height")}`,
  tw`transition-perimeter duration-300`,
  !noShadow && (darkMode ? tw`shadow-panel-dark` : tw`shadow-panel-light`),
  css`
    width: ${+width===width ? width+"px" : width};
    ${!!padding===padding
    ? ( padding===true
        ? cssSize(30, "padding")
        : ""
    )
    : cssSize(padding, "padding")
  }
    ${
  ({
      [ETextAlign.CENTER]: tw`mx-auto`,
      [ETextAlign.LEFT]: tw`mr-auto`,
      [ETextAlign.RIGHT]: tw`ml-auto`,
      [ETextAlign.ADJUST]: tw`w-full`,
      none: ""
    })[align || "none"] || ""}
  `
]);
const TWPanelIcon = styled("div")(({ offsetY }) => [
  tw`relative float-left pr-15p text-right w-auto`,
  !!offsetY && css`margin-top: ${offsetY}px;`,
  !!offsetY && css`margin-bottom: ${offsetY}px;`,
]);
const TWCloseButton = styled("div")(({ theme, darkMode, disabled, captionKind, noRounded }) => [
  tw`absolute float-right pl-6p right-30p cursor-pointer text-right w-20p`,
  captionKind!==EPanelCaptionKind.SM && tw`mt-2p`,  ]);

////////////////////////////////////////////////////////////////////////////////

export interface IPanelHeader extends TThemed {
  text?: ITextBoxProps;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  align?: ETextAlign;
  [prop: string]: any;
}
export enum EPanelCaptionKind {
  SM = 16,
  LG = 19,
  LGB = 20
}
export const PanelHeader = (props: IPanelHeader) => {
  const { icon, children, align, text, kind=EPanelCaptionKind.LG, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const TextProps = {
    // [EPanelCaptionKind.LG]: { size: ETextSize.LARGER, height: 26},
    // [EPanelCaptionKind.LGB]: { size: ETextSize.LARGER, height: 26, weight: ETextWeight.SEMIBOLD},
    // [EPanelCaptionKind.SM]: { size: ETextSize.LARGE, height: 22, weight: ETextWeight.SEMIBOLD},
    [EPanelCaptionKind.LG]: { size: ETextSize.LARGER},
    [EPanelCaptionKind.LGB]: { size: ETextSize.LARGER, weight: ETextWeight.SEMIBOLD},
    [EPanelCaptionKind.SM]: { size: ETextSize.LARGE, weight: ETextWeight.SEMIBOLD},
  };
  const CaptionProps = {
    ...extractTextProps(text, true)
  };
  if ( !ifCond(_if) || !text ) return null;
  return (
    <TWPanelHeader align={align} kind={kind}>
      <TextBox {...TextProps[kind]} color={ETextColor.PRIMARY} {...CaptionProps}/>{children}
    </TWPanelHeader>
  );
};
const TWPanelHeader = styled("div")(({ theme, darkMode, disabled, align, kind }) => [
  tw`inline-flex flex-auto`,
  // kind === EPanelCaptionKind.LG && tw`h-26p text-larger`,
  // kind === EPanelCaptionKind.SM && tw`h-22p text-large font-semibold`,
  kind === EPanelCaptionKind.LG && tw`text-larger`,
  kind === EPanelCaptionKind.SM && tw`text-large font-semibold`,
  align && css`
    display: block;
    text-align: ${align};
    flex:none;
    `,
  css``
]);




////////////////////////////////////////////////////////////////////////////////

export interface IPanelFooter extends TThemed {
  children?: React.ReactNode;
  padding?: boolean | number | string;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export const PanelFooter = (props: IPanelFooter) => {
  const { children, padding=true, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const ref = React.useRef();
  const spaceRef = React.useRef();
  React.useLayoutEffect(() => {
    if (ref.current && spaceRef.current) {
      (spaceRef.current as HTMLElement).style.height=(ref.current as HTMLElement).style.height;
    }
  }, [ref, spaceRef, ref.current, spaceRef.current]);
  if ( !ifCond(_if) || !children ) return null;
  return (<>
      <TWPanelFooter ref={ref} padding={padding}>
        {children}
      </TWPanelFooter>
      <SpaceBox ref={spaceRef} height={81}/>
    </>
  );
};
const TWPanelFooter = styled("div")(({ theme, darkMode, disabled, padding }) => [
  tw`absolute left-0 bottom-0 w-full`,
  css`
    ${!!padding===padding
    ? ( padding===true ? tw`px-30p mb-30p` : "" )
    :  `
        margin-bottom: ${cssSize(padding)};
        padding-left: ${cssSize(padding)};
        padding-right: ${cssSize(padding)};
      `
  }
  `
]);
