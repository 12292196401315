module.exports = {
  purge: [],
  target: 'relaxed',
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    screens: {
      mobile: {max: '767px'},
      tablet: {max: '1024px', min: '768px'},
      desktop: {min: '1025px'},
      hone: {max: '657px'},
      htwo: {min: '658px', max: '960px'},
      hthree: {min: '961px', max: '1301px'},
      hfour: {min: '1302px', max: '1725px'},
      hfive: {min: '1726px', max: '2148px'},
      hsix: {min: '2149px', max: '2592px'},
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      inherit: 'inherit',
      unset: 'unset',
      black: '#000',
      white: '#fff',
      ghost: 'rgba(255,255,255,0.85)',
      twilight: 'rgba(26, 30, 35, 0.85)',

      'coral': '#ff4b00',/*тэг 1*/
      'fox': '#ff8f00',/*тэг 2*/
      'gold': '#c5a900',/*тэг 3*/
      'forest': '#5fa400',/*тэг 4*/
      'grass': '#3ab600',/*тэг Paid*/
      'ashen': '#7a8893',/*тэг Unpaid*/
      'jade': '#00ac8d',/*тэг 5*/
      'cyan': '#0098ac',/*тэг 6*/
      'royal': '#4248ec',/*тэг 7*/
      'violet': '#8219e8',/*тэг 8*/
      'orchid': '#d200a8',/*тэг 9*/
      'crimson': '#d2002a',/*тэг 10, текст инпута с ошибкой*/
      'sky': '#0070e9',
      'cornflower': '#62aeff',
      'cinder': '#888f9a',
      error: '#ce0029',
      success: '#0f9d00',
      info: '#f5f5f7',
      accent: {
        100: '#f5f5f7',/*Выделение и основной фон*/
        101: 'rgba(245,245,247,0.5)',
        150: '#d5dade',
        /*150: '#b8c3cc',*/
        200: '#b3c0cb',/*Неактивная кнопка*/
        300: '#afb8c0',/*Обводка*/
        400: '#7a8893',/*Иконки*/
        450: '#3d414a',/*Not available panel*/
        500: '#2d92ff',
        501: '#f2f8ff',
        600: '#007aff',
        700: '#0070e9',/*Основной цвет, акцент*/
        800: '#005ec5',
        803: 'rgba(0, 94, 197, .3)',
        804: 'rgba(0, 112, 233, 0.25)',
        805: 'rgba(0, 112, 233, 0.7)',
        900: '#2c3036'
      },
      button: {
        bfd: '#b3c0cb',/*blue filled disabled*/
        bfdt: '#ffffff',/*blue filled disabled text*/
        bf: '#0070e9',/*blue filled*/
        bft: '#ffffff',/*blue filled text*/
        bfh: '#007aff',/*blue filled hover/focus*/
        bfht: '#ffffff',/*blue filled hover/focus text*/
        bfa: '#007aff',/*blue filled active*/
        bfar: '#2d92ff',/*blue filled active ripple*/
        bfat: '#ffffff',/*blue filled active text*/

        bod: '#b3c0cb',/*blue outlined disabled*/
        bodt: '#7a8893',/*blue outlined disabled text*/
        bo: '#0070e9',/*blue outlined*/
        bot: '#0070e9',/*blue outlined*/
        boh: '#005fc6',/*blue outlined hover/focus*/
        boht: '#005ec5',/*blue outlined hover/focus text*/
        boa: '#0070e9',/*blue outlined active*/
        boar: '#f2f8ff',/*blue outlined active ripple*/
        boat: '#0070e9',/*blue outlined active text*/

        rfd: '#b3c0cb',/*red filled disabled*/
        rfdt: '#ffffff',/*red filled disabled text*/
        rf: '#ce0029',/*red filled*/
        rft: '#ffffff',/*red filled*/
        rfh: '#f51b47',/*red filled hover/focus*/
        rfht: '#ffffff',/*red filled hover/focus text*/
        rfa: '#f51b47',/*red filled active*/
        rfar: '#df0f38',/*red filled active ripple*/
        rfat: '#ffffff',/*red filled active text*/

        god: '#ccd3d9',/*gray outlined disabled*/
        godt: '#7a8893',/*gray outlined disabled text*/
        go: '#ccd3d9',/*gray outlined*/
        got: '#5f6c76',/*gray outlined*/
        goh: '#afb8c0',/*gray outlined hover/focus*/
        goht: '#5f6c76',/*gray outlined hover/focus text*/
        goa: '#afb8c0',/*gray outlined active*/
        goar: '#f5f5f7',/*gray outlined active ripple*/
        goat: '#5f6c76',/*gray outlined active text*/
        bl: '#353d43',/*gray outlined active text*/
      },
      light: {/*Светлая тема*/
        secondary: '#5f6c76',/*Вспомогательный текст*/
        primary: '#353d43',/*Основной текст*/
      },
      dark: {/*Тёмная тема*/
        secondary: '#5f6c76',/*Вспомогательный текст*/
        primary: '#bfc5ce',/*Основной текст*/
      },
      gray: {
        100: '#f7fafc',
        200: '#f5f5f7',/*Выделение и основной фон*/
        290: '#e8e8e8',
        300: '#e2e8f0',

        350: '#ccd3d9',/*Обводка полей и кнопок*/
        400: '#cbd5e0',
        410: '#cacfd8',
        450: '#afb8c0',
        500: '#a0aec0',
        505: '#9ca5ad',
        510: '#979797',
        520: '#888f9a',
        //530: '#818289',
        550: '#7a8893',
        600: '#718096',
        650: '#5f6c76',/*Вспомогательный текст*/
        700: '#4a5568',
        750: '#353d43',/*Основной текст*/
        800: '#2d3748',
        850: '#262e34',/*Основной текст селекта и прямоугольного инпута???*/
        900: '#1a202c'
      },
      red: {
        100: '#fff5f5',
        200: '#fed7d7',
        300: '#feb2b2',
        400: '#fc8181',
        500: '#f56565',
        600: '#e53e3e',
        700: '#c53030',
        800: '#9b2c2c',
        900: '#742a2a',
      },
      orange: {
        100: '#fffaf0',
        200: '#feebc8',
        300: '#fbd38d',
        400: '#f6ad55',
        500: '#ed8936',
        600: '#dd6b20',
        700: '#c05621',
        800: '#9c4221',
        900: '#7b341e',
      },
      yellow: {
        100: '#fffff0',
        200: '#fefcbf',
        300: '#faf089',
        400: '#f6e05e',
        500: '#ecc94b',
        600: '#d69e2e',
        650: '#c5a900',
        700: '#b7791f',
        800: '#975a16',
        900: '#744210',
      },
      green: {
        100: '#f0fff4',
        200: '#c6f6d5',
        300: '#9ae6b4',
        400: '#68d391',
        500: '#48bb78',
        600: '#38a169',
        700: '#2f855a',
        800: '#276749',
        900: '#22543d',
      },
      teal: {
        100: '#e6fffa',
        200: '#b2f5ea',
        300: '#81e6d9',
        400: '#4fd1c5',
        500: '#38b2ac',
        600: '#319795',
        700: '#2c7a7b',
        800: '#285e61',
        900: '#234e52',
      },
      blue: {
        100: '#ebf8ff',
        200: '#bee3f8',
        300: '#90cdf4',
        400: '#63b3ed',
        500: '#4299e1',
        600: '#3182ce',
        700: '#2b6cb0',
        800: '#2c5282',
        900: '#2a4365',
      },
      indigo: {
        100: '#ebf4ff',
        200: '#c3dafe',
        300: '#a3bffa',
        400: '#7f9cf5',
        500: '#667eea',
        600: '#5a67d8',
        700: '#4c51bf',
        800: '#434190',
        900: '#3c366b',
      },
      purple: {
        100: '#faf5ff',
        200: '#e9d8fd',
        300: '#d6bcfa',
        400: '#b794f4',
        500: '#9f7aea',
        600: '#805ad5',
        700: '#6b46c1',
        800: '#553c9a',
        900: '#44337a',
      },
      pink: {
        100: '#fff5f7',
        200: '#fed7e2',
        300: '#fbb6ce',
        400: '#f687b3',
        500: '#ed64a6',
        600: '#d53f8c',
        700: '#b83280',
        800: '#97266d',
        900: '#702459',
      },
    },
    fontSize: {
      xsmall: '11px',
      small: '12px',
      normal: '13px',
      medium: '14px',
      large: '16px',
      larger: '19px',
      xlarger: '21px',
      xxlarger: '32px',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
    },
    spacing: {
      px: '1px',
      '1p': '1px',
      '2p': '2px',
      '5/2p': '2.5px',
      '3p': '3px',
      '4p': '4px',
      '5p': '5px',
      '6p': '6px',
      '7p': '7px',
      '15/2p': '7.5px',
      '8p': '8px',
      '9p': '9px',
      '10p': '10px',
      '11p': '11px',
      '12p': '12px',
      '14p': '14px',
      '15p': '15px',
      '16p': '16px',
      '17p': '17px',
      '18p': '18px',
      '19p': '19px',
      '20p': '20px',
      '21p': '21px',
      '22p': '22px',
      '24p': '24px',
      '25p': '25px',
      '26p': '26px',
      '28p': '28px',
      '30p': '30px',
      '32p': '32px',
      '34p': '34px',
      '36p': '36px',
      '40p': '40px',
      '42p': '42px',
      '48p': '48px',
      '49p': '49px',
      '50p': '50px',
      '51p': '51px',
      '52p': '52px',
      '54p': '54px',
      '55p': '55px',
      '60p': '60px',
      '70p': '70px',
      '80p': '80px',
      '90p': '90px',
      '100p': '100px',
      '102p': '102px',
      '104p': '104px',
      '110p': '110px',
      '120p': '120px',
      '150p': '150px',
      '160p': '160px',
      '180p': '180px',
      '200p': '200px',
      '220p': '220px',
      '250p': '250px',
      '260p': '260px',
      '270p': '270px',
      '300p': '300px',
      '320p': '320px',
      '375p': '375px',
      '400p': '400px',
      '500p': '500px',
      '670p': '670px',
      '0': '0',
      '1/2': '0.125rem',
      '1': '0.25rem',
      '3/2': '0.375rem',
      '2': '0.5rem',
      '5/2': '0.625rem',
      '3': '0.75rem',
      '7/2': '0.875rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '13': '3.25rem',
      '14': '3.5rem',
      '15': '3.75rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '32': '8rem',
      '40': '10rem',
      '48': '12rem',
      '56': '14rem',
      '64': '16rem',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      '3/100': '3%',
      'quarter-2': '23%',
      'half-2': '48%',
      quarter: '25%',
      half: '50%',
      full: '100%',
      double: '200%',
      '1xs': '20rem',
      '1sm': '24rem',
      '1md': '28rem',
      '1lg': '32rem',
      '1xl': '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem'
    },
    inset: (theme, {breakpoints, negative}) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
      ...theme('fontSize'),
      "screen-y": '100vh',
      "screen-x": '100vw',
    }),
    margin: (theme, {negative}) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    padding: theme => theme('spacing'),
    maxHeight: (theme, {breakpoints}) => ({
      none: 'none',
      screen: '100vh',
      ...theme('spacing'),
      ...theme('fontSize'),
      fhd: '1080px',
      9999: '9999px',
    }),
    maxWidth: (theme, {breakpoints}) => ({
      none: 'none',
      screen: '100vw',
      ...theme('spacing'),
      ...breakpoints(theme('screens')),
    }),
    minHeight: (theme, {breakpoints}) => ({
      screen: '100vh',
      ...theme('spacing'),
      ...theme('fontSize'),
    }),
    minWidth: (theme, {breakpoints}) => ({
      screen: '100vw',
      ...theme('spacing'),
      ...breakpoints(theme('screens')),
    }),
    height: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      screen: '100vh',
      ...theme('fontSize'),
    }),
    width: (theme, {breakpoints}) => ({
      auto: 'auto',
      ...theme('spacing'),
      screen: '100vw',
      fit: 'fit-content',
      min: 'min-content',
      max: 'max-content',
      ...breakpoints(theme('screens')),
    }),
    backgroundColor: theme => theme('colors'),
    backgroundOpacity: theme => theme('opacity'),
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
      half: '50% 50%',
    },
    borderColor: theme => ({
      ...theme('colors'),
      default: theme('colors.gray.300', 'currentColor'),
    }),
    borderOpacity: theme => theme('opacity'),
    borderRadius: {
      none: '0',
      xxs: '3px',
      xs: '5px',
      sm: '10px',
      default: '5px',
      md: '15px',
      lg: '20px',
      xl: '35px',
      /*sm: '0.125rem',
      default: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',*/
      half: '50%',
      full: '9999px',
    },
    borderWidth: {
      default: '1px',
      '0': '0',
      '3/2': '1.5px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
    },
    boxShadow: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      'header-light': '0 1px 0 0 #e5eaed',
      'header-dark': '0 1px 0 0 #393d44',
      'card-light': '0 1px 6px 0 rgba(90, 90, 125, 0.1)',
      'card-light-hover': '0 10px 40px 0 rgba(0, 0, 0, 0.2)',
      'card-gradient': '0 2px 50px 0 rgba(90, 90, 125, 0.02)',
      'panel-dark': '0 5px 30px 0 rgba(0, 11, 43, 0.5)',
      'panel-light': '0 5px 25px 0 rgba(90, 90, 125, 0.2)',
      'snack-dark': '0 10px 25px 0 rgba(0, 0, 0, 0.5)',
      'snack-light': '0 5px 15px 0 rgba(0, 2, 33, 0.15)',
      'msnack-dark': '0 1px 0 0 #393d44',
      'msnack-light': '0 1px 0 0 #e5eaed',
      'menu-light': '0 5px 30px 0 rgba(0, 11, 43, 0.15)',
      'menu-dark': '0 10px 25px 0 rgba(0, 0, 0, 0.5)',
      'context-menu': '0 5px 30px 0 rgba(0, 0, 0, 0.15)',
      'slider-range': '0 1px 5px 0 rgba(0, 8, 34, 0.2)',
      'calendar': '0 5px 30px 0 rgba(0, 11, 43, 0.15)',
      'combobox': '0 5px 25px 0 rgba(0, 5, 32, 0.1)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      none: 'none',
    },
    container: {},
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      "zoom-in": "zoom-in",
      "zoom-out": "zoom-out",
      'not-allowed': 'not-allowed',
    },
    divideColor: theme => theme('borderColor'),
    divideOpacity: theme => theme('borderOpacity'),
    divideWidth: theme => theme('borderWidth'),
    fill: {
      current: 'currentColor',
    },
    flex: {
      '1': '1 1 0%',
      auto: '1 1 auto',
      min: '1 0 auto',
      initial: '0 1 auto',
      none: 'none',
    },
    flexGrow: {
      '0': '0',
      default: '1',
    },
    flexShrink: {
      '0': '0',
      default: '1',
    },
    fontFamily: {
      sans: ['"Open Sans"', 'Roboto', 'system-ui',
        '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
        '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif',
        '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
    },
    fontWeight: {
      hairline: '100',
      thin: '200',
      light: '300',
      regular: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    lineHeight: {
      none: '1',
      slight: '1.2',
      tight: '1.3',
      snug: '1.4',
      normal: '1.5',
      relaxed: '1.625',
      loose: '2',
      '1': '1',
      '2': '2',
      '3': '.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    opacity: {
      '0': '0',
      '10': '0.1',
      '25': '0.25',
      '30': '0.3',
      '50': '0.5',
      '75': '0.75',
      '85': '0.85',
      '90': '0.9',
      '100': '1',
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
    },
    placeholderColor: theme => theme('colors'),
    placeholderOpacity: theme => theme('opacity'),
    space: (theme, {negative}) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    stroke: {
      current: 'currentColor',
    },
    strokeWidth: {
      '0': '0',
      '1': '1',
      '2': '2',
    },
    textColor: theme => theme('colors'),
    textOpacity: theme => theme('opacity'),
    zIndex: {
      auto: 'auto',
      '0': '0',
      '5': '5',
      '10': '10',
      '15': '15',
      '20': '20',
      '30': '30',
      '40': '40',
      '50': '50',
      '60': '60',
      '70': '70',
      '80': '80',
      '90': '90',
      '100': '100',
      '9999': '9999'
    },
    gap: theme => theme('spacing'),
    gridTemplateColumns: {
      none: 'none',
      'auto': 'auto',
      'full': '100%',
      '1a1': 'minmax(0, 1fr) auto minmax(0, 1fr)',
      '1a': 'minmax(0, 1fr) auto',
      'a1': 'auto minmax(0, 1fr)',
      'a1a': 'auto minmax(0, 1fr) auto',
      'aa1': 'auto auto minmax(0, 1fr)',
      '1aa': 'minmax(0, 1fr) auto auto',
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
      '7': 'repeat(7, minmax(0, 1fr))',
      '8': 'repeat(8, minmax(0, 1fr))',
      '9': 'repeat(9, minmax(0, 1fr))',
      '10': 'repeat(10, minmax(0, 1fr))',
      '11': 'repeat(11, minmax(0, 1fr))',
      '12': 'repeat(12, minmax(0, 1fr))',
      'cards1': 'repeat(1, minmax(256px, 320px))',
      'cards2': 'repeat(2, minmax(256px, 320px))',
      'cards3': 'repeat(3, minmax(256px, 320px))',
      'cards4': 'repeat(4, minmax(256px, 320px))',
      'cards5': 'repeat(5, minmax(256px, 320px))',
      'cards6': 'repeat(6, minmax(256px, 320px))',
      'cards7': 'repeat(7, minmax(256px, 320px))',
      '1xa': 'repeat(1,auto)',
      '2xa': 'repeat(2,auto)',
      '3xa': 'repeat(3,auto)',
      '4xa': 'repeat(4,auto)',
      '5xa': 'repeat(5,auto)',
      '6xa': 'repeat(6,auto)',
      '7xa': 'repeat(7,auto)',
      '8xa': 'repeat(8,auto)',
      '9xa': 'repeat(9,auto)',
      '10xa': 'repeat(10,auto)',
      '11xa': 'repeat(11,auto)',
      '12xa': 'repeat(12,auto)',
    },
    gridColumn: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
    },
    gridColumnStart: {
      auto: 'auto',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
    },
    gridColumnEnd: {
      auto: 'auto',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
    },
    gridTemplateRows: {
      none: 'none',
      'auto': 'auto',
      'full': '100%',
      '1a1': 'minmax(0, 1fr) auto minmax(0, 1fr)',
      '1a': 'minmax(0, 1fr) auto',
      'a1': 'auto minmax(0, 1fr)',
      'aa1': 'auto auto minmax(0, 1fr)',
      '1aa': 'minmax(0, 1fr) auto auto',
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
    },
    gridRow: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
    },
    gridRowStart: {
      auto: 'auto',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
    },
    gridRowEnd: {
      auto: 'auto',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
    },
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'left-center': 'left center',
      'top-left': 'top left',
      '55p-55p': '55px 55px',
    },
    scale: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
    },
    rotate: {
      '-180': '-180deg',
      '-135': '-135deg',
      '-90': '-90deg',
      '-45': '-45deg',
      '-20': '-20deg',
      '0': '0',
      '20': '20deg',
      '45': '45deg',
      '50': '50deg',
      '90': '90deg',
      '135': '135deg',
      '180': '180deg',
    },
    translate: (theme, {negative}) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
      '-full': '-100%',
      '-half': '-50%',
    }),
    skew: {
      '-12': '-12deg',
      '-6': '-6deg',
      '-3': '-3deg',
      '0': '0',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg',
    },
    transitionProperty: {
      none: 'none',
      all: 'all',
      default: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      top: 'top',
      bottom: 'bottom',
      left: 'left',
      right: 'right',
      pos: 'top, left, right, bottom',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform',
      perimeter: 'width, height',
    },
    transitionTimingFunction: {
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    transitionDuration: {
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
    },
    transitionDelay: {
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
    },
    darkSelector: '.dark-mode',
  },
  variants: {
    accessibility: ['responsive', 'focus'],
    alignContent: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['responsive', 'hover', 'focus'],
    backgroundOpacity: ['responsive', 'hover', 'focus'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderCollapse: ['responsive'],
    borderColor: ['responsive', 'hover', 'focus'],
    borderOpacity: ['responsive', 'hover', 'focus'],
    borderRadius: ['responsive'],
    borderStyle: ['responsive'],
    borderWidth: ['responsive'],
    boxShadow: ['responsive', 'hover', 'focus', 'dark', 'dark-hover'],
    boxSizing: ['responsive'],
    cursor: ['responsive'],
    display: ['responsive'],
    divideColor: ['responsive'],
    divideOpacity: ['responsive'],
    divideWidth: ['responsive'],
    fill: ['responsive'],
    flex: ['responsive'],
    flexDirection: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    flexWrap: ['responsive'],
    float: ['responsive'],
    clear: ['responsive'],
    fontFamily: ['responsive'],
    fontSize: ['responsive'],
    fontSmoothing: ['responsive'],
    fontStyle: ['responsive'],
    fontWeight: ['responsive', 'hover', 'focus'],
    height: ['responsive'],
    inset: ['responsive'],
    justifyContent: ['responsive'],
    letterSpacing: ['responsive'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['responsive'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    objectFit: ['responsive'],
    objectPosition: ['responsive'],
    opacity: ['responsive', 'hover', 'focus'],
    order: ['responsive'],
    outline: ['responsive', 'focus'],
    overflow: ['responsive'],
    padding: ['responsive'],
    placeholderColor: ['responsive', 'focus'],
    placeholderOpacity: ['responsive', 'focus'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    resize: ['responsive'],
    space: ['responsive'],
    stroke: ['responsive'],
    strokeWidth: ['responsive'],
    tableLayout: ['responsive'],
    textAlign: ['responsive'],
    textColor: ['responsive', 'hover', 'focus'],
    textOpacity: ['responsive', 'hover', 'focus'],
    textDecoration: ['responsive', 'hover', 'focus'],
    textTransform: ['responsive'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    width: ['responsive'],
    wordBreak: ['responsive'],
    zIndex: ['responsive'],
    gap: ['responsive'],
    gridAutoFlow: ['responsive'],
    gridTemplateColumns: ['responsive'],
    gridColumn: ['responsive'],
    gridColumnStart: ['responsive'],
    gridColumnEnd: ['responsive'],
    gridTemplateRows: ['responsive'],
    gridRow: ['responsive'],
    gridRowStart: ['responsive'],
    gridRowEnd: ['responsive'],
    transform: ['responsive'],
    transformOrigin: ['responsive'],
    scale: ['responsive', 'hover', 'focus'],
    rotate: ['responsive', 'hover', 'focus'],
    translate: ['responsive', 'hover', 'focus'],
    skew: ['responsive', 'hover', 'focus'],
    transitionProperty: ['responsive'],
    transitionTimingFunction: ['responsive'],
    transitionDuration: ['responsive'],
    transitionDelay: ['responsive'],
  },
  corePlugins: {},
  plugins: [
    require('@danestves/tailwindcss-darkmode')()
  ],
};
