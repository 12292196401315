import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { Logo } from 'components';
import { useStoreState, useStoreActions } from 'store';
import {ifCond} from 'utils';
import {PORTAL} from 'const';

export interface IHeader extends TThemed {
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Header = React.forwardRef((props: IHeader, ref) => {
  const { children, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const headerOpened = useStoreState(s=>s.screenshots.headerOpened);
  if ( !ifCond(_if) ) return null;
  return (
    <TWHeader ref={ref} headerOpened={headerOpened} darkMode={darkMode} {...rest}>
      {children}
    </TWHeader>
  );
});

const TWHeader = styled("header")(({ theme, darkMode, disabled, headerOpened }) => [
  tw`flex fixed content-start items-start z-50`,
  darkMode ? tw`bg-twilight shadow-header-dark` : tw`bg-ghost shadow-header-light`,
  tw`w-full h-auto px-30p py-5p top-0 left-0`,
  tw`mobile:px-15p  tablet:px-15p`,
  headerOpened && tw`mobile:min-h-screen mobile:bg-white`,
  css`
    grid-auto-columns: auto;
    grid-column-gap: 20px;
    -webkit-backdrop-filter: blur(${darkMode ? 5 : 12}px);
    backdrop-filter: blur(${darkMode ? 5 : 12}px);
  `
]);

const TWGrow = styled("div")(() => [
  css`
    flex-grow: 1;
  `
]);
