import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {getTrackBackground, Range} from "react-range";
import {ifCond} from 'utils';

export interface ISlider extends TThemed {
  value?: number;
  step?: number;
  max?: number;
  min?: number;
  onChange: (values: number[]) => void;
  children?: React.ReactChildren;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Slider = React.forwardRef((props: ISlider, ref) => {
  const { value=100, step=1, min=0, max=100, onChange, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [values, setValues] = React.useState([value]);
  const currentValue = onChange instanceof Function ? [value] : values;
  const handleChange = React.useCallback((vals) => {
    if (onChange instanceof Function) {
      onChange(vals);
    } else {
      setValues(vals);
    }
  }, [onChange]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWSlider ref={ref} darkMode={darkMode} {...rest}>
      <Range {...{values: currentValue, step, min, max}} onChange={ handleChange }
             renderTrack={({ props: trackProps, children }) => (
               <TWTrack
                 onMouseDown={trackProps.onMouseDown}
                 onTouchStart={trackProps.onTouchStart}
                 style={{...(trackProps.style)}}
               >
                 <TWTrackCore ref={trackProps.ref} {...{values: currentValue, min, max}} >
                   {children}
                 </TWTrackCore>
               </TWTrack>
             )}
             renderThumb={({ props: thumbProps, isDragged }) => (
               <TWThumb {...thumbProps} style={{ ...thumbProps.style }} >
                 {/*<TWThumbCore
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />*/}
               </TWThumb>
             )}
      />
    </TWSlider>
  );
});

const TWSlider = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex flex-wrap justify-center`,
  css``
]);

const TWTrackCore = styled("div")(({ theme, darkMode, values, min, max }) => [
  tw`w-full h-3p rounded-xs self-center`,
  css`
    background: ${getTrackBackground({
    values, min, max, colors: [theme.colors.button.bf, theme.colors.button.go]
  })}
  `
]);
const TWTrack = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex w-full h-36p`,
  css`
`
]);

const TWThumb = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex justify-center items-center w-16p h-16p shadow-slider-range outline-none
     border border-solid border-accent-300 bg-white rounded-full`,
  // css`
  //   box-shadow: 0 2px 6px #AAA;
  // `
]);