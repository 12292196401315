import React from "react";
import { Switch, SpaceBox } from 'components';
import { useStoreActions, useStoreState } from 'hooks';

interface IPlaygroundHeader extends TThemed {

}

export const PlaygroundHeader = (props: IPlaygroundHeader) => {
  const darkMode = useStoreState(state => state.pgDarkMode);
  const setPgDarkMode = useStoreActions(actions => actions.setPgDarkMode);
  //const pushCommonMessage = useStoreActions(actions => actions.pushCommonMessage);
  //const clearCommonMessage = useStoreActions(actions => actions.clearCommonMessage);
  return (<>
      <Switch darkMode={darkMode}
              leftText={["Light Mode","Dark Mode"]}
              onChange={(e,m:boolean) => setPgDarkMode(!!m)}
      />
      <SpaceBox width={10} inline={true} if={false}/>
    </>
  );
};