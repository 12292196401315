import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  EIcon,
  EPanelCaptionKind,
  EPanelWidth,
  ETextAlign,
  ETextSize,
  ETextWeight,
  Icon,
  Panel, SpaceBox,
  TextBox
} from 'components';
import {ifCond} from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";

export interface ISuccessfulPayment extends TThemed {
  onClose?: Function;
  icon?: keyof typeof EIcon;
  header?: string;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const SuccessfulPayment = React.forwardRef((props: ISuccessfulPayment, ref) => {
  const { onClose, icon, header, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWProfileChangeEmail ref={ref} darkMode={darkMode} {...rest}>
      <Panel width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <TWIcon><Icon icon={icon} size={50}/></TWIcon>
        <SpaceBox height={10} />
        <TextBox text={header} size={ETextSize.LARGER} fillX={true} block={true} align={ETextAlign.CENTER} width={ETextWeight.SEMIBOLD}/>
      </Panel>
    </TWProfileChangeEmail>
  );
});

const TWProfileChangeEmail = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWIcon = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex justify-center w-full`,
  css``
]);
