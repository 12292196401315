import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  EButtonKind, EIcon,
  EIconSize,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight,
  Icon,
  TextBox,
  If, Avatar, EVAlign, SpaceBox
} from 'components';
import {PLAN, ROLE, ROUTE} from 'const';
import {useBrowser, useMediaBreakpoints, useStoreActions, useStoreState} from 'hooks';
import {ifCond, str} from 'utils';

export interface ISettingsNav extends TThemed {
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const SettingsNav = React.forwardRef((props: ISettingsNav, ref) => {
  
  const { darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const path = useStoreState(s=>s.router.path);
  const bp = useMediaBreakpoints();
  const team = useStoreState<any>(s => s.profile.plan.unique) === 'team';
  const profile = useStoreState(s=>s.profile);
  const push = useStoreActions(a=>a.router.push);
  const replace = useStoreActions(a=>a.router.replace);
  const handleGo = React.useCallback((to, rep=false) => {
    if (rep) replace({to}); else push({to});
  }, [push, replace]);
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWSettingNav>
      {/*<SpaceBox if={mainAction.if!==false} width={20} inline={true} />*/}
      <TWCaptures>
        <Button onClick={() => handleGo(ROUTE.HISTORY)} caption={MSG.HEADER.CAPTURES} kind={EButtonKind.LINK}/>
        <TWArrow>
          <Icon icon={EIcon.ChevronGray} size={EIconSize.XS} />
        </TWArrow>
      </TWCaptures>
      
      <TWNav>
        <If if={profile?.plan?.unique !== PLAN.FREE.unique}>
          <SpaceBox width={bp.tablet ? 10 : 20} inline={true} />
          <Button onClick={() => handleGo(ROUTE.PROFILE)} caption={MSG.HEADER.MY_SETTINGS} kind={path === ROUTE.PROFILE ? EButtonKind.LINK_CURRENT : EButtonKind.LINK}/>
          <If if={!!team}>
            <SpaceBox width={bp.tablet ? 10 : 20} inline={true} />
            <Button onClick={() => handleGo(ROUTE.TEAM)} tw={'tablet:px-15p'} caption={MSG.HEADER.TEAM_SETTINGS} kind={path === ROUTE.TEAM ? EButtonKind.LINK_CURRENT : EButtonKind.LINK}/>
          </If>
          <SpaceBox width={bp.tablet ? 10 : 20} inline={true} />
          <Button onClick={() => handleGo(ROUTE.BILLING)} tw={'tablet:px-15p'} caption={MSG.HEADER.BILLING} kind={path === ROUTE.BILLING ? EButtonKind.LINK_CURRENT : EButtonKind.LINK}/>
        </If>
      </TWNav>
  
    </TWSettingNav>
  );
});

const TWSettingNav = styled("div")(({ theme, darkMode, disabled }) => [
  tw`w-full flex justify-between px-20p tablet:px-10p`,
  
  css``
]);

const TWNav = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex`,
  
  css``
]);

const TWCaptures = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative`,
  css``
]);

const TWArrow = styled("div")(({ theme, darkMode, disabled }) => [
  tw`absolute`,
  css`
  transform: rotate(90deg);
  top: 10px;
  left: 10px;
`
]);
