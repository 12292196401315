import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line } from 'components';
import { cssSize, ifCond, str, extractTextColor } from 'utils';
import { useRect } from 'hooks';

export interface IAlign extends TThemed {
  width?: number | EAlignWidth; //full
  height?: number | EAlignHeight; //full
  absolute?: boolean;
  rounded?: boolean;
  bg?: string;
  bgHover?: string;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  fill?: boolean; //ignores w & h and sets w-full & h-full
  align?: EAlign;
  inline?: boolean;
  justify?: EJustify;
  stickyOffset?: number;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}
export enum EAlignWidth {
  AUTO = "auto", FULL = "100%", SCREEN = "100vw"
}
export enum EAlignHeight {
  AUTO = "auto", FULL = "100%", SCREEN = "100vh"
}
export enum EAlign {
  TOP = "flex-start", MIDDLE = "center", BOTTOM = "flex-end",
  STRETCH = "stretch", BASE = "baseline", NONE = "unset"
}
export enum EVAlign {
  TOP = "top", MIDDLE = "middle", BOTTOM = "bottom",
  BASE = "baseline", NONE = "unset"
}
export enum EJustify {
  LEFT = "flex-start", CENTER = "center", RIGHT = "flex-end",
  STRETCH = "stretch", NONE = "unset"
}

export const Align = React.forwardRef((props: IAlign, ref) => {
  const { width=EAlignWidth.FULL, height=EAlignHeight.FULL, fill=false,
    align=EAlign.MIDDLE, justify=EJustify.CENTER, absolute=false, rounded=false,
    children, demo=false, if:_if=true, padding, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWAlign padding={padding} ref={ref} {...{width, height, align, justify, absolute, rounded}}
             fill={fill ? true : undefined} clickable={rest.onClick instanceof Function} {...rest}>
      {children}
    </TWAlign>
  );
});

export const Sticky = (props: IAlign & {stickerRef?: React.RefObject<HTMLElement[]>;}) => {
  const { stickyOffset=0, stickerRef, children, demo=false, if:_if=true, ...rest } = props;
  const [sticky, setSticky] = React.useState(false);
  const ref = React.useRef();
  const stickerRect = useRect(ref);

  React.useLayoutEffect(() => {
    if (stickerRect.top <= stickyOffset && !sticky) {
      const stickers = stickerRef ? stickerRef.current : null;
      if (stickers) {
        if (stickers.length > 0) {
          stickers[stickers.length-1].style.display = "none";
        }
        stickers.push(ref.current);
      }
      setSticky(true);

    }
    if (stickerRect.top > stickyOffset && !!sticky) {
      const stickers = stickerRef ? stickerRef.current : null;
      if (stickers) {
        stickers.pop();
        if (stickers.length > 0) {
          stickers[stickers.length-1].style.display = "";
        }
      }
      setSticky(false);
    }
  }, [stickyOffset, stickerRect.top, sticky, setSticky]);
  if ( !ifCond(_if) ) return null;
  return (
    <Align ref={ref} sticky={sticky} stickyOffset={stickyOffset} {...rest}>
      
      {children}
    </Align>
  );
};


const TWAlign = styled("div")(({ theme, disabled, flex, width, height, fill, clickable,
  align, justify, absolute, inline, rounded, top, left, right, bottom, sticky, stickyOffset, bg, bgHover, padding }) => [
  //TODO flex if needed
  tw ``,
  clickable && tw`cursor-pointer`,
  inline ? tw`inline-grid` : tw`grid`,
  absolute? tw`absolute` : tw`relative`,
  rounded && tw`rounded-half`,
  fill? tw`` : (
    css`
      ${cssSize(width, "width")}
      ${cssSize(height, "height")}
      ${cssSize(top, "top")}
      ${cssSize(left, "left")}
      ${cssSize(right, "right")}
      ${cssSize(bottom, "bottom")}
    `
  ),
  bg && css`
    background-color: ${extractTextColor(bg, theme)};
  `,
  bgHover && css`
    &:hover {
      background-color: ${extractTextColor(bgHover, theme)};
    }
  `,
  css`
    padding: ${padding};
    grid-template-columns: 1fr;
    align-items: ${align};
    align-content: ${align};
    justify-items: ${justify};
    justify-content: ${justify};
  `,
  sticky ? css`
    &>* {
      ${tw`fixed left-0 z-10`}
      ${cssSize(stickyOffset, "top")}
    }
  ` : css`${tw`z-20`}`
]);


