import {EIcon} from 'const';
class ValidationError extends Error {
  readonly id: string;
  readonly field?: string;
  readonly apiName: string;
  readonly type?: string;
  readonly icon?: string;
  readonly persist: boolean;
  constructor (id: any, message: string, field?: string, apiName?: string, persist: boolean = false) {
    super(message);
    this.id = ( id || Date.now() ) + "";
    this.field = field;
    this.apiName = apiName;
    this.type=apiName;
    this.icon=EIcon.CloseCircle;
    this.persist = persist;
    try {Object.setPrototypeOf(this, ValidationError.prototype);} catch (e){}
  }
}
class MessageList extends Array<ValidationError | Tip> {
  constructor (errors: Array<ValidationError | Tip> | number = []) {
    if (errors instanceof Array) super(...errors); else super(errors);
    try {Object.setPrototypeOf(this, MessageList.prototype);} catch (e){}
  }
}
class Tip {
  message: string;
  readonly icon: string;
  readonly id: string;
  readonly field: string = "";
  readonly persist: boolean;
  constructor (id: any, message="", icon=EIcon.Check, persist=false) {
    this.id = ( id || Date.now() ) + "";
    this.message = message;
    this.icon = icon;
    this.persist = persist;
  }
}
enum ETipType {
  INFO = "info", ERROR = "error"
}
// class ApiError extends Error {
//   readonly apiName: string;
//   constructor (message: string, apiName?: string) {
//     super(message);
//     this.apiName = apiName;
//     Object.setPrototypeOf(this, ApiError.prototype);
//   }
// }

export {
  Tip, ETipType,
  ValidationError,
  MessageList,
};