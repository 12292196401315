import React from 'react';
import tw, {css, styled} from 'twin.macro';
import { RouteComponentProps } from "react-router-dom";
import {Auth, ESign, ESocAuthType} from 'components';
import {ifCond, MessageList} from 'utils';
import api from 'api';
import {useStoreActions, useStoreState} from 'hooks';
import {ROUTE, LOCAL_PARAM, FIELD, URL_PARAM} from 'const';

export interface IResetPage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const ResetPage = React.forwardRef((props: RouteComponentProps<IResetPage>, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, match, ...rest } = props;
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const matchParams = useStoreState(state => state.router.params);
  const homePath = useStoreState(state => state.homePath);
  const push = useStoreActions(actions => actions.router.push);
  const doValidate = useStoreActions(actions => actions.validate);
  const doForgot = useStoreActions(actions => actions.forgot);
  const doChange = useStoreActions(actions => actions.change);
  const doResend = useStoreActions(actions => actions.resend);
  const setToken = useStoreActions(actions => actions.setTid);
  const clearMessagesByField = useStoreActions(actions => actions.clearMessagesByField);
  const clearFieldMessages = useStoreActions(actions => actions.clearFieldMessages);
  const switchToHome = useStoreActions(actions => actions.switchToHome);
  const consumeParams = useStoreActions(actions => actions.consumeUrlParams);

  const confirmToken = matchParams[URL_PARAM.TOKEN];
  const emailParam = matchParams[URL_PARAM.EMAIL];
  const [mode, setMode] = React.useState(null);
  const [values, setValues] = React.useState({[FIELD.EMAIL]: null});

  const preprocess = React.useCallback(async () => {
    if (mode == null) {
      if (emailParam) {
        setValues({...values, [FIELD.EMAIL]: emailParam});
        await consumeParams({params: [URL_PARAM.EMAIL]});
        return;
      } else if (confirmToken) {
        const valid = await doValidate(confirmToken);
        if (valid !== false) {
          setMode(ESign.PWD_INPUT);
        } else {
          setMode(ESign.PWD_WRONG);
          await consumeParams({});
        }
      } else {
        setMode(ESign.PWD_SEND);
      }
    }
  }, [confirmToken, emailParam, switchToHome, setToken, mode, setMode]);
  const handleChange = React.useCallback((e, fld, val, setVal) => {
    const value = e.target.value;
    if (val[fld]!==value) {
      setValues({...values, ...val, [fld]: value});
    }
  }, [values, setValues]);
  const handleSubmit = React.useCallback(async (e, values, setValues) => {
    clearFieldMessages();
    switch (mode) {
      case ESign.PWD_SEND:
        const res = await doForgot(values[FIELD.EMAIL]);
        if (res) setMode(ESign.PWD_SENT);
        break;
      case ESign.PWD_SENT:
        push({to: ROUTE.AUTH});
        break;
      case ESign.PWD_WRONG:
        setMode(ESign.PWD_SEND);
        break;
      case ESign.PWD_INPUT:
        const done = await doChange([confirmToken, values[FIELD.PWD]]);
        if (done) {//true

          await consumeParams({to: ROUTE.AUTH});
        } else {
          if (done===null) setMode(ESign.PWD_WRONG);
        }
        break;
    }
  }, [mode, setMode, doForgot, doChange, consumeParams, push]);
  const handleSignUpClick = (e) => { push({to: ROUTE.REG}); };
  const handleSignInClick = (e) => { push({to: ROUTE.AUTH}); };
  const handleForgotPwdClick = (e) => { push({to: ROUTE.RESET}); };
  React.useEffect(() => {
    if (mode == null) {
      preprocess();
    }
  }, [mode, preprocess]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWResetPage ref={ref} darkMode={darkMode} {...rest}>
      <Auth errors={fieldErrors} mode={mode} values={values} onChange={handleChange} onClearError={clearMessagesByField} onSubmit={handleSubmit} onSignUpClick={handleSignUpClick} onSignInClick={handleSignInClick} onForgotPwdClick={handleForgotPwdClick}/>
    </TWResetPage>
  );
});

const TWResetPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative w-full h-full flex flex-col items-center justify-center`,
  tw`mobile:bg-white mobile:h-full mobile:flex mobile:flex-col mobile:justify-center mobile:mt-0`,
  css`min-height: 600px`
]);
