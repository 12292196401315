import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  BillingCancelSub, BillingChangeMethod, Button, ComparePlans, ComparePlansMobile, EButtonKind,
  ETagColor,
  ETagType,
  ETextAlign,
  ETextColor,
  ETextWeight, If,
  Line,
  LinkButton, Modal,
  Panel,
  PanelHeader,
  SpaceBox,
  TagLabel,
  TextBox, UpgradePlan
} from 'components';
import {extractTextProps, cssSize, ifCond, formatPrice, humanizeDate} from 'utils';
import {MSG, PLAN} from 'const';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {FileButton} from "../../base/Button/FileButton";

export interface IBilling extends TThemed {
  billing: any;
  profile: any;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Billing = React.forwardRef((props: IBilling, ref) => {
  const { billing={}, profile={}, setMessages, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const ComparePlansProps = {
    profile: { plan: PLAN.FREE }
  };
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const removePaymentMethod = useStoreActions(actions => actions.removePaymentMethod);
  const askSetPlans = useStoreActions(a=>a.screenshots.askSetPlans);
  const askSetPayPlan = useStoreActions(a=>a.screenshots.askSetPayPlan);
  const askSetUnsubscribe = useStoreActions(a=>a.screenshots.askSetUnsubscribe);
  const askSetChangeMethod = useStoreActions(a=>a.screenshots.askSetChangeMethod);
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  if ( !ifCond(_if) ) return null;
  return (
    <TWBilling ref={ref} darkMode={darkMode} {...rest}>
      {/*<Modal if={!!modalType} closeOnMisclick={!!modalType} onClose={() => {setModalType('')}}>*/}
      {/*  {modalType === 'unsubscribe' &&  <BillingCancelSub onSubmit={() => {setModalType('')}} onClose={() => {setModalType('')}} date={new Date()}/>}*/}
      {/*  {modalType === 'billing-method' &&  <BillingChangeMethod*/}
      {/*    errors={fieldErrors}*/}
      {/*    onClearError = {clearMessagesByField}*/}
      {/*    // onSubmit={() => {setModalType('')}}*/}
      {/*    onClose={() => {setModalType('')}} />}*/}
      {/*  {modalType === 'plans' && (bp.mobile ? <ComparePlansMobile {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/> : <ComparePlans {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/>)}*/}
      {/*  {modalType === 'upgrade-pro' && <UpgradePlan plan={PLAN.PRO} onClose={() => setModalType('plans')}/>}*/}
      {/*  {modalType === 'upgrade-team' && <UpgradePlan plan={PLAN.TEAM} onClose={() => setModalType('plans')}/>}*/}
      {/*</Modal>*/}
      <Panel caption={MSG.BILL.HEADER} mobileCenter={true} padding={!bp.mobile ? 30 : 10} noShadow={!bp.desktop} width={!bp.mobile ? 700 : 'auto'} align={ETextAlign.CENTER}>
        <SpaceBox height={22} width="100%"/>
        <Line />
        <Row caption={MSG.BILL.PAYMENT_METHOD} captionWidth={120} actionContent={
          <div tw="h-full w-full flex">
            <LinkButton text={MSG.BILL.CHANGE_BILLING_METHOD} onClick={() => askSetChangeMethod()}/>
            <SpaceBox if={!!billing.method} height='100%' width={30}/>
            <LinkButton if={!!billing.method} text={MSG.BILL.REMOVE_BILLING_METHOD} onClick={() => removePaymentMethod()}/>
          </div>
        }>
          <TextBox text={billing.method || MSG.BILL.CARD_NOT_LINKED} color={ETextColor.PRIMARY}/>
        </Row>
        
        <Line />
        <Row caption={MSG.BILL.NEXT_BILL} captionWidth={120} actionContent={<LinkButton text={MSG.BILL.CANCEL_SUB} onClick={() => askSetUnsubscribe({date: new Date()})}/>}>
          <TextBox text={humanizeDate(billing.nextBill, MSG.DATE.BILLING.HISTORY, MSG.LANG_UNIQUE)} color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        {billing.history && billing.history.length && <>
          <SpaceBox height={40}/>
          <TWHeader>
            <PanelHeader text={MSG.BILL.HISTORY}/>
          </TWHeader>
          <SpaceBox height={20}/>
          <Line/>
          {billing.history.map((hist, i) => {
            return (<>
              <Row history={true} caption={humanizeDate(hist.date, MSG.DATE.BILLING.HISTORY, MSG.LANG_UNIQUE)} captionWidth={100} actionContent={
                <TWRowActions>
                  { hist.paid || bp.mobile ? <div/> : <LinkButton text={MSG.BILL.PAY_NOW}/> }
                  <TagLabel type={ETagType.FLAG}
                    color={hist.paid ? ETagColor.SUCCESS : ETagColor.ASHEN}
                    text={hist.paid ? MSG.BILL.PAID : MSG.BILL.UNPAID}
                  />
                  <TextBox text={hist.price && formatPrice(hist.price)} weight={ETextWeight.SEMIBOLD} align={ETextAlign.RIGHT}/>
                </TWRowActions>
              }>
                <TextBox text={`${MSG.BRAND} ${hist.plan.name || PLAN.FREE.name}`} color={ETextColor.PRIMARY}/>
              </Row>
              <Line/>
            </>);
          })}
        </>}
        <If if={bp.mobile}>
          <SpaceBox height={40}/>
          <Button fill={true} caption={MSG.PROFILE.COMPARE_PLANS} onClick={() => askSetPlans({
            mobile: bp.mobile,
            onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
            onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
            ...ComparePlansProps})} kind={EButtonKind.OUTLINED_PRIMARY} />
        </If>
      </Panel>
    </TWBilling>
  );
});


interface IRow {
  caption: string;
  captionWidth?: number;
  children: React.ReactNode;
  actionContent: React.ReactNode;
  if?: boolean | (() => boolean);
  avatar?: boolean;
  height?: number;
  history?: boolean;
}
const Row = (props: IRow) => {
  const { caption, captionWidth=100, history, children, actionContent, avatar=false, height, if:_if=true } = props;
  const CaptionProps = {
    ...extractTextProps(caption)
  };
  if (!ifCond(_if)) return null;
  return (<TWRow avatar={avatar} height={height} caption={!!caption} captionWidth={captionWidth}>
    <If if = {!!caption}><TWCaptionCol><TextBox color={ETextColor.SECONDARY} {...CaptionProps}/></TWCaptionCol></If>
    <TWValueCol avatar={avatar}>{children}</TWValueCol>
    <TWActionCol history={history} avatar={avatar}>{actionContent}</TWActionCol>
  </TWRow>);
};


const TWBilling = styled("div")(() => [ tw`w-full`, css`` ]);
const TWHeader = styled("div")(() => [ tw`mobile:w-full mobile:text-center`, css`` ]);
const TWRow = styled("section")(({captionWidth, caption, avatar, height}) => [
  tw`grid relative w-full h-50p items-center content-center text-center capitalize`,
  tw`mobile:h-70p`,
  avatar && tw`mobile:justify-center mobile:h-100p mobile:grid-cols-none`,
  caption && tw`col-gap-15p`,
  css`grid-template-columns: ${captionWidth}px 1fr auto` ]);
const TWCaptionCol = styled("article")(() => [ tw`inline-grid relative h-full justify-start grid-flow-col items-center content-center justify-self-start`, css`` ]);
const TWValueCol = styled("article")(({avatar}) => [
  tw`inline-grid relative h-full justify-start grid-flow-col items-center content-center justify-self-start`,
  avatar && tw`mobile:justify-self-center`,
  css``
]);
const TWActionCol = styled("article")(({avatar, history}) => [
  tw`inline-grid relative h-full text-right justify-end grid-flow-col items-center content-center justify-self-end`,
  !history && tw`mobile:col-start-2 mobile:row-start-2 mobile:text-left mobile:justify-self-start`,
  css``

]);
const TWRowActions = styled("div")(() => [ tw`inline-grid grid-flow-col col-gap-30p items-center content-center`,
  css`
    grid-template-columns: auto auto minmax(40px, auto);
    grid-auto-columns: auto;
  `
]);
