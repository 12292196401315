import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {EIcon, EIconSize, Icon, If, SpaceBox} from 'components';
import {ifCond, cssSize} from 'utils';


export interface IAvatar extends TThemed {
  onClick?: Function;
  asDropDown?: boolean;
  avatar?: string;
  height?: number;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const Avatar = React.forwardRef((props: IAvatar, ref) => {
  const { avatar, width=60, height=40, asDropDown=false, onClick, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const handleClick = React.useCallback((e) => {
    if (onClick instanceof Function) onClick(e);
  }, [onClick]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWAvatar ref={ref} darkMode={darkMode} height={height} clickable={!!onClick} onClick={handleClick} {...rest}>
      <TWPhoto darkMode={darkMode} >
      <If if={!!avatar} else={
        <Icon icon={EIcon.Guest} size={EIconSize.LG} />
      }>
        <TWImage src={avatar} alt=""/>
      </If>
      </TWPhoto>
      <SpaceBox if={!!asDropDown} inline={true} width={10} />
      <Icon if={!!asDropDown} icon={EIcon.ChevronGray} size={EIconSize.XS} />
    </TWAvatar>
  );
});

const TWAvatar = styled("div")(({ theme, darkMode, disabled, height, width, clickable }) => [
  tw`relative inline-grid grid-flow-col items-center w-auto`,
  clickable && tw`cursor-pointer`,
  css`${cssSize(height, "height")}`,
  css`${cssSize(width, "width")}`
]);
const TWPhoto = styled("div")(({ theme, darkMode, disabled }) => [
  tw`inline-block relative w-40p h-40p`,
  css``
]);
const TWImage = styled("img")(({ theme, darkMode, disabled }) => [
  tw`inline-block relative w-40p h-40p rounded-full object-cover`,
  css`

`
]);
