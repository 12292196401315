import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  Card,
  EJustify,
  ETagColor,
  ETagType,
  SpaceBox,
} from 'components';
import {ifCond} from 'utils';
import {ICardItem} from 'types';

export interface ISlugPane extends TThemed {
  items: ICardItem[];
  total?: number;
  loading?: boolean;
  currentSlug?: string;
  hasMenu?: boolean;
  selected?: string[];
  onView?: Function;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const SlugPane = React.forwardRef((props: ISlugPane, ref) => {
  const { total=0, items=[], hasMenu=false, loading=false, currentSlug,
    onView, darkMode=false, demo=false, if:_if=true, children, ...rest
  } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWSlugPane ref={ref} {...rest}>
      {items.map((item, key) => {
        return (
          <Card currentSlug={currentSlug} blocked={!item?.name && item?.protected === 1} key={key} hasMenu={hasMenu} hasSelection={false} hasTags={false} index={key+1000} {...item} onView={onView}/>
        );
      })}
      {children}
    </TWSlugPane>
  );
});

const TWSlugPane = styled("section")(({ theme, darkMode, disabled }) => [
  tw`grid row-gap-24p items-start content-start justify-start w-full px-30p pb-20p mx-auto`,
  css`
    height: 100%;
    justify-items: center;
    grid-column-gap: 22px;
    margin-top: 20px;
  `,
  //breakpoints
  tw`grid-cols-7
     hsix:grid-cols-6
     hfive:grid-cols-5
     hfour:grid-cols-4
     hthree:grid-cols-3
     htwo:grid-cols-2
     hone:grid-cols-1
  `,
]);
