import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { encodeSvg, ifCond } from 'utils';
import { EIcon } from 'const';
import { EAlign, EVAlign } from 'components';

export interface IIcon extends TThemed {
  icon?: string | typeof React.Component;
  iconHover?: string | typeof React.Component;
  hint?: string;
  width?: number;
  size?: EIconSize | number;
  align?: EAlign;
  children?: React.ReactChildren;
  onClick?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export enum EIconSize {
  XS = 6, SM=17, MD = 23, LG=40, XL = 60
}
export enum EIconColor {
  PRIMARY = "sky", SECONDARY = "cinder", ERROR = "error", DISABLED = "ashen"
}


export const Icon = React.forwardRef((props: IIcon, ref) => {
  const { icon, iconHover, align=EVAlign.MIDDLE, size=EIconSize.MD, width, color=EIconColor.PRIMARY, hint, children, onClick, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  if ( !icon || !ifCond(_if) ) return null;
  const Icon = icon+""===icon ? iconsMap[icon] || null : null;
  const IconHover = iconHover+""===iconHover ? iconsMap[iconHover] || null : null;
  const IconComp = icon && icon+""!==icon ? icon : null;
  return (
    !icon ? null : (<>{[
      Icon && <TWIconContainer width={width} iconHover={!!iconHover} align={align} onClick={onClick} key={0} darkMode={darkMode} color={color} size={size} title={hint} {...rest}>
        <Icon className={iconHover ? "icon-normal" : null} width="auto" preserveAspectRatio="xMinYMin slice">{children}</Icon>
        {iconHover && <IconHover className="icon-hover" width="auto" preserveAspectRatio="xMinYMin slice">{children}</IconHover> }
    </TWIconContainer>,
      IconComp && <TWIconContainer align={align} onClick={onClick} key={1} darkMode={darkMode} color={color} size={size} title={hint} {...rest}>
        <IconComp ref={ref} darkMode={darkMode} color={color} size={size} {...rest}>{children}</IconComp>
      </TWIconContainer>,
    ]}</>)
  );
});
const TWIconContainer = styled("i")(({darkMode, color, size, align, onClick, iconHover, width}) => [
    tw`inline-grid`,
    onClick instanceof Function ? tw`cursor-pointer` : tw``,
    css`
      height: ${size}px;
      width: auto;
      vertical-align: ${align};
    `,
    css`
      & > svg {
        display: inline-block;
        height: ${size}px;
        width: ${width ? width + 'px' : 'auto'};
        min-width: 10px;
        vertical-align: middle;
      }
    `,
    iconHover && css`
      &:hover > *:first-child {
        display: none !important;
      }
      &:not(:hover) > *:nth-child(2) {
        display: none !important;;
      }
    `
  ]
);

const reqSvgs = (require as any).context('!@svgr/webpack!./svg', true, /\.svg$/);
const iconsMap = reqSvgs.keys().reduce((images, path) => {
  const key = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
  images[key] = reqSvgs(path).default;
  return images;
}, {});
export const IconRaw = (props: {icon: string; [prop: string]: any;}) => {
  const { icon, ...rest } = props;
  const Ico = iconsMap[icon];
  return <Ico {...rest}/>
};


export {EIcon};
