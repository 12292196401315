import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  EButtonKind,
  EditBox,
  EEditInput,
  EEditKind,
  EPanelCaptionKind,
  EPanelWidth,
  ETextAlign,
  ETextSize,
  ETextWeight,
  Line,
  Panel,
  Radio,
  RadioGroup,
  SpaceBox,
  TextBox
} from 'components';
import {EIcon, FIELD, SETTINGS} from 'const';
import {debounceHandler, ifCond, str} from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";


export interface IDonate extends TThemed {
  errors?: string[] | null;
  onClose?: Function;
  onSubmit?: Function;
  onClearErrors?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const Donate = React.forwardRef((props: IDonate, ref) => {
  const { onClose, plan, errors, onClearErrors, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  
  const bp = useMediaBreakpoints();
  const periods = ["once", "monthly"];
  const methods = ["card", "paypal"];
  const [period, setPeriod] = React.useState(periods[0]);
  const [method, setMethod] = React.useState(methods[0]);
  const [amount, setAmount] = React.useState(3);
  const [comment, setComment] = React.useState("");
  const handleChange = React.useCallback((val, selected, name) => {
    //TODO: replace string to MSG
    if (!!~name.search(/^billing-period-/) && period!==val) setPeriod(val);
    else if (!!~name.search(/^billing-method-/) && method!==val) setMethod(val);
    else if (name===FIELD.AMOUNT && amount !==val) setAmount(val);
    else if (name===FIELD.COMMENT && comment !==val) setComment(val);
  },[period, setPeriod, method, setMethod]);
  const handleClose = React.useCallback((e) => { if (onClose instanceof Function) onClose(e) }, [onClose]);
  const handleSubmit = React.useCallback((e) => { if (onSubmit instanceof Function) onSubmit(e) }, [onSubmit]);
  const isYearly = period===periods[0], isMonthly = !isYearly,
    isCard = methods[0] === method, isPayPal = !isCard;

  useKeydown(handleSubmit, handleClose, {period, method, amount, comment});

  if ( !ifCond(_if) ) return null;
  return (
    <TWDonate ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={str(MSG.UPGRADE_PLAN.DONATE_BRAND)} captionKind={EPanelCaptionKind.LGB} icon={EIcon.Coin} iconSize={30} onClose={handleClose} width={EPanelWidth.SM} align={ETextAlign.CENTER}>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={30} />
        <RadioGroup name={MSG.UPGRADE_PLAN.BILLING_PERIOD + "-" + Date.now()} as={TWPeriod} value={period} onChange={handleChange}>
          <Radio value={periods[0]}><TextBox text={MSG.ONCE}/></Radio>
          <Radio value={periods[1]}><TextBox text={MSG.MONTHLY}/></Radio>
        </RadioGroup>
        <SpaceBox height={30} />
        <EditBox caption={MSG.DONATION_AMOUNT} kind={EEditKind.LG} input={EEditInput.TEXT} fill={true}
                 onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.AMOUNT)}, SETTINGS.inputDelay)}
        />
        <SpaceBox height={10} />
        <EditBox caption={MSG.COMMENT} kind={EEditKind.LG} input={EEditInput.TEXT} fill={true}
                 onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.COMMENT)}, SETTINGS.inputDelay)}
        />
        <SpaceBox height={30} />
        <TextBox text={MSG.UPGRADE_PLAN.CHOOSE_DONATION_METHOD} size={ETextSize.LARGER}/>
        <SpaceBox height={30} />
        <RadioGroup name={MSG.UPGRADE_PLAN.BILLING_METHOD + "-" + Date.now()} as={TWPeriod} value={method} onChange={handleChange}>
          <Radio value={methods[0]}><TextBox text={MSG.CREDIT_CARD}/></Radio>
          <Radio value={methods[1]}><TextBox text={MSG.PAYPAL}/></Radio>
        </RadioGroup>
        <SpaceBox height={30} />
        <TextBox if={isPayPal} text={MSG.UPGRADE_PLAN.PAYPAL_NOTE}/>
        <EditBox if={isCard} caption={MSG.PAYMENT.CARD_NUMBER} kind={EEditKind.LG} input={EEditInput.TEXT} inline={false} fill={true}
                 onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.CARD)}, SETTINGS.inputDelay)}/>
        <SpaceBox if={isCard} height={10} />
        <div tw="grid grid-flow-col col-gap-10p">
          <EditBox if={isCard} caption={MSG.PAYMENT.EXPIRES} kind={EEditKind.LG} input={EEditInput.TEXT} width={!bp.mobile ? 111 : 111}
                   onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.EXPIRES)}, SETTINGS.inputDelay)}/>
          <EditBox if={isCard} width={!bp.mobile ? 87 : 87} caption={MSG.PAYMENT.CVC} kind={EEditKind.LG} input={EEditInput.TEXT}
                   onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.CVC)}, SETTINGS.inputDelay)}/>
          <EditBox if={isCard} width={!bp.mobile ? 97 : 97} caption={MSG.PAYMENT.ZIP} kind={EEditKind.LG} input={EEditInput.TEXT}
                   onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.ZIP)}, SETTINGS.inputDelay)}/>
        </div>
        <SpaceBox height={30} />
        <Button kind={EButtonKind.PRIMARY} caption={MSG.UPGRADE_PLAN.UPGRADE_NOW} fill={true} onClick={handleSubmit}/>
      </Panel>
    </TWDonate>
  );
});

const TWDonate = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWPeriod = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid col-gap-10p items-center content-center w-auto h-18p`,
  css`grid-template-columns: repeat(2, 116px);`
]);
