import React from 'react';

const isClient = typeof window !== 'undefined';
export function useMedia (query: string, defaultState=false) {
  const mql = React.useMemo(() => isClient ? window.matchMedia(query) : null, []);
  const [state, setState] = React.useState(mql ? mql.matches : defaultState);
  const onChange = React.useCallback(function (e) {
    setState(!!e.matches);
  }, [setState]);
  React.useLayoutEffect(function () {
    if (!mql) return;
    setState(mql.matches);
    mql.addEventListener("change", onChange);
    return function () {
      mql.removeEventListener("change", onChange);
    };
  }, [setState, onChange]);
  return state;
}

export const useMediaBreakpoints = () => {
  const mobile = useMedia('(max-width: 767px)');
  const tablet = useMedia('(max-width: 1024px) and (min-width: 768px)');
  const desktop = useMedia('(min-width: 1025px)');
  if (typeof window !== 'undefined') {
    return {
      mobile,
      tablet,
      desktop
    };
  } else return { mobile: false, tablet: false, desktop: true };
};
export const useMediaBreakpoint = () => {
  const media = useMediaBreakpoints();
  return Object.keys(media).find(m => !!media[m]);
};
