export const CODE = {
  SOCKET: {
    "-1": {
      code: "any other",
      name: "UNKNOWN_ERROR",
      description: "Unknown error"
    },
    1: {
      code: 1,
      name: "CHANGE_USER_NAME_SUCCESS",
      description: "Username changed",
    },
    2: {
      code: 2,
      name: "CHANGE_USER_NAME_FAILURE",
      description: "Error changing username",
    },
    3: {
      code: 3,
      name: "CHANGE_USER_PASSWORD_SUCCESS",
      description: "Password changed",
    },
    4: {
      code: 4,
      name: "CHANGE_USER_PASSWORD_FAILURE",
      description: "Error changing password",
    },
    5: {
      code: 5,
      name: "CHANGE_USER_EMAIL_SUCCESS",
      description: "Email changed",
    },
    6: {
      code: 6,
      name: "CHANGE_USER_EMAIL_FAILURE",
      description: "Error changing email",
    },
    7: {
      code: 7,
      name: "CHANGE_USER_LANGUAGE_SUCCESS",
      description: "Language changed",
    },
    8: {
      code: 8,
      name: "CHANGE_USER_LANGUAGE_FAILURE",
      description: "Error changing language",
    },
    9: {
      code: 9,
      name: "ADD_TAG_SUCCESS",
      description: "Tag added",
    },
    10: {
      code: 10,
      name: "ADD_TAG_FAILURE",
      description: "Error adding tag",
    },
    11: {
      code: 11,
      name: "DROP_TAG_SUCCESS",
      description: "Tag dropped",
    },
    12: {
      code: 12,
      name: "DROP_TAG_FAILURE",
      description: "Error dropping tag",
    },
    13: {
      code: 13,
      name: "ALTER_TAG_SUCCESS",
      description: "Tag changed",
    },
    14: {
      code: 14,
      name: "ALTER_TAG_FAILURE",
      description: "Error changing tag",
    },
    15: {
      code: 15,
      name: "FILE_ADD_TAG_SUCCESS",
      description: "File tag added",
    },
    16: {
      code: 16,
      name: "FILE_ADD_TAG_FAILURE",
      description: "Error adding file tag",
    },
    17: {
      code: 17,
      name: "FILE_DROP_TAG_SUCCESS",
      description: "File tag dropped",
    },
    18: {
      code: 18,
      name: "FILE_DROP_TAG_FAILURE",
      description: "Error dropping file tag",
    },
    19: {
      code: 19,
      name: "DROP_HISTORY_FILE_SUCCESS",
      description: "Files dropped",
    },
    20: {
      code: 20,
      name: "DROP_HISTORY_FILE_FAILURE",
      text: "Error dropping files",
      description: "Error dropping files",
    },
    21: {
      code: 21,
      name: "GET_HISTORY_FAILURE",
      text: "Error getting history",
      description: "Error on getting history",
    },
    22: {
      code: 22,
      name: "UNLOCK_FILE_FAILURE",
      description: "Error unlocking files",
    },
    23: {
      code: 23,
      name: "UNPROTECT_FILE_FAILURE",
      description: "Error unprotect files",
    },
    24: {
      code: 24,
      name: "PROTECT_FILE_FAILURE",
      description: "Error protecting files",
    },
    25: {
      code: 25,
      name: "SHOW_DATA_BY_SLUG_FAILURE",
      description: "Error showing data by slug",
    },
    26: {
      code: 26,
      name: "UNKNOWN_USER",
      description: "Unknown user",
    },
    27: {
      code: 27,
      name: "GET_TAGS_FAILURE",
      description: "Error on getting tags",
    },
    28: {
      code: 28,
      name: "DROP_USER_SUCCESS",
      description: "User dropped",
    },
    29: {
      code: 29,
      name: "DROP_USER_FAILURE",
      description: "User dropping error",
    },
    30: {
      code: 30,
      name: "DROP_AVATAR_SUCCESS",
      description: "Avatar dropped"
    },
    31: {
      code: 31,
      name: "DROP_AVATAR_FAILURE",
      description: "Error on drop avatar"
    },
    32: {
      code: 32,
      name: "UPLOAD_AVATAR_FAILURE",
      description: "Error on getting upload avatar"
    },
    33: {
      code: 33,
      name: "CREATE_NODE_FAILURE",
      description: "Error on mass link creation"
    },
    34: {
      code: 34,
      name: "CREATE_SHARE_FAILURE",
      description: "Error on share link creation"
    },
    35: {
      code: 35,
      name: "GET_HISTORY_INFO_FAILURE",
      description: "Error on getting history metadata"
    },
    36: {
      code: 36,
      name: "GET_TEAM_INFO_FAILURE",
      description: "Error on getting team metadata"
    },
    37: {
      code: 37,
      name: "EXTEND_TEAM_SUCCESS",
      description: "Team extended"
    },
    38: {
      code: 38,
      name: "EXTEND_TEAM_FAILURE",
      description: "Error on extending team"
    },
    39: {
      code: 39,
      name: "INVITE_USER_SUCCESS",
      description: "User invited"
    },
    40: {
      code: 40,
      name: "INVITE_USER_FAILURE",
      description: "Error on inviting user"
    },
    41: {
      code: 41,
      name: "EXPEL_TEAM_MEMBER_SUCCESS",
      description: "User deleted"
    },
    42: {
      code: 42,
      name: "EXPEL_TEAM_MEMBER_FAILURE",
      description: "Error on deleting user"
    },
    43: {
      code: 43,
      name: "CANCEL_TEAM_INVITATION_SUCCESS",
      description: "Invitation canceled"
    },
    44: {
      code: 44,
      name: "CANCEL_TEAM_INVITATION_FAILURE",
      description: "Error on invitation canceling"
    },
    45: {
      code: 45,
      name: "PROMOTE_TEAM_MEMBER_SUCCESS",
      description: "User role changed to admin"
    },
    46: {
      code: 46,
      name: "PROMOTE_TEAM_MEMBER_FAILURE",
      description: "Error on changing role"
    },
    47: {
      code: 47,
      name: "DEMOTE_TEAM_MEMBER_SUCCESS",
      description: "User role changed to member"
    },
    48: {
      code: 48,
      name: "DEMOTE_TEAM_MEMBER_FAILURE",
      description: "Error on changing role"
    },
    49: {
      code: 49,
      name: "SET_TEAM_IP_FILTER_SUCCESS",
      description: "IP filter changed"
    },
    50: {
      code: 50,
      name: "SET_TEAM_IP_FILTER_FAILURE",
      description: "Error on IP filter changing"
    },
    51: {
      code: 51,
      name: "ACCEPT_INVITATION_SUCCESS",
      description: "Invitation accepted"
    },
    52: {
      code: 52,
      name: "ACCEPT_INVITATION_FAILURE",
      description: "Error on invitation accepting"
    },
    53: {
      code: 53,
      name: "LEAVE_TEAM_SUCCESS",
      description: "You left the team"
    },
    54: {
      code: 54,
      name: "LEAVE_TEAM_FAILURE",
      description: "Error on getting leaving team"
    },
    55: {
      code: 55,
      name: "UPLOAD_TEAM_AVATAR_SUCCESS",
      description: "Team avatar uploaded"
    },
    56: {
      code: 56,
      name: "UPLOAD_TEAM_AVATAR_FAILURE",
      description: "Error on team avatar uploading"
    },
    57: {
      code: 57,
      name: "DROP_TEAM_AVATAR_SUCCESS",
      description: "Team avatar dropped"
    },
    58: {
      code: 58,
      name: "DROP_TEAM_AVATAR_FAILURE",
      description: "Error on dropping team avatar"
    },
    59: {
      code: 59,
      name: "DOMAIN_CHANGE_SUCCESS",
      description: "Domain changed"
    },
    60: {
      code: 60,
      name: "DOMAIN_CHANGE_FAILURE",
      description: "Error on changing domain"
    },
    61: {
      code: 61,
      name: "MAXIMUM_FILE_SIZE_EXCEEDED",
      description: "File size exceeded"
    },
    62: {
      code: 62,
      name: "QUOTA_SIZE_EXCEEDED",
      description: "Quota size exceeded"
    },
    63: {
      code: 63,
      name: "IP_FILTER_ACCESS_DENIED",
      description: "IP filter access denied"
    },
  }
};

export const COMMAND = {
  //socket command : store command
  INCOMING: {
    MAIN: {
      "SET_USER_INFO": "SET_USER_INFO",
      "UPLOAD_AVATAR_COMPLETE": "UPLOAD_AVATAR_COMPLETE",
      "UPLOAD_TEAM_AVATAR_COMPLETE": "UPLOAD_TEAM_AVATAR_COMPLETE",
      "SHOW_TEAM_INFO": "SHOW_TEAM_INFO",
      "UPDATE_AUTH_TOKEN": "UPDATE_AUTH_TOKEN",
      "LIST_LANGUAGES": "LIST_LANGUAGES"
    },
    FILE: {
      "SHOW_HISTORY": "SHOW_HISTORY",
      "SHOW_DATA_BY_SLUG": "SHOW_DATA_BY_SLUG",
      "UNLOCKED_FILE": "UNLOCKED_FILE",
      "UNPROTECTED_FILE": "UNPROTECTED_FILE",
      "PROTECTED_FILE": "PROTECTED_FILE",
      "INIT_FINISHED": "INIT_FINISHED",
      "PROCESS_UPLOAD": "PROCESS_UPLOAD",
      "UPLOAD_COMPLETE": "UPLOAD_COMPLETE",
      "UPLOAD_FAILED": "UPLOAD_FAILED",
      "LIST_TAGS": "LIST_TAGS",
      "SET_HISTORY_INFO": "SET_HISTORY_INFO",
      "DROPPED_HISTORY_FILES": "DROPPED_HISTORY_FILES",
      "ADDED_TAG": "ADDED_TAG",
      "ALTERED_TAG": "ALTERED_TAG",
      "DROPPED_TAG": "DROPPED_TAG",
      //"FILE_ADDED_TAGS": "FILE_ADDED_TAGS",
      //"FILE_DROPPED_TAGS": "FILE_DROPPED_TAGS",
      "FILE_TAGS_CHANGED": "FILE_TAGS_CHANGED",
      "FILE_TAGS_ADDED": "FILE_TAGS_ADDED",
      "GROUP_LINK_CREATED": "GROUP_LINK_CREATED",
      "SHARE_LINK_CREATED": "SHARE_LINK_CREATED",
    },
    COMMON: {
      "SHOW_ERROR": "SHOW_ERROR",
      "SHOW_MESSAGE": "SHOW_MESSAGE",
      "SHOW_CONST_MESSAGE": "SHOW_CONST_MESSAGE",
    },
  },
  //store command : socket command
  OUTGOING: {
    MAIN: {
      "GET_USER_INFO": "GET_USER_INFO",
      "GET_TEAM_INFO": "GET_TEAM_INFO",
      "INVITE_REQUEST": "INVITE_REQUEST",
      "DELETE_FROM_TEAM": "DELETE_FROM_TEAM",
      "TEAM_ROLE_CHANGE": "TEAM_ROLE_CHANGE",
      "DOMAIN_CHANGE": "DOMAIN_CHANGE",
      "REQUEST_TEAM_SIZE": "REQUEST_TEAM_SIZE",
      "SET_USER_NAME": "SET_USER_NAME",
      "SET_USER_EMAIL": "SET_USER_EMAIL",
      "DROP_HISTORY_FILES": "DROP_HISTORY_FILES",
      "DROP_USER": "DROP_USER",
      "SET_USER_PASSWORD": "SET_USER_PASSWORD",
      "SET_USER_LANGUAGE": "SET_USER_LANGUAGE",
      "GET_LANGUAGES": "GET_LANGUAGES",
      "TEAM_SETTINGS": "TEAM_SETTINGS",
      "DROP_AVATAR": "DROP_AVATAR",
      "UPLOAD_AVATAR": "UPLOAD_AVATAR",
      "INVITE_USER": "INVITE_USER",
      "CANCEL_TEAM_INVITATION": "CANCEL_TEAM_INVITATION",
      "PROMOTE_TEAM_MEMBER": "PROMOTE_TEAM_MEMBER",
      "DEMOTE_TEAM_MEMBER": "DEMOTE_TEAM_MEMBER",
      "EXTEND_TEAM": "EXTEND_TEAM",
      "EXPEL_TEAM_MEMBER": "EXPEL_TEAM_MEMBER",
      "LEAVE_TEAM" : "LEAVE_TEAM",
      "ACCEPT_INVITATION": "ACCEPT_INVITATION",
      "UPLOAD_TEAM_AVATAR": "UPLOAD_TEAM_AVATAR",
      "SET_TEAM_IP_FILTER": "SET_TEAM_IP_FILTER",
    },
    FILE: {
      "GET_HISTORY": "GET_HISTORY",
      "UNLOCK_FILE": "UNLOCK_FILE",
      "UNPROTECT_FILE": "UNPROTECT_FILE",
      "PROTECT_FILE": "PROTECT_FILE",
      "ADD_TAG": "ADD_TAG",
      "DROP_TAG": "DROP_TAG",
      "ALTER_TAG": "ALTER_TAG",
      "FILE_ADD_TAG": "FILE_ADD_TAG",
      //"FILE_DROP_TAGS": "FILE_DROP_TAGS",
      "FILE_SET_TAGS": "FILE_SET_TAGS",
      "INIT_UPLOAD": "INIT_UPLOAD",
      "GET_DATA_BY_SLUG": "GET_DATA_BY_SLUG",
      "GET_TAGS": "GET_TAGS",
      "GET_HISTORY_INFO": "GET_HISTORY_INFO",
      "DROP_HISTORY_FILES": "DROP_HISTORY_FILES",
      "CREATE_GROUP_LINK": "CREATE_GROUP_LINK",
      "CREATE_SHARE_LINK": "CREATE_SHARE_LINK",
    },
  }
};
export const INCOMING = {
  ...COMMAND.INCOMING.MAIN,
  ...COMMAND.INCOMING.FILE,
  ...COMMAND.INCOMING.COMMON
};
