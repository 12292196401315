import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button, EButtonKind, EditBox, EEditInput, EIcon, EIconSize, EPanelWidth, ETextAlign,
  ETextSize, Icon, If, Line, LinkButton, Panel, PanelFooter, SpaceBox, TextBox
} from 'components';
import { IFieldErrors } from 'types';
import {FIELD, URLS} from 'const';
import {ifCond, str} from 'utils';
import _ from 'lodash';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";

export interface IAuth extends TThemed {
  values?: IAuthValues;
  onClearError?: Function;
  onSubmit?: Function;
  onChange?: Function;
  onSocAuthClick?: Function;
  onSignInClick?: Function;
  onSignUpClick?: Function;
  onForgotPwdClick?: Function;
  errors?: IFieldErrors;
  mode?: ESign;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}
export interface IAuthValues {
  login?: string;
  email?: string;
  password?: string;
}
export enum ESocAuthType {
  GOOGLE = "google", FACEBOOK = "facebook", VK = "vk", OK = "ok", TWITTER = "twitter"
}
export enum ESign {
  IN, UP, UP_SENT, UP_WRONG, PWD_SEND, PWD_SENT, PWD_WRONG, PWD_INPUT
}

export const Auth = React.forwardRef((props: IAuth, ref) => {
  const { mode, errors={}, values = {}, onClose, onClearError, onChange, onSubmit, onSocAuthClick, onSignInClick, onSignUpClick, onForgotPwdClick, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const SignHeader = [MSG.AUTH.SIGN_IN, MSG.AUTH.SIGN_UP, MSG.AUTH.SIGN_UP, MSG.AUTH.SIGN_UP, MSG.AUTH.RESET_HDR, MSG.AUTH.RESET_HDR, MSG.AUTH.RESET_HDR, MSG.AUTH.NEW_PWD_HDR];
  const ButtonCaption = [MSG.AUTH.SIGN_IN, MSG.AUTH.SIGN_UP, MSG.AUTH.RESEND_BTN , MSG.AUTH.RECONFIRM_BTN, MSG.AUTH.RESET_BTN, MSG.AUTH.SIGN_IN, MSG.AUTH.RESET_BTN, MSG.AUTH.RESET_BTN];
  const MessageText = ["", "", MSG.AUTH.INSTRUCTIONS_SENT_REG, MSG.AUTH.WRONG_TOKEN_REG, "", MSG.AUTH.INSTRUCTIONS_SENT_RESET, MSG.AUTH.WRONG_TOKEN_RESET, ""];
  const showPwd = [ESign.PWD_INPUT, ESign.IN, ESign.UP].includes(mode);
  const showEmail = [ESign.UP, ESign.PWD_SEND].includes(mode);
  const showLogin = mode === ESign.IN;
  const showMessage = [ESign.UP_SENT, ESign.UP_WRONG, ESign.PWD_SENT, ESign.PWD_WRONG].includes(mode);
  const [vals, setVals] = React.useState(values);
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleSubmit = React.useCallback((e, fld) => {
    if (onSubmit instanceof Function) {
      if (!fld) {
        onSubmit(e, vals);
      } else {
        const value = e.target.value;
        setVals({...vals, [fld]: value});
        onSubmit(e, {...vals, [fld]: value});
      }
    }
  }, [onSubmit, vals]);
  const handleKeyPress = React.useCallback((e, fld) => {
    switch (fld) {
      case FIELD.EMAIL:
        if (!showEmail || showPwd) return;
        break;
      case FIELD.PWD:
        if (!showPwd) return;
        break;
    }
    if (e.key==='Enter') handleSubmit(e, fld);
  }, [handleSubmit]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    if (vals[fld]!=value) {
      //if any value changed
      let preventDefault = null;
      if (onChange instanceof Function) {
        preventDefault = onChange(e, fld, vals, setVals);
      }
      //...and if onChange did not strictly return false
      if (preventDefault !== false) {
        //...then update inner state
        setVals({...vals, [fld]: value});
        if (onClearError instanceof Function) onClearError(fld);
      }
    }
  }, [vals, setVals, onClearError, onChange]);
  const handleSocAuth = React.useCallback((e, soc: ESocAuthType) => {
    if (onSocAuthClick instanceof Function) onSocAuthClick(e, soc);
  }, [onSocAuthClick]);
  const handleSignIn = React.useCallback((e) => {
    if (onSignInClick instanceof Function) onSignInClick(e);
  }, [onSignInClick]);
  const handleSignUp = React.useCallback((e) => {
    if (onSignUpClick instanceof Function) onSignUpClick(e);
  }, [onSignUpClick]);
  React.useEffect(() => {
    if (!_.isEqual(vals, vals)) {
      setVals(vals);
    }
  }, [vals, vals, setVals]);
  if ( mode == null || !ifCond(_if)) return null;
  return (
    <TWAuth ref={ref} darkMode={darkMode} {...rest}>
      <Panel noShadow={bp.mobile} onClose={onClose && handleClose} darkMode={darkMode} width={EPanelWidth.SM}>
        <TextBox text={SignHeader[mode]} fillX={true} block={true} align={ETextAlign.CENTER} size={ETextSize.LARGER}/>
        <SpaceBox height={20} />
        <If if={mode===ESign.IN || mode===ESign.UP}>
            <TWButton onClick={(e) => handleSocAuth(e, ESocAuthType.GOOGLE)}>
              <Icon icon={EIcon.SocGoogle} />
              <TextBox text={mode===ESign.IN ? MSG.AUTH.SIGN_IN_GOOGLE : MSG.AUTH.SIGN_UP_GOOGLE}/>
            </TWButton>
            <SpaceBox height={10} />
            <TWButton onClick={(e) => handleSocAuth(e, ESocAuthType.FACEBOOK)}>
              <Icon icon={EIcon.SocFacebook} />
              <TextBox text={mode===ESign.IN ? MSG.AUTH.SIGN_IN_FACEBOOK : MSG.AUTH.SIGN_UP_FACEBOOK}/>
            </TWButton>
            <SpaceBox height={20} />
            <Line text={MSG.OR}/>
            <SpaceBox height={20} />
        </If>
        <EditBox if={showLogin} caption={MSG.PROFILE.LOGIN} input={EEditInput.TEXT} errors={errors[FIELD.LOGIN]}
                 onBlur={(e) => handleChange(e, FIELD.LOGIN)}
                 onChange={(e) => handleChange(e, FIELD.LOGIN)}
                 fill={true}/>
        <EditBox if={showEmail} caption={MSG.PROFILE.EMAIL} input={EEditInput.EMAIL} errors={errors[FIELD.EMAIL]}
                 onKeyPress={(e) => handleKeyPress(e, FIELD.EMAIL)}
                 onBlur={(e) => handleChange(e, FIELD.EMAIL)}
                 onChange={(e) => handleChange(e, FIELD.EMAIL)}
                 fill={true}/>
        <SpaceBox if={(showEmail || showLogin) && showPwd} height={10} />
        <EditBox if={showPwd} caption={MSG.PROFILE.PWD} input={EEditInput.PWD} errors={errors[FIELD.PWD]}
                 onKeyPress={(e) => handleKeyPress(e, FIELD.PWD)}
                 onBlur={(e) => handleChange(e, FIELD.PWD)}
                 onChange={(e) => handleChange(e, FIELD.PWD)}
                 fill={true}
                 forgotPwdBtn={mode===ESign.IN}
                 onForgotPwdClick={onForgotPwdClick}
                 showPwdBtn={[ESign.UP, ESign.PWD_INPUT].includes(mode)}
        />
        <SpaceBox if={mode===ESign.UP} height={20} />
        <TextBox if={mode===ESign.UP}
                 text={<>
                   {MSG.AUTH.AGREE_TO_TERMS.split(/%%\d+%%/)[0]}
                   <LinkButton href={URLS.TERMS} caption={{text: MSG.AUTH.TERMS, size:ETextSize.SMALL}}/>
                   {MSG.AUTH.AGREE_TO_TERMS.split(/%%\d+%%/)[1]}
                 </>} size={ETextSize.SMALL} fillX={true} block={true} align={ETextAlign.CENTER}/>
        <TextBox if={showMessage} fillX={true} block={true} align={ETextAlign.CENTER} text={str(MessageText[mode], vals[FIELD.EMAIL])}/>
        <SpaceBox height={26} />
        <PanelFooter>
          <Button caption={ButtonCaption[mode]} kind={EButtonKind.PRIMARY} onClick={handleSubmit} fill={true}/>
          <SpaceBox height={20}/>
          <div tw="w-full text-center">
            <TextBox if={mode===ESign.IN} text={MSG.AUTH.HAVE_NO_ACCOUNT} size={ETextSize.SMALL}/>
            <TextBox if={mode===ESign.UP} text={MSG.AUTH.HAVE_ACCOUNT} size={ETextSize.SMALL}/>
            <LinkButton if={![ESign.IN, ESign.PWD_SENT].includes(mode)} caption={{text: MSG.AUTH.SIGN_IN, size:ETextSize.SMALL}} onClick={handleSignIn}/>
            <Line if={![ESign.IN, ESign.PWD_SENT, ESign.UP, ESign.UP_WRONG, ESign.UP_SENT].includes(mode)} vertical={true} />
            <LinkButton if={![ESign.UP, ESign.UP_WRONG, ESign.UP_SENT].includes(mode)} caption={{text: MSG.AUTH.SIGN_UP, size:ETextSize.SMALL}} onClick={handleSignUp}/>
          </div>
        </PanelFooter>
      </Panel>
      <If if={mode===ESign.IN}>
        <SpaceBox height={20} />
        <TWSocAuth>
          <div>
            <Icon icon={EIcon.AuthTwitter} size={30} onClick={(e) => handleSocAuth(e, ESocAuthType.TWITTER)}/>
            <SpaceBox width={20} inline={true} />
            <Icon icon={EIcon.AuthVK} size={30} onClick={(e) => handleSocAuth(e, ESocAuthType.VK)}/>
            <SpaceBox width={20} inline={true} />
            <Icon icon={EIcon.AuthOK} size={30} onClick={(e) => handleSocAuth(e, ESocAuthType.OK)}/>
          </div>
        </TWSocAuth>
      </If>
    </TWAuth>
  );
});

const TWAuth = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWButton = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid grid-cols-a1 items-center content-center col-gap-15p px-15p rounded-xs
     border border-solid border-gray-350 cursor-pointer w-full h-50p
  `,
  tw`hover:bg-gray-200`,
  css``
]);
const TWSocAuth = styled("div")(({ theme, darkMode, disabled }) => [
  tw`inline-grid relative w-full`,
  css`justify-items: center;`
]);
