import React from 'react';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import {EIcon} from 'components';

export function useBrowser() {
  try {
    let details = useUserAgent();
    let {browser} = details;
    const icons = {
      Chrome: EIcon.Chrome, Firefox: EIcon.Firefox, Opera: EIcon.Opera, Yandex: EIcon.Yandex
    };
    return [icons[browser.name], browser.name];
  } catch (e) {
    return [null, null];
  }
}
