import React, {useEffect} from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Avatar, Billing, BillingCancelSub, BillingChangeMethod,
  Button,
  Buttons, ClearHistory, ComparePlans, ComparePlansMobile, DeleteAccount,
  EButtonKind,
  EButtonsAlign, EIcon,
  EPanelWidth,
  EProgressKind,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight, HelpTranslate,
  Icon, If,
  Line,
  LinkButton,
  Modal,
  Panel,
  PanelFooter,
  PanelHeader, ProfileChangeEmail, ProfileChangeLang, ProfileChangeName, ProfileChangePwd,
  Progress,
  SpaceBox,
  TextBox, UpgradePlan
} from 'components';
import {declension, extractTextProps, formatPrice, humanFileSize, ifCond, processApiResponse, str} from 'utils';
import {PLAN, ROUTE, FIELD} from 'const';
import {useStoreActions, useStoreState, history} from "../../../store";
import {FileButton} from "../../base/Button/FileButton";
import {DeleteAvatar} from "../Confirm/DeleteAvatar";
import {useMediaBreakpoints} from "../../../hooks";

export interface IProfile extends TThemed {
  profile: any;
  onComparePlansClick?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

const BillingProps = {
  method: "•••• •••• •••• 2814",
  nextBill: new Date(2020, 4, 21),
  history: [
    {date: new Date(2020, 0, 17), price: {amount: 36, currency: "USD"}, paid: true, plan: PLAN.PRO},
    {date: new Date(2020, 0, 1), price: {amount: 1200, currency: "USD" }, paid: false, plan: PLAN.TEAM},
  ]
};

export const Profile = React.forwardRef((props: IProfile, ref) => {
  const MSG = useStoreState(s=>s.MSG);
  const { profile={}, darkMode=false, demo=false, path, push, setName, setEmail, setPassword, setLanguage, dropHistoryFiles, dropUser, doLogout, dropAvatar, uploadAvatar, getHistoryPeriod, getHistory, info, languagesList, if:_if=true, ...rest } = props;
  const [avatarFile, setAvatarFile] = React.useState(null);
  const loadLink = useStoreState(state=>state.avatarLoad);
  const putAvatar = useStoreActions(a=>a.putAvatar);
  const setLink = useStoreActions(a=>a.setAvatarLoad);
  const bp = useMediaBreakpoints();
  
  const askSetName = useStoreActions(a=>a.screenshots.askSetName);
  const askSetEmail = useStoreActions(a=>a.screenshots.askSetEmail);
  const askSetPassword = useStoreActions(a=>a.screenshots.askSetPassword);
  const askSetPlans = useStoreActions(a=>a.screenshots.askSetPlans);
  const askSetPayPlan = useStoreActions(a=>a.screenshots.askSetPayPlan);
  const askSetHelpTranslate = useStoreActions(a=>a.screenshots.askSetHelpTranslate);
  const askSetClearHistory = useStoreActions(a=>a.screenshots.askSetClearHistory);
  const askSetDeleteAccount = useStoreActions(a=>a.screenshots.askSetDeleteAccount);
  const askSetLanguage = useStoreActions(a=>a.screenshots.askSetLanguage);
  const askSetDeleteAvatar = useStoreActions(a=>a.screenshots.askSetDeleteAvatar);
  
  const fileInput = React.useRef(null)
  
  const onChangePhotoClick = () => {
    fileInput.current.click()
  }
  
  const onSelectPhoto = async (e) => {
    if (e.currentTarget.files[0]) {
      uploadAvatar()
      let file = e.currentTarget.files[0];
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function() {
        setAvatarFile(reader.result)
      };
      reader.onerror = function() {
        console.error(reader.error);
      };
    }
  };
  
  React.useEffect(() => {
    if (loadLink?.link && avatarFile) {
      putAvatar({image: avatarFile, link: loadLink.link, MSG})
      setLink({link: ''})
      setAvatarFile('')
    }
  }, [loadLink, avatarFile])
  
  const ComparePlansProps = {
    profile: { plan: PLAN.FREE }
  };
  const handleGo = React.useCallback((to, rep=false) => {
    push({to})
  }, [push]);
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWProfile ref={ref} darkMode={darkMode} {...rest}>
      {/*<Modal if={!!modalType} closeOnMisclick={!!modalType} onClose={() => {setModalType('')}}>*/}
      {/*  {modalType === 'name' && <ProfileChangeName username={profile.username} onSubmit={(e, value) => {setModalType(''); setName(value)}} onClose={() => {setModalType('')}} />}*/}
      {/*  {modalType === 'email' && <ProfileChangeEmail onSubmit={(e, value) => {setModalType(''); setEmail(value)}} onClose={() => {setModalType('')}}/>}*/}
      {/*  {modalType === 'password' && <ProfileChangePwd onSubmit={(e, value) => {setModalType(''); setPassword(value)}} onClose={() => {setModalType('')}}/>}*/}
      {/*  {modalType === 'language' && <ProfileChangeLang languages={languagesList} onSubmit={(value) => {setModalType(''); setLanguage(value)}} onClose={() => {setModalType('')}}/>}*/}
      {/*  {modalType === 'clear-history' && <ClearHistory onGetHistory={getHistory} info={info} onSubmit={(e, value) => {setModalType(''); dropHistoryFiles(value)}} onClose={() => {setModalType('')}} />}*/}
      {/*  {modalType === 'delete-account' && <DeleteAccount onSubmit={(e, value) => {setModalType(''); dropUser(value); doLogout(); }} onClose={() => {setModalType('')}}/>}*/}
      {/*  {modalType === 'help-translate' &&  <HelpTranslate onSubmit={() => {setModalType('')}} onClose={() => {setModalType('')}}/>}*/}
      {/*  {modalType === 'delete-avatar' &&  <DeleteAvatar onSubmit={() => {dropAvatar(); setModalType('')}} onClose={() => {setModalType('')}} />}*/}
      {/*  {modalType === 'plans' && (bp.mobile ? <ComparePlansMobile {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/> : <ComparePlans {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/>)}*/}
      {/*  {modalType === 'upgrade-pro' && <UpgradePlan plan={PLAN.PRO} onClose={() => setModalType('plans')}/>}*/}
      {/*  {modalType === 'upgrade-team' && <UpgradePlan plan={PLAN.TEAM} onClose={() => setModalType('plans')}/>}*/}
      {/*</Modal>*/}
      <Panel noShadow={!bp.desktop} mobileCenter={true} padding={!bp.mobile ? 30 : 10} caption={MSG.PROFILE.HEADER} width={!bp.mobile ? 700 : 'auto'} align={ETextAlign.CENTER}>
        <SpaceBox height={22} width="100%"/>
        <Line />
        <Row avatar={true} caption={bp.desktop ? MSG.PROFILE.PHOTO : ''} captionWidth={100} actionContent={<div tw="h-full w-full"><FileButton ref={fileInput} onChange={(e) => onSelectPhoto(e)}/><LinkButton text={MSG.PROFILE.CHANGE_PHOTO} onClick={() => {onChangePhotoClick()}}/>
        <If if={!!profile.avatar}><TextBox text={` ${MSG.OR} `} color={ETextColor.SECONDARY}/><LinkButton text={MSG.PROFILE.REMOVE_PHOTO} onClick={() => {if (profile.avatar) askSetDeleteAvatar()}}/></If>
        </div>}>
          <Avatar avatar={profile.avatar || ''}/>
        </Row>
        <Line />
        <Row caption={MSG.PROFILE.NAME} captionWidth={100} actionContent={<LinkButton
          onClick={() => {askSetName({username: profile.username})}}
          // onClick={() => {setModalType('name')}}
          text={MSG.PROFILE.CHANGE_NAME}/>}>
          <TextBox text={profile.username} color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        <Row caption={MSG.PROFILE.EMAIL} captionWidth={100}
             actionContent={<LinkButton onClick={() => {askSetEmail()}}
                                        // text={MSG.PROFILE.CHANGE_EMAIL}
                                        text={''}
             />}>
          
          <TextBox text={profile.email} color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        <Row caption={MSG.PROFILE.PWD} captionWidth={100} actionContent={<LinkButton onClick={() => {askSetPassword()}} text={MSG.PROFILE.CHANGE_PWD}/>}>
          <TextBox text={Array(profile.passwordLength || 6).fill("•")} color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        <Row caption={MSG.PROFILE.LANG} captionWidth={100} actionContent={<div tw="h-full w-full"><Icon if={!bp.mobile} size={16} icon={EIcon.GlobeGray} tw={'mr-10p'}/><LinkButton if={!bp.mobile} onClick={() => {askSetHelpTranslate()}} text={MSG.PROFILE.HELP_TRANSLATE} tw={'mr-30p'}/><LinkButton onClick={() => {askSetLanguage({languages: languagesList})}} text={MSG.PROFILE.CHANGE_LANG}/></div>}>
          
          <TextBox text={profile.language && profile.language.name} color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        {/* <SpaceBox height={40}/>
        <PanelHeader text="One-click login" />
        <SpaceBox height={20}/>
        <TWOneClickLogin>
          <TWLoginButton>
            <Icon icon="SocGoogle" size={EIconSize.MD}/>
            <TextBox text={profile.isLoggedIn ? "Disconnect Google Login" : "Connect Google"}/>
          </TWLoginButton>
          <TextBox text={MSG.PROFILE.GOOGLE_LOGIN} width={280} color={ETextColor.SECONDARY} inline={false} fillY={true}/>
          <div/>
        </TWOneClickLogin>*/}
        <SpaceBox height={40}/>
        <TWHeader>
          <PanelHeader text={MSG.PROFILE.USAGE} />
        </TWHeader>
        <SpaceBox height={20}/>
        <div>
          <Progress kind={EProgressKind.LG} value={profile.quota_used || 0} min={0} max={profile.plan.quota_total}/>
          <SpaceBox height={10} width="100%"/>
          <div tw="flex">
            <TextBox text={ str( MSG.PROFILE.SPACE_USED, humanFileSize(profile.quota_used || 0, 0), humanFileSize(profile.plan.quota_total, 0) ) } inline={false}/>
            <SpaceBox width="stretch" inline={true}/>
            <TextBox if={!bp.mobile} text={(profile.count_item || 0) + " " + declension(profile.count_item, MSG.PROFILE.ITEMS)} />
            <SpaceBox if={!!profile.count_item} width={10} inline={true}/>
            <LinkButton if={!!profile.count_item} onClick={() => {askSetClearHistory()}} text={MSG.PROFILE.CLEAR_HISTORY}/>
          </div>
        </div>
        <SpaceBox height={40}/>
        <TWHeader><PanelHeader text={MSG.PROFILE.SUB_PLAN} /></TWHeader>
        <SpaceBox height={20}/>
        <TWPlanes>
          {[PLAN.FREE, PLAN.PRO, PLAN.TEAM].map((plan, i, plans) => {
            const isActive = profile.plan && profile.plan.unique===plan.unique;
            const isLeft = i===0;
            const isMiddle = i>0 && i<plans.length-1;
            const isRight = i===plans.length-1;
            return (
              <TWSelectPlanButton
                onClick={() => askSetPlans({
                  mobile: bp.mobile,
                  onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
                  onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
                  ...ComparePlansProps})} key={i} isActive={isActive} isLeft={isLeft} isRight={isRight} isMiddle={isMiddle}>
                <TWSelectPlanButtonActive isActive={isActive} isLeft={isLeft} isRight={isRight} isMiddle={isMiddle}>
                  <Icon if={plan.icon} icon={isActive ? (plan.iconActive || plan.icon) : plan.icon} size={plan.size}/>
                  <SpaceBox if={plan.icon} width={15} inline={true}/>
                  <div tw="inline-grid grid-flow-row">
                    <TextBox text={plan.name} color={ETextColor.PRIMARY} weight={ETextWeight.SEMIBOLD} size={ETextSize.MEDIUM} className="subscription-plan-name" />
                    <TextBox text={
                      isActive ? MSG.PROFILE.CURRENT_PLAN :
                        str(MSG.PROFILE.PRICE_PER_MONTH, formatPrice(plan.yearly), plan.unique===PLAN.TEAM.unique ? MSG.PROFILE.PER_USER_MONTH : MSG.PROFILE.PER_MONTH)
                    } color={ETextColor.SECONDARY} className="subscription-plan-price"
                    />
                  </div>
                </TWSelectPlanButtonActive>
              </TWSelectPlanButton>
            );
          })}
        </TWPlanes>
        
        <SpaceBox height={15}/>
        <TWComparePlansButton onClick={() => askSetPlans({
          mobile: bp.mobile,
          onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
          onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
          ...ComparePlansProps})}>
          <TextBox text={MSG.PROFILE.COMPARE_PLANS}  color={ETextColor.BLUE} className="compare-plans-button-caption"/>
        </TWComparePlansButton>
        <SpaceBox height={40}/>
        <TWDeleteAccount>
          <PanelHeader text={MSG.PROFILE.DELETE_ACCOUNT} />
          <div tw="mt-5p mb-15p">
            <TextBox text={MSG.PROFILE.DELETE_FILES} color={ETextColor.SECONDARY}/>
          </div>
          <TWDeleteAccountButton onClick={() => askSetDeleteAccount()}>
            <TextBox text={MSG.PROFILE.TO_DELETE_ACCOUNT} color={ETextColor.PRIMARY} className="delete-account-button-caption" />
          </TWDeleteAccountButton>
        </TWDeleteAccount>
      </Panel>
    </TWProfile>
  );
});

interface IRow {
  caption: string;
  captionWidth?: number;
  children: React.ReactNode;
  actionContent: React.ReactNode;
  if?: boolean | (() => boolean);
  avatar?: boolean;
  height?: number;
}
const Row = (props: IRow) => {
  const { caption, captionWidth=100, children, actionContent, avatar=false, height, if:_if=true } = props;
  const CaptionProps = {
    ...extractTextProps(caption)
  };
  if (!ifCond(_if)) return null;
  return (<TWRow avatar={avatar} height={height} caption={!!caption} captionWidth={captionWidth}>
    <If if = {!!caption}><TWCaptionCol><TextBox color={ETextColor.SECONDARY} {...CaptionProps}/></TWCaptionCol></If>
    <TWValueCol avatar={avatar}>{children}</TWValueCol>
    <TWActionCol avatar={avatar}>{actionContent}</TWActionCol>
  </TWRow>);
};

const TWProfile = styled("div")(() => [ tw`w-full`, css`` ]);
const TWPlanes = styled("div")(() => [ tw`grid grid-cols-3 w-full`, tw`mobile:grid-cols-1`, css`` ]);
const TWHeader = styled("div")(() => [ tw`w-full`, tw`mobile:text-center`, css`` ]);
const TWRow = styled("section")(({captionWidth, caption, avatar, height}) => [
  tw`grid relative w-full h-50p items-center content-center text-center`,
  tw`mobile:h-70p`,
  avatar && tw`mobile:justify-center mobile:h-100p mobile:grid-cols-none`,
  caption && tw`col-gap-15p`,
  css`grid-template-columns: ${captionWidth}px 1fr auto` ]);
const TWCaptionCol = styled("article")(() => [ tw`inline-grid relative h-full justify-start grid-flow-col items-center content-center justify-self-start`, css`` ]);
const TWValueCol = styled("article")(({avatar}) => [
  tw`inline-grid relative h-full justify-start grid-flow-col items-center content-center justify-self-start`,
  avatar && tw`mobile:justify-self-center`,
  css``
]);
const TWActionCol = styled("article")(({avatar}) => [
  tw`inline-grid relative h-full text-right justify-end grid-flow-col items-center content-center justify-self-end`,
  !avatar && tw`mobile:col-start-2 mobile:row-start-2 mobile:text-left mobile:justify-self-start`,
  css``

]);
const TWOneClickLogin = styled("div")(() => [ tw`grid col-gap-25p grid-flow-col`,
  css`
    grid-auto-columns: auto;
    grid-template-columns: auto auto 1fr;
  `]);
const TWLoginButton = styled("div")(({disabled}) => [
  tw`inline-grid grid-cols-a1 col-gap-15p items-center content-center
     h-50p pl-15p rounded-xs border border-solid border-gray-350`,
  !disabled && tw`cursor-pointer`,
  css`width: 243px;`
]);
const TWSelectPlanButton = styled("div")(({ isActive, isLeft, isMiddle, isRight, disabled }) => [
  tw`inline-block h-70p bg-gray-200 border border-solid border-gray-350`,
  isActive && tw`bg-white`,
  isLeft && tw`desktop:rounded-l-xs tablet:rounded-l-xs mobile:rounded-t-xs`,
  isMiddle && tw`desktop:border-l-0 desktop:border-r-0 tablet:border-l-0 tablet:border-r-0 mobile:border-b-0 mobile:border-t-0`,
  isRight && tw`desktop:rounded-r-xs tablet:rounded-r-xs mobile:rounded-b-xs`,
  !disabled && tw`cursor-pointer`
]);
const TWSelectPlanButtonActive = styled("div")(({ isActive, isLeft, isRight }) => [
  tw`inline-grid grid-cols-aa1 items-center content-center w-full h-full pl-20p`,
  isActive && tw`desktop:border-b-2 desktop:border-sky desktop:border-solid tablet:border-b-2 tablet:border-sky tablet:border-solid mobile:border-l-2 mobile:border-sky mobile:border-solid`,
  isLeft && tw`desktop:rounded-l-xs tablet:rounded-l-xs mobile:rounded-t-xs`,
  isRight && tw`desktop:rounded-r-xs tablet:rounded-r-xs mobile:rounded-b-xs`
]);
const TWComparePlansButton = styled("div")(({ disabled }) => [
  tw`inline-grid grid-cols-auto items-center content-center justify-center
     w-full h-50p rounded-xs border border-solid border-gray-350`,
  tw`mobile:h-70p`,
  !disabled && tw`cursor-pointer`,
  css`
    justify-items: center;
    &:hover>.compare-plans-button-caption { text-decoration: underline; }
  `
]);
const TWDeleteAccountButton = styled("div")(({ disabled }) => [
  tw`inline-grid grid-cols-auto items-center content-center justify-center
     h-50p rounded-xs border border-solid border-gray-350`,
  !disabled && tw`cursor-pointer`,
  css`
    width: 154px;
    justify-items: center;
    &:hover>.delete-account-button-caption { text-decoration: underline; }
  `
]);

const TWDeleteAccount = styled("div")(() => [
  tw`flex flex-col`,
  tw`tablet:items-center mobile:items-center`,
]);
