import React from 'react';
import tw, { styled, css } from 'twin.macro';

export interface IConnectedModal extends TThemed {

  demo?: boolean;
  [prop: string]: any;
}

export const ConnectedModal = React.forwardRef((props: IConnectedModal, ref) => {
  const { darkMode=false, demo=false, ...rest } = props;
  return (
    <TWConnectedModal ref={ref} {...rest}/>
  );
});

const TWConnectedModal = styled("div")(({ darkMode }) => [
    tw``,
    css``
  ]
);