import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {TextBox, SpaceBox, Line, EButtonKind, Button} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {ROUTE} from 'const';
import {useStoreActions, useStoreState} from 'hooks';

export interface ILanding extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const Landing = React.forwardRef((props: ILanding, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const push = useStoreActions(actions => actions.router.push);
  const MSG = useStoreState(s=>s.MSG);
  if ( !ifCond(_if) ) return null;
  return (
    <TWLanding ref={ref} darkMode={darkMode} {...rest}>
      <div tw="flex items-center justify-center mx-auto w-200p">
        <div tw="w-full">
          <Button caption={MSG.AUTH.SIGN_IN} onClick={() => push({to: ROUTE.AUTH})} kind={EButtonKind.PRIMARY} fill={true}/>
          <SpaceBox height={20} />
          <Button caption={MSG.AUTH.SIGN_UP} onClick={() => push({to: ROUTE.REG})} kind={EButtonKind.OUTLINED_PRIMARY} fill={true}/>
        </div>
      </div>
    </TWLanding>
  );
});

const TWLanding = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex absolute w-full h-full`,
  css``
]);
