import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {EScrollDir, Header, Main} from 'components';
import { useStoreState } from 'store';
import {ifCond} from 'utils';
import {PORTAL} from 'const';

export interface IBaseLayout extends TThemed {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;//never used yet
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const BaseLayout = React.forwardRef((props: IBaseLayout, ref) => {
  const { header, children, footer, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const headerOpened = useStoreState(s=>s.screenshots.headerOpened);
  const zoom = useStoreState(state => state.zoom);
  if ( !ifCond(_if) ) return null;
  return (
    <TWBaseLayout ref={ref} darkMode={darkMode} headerOpened={headerOpened} {...rest}>
      <Header id={PORTAL.HEADER} darkMode={darkMode}>{header}</Header>
      <div id={PORTAL.UNDER_HEADER} tw="fixed right-0 top-0 z-50"/>
      <div id={PORTAL.UNDER_HEADER2} />
      <Main id={PORTAL.MAIN} zoom={zoom} darkMode={darkMode} scroll={EScrollDir.BOTH}>{children}</Main>
      {footer}
    </TWBaseLayout>
  );
});

const TWBaseLayout = styled("div")(({ theme, darkMode, disabled, headerOpened }) => [
    tw`w-full h-full min-h-screen`,
    darkMode ? tw`bg-accent-900` : tw`bg-accent-100`,
    css``,
    headerOpened && css`
    &:after {
      content: "";
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: transparent;
      height: 100vh;
    }
    `
  ]
);