import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {cssSize, ifCond} from 'utils';

export interface ISpaceBox extends TThemed {
  width?: string | number;
  height?: string | number;
  inline?: boolean;
  if?: boolean | (() => boolean);
}

export const SpaceBox = React.forwardRef((props: ISpaceBox, ref) => {
  const { width=0, height=0, inline=false, if:_if=true, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWSpaceBox ref={ref} {...{width, height, inline}} {...rest}/>
  );
});
const TWSpaceBox = styled("div")(({ theme, width, height, inline }) => [
  inline ? tw`inline-grid` : tw`grid`,
  css`
    ${cssSize(height, "height")}
    ${width==="stretch" ? "flex: 1 1 auto;" : cssSize(width, "width")}
  `
]);