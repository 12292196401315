import {useLayoutEffect, useCallback, useState, useEffect} from "react";
import {useRect, useWindowRect} from 'hooks';

export function useDropDownPos<T extends HTMLElement> (comboRef: React.RefObject<T>, listParams?) {

  const comboRect = useRect(comboRef);
  const windowRect = useWindowRect();
  const { height: wh, width: ww } = windowRect;
  const { itemHeight, itemCount } = listParams || {} as any;
  const [res, setRes] = useState<object>({
    dirUp: false,
    height: (itemHeight || 50) * (itemCount || 4)
  });
  //+ top or bottom
  useEffect(() => {
    let listHeight = (itemHeight || 50) * (itemCount || 4);
    const topSpace = comboRect.top;
    const bottomSpace = wh-comboRect.bottom;
    let dirUp = false;
    const resAppend = {};
    if (bottomSpace < listHeight) {
      if (topSpace >= listHeight) {
        dirUp = true;
      } else {
        const max = Math.max(topSpace, bottomSpace);
        listHeight = max;
        if (max===topSpace) dirUp = true;
      }
    }
    resAppend["top"] = dirUp ? comboRect.top-listHeight : comboRect.bottom;
    setRes({
      ...resAppend,
      dirUp,
      height: listHeight,
      width: comboRect.width
    });
  }, [listParams, comboRect, windowRect]);

  return res;
}
