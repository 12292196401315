import {action, Action, ActionOn, actionOn, computed, Computed, thunk, Thunk, } from "easy-peasy";
import {IStoreModel} from 'store';
import { remove } from 'lodash';
import {FIELD, LOCAL_PARAM, ROUTE, URL_PARAM} from 'const';
import {ICardItem, ICardPaneItem, ICardTag, IFunctions, IObject } from 'types';
import {EDialogAction, EDialogConfirm, EDialogInfo, EDialogType, TDialog, IDialogResponse} from 'pages/widgets';
import {
  concatSearchParams,
  MessageList,
  extractRouterParams,
  ValidationError,
  getFieldErrorsOnly,
  hasFieldErrors,
  getObjectArrayComparator,
} from 'utils';

export interface IDialogsModel {
  dialogs: IDialogs;
}
export interface IDialogs {
  //state
  type: EDialogType;
  subType: TDialog;
  props: any;
  resolve: ((IDialogResponse) => any) | null;
  reject: ((any) => any) | null;
  //computed
  //actions: IFunctions;
  //actions
  close: Action<IDialogs>;
  setType: Action<IDialogs, [EDialogType.ACTION, EDialogAction] | [EDialogType.CONFIRM, EDialogConfirm] | [EDialogType.INFO, EDialogInfo]>;
  setProps: Action<IDialogs, any>;
  setPromise: Action<IDialogs, {resolve: ((IDialogResponse) => void); reject: ((any) => void)} | null>;
  setDialog: Action<IDialogs, [EDialogType, EDialogAction | EDialogConfirm | EDialogInfo, any]>;
  //thunks
  showDialog: Thunk<IDialogs, [EDialogType.ACTION, EDialogAction, any] | [EDialogType.CONFIRM, EDialogConfirm, any] | [EDialogType.INFO, EDialogInfo, any], any, IStoreModel>;
  action: Thunk<IDialogs, [EDialogAction, any]>;
  confirm: Thunk<IDialogs, [EDialogConfirm, any]>;
  info: Thunk<IDialogs, [EDialogInfo, any]>;
  //listeners
}

const stateDefaults = {
  type: null,
  subType: null,
  props: null,
  resolve: null,
  reject: null,
};
export const dialogsModel: IDialogs = {
//state
  type: stateDefaults.type,
  subType: stateDefaults.subType,
  props: stateDefaults.props,
  resolve: stateDefaults.resolve,
  reject: stateDefaults.reject,
//computed
//   actions: computed((state) => {
//     return {
//       onSubmit: () => {}
//     };
//   }),
//actions
  close: action((state, payload) => {
    try {
      document.body.style.overflow = "";
    } catch (e) {}
    return {...state, ...stateDefaults};
  }),
  setType: action((state, [type, subType]) => {
    return {...state, type, subType};
  }),
  setProps: action((state, props) => {
    return {...state, props};
  }),
  setPromise: action((state, promise?) => {
    const {resolve, reject} = promise || {resolve: null, reject: null};
    return {...state, resolve, reject};
  }),

  setDialog: action((state, [type, subType, props]) => {
    
    try {
      document.body.style.overflow="hidden";
    } catch (e) {}
    return {...state, type, subType, props};
  }),
//thunks
  showDialog: thunk(async (actions, [type, subType, props]) => {
    //new Promise<IDialogResponse>;
    return new Promise<IDialogResponse>(async (resolve, reject) => {
      actions.setPromise({resolve, reject});
      await actions.setDialog([type, subType, props]);
    }).catch(() => actions.close());

  }),
  action: thunk(async (actions, [subType, props]) => {
    return actions.showDialog([EDialogType.ACTION, subType, props]);
  }),
  confirm: thunk(async (actions, [subType, props]) => {
    return actions.showDialog([EDialogType.CONFIRM, subType, props]);
  }),
  info: thunk(async (actions, [subType, props]) => {
    return actions.showDialog([EDialogType.INFO, subType, props]);
  }),
//listeners
};
