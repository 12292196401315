import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {ETextAlign, ETextColor, ETextSize, ETextWeight, TextBox, ITextBox} from 'components';
import { useSplitEffect } from 'hooks';
import { extractTextProps, ifCond } from 'utils';

export interface ISwitch extends TThemed {
  checked?: boolean;
  leftText?: string | ITextBox | Array<string | ITextBox>;
  rightText?: string | ITextBox | Array<string | ITextBox>;
  disabled?: boolean;
  demo?: boolean;
  isUser?: boolean;
  [prop: string]: any;
}

export const Switch = React.forwardRef((props: ISwitch, ref) => {
  const { checked=false, disabled=false, isUser=false, if:_if=true, leftText, rightText, onChange, darkMode=false, demo=false, ...rest } = props;
  const leftProps = extractTextProps(leftText, checked);
  const rightProps = extractTextProps(rightText, checked);
  const [marked, setMarked] = React.useState({checked, leftProps, rightProps});
  const handleChecked = React.useCallback((e) => {
    if (onChange instanceof Function) {
      const allowDefault = onChange(e, e.target.checked);
      if (allowDefault === false) {
        e.preventDefault();
        return false;
      }
    }
    if (e.target.checked !== marked.checked) setMarked({
      checked: e.target.checked,
      leftProps: extractTextProps(leftText, e.target.checked),
      rightProps: extractTextProps(rightText, e.target.checked),
    });
  }, [marked, setMarked, onChange, leftText, rightText]);
  // React.useEffect(() => {
  //   setMarked({
  //     checked,
  //     leftProps: extractTextProps(leftText, checked),
  //     rightProps: extractTextProps(rightText, checked),
  //   });
  // },[checked, leftText, rightText]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWSwitchLabel isUser={isUser} darkMode={darkMode} side={+ifCond(leftProps.if) + 2*(+ifCond(rightProps.if))}>
      <TWLabel isUser={isUser}>
        <TextBox {...marked.leftProps} darkMode={darkMode} align={ETextAlign.RIGHT}/>
      </TWLabel>
      <TWSwitch isUser={isUser} darkMode={darkMode}>
        <TWCheckBox ref={ref} darkMode={darkMode} checked={marked.checked} disabled={disabled} onChange={handleChecked} type="checkbox" {...rest}/>
      </TWSwitch>
      <TextBox {...marked.rightProps} darkMode={darkMode} align={ETextAlign.LEFT}/>
    </TWSwitchLabel>
  );
});

const TWSwitchLabel = styled("label")(({ darkMode, side, isUser }) => [
    tw`inline-grid items-center col-gap-10p align-middle cursor-pointer m-0 p-0`,
    [tw`grid-cols-auto`,tw`grid-cols-1a`,tw`grid-cols-a1`,tw`grid-cols-1a1`][(side)],
    isUser && tw`mobile:grid-cols-auto`,
    
    css`
    
    `
  ]
);
const TWLabel = styled("div")(({reverse, isUser}) => [
  isUser && tw`mobile:col-start-2 mobile:row-start-1 `,
  ]
);
const TWSwitch = styled("div")(({reverse, isUser}) => [
    tw`flex cursor-pointer align-top items-center`,
    tw`disabled:cursor-not-allowed`,
  isUser && tw`mobile:col-start-1 mobile:row-start-1`,
    reverse ? tw`justify-end` : tw`justify-start`
  ]
);
const TWCheckBox = styled("input")(({ reverse, text }) => [
  tw`inline-block relative outline-none align-top cursor-pointer`,
  tw`bg-ashen border-accent-400 border border-solid m-0`,

  tw`hover:border-accent-700`,
  tw`disabled:bg-accent-100 disabled:cursor-not-allowed disabled:opacity-90
     disabled:border-accent-200`,//disabled

  tw`checked:bg-accent-700 checked:border-accent-700 checked:hover:border-accent-400
      disabled:checked:bg-accent-200 disabled:checked:border-accent-200`,//checked

  tw`after:bg-white after:border-none after:opacity-100 after:rounded-full
      after:absolute after:block
      after:w-14p after:h-14p after:m-1p after:left-0`,//after
  tw`disabled:after:border disabled:checked:after:border-none disabled:after:border-accent-200 disabled:after:border-solid`,
  tw`checked:after:left-14p`,//checked:after
  tw`w-32p h-18p rounded-sm`,
  text && (reverse ? tw`ml-10p` : tw`mr-10p`),
  css`
      --focus: 2px rgba(0, 94, 197, .3);/*(39, 94, 254, .3);*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:after {
        content: '';
        transition: left var(--d-o, .2s);
      }
      &:checked {
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
  `]
);
