import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { ObserverGroup, elastic } from 'react-tweenful';
import {TextBox, SpaceBox, Line, Snack, ESnackKind} from 'components';
import {cssSize, ifCond, str, Tip, ValidationError} from 'utils';
import {useMediaBreakpoints, useStoreActions, useStoreState} from 'hooks';
import { MSG } from 'const';

export interface INotificator extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const Notificator = React.forwardRef((props: INotificator, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const messages = useStoreState(state => state.commonMessages);
  const pushMessage = useStoreActions(actions => actions.pushCommonMessage);
  const clearMessage = useStoreActions(actions => actions.clearCommonMessage);
  const handleClose = React.useCallback((e, id) => {
    clearMessage(id);
  }, [clearMessage]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWNotificator ref={ref} darkMode={darkMode} {...rest}>
      <TWNotifications>
        <ObserverGroup
          config={{
            duration: 800,
            style: { overflow: 'hidden' },
            mount: { right: ['-300px', '20px'] },
            unmount: { opacity: [1, 0] },
            easing: elastic(0.5, 0.1)
          }}
          skipInitial={true}
        >
          {messages.concat([]).reverse().map((msg, key) => (
            <Snack
              key={msg.id}
              id={msg.id}
              icon={msg.icon}
              text={msg.message}
              onClose={handleClose}
              kind={ESnackKind.DESKTOP}
            />
          ))}
        </ObserverGroup>
      </TWNotifications>
    </TWNotificator>
  );
});

const TWNotificator = styled("div")(({ theme, darkMode, disabled }) => [
  tw`fixed right-0 top-0 pt-80p max-w-300p`,
  // tw`mobile:max-w-screen mobile:pt-0`,
  css`z-index: 9999`,
]);
const TWNotifications = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative`,
  css``
]);
