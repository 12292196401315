import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {CardPane, CardPaneLoader} from 'components';
import {debounce, ifCond} from 'utils';
import {usePubSub, useStoreActions, useStoreState} from 'hooks';
import {PORTAL, PUBSUB, ROUTE, EIcon, PLAN, FIELD} from 'const';
import { ICardItem } from 'types';
import { isEmpty } from 'lodash';
import {IHistoryModel} from "../../../store/model";

export interface IHistoryPage extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const HistoryPage = React.forwardRef((props: IHistoryPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [root, loading, cardItems, path, selected, selectionMode, headerOpened, tagList, plan] = useHistoryState();
  const [getHistory, moreHistory, setSelected, askSetCardTags, setControls, goto] = useHistoryActions();
  const getProfile = useStoreActions(a=>a.getProfile);
  const connect = useStoreActions(a=>a.connect);
  const isLoggedIn = useStoreState(s=>s.isLoggedIn);
  
  const getItemActions = useHistoryContextMenu(typeof selectionMode == "boolean" && selectionMode);
  const handleMore = React.useCallback(() => { moreHistory(null); }, []);
  const handleSelect = React.useCallback((e, _selected, _id) => { setSelected(_selected); }, [setSelected]);
  const handleTagClick = usePubSub(PUBSUB.HISTORY.FILTER_CHANGE);
  const handleView = React.useCallback(async (slug) => {
    await setControls(true);
    goto({to: ROUTE.SLUG.replace(":slug", slug)} as any);
  },[goto]);
  const handleScrolledToBottom = React.useCallback(debounce(() => {
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) { handleMore(); }
  }, 50), []);
  const headerEl = document.querySelector("#"+PORTAL.FILTER);
  const headerRect = headerEl && headerEl.getBoundingClientRect() || { height: 50 };
  const cardHeaderOffset = headerOpened ? headerRect.height + 30 : 80;
  React.useLayoutEffect(() => {
    if (!(handleMore instanceof Function)) return;
    window.addEventListener("scroll", handleScrolledToBottom);
    return () => {
      window.removeEventListener("scroll", handleScrolledToBottom);
    }
  }, [handleScrolledToBottom]);
  React.useLayoutEffect(() => {
    if (path===ROUTE.HISTORY) {
      getHistory(false)
    };
  }, [path]);
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWHistoryPage ref={ref} darkMode={darkMode} {...rest}>
      <CardPane
        items={cardItems}
        selected={selected}
        onSelect={handleSelect}
        onTagClick={(e, tag) => handleTagClick([e, "tag", tag.name])}
        onAddTagClick={askSetCardTags}
        onView={handleView}
        getItemActions={getItemActions}
        selectionMode={selectionMode}
        hasSelection={true}
        hasTags={[PLAN.TEAM.unique, PLAN.PRO.unique].includes(plan as string | null)}
        headerOffset={cardHeaderOffset}
        hasMenu={true}
      >
        <CardPaneLoader if={!!loading} darkMode={darkMode}/>
      </CardPane>
    </TWHistoryPage>
  );
});
const TWHistoryPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative pt-20p w-full`,
  css``
]);

const useHistoryActions = () => {
  const getHistory = useStoreActions(actions => actions.screenshots.getHistory);
  const moreHistory = useStoreActions(actions => actions.screenshots.moreHistory);
  const setSelected = useStoreActions(actions => actions.screenshots.setSelected);
  const askSetCardTags = useStoreActions(actions => actions.screenshots.askSetCardTags);
  const setControls: (boolean)=>void = useStoreActions(actions => actions.screenshots.setControls);
  const goto = useStoreActions(actions => actions.router.push);
  return [getHistory, moreHistory, setSelected, askSetCardTags, setControls, goto];
};

const useHistoryState = () => {
  const root = useStoreState(state => state.router.root);
  const cardItems = useStoreState(state => state.screenshots.items);
  const loading = useStoreState(state => state.screenshots.loading);
  const path = useStoreState(state => state.router.path);
  const selected = useStoreState(state => state.screenshots.selected);
  const selectionMode = useStoreState(state => state.screenshots.selectionMode);
  const headerOpened = useStoreState(state => state.screenshots.headerOpened);
  const tagList = useStoreState(state => state.screenshots.tagList);
  const plan = useStoreState(state => state.plan);
  return [root, loading, cardItems, path, selected, selectionMode, headerOpened, tagList, plan];
};

export const useSlugContextMenu = () => {
  const MSG = useStoreState(state => state.MSG);
  const profile = useStoreState((state) => state.profile);
  const copyItemLinks = useStoreActions(actions => actions.screenshots.copyItemLinks);
  const downloadItem = useStoreActions(actions => actions.screenshots.downloadItem);
  const askShareItems = useStoreActions(actions => actions.screenshots.askShareItems);
  const askDeleteItems = useStoreActions(actions => actions.screenshots.askDeleteItems);
  const askSetCardTags = useStoreActions(actions => actions.screenshots.askSetCardTags);
  const setUnblockSlug = useStoreActions(actions => actions.screenshots.setUnblockSlug);
  const askProtectCard = useStoreActions(actions => actions.screenshots.askProtectCard);
  const askUnprotectCard = useStoreActions(actions => actions.screenshots.askUnprotectCard);
  //copyItemLinks, downloadItems, askShareItems, askDeleteItems, askSetCardTags
  return React.useCallback((item: any) => {
    if (isEmpty(item)) return []; //TODO uncomment this when rerender on history click will be removed
    if (!item.currentItem) {
      return [];
    }
    const items = [].concat(item);
    const actions = [];
    if (!item?.currentItem.length) {
      if ((item && !item?.currentItem.is_owner && !item?.currentItem.name)) actions.push({
        text: MSG?.ACTIONS.UNBLOCK_SCREEN, value: items, icon: EIcon.UnlockGray, onClick: () => {setUnblockSlug(items)}, inactiveIcon: EIcon.UnlockGray})
      if ((item && item?.currentItem?.name)) actions.push({
        text: MSG?.ACTIONS.COPY_LINK, value: items, onClick: () => {copyItemLinks(items)}, icon: EIcon.LinkGray, inactiveIcon: EIcon.LinkGray
      });
      if ((item && item?.currentItem?.name)) actions.push({
        text: MSG?.ACTIONS.DOWNLOAD, value: items, onClick: () => {downloadItem(items)}, icon: EIcon.DownloadGray, inactiveIcon: EIcon.DownloadGray
      });
      if ((item && item?.currentItem?.is_owner)) actions.push({
        text: MSG?.ACTIONS.TAGS, value: items, onClick: () => {askSetCardTags(items)}, icon: EIcon.TagGray, inactiveIcon: EIcon.TagGray, inactive: profile?.plan?.unique===PLAN.FREE.unique
      });
      if ((item && item?.currentItem?.is_owner) && items.every(it => it?.currentItem.protected === 0)) actions.push({
        text: MSG?.ACTIONS.LOCK, value: items, onClick: () => {askProtectCard(items)}, icon: EIcon.LockGray, inactiveIcon: EIcon.LockGray, inactive: profile?.plan?.unique===PLAN.FREE.unique
      });
      if ((item && item?.currentItem?.is_owner) && items.some(it => it?.currentItem.protected === 1)) actions.push({
        text: MSG?.ACTIONS.UNLOCK, value: items, onClick: () => {askUnprotectCard(items)}, icon: EIcon.UnlockGray, inactiveIcon: EIcon.UnlockGray, inactive: profile?.plan?.unique===PLAN.FREE.unique
      });
      if ((item && item?.currentItem?.name)) actions.push({
        text: MSG?.ACTIONS.SHARE, value: items, onClick: () => {askShareItems(items)}, icon: EIcon.ShareGray, inactiveIcon: EIcon.ShareGray
      });
      if ((item && item?.currentItem?.is_owner)) actions.push({
        text: MSG?.ACTIONS.DELETE, value: items, onClick: () => {askDeleteItems(items)}, icon: EIcon.TrashGray, inactiveIcon: EIcon.TrashGray
      });
    } else {
      if ((item)) actions.push({
        text: MSG?.ACTIONS.COPY_LINK, value: items, onClick: () => {copyItemLinks(items)}, icon: EIcon.LinkGray, inactiveIcon: EIcon.LinkGray
      });
      if ((item)) actions.push({
        text: MSG?.ACTIONS.SHARE, value: items, onClick: () => {askShareItems(items)}, icon: EIcon.ShareGray, inactiveIcon: EIcon.ShareGray
      });
      if ((item)) actions.push({
        text: MSG?.ACTIONS.DELETE, value: items, onClick: () => {askDeleteItems(items)}, icon: EIcon.TrashGray, inactiveIcon: EIcon.TrashGray
      });
    }
    return actions;
  }, [copyItemLinks, downloadItem, askProtectCard, askUnprotectCard, askShareItems, askDeleteItems, askSetCardTags, profile, MSG]);
}

export const useHistoryContextMenu = (mass=false) => {
  const MSG = useStoreState(state => state.MSG);
  const currentPlan = useStoreState(state => state.profile && state.profile.plan || {}) as any;
  const deselect = useStoreActions(actions => actions.screenshots.deselect);
  const copyItemLinks = useStoreActions(actions => actions.screenshots.copyItemLinks);
  const downloadItems = useStoreActions(actions => actions.screenshots.downloadItems);
  const askShareItems = useStoreActions(actions => actions.screenshots.askShareItems);
  const askDeleteItems = useStoreActions(actions => actions.screenshots.askDeleteItems);
  const askSetCardTags = useStoreActions(actions => actions.screenshots.askSetCardTags);
  const askProtectCard = useStoreActions(actions => actions.screenshots.askProtectCard);
  const askUnprotectCard = useStoreActions(actions => actions.screenshots.askUnprotectCard);
  //copyItemLinks, downloadItems, askShareItems, askDeleteItems, askSetCardTags
  return React.useCallback((item: ICardItem | ICardItem[]) => {
    if (isEmpty(item)) return []; //TODO uncomment this when rerender on history click will be removed
    const items = [].concat(item);
    const actions = [];
    if ((item) && mass) actions.push({
      text: MSG.ACTIONS.DESELECT, value: items, onClick: () => {deselect()}, icon: EIcon.CloseCircle, inactiveIcon: EIcon.CloseCircleGray
    });
    if ((item)) actions.push({
      text: MSG.ACTIONS.COPY_LINK, value: items, onClick: () => {copyItemLinks(items)}, icon: EIcon.Link, inactiveIcon: EIcon.LinkGray
    });
    if ((item)) actions.push({
      text: MSG.ACTIONS.DOWNLOAD, value: items, onClick: () => {downloadItems(items)}, icon: EIcon.Download, inactiveIcon: EIcon.DownloadGray
    });
    if ((item)) actions.push({
      text: MSG.ACTIONS.TAGS, value: items, onClick: () => {askSetCardTags(items)}, icon: EIcon.Tag, inactiveIcon: EIcon.TagGray, inactive: currentPlan.unique===PLAN.FREE.unique
    });
    if ((item) && items.every(it => !it.protected)) actions.push({
      text: MSG.ACTIONS.LOCK, value: items, onClick: () => {askProtectCard(items)}, icon: EIcon.Lock, inactiveIcon: EIcon.LockGray, inactive: currentPlan.unique===PLAN.FREE.unique
    });
    if ((item) && items.some(it => it.protected)) actions.push({
      text: MSG.ACTIONS.UNLOCK, value: items, onClick: () => {askUnprotectCard(items)}, icon: EIcon.Unlock, inactiveIcon: EIcon.UnlockGray, inactive: currentPlan.unique===PLAN.FREE.unique
    });
    if ((item)) actions.push({
      text: MSG.ACTIONS.SHARE, value: items, onClick: () => {askShareItems(items)}, icon: EIcon.Share, inactiveIcon: EIcon.ShareGray
    });
    if ((item)) actions.push({
      text: MSG.ACTIONS.DELETE, value: items, onClick: () => {askDeleteItems(items)}, icon: EIcon.Trash, inactiveIcon: EIcon.TrashGray
    });
    return actions;
  }, [mass, deselect, copyItemLinks, downloadItems, askProtectCard, askUnprotectCard, askShareItems, askDeleteItems, askSetCardTags, currentPlan]);
};

export * from './clear';
export * from './header';
