import { createStore, createTypedHooks, persist } from "easy-peasy";
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history'
import {
  createReduxHistoryContext, mainModel, apiModel, historyModel, websocketModel, dialogsModel,
  IMainModel, IApiModel, IRouterModel, IHistoryModel, IWebsocketModel, IDialogsModel
} from "./model";
import { IS_PROD, STORE_PARAM } from 'const';


export type IStoreModel = IMainModel & IRouterModel & IApiModel & IHistoryModel & IWebsocketModel & IDialogsModel;

const { createReduxHistory, routerMiddleware, routerModel } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: false, // must be false in production
  routerReducerKey: STORE_PARAM.ROUTER_KEY
});

const store = createStore(
  persist(
    {
      ...apiModel,
      ...mainModel,
      ...websocketModel,
      screenshots: historyModel,
      dialogs: dialogsModel,
      router: routerModel,

    },
    {
      whitelist: ['profile', 'team'],
      storage: 'localStorage'
    }
  ),
  {
    disableImmer: true,
    devTools: !IS_PROD,
    middleware: [thunk, routerMiddleware]
  },
); // create our store
//.withExtraArgument({ emit })
const history = createReduxHistory(store as any);

const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<IStoreModel>();

export {
  store,
  history,
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore
}