import {action, Action, ActionOn, actionOn, computed, Computed, thunk, Thunk} from "easy-peasy";
import RobustWebSocket from 'robust-websocket';
import {IStoreModel, store} from 'store';
import {URLS, CODE, LOCAL_PARAM, MSG, EIcon, COMMAND, INCOMING} from "const";
import {ValidationError, getToken, MessageList, processApiResponse, Tip,} from 'utils';
import {
  ICardItem,
  IShowHistory,
  IHistoryFilter,
  IEmit,
  IUserInfo,
  ICardTagList,
  IHistoryInfo,
  IShowError,
  IHistoryDelete,
  IHistorySlugList,
  ICardTagsAppend,
  ICardTag,
  ICardTagAdd,
  ICardTagEdit,
  ICardTagId,
  ICardTagsSet,
  ICardUnlock,
  ICardUnlocked,
  ICardUnprotect,
  ICardUnprotected,
  ICardProtect,
  ICardProtected,
  ICardSlugs,
  ICardSlug,
  IGroupLink,
  IGroupLinkResult,
  ITeamInfo,
  IShareLink,
  IShareLinkResult,
  IHistoryFragment,
  ISetName,
  ISetEmail,
  ISetPassword,
  IDropUser,
  IDropHistory,
  IAcceptTeamInvite,
  IAvatarLoad,
  ISetLanguage,
  IListLanguages,
  IHistoryPeriod,
  IInviteUser,
  IExpelMember,
  IRoleChange,
  IDomainChange,
  IRequestTeamSize, IExtendTeam, ITeamAvatar, ISetTeamIPFilter,
} from 'types';
import {ENoFileReason} from "../../components/composite/FileView";
import {ITeamIPLimit} from "../../components/form/Action";

export interface IWebsocketModel {
  //state
  wsInitialized: boolean;
  socket: any;
  fileSocket: any;
  //computed
  //actions
  open: Action<IStoreModel, {socket: any; fileSocket: any;}>;
  close: Action<IStoreModel>;
  //thunks
  connect: Thunk<IStoreModel, string|void>;
  disconnect: Thunk<IStoreModel>;
  send: Thunk<IStoreModel, IEmit>;

  GET_HISTORY: Thunk<IStoreModel, IHistoryFilter>;
  SHOW_HISTORY: Thunk<IStoreModel, IShowHistory>;
  GET_DATA_BY_SLUG: Thunk<IStoreModel, ICardSlug>;
  SHOW_DATA_BY_SLUG: Thunk<IStoreModel, IHistoryFragment>;
  GET_USER_INFO: Thunk<IStoreModel>;
  GET_LANGUAGES: Thunk<IStoreModel>;
  SET_USER_NAME: Thunk<IStoreModel, ISetName>;
  LIST_LANGUAGES: Thunk<IStoreModel, IListLanguages>;
  SET_USER_EMAIL: Thunk<IStoreModel, ISetEmail>;
  SET_USER_LANGUAGE: Thunk<IStoreModel, ISetLanguage>;
  SET_USER_PASSWORD: Thunk<IStoreModel, ISetPassword>;
  SET_USER_INFO: Thunk<IStoreModel, IUserInfo>;
  GET_TEAM_INFO: Thunk<IStoreModel>;
  SHOW_TEAM_INFO: Thunk<IStoreModel, ITeamInfo>;
  // INVITE_REQUEST: Thunk<IStoreModel, IUserInfo>;
  // DELETE_FROM_TEAM: Thunk<IStoreModel, IUserInfo>;
  // TEAM_ROLE_CHANGE: Thunk<IStoreModel, IUserInfo>;
  // DOMAIN_CHANGE: Thunk<IStoreModel, IUserInfo>;
  // REQUEST_TEAM_SIZE: Thunk<IStoreModel, IUserInfo>;
  DROP_USER: Thunk<IStoreModel, IDropUser>;
  INVITE_USER: Thunk<IStoreModel, IInviteUser>,
  CANCEL_TEAM_INVITATION: Thunk<IStoreModel, IInviteUser>,
  EXPEL_TEAM_MEMBER: Thunk<IStoreModel, IExpelMember>,
  LEAVE_TEAM: Thunk<IStoreModel>,
  SET_TEAM_IP_FILTER: Thunk<IStoreModel, ISetTeamIPFilter>,
  PROMOTE_TEAM_MEMBER: Thunk<IStoreModel, IExpelMember>,
  DEMOTE_TEAM_MEMBER: Thunk<IStoreModel, IExpelMember>,
  EXTEND_TEAM: Thunk<IStoreModel, IExtendTeam>,
  TEAM_ROLE_CHANGE: Thunk<IStoreModel, IRoleChange>,
  DOMAIN_CHANGE: Thunk<IStoreModel, IDomainChange>,
  REQUEST_TEAM_SIZE: Thunk<IStoreModel, IRequestTeamSize>,
  ACCEPT_INVITATION: Thunk<IStoreModel, IAcceptTeamInvite>,
  DROP_HISTORY: Thunk<IStoreModel, IDropHistory>;
  GET_TAGS: Thunk<IStoreModel>;
  LIST_TAGS: Thunk<IStoreModel, ICardTagList>;
  GET_HISTORY_INFO: Thunk<IStoreModel, void | boolean | IHistoryPeriod>
  SET_HISTORY_INFO: Thunk<IStoreModel, IHistoryInfo>;
  DROP_HISTORY_FILES: Thunk<IStoreModel, IHistoryDelete>;
  DROPPED_HISTORY_FILES: Thunk<IStoreModel, ICardSlugs>;

  ADD_TAG: Thunk<IStoreModel, ICardTagAdd>;
  ADDED_TAG: Thunk<IStoreModel, ICardTag>;
  ALTER_TAG: Thunk<IStoreModel, ICardTagEdit>;
  ALTERED_TAG: Thunk<IStoreModel, ICardTag>;
  DROP_TAG: Thunk<IStoreModel, ICardTagId>;
  DROPPED_TAG: Thunk<IStoreModel, ICardTagId>;
  UNLOCK_FILE: Thunk<IStoreModel, ICardUnlock>;
  UNLOCKED_FILE: Thunk<IStoreModel, ICardUnlocked>;
  UNPROTECT_FILE: Thunk<IStoreModel, ICardUnprotect>;
  UNPROTECTED_FILE: Thunk<IStoreModel, ICardUnprotected>;
  PROTECT_FILE: Thunk<IStoreModel, ICardProtect>;
  PROTECTED_FILE: Thunk<IStoreModel, ICardProtected>;

  CREATE_GROUP_LINK: Thunk<IStoreModel, IGroupLink>;
  GROUP_LINK_CREATED: Thunk<IStoreModel, IGroupLinkResult>;
  CREATE_SHARE_LINK: Thunk<IStoreModel, IShareLink>;
  SHARE_LINK_CREATED: Thunk<IStoreModel, IShareLinkResult>;
  /*
  FILE_ADD_TAGS?: Thunk<IStoreModel, ICardTagsAppend>;
  FILE_ADDED_TAGS?: Thunk<IStoreModel, ICardTagsAppend>;
  FILE_DROP_TAGS?: Thunk<IStoreModel, ICardTagsAppend>;
  FILE_DROPPED_TAGS?: Thunk<IStoreModel, ICardTagsAppend>;
  */
  UPLOAD_AVATAR_COMPLETE:Thunk<IStoreModel, IAvatarLoad>;
  UPLOAD_AVATAR: Thunk<IStoreModel>;
  UPLOAD_TEAM_AVATAR_COMPLETE:Thunk<IStoreModel, any>;
  UPLOAD_TEAM_AVATAR: Thunk<IStoreModel, ITeamAvatar>;
  DROP_AVATAR: Thunk<IStoreModel>;
  FILE_SET_TAGS: Thunk<IStoreModel, ICardTagsSet>;
  FILE_TAGS_CHANGED: Thunk<IStoreModel, ICardTagsSet>;
  FILE_TAGS_ADDED: Thunk<IStoreModel, ICardTagsSet>;

  SHOW_ERROR: Thunk<IStoreModel, IShowError>;
  SHOW_CONST_MESSAGE: Thunk<IStoreModel, any>;
  //listeners
}


export const websocketModel: IWebsocketModel = {
//state
  wsInitialized: false,
  socket: null,
  fileSocket: null,
//computed
//actions
  open: action((state, payload) => {
    return {...state, ...payload, wsInitialized: true};
  }),
  close: action((state) => {
    if (state.socket) state.socket.close();
    if (state.fileSocket) state.fileSocket.close();
    return {...state, socket: null, fileSocket: null, wsInitialized: false};
  }),
//thunks
  connect: thunk(async (actions, tkn, {getState}) => {
    //console.log(RobustWebSocket);
    const state = getState();
    const logged = !!getToken();
    const token = getToken();
    let socket = state.socket, fileSocket = state.fileSocket;
    if (token && !state.wsInitialized) {
      await new Promise((res, rej) => {
        if (!socket) socket = new RobustWebSocket(`${URLS.SOCKET}?token=${token}`);
        else if (socket.readyState === WebSocket.CLOSED) socket.open();
        socket.onopen = () => { res(); };
        socket.onerror = (e) => { console.error(e); rej(); };
        socket.onmessage = async (event) => {
          const {data: rawData} = event;
          const data = JSON.parse(rawData);
          const { data: payload, command: type } = data;
          if ({...COMMAND.INCOMING.MAIN, ...COMMAND.INCOMING.COMMON}[type]) {
            await store.getActions()[INCOMING[type]](payload);
          }
        };
      })
    }

    await new Promise((res, rej) => {
      if (!fileSocket) fileSocket = new RobustWebSocket(`${URLS.FILE_SOCKET}` + (token ? `?token=${token}` : ``));
      else if (fileSocket.readyState === WebSocket.CLOSED) fileSocket.open();
      fileSocket.onopen = () => { res(); };
      fileSocket.onerror = (e) => { console.error(e); rej(); };
      fileSocket.onmessage = async (event) => {
        const {data: rawData} = event;
        const data = JSON.parse(rawData);
        const { data: payload, command: type } = data;
        if ({...COMMAND.INCOMING.FILE, ...COMMAND.INCOMING.COMMON}[type]) {
          await store.getActions()[INCOMING[type]](payload);
        }
      };
    });

    await actions.open({socket, fileSocket});
  }),
  disconnect: thunk(async (actions, payload, {getState}) => {
    await actions.close();
  }),
  send: thunk(async (actions, {command, params, status}, { getState }) => {
    console.log('status', status);
    const state = getState();
    if (!state.wsInitialized) return false;

    if (COMMAND.OUTGOING.MAIN[command] && !state.socket)
      return false;
    if (COMMAND.OUTGOING.FILE[command] && !state.fileSocket)
      return false;

    if (COMMAND.OUTGOING.MAIN[command] && !status) {
      await state.socket.send(JSON.stringify({command: COMMAND.OUTGOING.MAIN[command], params}));
    }
    if (COMMAND.OUTGOING.FILE[command]) {
      await state.fileSocket.send(JSON.stringify({command: COMMAND.OUTGOING.FILE[command], params}));
    }
    return true;
  }),
  /*[COMMAND.OUTGOING.FILE.GET_HISTORY]*/
  GET_HISTORY: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.GET_HISTORY, params});
  }),
  DROP_AVATAR: thunk(async (actions) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.DROP_AVATAR});
  }),
  UPLOAD_AVATAR: thunk(async (actions) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.UPLOAD_AVATAR});
  }),
  UPLOAD_AVATAR_COMPLETE: thunk(async (actions, payload) => {
    await actions.setAvatarLoad(payload);
  }),
  UPLOAD_TEAM_AVATAR_COMPLETE: thunk(async (actions, payload) => {
    await actions.setLogoLoad(payload);
  }),
  GET_DATA_BY_SLUG: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.GET_DATA_BY_SLUG, params});
  }),
  /*[COMMAND.INCOMING.FILE.SHOW_HISTORY]*/
  SHOW_HISTORY: thunk(async (actions, payload) => {
    await actions.screenshots.setHistory(payload);
  }),
  /*[COMMAND.INCOMING.FILE.SHOW_DATA_BY_SLUG]*/
  SHOW_DATA_BY_SLUG: thunk(async (actions, payload) => {
    await actions.screenshots.setSlug(payload);
  }),
  /*[COMMAND.OUTGOING.MAIN.GET_USER_INFO]*/
  GET_USER_INFO: thunk(async (actions, payload) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.GET_USER_INFO});
  }),
  GET_LANGUAGES: thunk(async (actions, payload) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.GET_LANGUAGES});
  }),
  SET_USER_INFO: thunk(async (actions, profile) => {
    await actions.setProfile(profile);
  }),
  LIST_LANGUAGES: thunk(async (actions, payload) => {
    await actions.setListLanguages(payload);
  }),
  /*[COMMAND.OUTGOING.MAIN.SET_USER_NAME]*/
  SET_USER_NAME: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.SET_USER_NAME, params});
  }),
  SET_USER_EMAIL: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.SET_USER_EMAIL, params});
  }),
  SET_USER_PASSWORD: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.SET_USER_PASSWORD, params});
  }),
  SET_USER_LANGUAGE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.SET_USER_LANGUAGE, params});
  }),
  DROP_USER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.DROP_USER, params});
  }),
  DROP_HISTORY: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.DROP_HISTORY_FILES, params, status: true});
  }),
  GET_TEAM_INFO: thunk(async (actions, payload) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.GET_TEAM_INFO});
  }),
  /*[COMMAND.INCOMING.MAIN.SHOW_TEAM_INFO]*/
  SHOW_TEAM_INFO: thunk(async (actions, payload) => {
    await actions.setTeam(payload);
    await actions.setTeamBuffer(payload);
  }),
  /*[COMMAND.OUTGOING.FILE.GET_TAGS]*/
  GET_TAGS: thunk(async (actions, payload) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.GET_TAGS});
  }),
  /*[COMMAND.INCOMING.FILE.LIST_TAGS]*/
  LIST_TAGS: thunk(async (actions, {list}) => {
    await actions.screenshots.updateTagList(list);
  }),
  /*[COMMAND.OUTGOING.FILE.GET_HISTORY_INFO]*/
  GET_HISTORY_INFO: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.GET_HISTORY_INFO, params});
  }),
  
  /*[COMMAND.INCOMING.FILE.SET_HISTORY_INFO]*/
  SET_HISTORY_INFO: thunk(async (actions, info) => {
    await actions.screenshots.setInfo(info);
  }),
  DROP_HISTORY_FILES: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.DROP_HISTORY_FILES, params, status: true});
  }),
  DROPPED_HISTORY_FILES: thunk(async (actions, data) => {
    await actions.screenshots.applyDeletedItems(data);
  }),
  ADD_TAG: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.ADD_TAG, params});
  }),
  ADDED_TAG: thunk(async (actions, data) => {
    // @ts-ignore
    await actions.screenshots.setAddedTag([data.id])
    await actions.screenshots.applyAppendedTag(data);
  }),
  ALTER_TAG: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.ALTER_TAG, params});
  }),
  ALTERED_TAG: thunk(async (actions, data) => {
    await actions.screenshots.applyAppendedTag(data);
  }),
  DROP_TAG: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.DROP_TAG, params});
  }),
  DROPPED_TAG: thunk(async (actions, data) => {
    await actions.screenshots.applyRemovedTag(data);
  }),
  UNLOCK_FILE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.UNLOCK_FILE, params});
  }),
  UNLOCKED_FILE: thunk(async (actions, data) => {
    await actions.screenshots.applyUnlockCard(data);
  }),
  UNPROTECT_FILE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.UNPROTECT_FILE, params});
  }),
  UNPROTECTED_FILE: thunk(async (actions, data) => {
    await actions.screenshots.applyUnprotectCard(data);
  }),
  PROTECT_FILE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.PROTECT_FILE, params});
  }),
  PROTECTED_FILE: thunk(async (actions, data) => {
    await actions.screenshots.applyProtectCard(data);
  }),
  /*[COMMAND.OUTGOING.MAIN.SET_USER_NAME]*/
  INVITE_USER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.INVITE_USER, params});
  }),
  CANCEL_TEAM_INVITATION: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.CANCEL_TEAM_INVITATION, params});
  }),
  EXPEL_TEAM_MEMBER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.EXPEL_TEAM_MEMBER, params});
  }),
  LEAVE_TEAM: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.LEAVE_TEAM});
  }),
  SET_TEAM_IP_FILTER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.SET_TEAM_IP_FILTER, params});
  }),
  UPLOAD_TEAM_AVATAR: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.UPLOAD_TEAM_AVATAR, params});
  }),
  PROMOTE_TEAM_MEMBER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.PROMOTE_TEAM_MEMBER, params});
  }),
  DEMOTE_TEAM_MEMBER: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.DEMOTE_TEAM_MEMBER, params});
  }),
  EXTEND_TEAM: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.EXTEND_TEAM, params});
  }),
  TEAM_ROLE_CHANGE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.TEAM_ROLE_CHANGE, params});
  }),
  DOMAIN_CHANGE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.DOMAIN_CHANGE, params});
  }),
  REQUEST_TEAM_SIZE: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.REQUEST_TEAM_SIZE, params});
  }),
  ACCEPT_INVITATION: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.MAIN.ACCEPT_INVITATION, params});
  }),
  /*FILE_ADD_TAGS: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.FILE_ADD_TAGS, params});
  }),
  FILE_ADDED_TAGS: thunk(async (actions, data) => {
    await actions.screenshots.applyAddedCardTags(data);
  }),
  FILE_DROP_TAGS: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.FILE_DROP_TAGS, params});
  }),
  FILE_DROPPED_TAGS: thunk(async (actions, data) => {
    await actions.screenshots.applyRemovedCardTags(data);
  }),*/
  FILE_SET_TAGS: thunk(async (actions, params) => {
    if (params.list.length > 1) {
      await actions.send({command: COMMAND.OUTGOING.FILE.FILE_ADD_TAG, params});
    } else {
      await actions.send({command: COMMAND.OUTGOING.FILE.FILE_SET_TAGS, params});
    }
  }),
  FILE_TAGS_CHANGED: thunk(async (actions, data) => {
    await actions.screenshots.applySetCardTags(data);
  }),
  FILE_TAGS_ADDED: thunk(async (actions, data) => {
    await actions.screenshots.applyAddCardTags(data);
  }),
  CREATE_GROUP_LINK: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.CREATE_GROUP_LINK, params});
  }),
  GROUP_LINK_CREATED: thunk(async (actions, data) => {
    await actions.screenshots.applyCopyLink(data);
  }),
  CREATE_SHARE_LINK: thunk(async (actions, params) => {
    await actions.send({command: COMMAND.OUTGOING.FILE.CREATE_SHARE_LINK, params});
  }),
  SHARE_LINK_CREATED: thunk(async (actions, data) => {
    await actions.screenshots.applyShareLink(data);
  }),
  SHOW_ERROR: thunk(async (actions, error, {getState}) => {
    const state = getState()
    const err = state.MSG.SOCKET[error.code];
    const msg = err && (err.text || err.description) || "Unknown error";
    const code = err && err.code;
    //TODO replace with proper handler
    switch (code) {
      case 25: {
        await actions.screenshots.setSlug({reason: ENoFileReason.NO_FILE});
        await actions.screenshots.setLoadingFragment(false);
        break;
      }
      case 21: actions.screenshots.setLoading(false); break;
      case 20: break;
      default: break;
    }
    actions.pushCommonMessage(
      new ValidationError(null, msg)
    );
  }),
  SHOW_CONST_MESSAGE: thunk(async (actions, {code}, {getState}) => {
    //задержка для смены локализации. Без нее при смене языка сообщения появляются на предыдущем языке
   setTimeout(() => {
     const state = getState()
     const msg = state.MSG.SOCKET[code];
     const name = msg && msg.name;
     actions.pushCommonMessage(
       new Tip(null, msg.description, EIcon.Check)
     );
   }, 10)
    const state = getState()
    const msg = CODE.SOCKET[code];
    const name = msg && msg.name;
    const description = msg && msg.description;
    switch (code) {
      case 37: {
        //EXTEND_TEAM_SUCCESS
        await actions.setTeam({...state.team, size: state.teamBuffer.size})
        break;
      }
      case 39: {
        //INVITE_USER_SUCCESS
        await actions.setTeam({...state.team, invites: state.teamBuffer.invites})
        break;
      }
      case 41: {
        //EXPEL_TEAM_MEMBER_SUCCESS
        await actions.setTeam({...state.team, members: state.teamBuffer.members})
        break;
      }
      case 43: {
        //CANCEL_TEAM_INVITATION_SUCCESS
        await actions.setTeam({...state.team, invites: state.teamBuffer.invites})
        break;
      }
      case 45: {
        //PROMOTE_TEAM_MEMBER_SUCCESS
        await actions.setTeam({...state.team, members: state.teamBuffer.members})
        break;
      }
      case 47: {
        //DEMOTE_TEAM_MEMBER_SUCCESS
        await actions.setTeam({...state.team, members: state.teamBuffer.members})
        break;
      }
      case 49: {
        //SET_TEAM_IP_FILTER_SUCCESS
        await actions.setTeam({...state.team, ip_limit: state.teamBuffer.ip_limit})
        break;
      }
      // case 51: {
      //   //ACCEPT_INVITATION_SUCCESS
      //   await actions.setTeam({...state.team, size: state.teamBuffer.size})
      //   break;
      // }
      case 53: {
        //LEAVE_TEAM_SUCCESS
        await actions.switchToProfile(true)
        break;
      }
      case 55: {
        //UPLOAD_TEAM_AVATAR_SUCCESS
        // await actions.setTeam({...state.team, size: state.teamBuffer.size})
        break;
      }
      case 57: {
        //DROP_TEAM_AVATAR_SUCCESS
        // await actions.setTeam({...state.team, size: state.teamBuffer.size})
        break;
      }
      case 59: {
        //DOMAIN_CHANGE_SUCCESS
        console.log({domain: state.teamBuffer.domain})
        await actions.setTeam({...state.team, domain: state.teamBuffer.domain})
        break;
      }
    
      default: break;
    }
  }),
  // SHOW_ERROR: thunk(async (actions, error, {getState}) => {
  //   const err = CODE.SOCKET[error.code];
  //   const msg = err && (err.text || err.description) || "Unknown error";
  //   const code = err && err.code;
  //   //TODO replace with proper handler
  //   switch (code) {
  //     case 25: {
  //       await actions.screenshots.setSlug({reason: ENoFileReason.NO_FILE});
  //       await actions.screenshots.setLoadingFragment(false);
  //       break;
  //     }
  //     case 21: actions.screenshots.setLoading(false); break;
  //     case 20: break;
  //     default: break;
  //   }
  //   actions.pushCommonMessage(
  //     new ValidationError(null, msg)
  //   );
  // }),
  // SHOW_CONST_MESSAGE: thunk(async (actions, {code}, {getStoreActions}) => {
  //   const msg = CODE.SOCKET[code];
  //   const name = msg && msg.name;
  //   actions.pushCommonMessage(
  //     new Tip(null, msg.description, EIcon.Check)
  //   );
  // }),

  //listeners
};
