import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {cssSize, ifCond} from 'utils';

export interface IScrollable extends TThemed {
  dir?: EScrollDir
  top?: number | string;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum EScrollDir {
  NONE, X=1, Y, BOTH
}

export const Scrollable = React.forwardRef((props: IScrollable, ref) => {
  const { children, dir=EScrollDir.BOTH, top, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWScrollable ref={ref} dir={dir} top={top} darkMode={darkMode} {...rest}>
      {children}
    </TWScrollable>
  );
});

const TWScrollable = styled("div")(({ theme, darkMode, dir, top }) => [
  tw`w-full h-full`,
  [EScrollDir.X, EScrollDir.BOTH].includes(dir) && tw`overflow-x-auto`,
  [EScrollDir.Y, EScrollDir.BOTH].includes(dir) && tw`overflow-y-auto`,
  // css`
  //   &::-webkit-scrollbar-track {
  //         ${cssSize(top, "margin-top")}
  //   }
  // `
]);