import dateFormat, { i18n } from 'dateformat';

i18n.monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const getDate = (dateStr) => {
    if (dateStr) {
      const date = new Date(dateStr);
      return dateFormat(date, 'mmm d, yyyy, В HH:MM');
    } else {
      return ''
    }
};