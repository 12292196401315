import React, {useEffect} from 'react';
import tw, {css, styled} from 'twin.macro';
import ReactTooltip from 'react-tooltip';
import {
  Align,
  Button,
  Buttons,
  EAlign,
  EButtonKind,
  EditBox,
  EEditKind,
  EIcon,
  EIconSize,
  EJustify,
  EPanelCaptionKind,
  EPanelWidth,
  ESwatchColor,
  ESwatchItemSize,
  ESwatchItemType,
  ESwatchType,
  EVAlign,
  Icon,
  Line,
  LinkButton,
  Panel,
  PanelFooter,
  SpaceBox,
  Swatch,
  SwatchColorItems,
  TextBox
} from 'components';
import {FIELD, SETTINGS} from 'const';
import {callIfExists, debounceHandler, ifCond, isArrayEqual} from 'utils';
import {ICardTag, IFieldErrors} from 'types';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";
import {useStoreActions} from "hooks";
import {useKeydownModal} from "../../../hooks/useKeydownModal";

export interface ICardTags extends TThemed {
  slugs?: string | string[];
  selectedItems?: string[];
  items?: ICardTag[];//tagList
  onClose?: Function;
  onSubmit?: Function;
  onDelete?: Function;
  onAdd?: Function;
  onEdit?: Function;
  errors?: IFieldErrors;
  onClearError?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const CardTags = React.forwardRef((props: ICardTags, ref) => {
  const { selectedItems = [], items=[], errors={}, slugs=[], onClearError, onClose, onAdd, onEdit, onDelete, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const emptyTagState = {id: null, name: null, color: null};
  const slugList = React.useMemo(() => [].concat(slugs), [slugs]);
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  //const editing = React.useRef({ item: emptyTagState });
  const [selected, setSelected] = React.useState(selectedItems);
  const [filteredItems, setFilteredItems] = React.useState<any>(items);
  const [clickCreate, setClickCreate] = React.useState<boolean>(false);
  const [tagItem, setTagItem] = React.useState({...emptyTagState});
  const addedTag = useStoreState((state) => state.screenshots.addedTag);
  const setIsBlocked = useStoreActions((state) => state.screenshots.setIsBlocked);
  const setAddedTagToNull = useStoreActions((state) => state.screenshots.setAddedTagToNull);
  const [filter, setFilter] = React.useState("");
  const handleClearErrors = React.useCallback(() => {
    callIfExists(onClearError, FIELD.TAG_NAME);
    callIfExists(onClearError, FIELD.TAG_COLOR);
  }, [onClearError]);
  const modes = React.useMemo(() => ({
    LIST: {mode: "list", caption: MSG.ACTION.SET_TAGS_HDR, accept: MSG.ACTIONS.OK, cancelKind: EButtonKind.OUTLINED_PRIMARY, submitKind: EButtonKind.PRIMARY, type: ESwatchType.LIST, multiple: true, },
    CREATE: {mode: "create", caption: MSG.ACTION.SET_TAGS_CREATE_HDR, accept: MSG.ACTIONS.CREATE, cancelKind: EButtonKind.OUTLINED_PRIMARY, submitKind: EButtonKind.PRIMARY, type: ESwatchType.SELECT, multiple: false, },
    EDIT: {mode: "edit", caption: MSG.ACTION.SET_TAGS_EDIT_HDR, accept: MSG.ACTIONS.SAVE, cancelKind: EButtonKind.OUTLINED_PRIMARY, submitKind: EButtonKind.PRIMARY, type: ESwatchType.SELECT, multiple: false, },
    DELETE: {mode: "delete", caption: MSG.CONFIRM.DELETE_ITEM_HDR, accept: MSG.ACTIONS.DELETE, cancelKind: EButtonKind.OUTLINED_SECONDARY, submitKind: EButtonKind.CRITICAL, type: ESwatchType.SELECT, multiple: false }
  }), []);
  const [state, setState] = React.useState(modes.LIST);
  const [hoverNewTag, setHoverNewTag] = React.useState(false);
  const handleFilter = React.useCallback((e) => {
    const val = e.target.value;
    setTagItem({...tagItem, name: val, color: 'coral'});
    if (val!=filter) setFilter(val);
  }, [filter, setFilter]);
  const handleChange = React.useCallback((what, who, values) => {
    if (what==="color") {
      if (state.mode === "list") {
        setSelected(values);
      } else {
        setTagItem({...tagItem, color: who});
        //editing.current.item.color = who;
      }
    } else if (what==="name") {
      //editing.current.item.name = who;
      setFilter(who);
      setTagItem({...tagItem, name: who});
    }
  }, [setSelected, state, tagItem, setTagItem]);

  const notFindCreateTag = () => {
    setState(modes.CREATE);
    setFilter('');
  }

  useEffect(() => {
    const filtered = items.filter(it => String(it.name).toLowerCase().includes(String(filter || "").toLowerCase()) ).map(it => ({ type:ESwatchItemType.COLOR, value: it.id, hint: it.name, color: it.color, id: it.id }));
    if (filtered.length === 0 && !clickCreate) {
      setState(modes.CREATE);
    } else if (!clickCreate) {
      setState(modes.LIST);
    }
    setFilteredItems(filtered);
  }, [filter, items, clickCreate]);

  const handleSubmit = React.useCallback(async (e) => {
    if (state.mode === "list") {
      await callIfExists(onSubmit, e, {tags: selected, slugs: slugList});
      handleClearErrors();
    } else if (state.mode==="delete") {
      await callIfExists(onDelete, tagItem);//editing.current.item);
      setState(modes.LIST);
      handleClearErrors();
    } else if (state.mode==="create") {
      const res = await callIfExists(onAdd, tagItem);//editing.current.item);
      if (res!==false) {
        setState(modes.LIST);
        setTagItem({...emptyTagState});
        setFilter('');
        handleClearErrors();
      }
    } else if (state.mode==="edit") {
      const res = await callIfExists(onEdit, tagItem);//editing.current.item);
      if (res!==false) {
        setState(modes.LIST);
        handleClearErrors();
      }
    }
    setIsBlocked(false);
  }, [onSubmit, onAdd, onEdit, onDelete, selected, state, tagItem, setTagItem, slugList, handleClearErrors]);
  const handleClose = React.useCallback((e) => {
    if (state.mode === "delete") {
      setState(modes.EDIT);
    } else if (state.mode!=="list") {
      setState(modes.LIST);
      setTagItem({...emptyTagState});
      setClickCreate(false);
    } else {
      callIfExists(onClose,e);
    }
    setIsBlocked(false);
    handleClearErrors();
  }, [onClose, setState, state, modes, tagItem, setTagItem, handleClearErrors]);

  const handleListAction = React.useCallback((item) => {
    console.log('item', item);
    setTagItem(item ? {name: item.hint, color: item.color, id: item.id} : {...emptyTagState, color: ESwatchColor.CORAL}); //, color: ESwatchColor.CORAL
    setState(item ? modes.EDIT : modes.CREATE);
    setClickCreate(true);
    setHoverNewTag(false);
  }, [setState, items, modes, tagItem, setTagItem]);

  React.useEffect(() => {
    const sel = selectedItems == null ? [] : selected;
    if (!isArrayEqual(selected, sel)) setSelected(sel);
  }, [selected, setSelected, selectedItems]);

  React.useEffect(() => {
    if (addedTag.length !== 0) {
      const allSelect = [...selected, ...addedTag];
      //@ts-ignore
      setSelected(allSelect);
      //@ts-ignore
      setAddedTagToNull([]);
    }
  }, [addedTag]);

  useKeydownModal(handleSubmit, handleClose, handleChange, {state, tagItem, selected});

  if ( !ifCond(_if) ) return null;
  return (
    <TWCardTags ref={ref} darkMode={darkMode} {...rest}>
      <ReactTooltip />
      <Panel
        caption={state.caption}
        captionKind={EPanelCaptionKind.SM}
        icon={state.mode!=="delete" ? EIcon.Tag : null}
        iconSize={20}
        width={!bp.mobile ? EPanelWidth.SM : '100%'}
        extraAction={state.mode==="list" ?
          <TWNewTag onMouseEnter={() => setHoverNewTag(true)} onMouseLeave={() => setHoverNewTag(false)}>
            <LinkButton noInlinegrid noUnderline onClick={() => handleListAction(null)} append={
              <>
                <SpaceBox width={10} inline/>
                <Icon data-tip={MSG.ACTION.SET_TAGS_CREATE_HDR} data-effect="solid" data-background-color="#0070e9" size={16} icon={hoverNewTag ? EIcon.Add : EIcon.AddGray}/>
              </>
            }/>
          </TWNewTag> : null}
        onClose={handleClose}
        darkMode={darkMode}
        noRoundBorders = {true}
      >
        <SpaceBox height={20} />
        <TextBox if={state.mode==="delete"} text={MSG.CONFIRM.DELETE_ITEM_ASK}/>
        <EditBox if={state.mode==="list"} value={filter} onInput={(e) => handleFilter(e)} autoFocus={true} caption={MSG.ACTION.SET_TAGS_SEARCH} kind={EEditKind.SM} fill={true}/>
        <EditBox if={["create", "edit"].includes(state.mode)} errors={errors[FIELD.TAG_NAME]}
                 onInput={(e) => {handleChange("name", e.target.value, [])}}
                 caption={state.mode==="create" ? MSG.ACTION.SET_TAGS_ENTER_A_NAME : MSG.ACTION.SET_TAGS_ENTER_THE_NAME}
                 kind={EEditKind.SM}
                 fill={true}
                 autoFocus={true}
                 value={tagItem.name}
                 currentValue={tagItem.name}
        />
        <SpaceBox if={state.mode!=="delete"} height={20} />
        <Swatch
          if={state.mode!=="delete"}
          type={state.type}
          multiple={state.multiple}
          items={state.mode==="list" ? filteredItems : SwatchColorItems}
          itemSize={state.mode==="list" ? ESwatchItemSize.MD : ESwatchItemSize.SM}
          onChange={(e, who, values) => handleChange("color", who, values)}
          value={state.mode==="list" ? selected : tagItem.color}
          listAction={{action: handleListAction, icon: EIcon.EditGray, iconHover: EIcon.Edit}}
          errors={errors[FIELD.TAG_COLOR]}
        >
          <Align justify={EJustify.CENTER} align={EAlign.MIDDLE} valign={EVAlign}>
            <TextBox text={MSG.NO_TAGS} italic block={true}/>
          </Align>
          <Align>
            <Button kind={modes.CREATE.submitKind} caption={modes.CREATE.caption} onClick={notFindCreateTag} />
          </Align>
        </Swatch>
        <PanelFooter>
          <SpaceBox height={20} />
          <Line />
          <SpaceBox height={20} />
          <Buttons left={
            (state.mode==="edit" &&
            <Icon if={state.mode==="edit"} icon={EIcon.TrashRed} size={EIconSize.SM} onClick={(e) => {setState(modes.DELETE)}} css="justify-self: flex-start;"/>)
          }>
            <Button caption={MSG.ACTIONS.CANCEL} kind={state.cancelKind} onClick={handleClose}/>
            <Button caption={state.accept} kind={state.submitKind} onClick={handleSubmit} fillX={true}/>
          </Buttons>
        </PanelFooter>
      </Panel>
    </TWCardTags>
  );
});

const TWCardTags = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);


const TWNewTag = styled("div")(({ theme, darkMode, disabled }) => [
  tw`mr-15p`,
  css`display: flex; cursor: pointer`
]);
