import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {Auth, Button, EButtonKind, EIcon, ESign, ESocAuthType} from 'components';
import {formatPrice, ifCond, Tip} from 'utils';
import { useStoreActions, useStoreState } from 'hooks';
import {FIELD, MSG, ROUTE, URL_PARAM} from 'const';

export interface ITeamInvitePage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const TeamInvitePage = ((props: ITeamInvitePage) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
  const isLoggedIn = useStoreState(s=>s.isLoggedIn);
  const matchParams = useStoreState(state => state.router.params);
  const profile = useStoreState(state => state.profile);
  const homePath = useStoreState(state => state.homePath);
  const push = useStoreActions(actions => actions.router.push);
  const clearFieldMessages = useStoreActions(actions => actions.clearFieldMessages);
  const doLogin = useStoreActions(actions => actions.login);
  const doLogout = useStoreActions(actions => actions.logout);
  
  const switchToTeam = useStoreActions(actions => actions.switchToTeam);
  const switchToHome = useStoreActions(actions => actions.switchToHome);
  const pushCommonMessage = useStoreActions(actions => actions.pushCommonMessage);
  const switchToSignin = useStoreActions(actions => actions.switchToSignin);
  
  const acceptInvitation = useStoreActions(actions => actions.acceptInvitation);
  const emailParam = matchParams[URL_PARAM.EMAIL];
  const confirmToken = matchParams[URL_PARAM.TOKEN];
  
  const preprocess = async () => {
    
    if (isLoggedIn) {
      if (profile && profile.email !== emailParam) {
        await switchToHome(true);
        pushCommonMessage(
          new Tip(null,
            `Приглашение может принять только ${emailParam}`,
            EIcon.CloseCircle
          )
        );
      }
      if (profile && !profile.team && profile.email === emailParam) {
        acceptInvitation({token: confirmToken})
        await switchToTeam(true);
      }
      if (profile && profile.team && profile.email === emailParam) {
        await switchToTeam(true);
      }
    } else {
      switchToSignin(true)
    }
   
  }

  React.useLayoutEffect(() => {
    preprocess();
  }, [profile]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWInvitePage darkMode={darkMode} {...rest}>
    
    </TWInvitePage>
  );
});

const TWInvitePage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative pt-20p w-full h-full flex items-center justify-center`,
  css``
]);
