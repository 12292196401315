import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {ContextMenu as Menu, ContextMenuTrigger as Target, MenuItem, connectMenu} from "react-contextmenu";
import {EIcon, EIconSize, ETextColor, Icon, SpaceBox, TextBox} from 'components';

export interface IContextMenu extends TThemed {
  id: string;
  data?: any;
  actions?: IContextMenuItem[] | ((any) => IContextMenuItem[]);
  menuHandle?: React.RefObject<{toggle: Function;}>;
  raw?: boolean;
  demo?: boolean;
  trigger?: any;
  [prop: string]: any;
}

export interface IComputedContextMenu extends IContextMenu {
  [prop: string]: any;
}

export interface IContextMenuItem {
  text?: string;
  value?: any;
  icon?: string;
  divider?: boolean;
  onClick?: Function;
  disabled?: boolean;
  if?: boolean | (() => boolean);
  [prop: string]: any;
}

export interface IContextMenuTarget {
  for: string;
  contextData?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  renderAs?: React.ElementType;
  menuHandle?: React.RefObject<{toggle: Function;}>
  [prop: string]: any;
}

export const ContextMenuTarget = (props: IContextMenuTarget) => {
  const { for:forMenu, renderAs, children, disabled=false, contextData, ...rest } = props;
  const handleMenu = React.useRef(null);
  const showMenu = React.useCallback((e) => handleMenu && handleMenu.current && handleMenu.current.handleContextClick(e), [handleMenu, handleMenu.current, contextData]);
  return (
    <Target
      id={forMenu} ref={re => handleMenu.current = re}
      collect={p => ({...contextData})}
      renderTag={renderAs}
      holdToDisplay={-1}
      attributes={{...rest, showMenu} as any}
    >
      <>{children}</>
    </Target>
  );
};

export const ContextMenu = (props: IContextMenu) => {
  const { id, trigger, actions=[], data={}, children, raw=false, darkMode=false, demo=false, ...rest } = props;
  const handleClick = React.useCallback((e, item, targetPropsAndData) => {
    if (item.onClick instanceof Function) item.onClick(e, item, targetPropsAndData);
  }, []);
  const current = (trigger || {}).item || {};
  //const current = contextData;
  
  const contextItems = React.useMemo(() => actions instanceof Function ? actions(current) : actions, [actions, trigger]);
  return (
    //@ts-ignore
    <Menu id={id} tw="rounded-sm overflow-hidden shadow-context-menu bg-white min-w-150p z-30">
      {contextItems.map((item, i) => {
        // {console.log(item, 'menuItems')}
        return (
          <MenuItem key={i} data={data} tw="cursor-pointer">
            
            <TWContextMenuItem
              data={data}
              isFirst={0 === i}
              isLast={contextItems.length-1 === i}
              divider={!!item.divider}
              onMouseDown={(e, targetPropsAndData) => handleClick(e, item, targetPropsAndData)}
              onTouchStart={(e, targetPropsAndData) => handleClick(e, item, targetPropsAndData)}
            >
              {item.icon ? <>
                <Icon icon={EIcon[item.icon] } size={EIconSize.MD}/>
                <SpaceBox inline width={10}/>
                </> : null
              }
              <TextBox text={item.text} color={ETextColor.PRIMARY}/>
            </TWContextMenuItem>
           </MenuItem>
        );
      })}
    </Menu>
  );
};

export const ComputedContextMenu = (props: IComputedContextMenu) => {
  const { id, ...restProps } = props;
  const DynamicMenu = //connectMenu(id)(ContextMenu);
  React.memo(
    connectMenu(id)(ContextMenu),
    (pp: IContextMenuItem, np: IContextMenuItem) => pp.actions === np.actions && pp.trigger === np.trigger
  );
  //@ts-ignore
  return <DynamicMenu data={restProps} {...restProps}/>
};

const TWContextMenuItem = styled("div")(({ theme, darkMode, disabled, isLast, isFirst }) => [
  tw`bg-white hover:bg-accent-100 cursor-pointer m-0 py-10p px-20p w-full h-full`,
  isFirst && tw`pt-20p rounded-t-sm`,
  isLast && tw`pb-20p rounded-b-sm`,
  css``
]);
