import React, {Suspense} from 'react';
import { Router, Route, Switch as RouterSwitch, Redirect, Link, withRouter } from "react-router-dom";
import { useStoreRehydrated } from 'easy-peasy';
import {
  Avatar, Menu, Playground, SpaceBox, Switch,
  BaseLayout, RootLayout, Main, EScrollDir, LandingLayout, Scrollable, AscCloudpayments
} from 'components';
import * as PAGE from 'pages/root';
import {Notificator, PageHeader, Dialogs} from 'pages/widgets';
import { history as historie } from 'store';
import {useMediaBreakpoints, useStoreActions, useStoreState} from 'hooks';
import {FIELD, PUBLIC_ID, ROUTE} from 'const';
import {getToken} from "../utils";
import {createCryptogram} from "../utils/createCryptogram";
import {useSlugContextMenu} from "pages/root";


declare global {
  interface Window {
    cp:any;
  }
}

const AuthRoutes = [ROUTE.AUTH, ROUTE.REG, ROUTE.RESET];
export function Application () {
  
  const profile = useStoreState(s=>s.profile);
  const language = useStoreState(s=>s.language);
  const tid = useStoreState(s => s.tid);
  const path = useStoreState(s=>s.router.path);
  const root = useStoreState(s=>s.router.root);
  const isGuest = useStoreState(s=>s.isGuest);
  const isLoggedIn = useStoreState(s=>s.isLoggedIn);
  const darkMode = useStoreState(s=>s.darkMode);
  const MSGFile = useStoreState(s=>s.MSG);
  const zoom = useStoreState(s => s.zoom);
  const push = useStoreActions(a=>a.router.push);
  const replace = useStoreActions(a=>a.router.replace);
  const switchToHome = useStoreActions(a=>a.switchToHome);
  const getCountryLang = useStoreActions(a=>a.getCountryLang);
  const getHistory = useStoreActions(a=>a.screenshots.getHistory);
  const getProfile = useStoreActions(a=>a.getProfile);
  const getLanguages = useStoreActions(a=>a.getLanguages);
  const setLanguage = useStoreActions(a=>a.setLanguage);
  const connect = useStoreActions(a=>a.connect);
  const currentFragment = useStoreState(state => state.screenshots.currentFragment);
  const setMSG = useStoreActions(a=>a.setMSG);
  const setMSGHistory = useStoreActions(a=>a.screenshots.setMSG);
  const setHistoryLang = useStoreActions(a=>a.screenshots.setHistoryLang);
  const currentItem = useStoreState(state => state.screenshots.currentItem);
  const currentSlug = useStoreState(state => state.screenshots.currentSlug);
  const [data, setData] = React.useState({});
  
  //payment
  const ascCloudpaymentFormRef = React.useRef(null)
  const cloudpaymentsAsc = useStoreState(state => state.cloudpaymentsAsc);
  const clearCloudpaymentsAsc = useStoreActions(actions => actions.clearCloudpaymentsAsc);

  const loading = useStoreRehydrated();
  const bp = useMediaBreakpoints();
  const handleGoHome = React.useCallback((e?) => {
    switchToHome(false);
  }, [switchToHome]);
  const init = async () => {
    await connect();
    if (isLoggedIn) {
      //TODO: !isGuest
      await getProfile();
      await getHistory();
      // paymentMethod();
      // getInvoice();
      // await getLanguages()
    }
  };

  React.useEffect(() => {
    if (currentItem) {
      setData((prev) => ({...prev, currentItem, slug: currentSlug}));
      return;
    }
    if (currentFragment) {
      setData((prev) => ({...prev, currentItem: currentFragment, slug: currentSlug}));
      return;
    }
  }, [currentSlug, currentItem, currentFragment]);

  const getMenu = useSlugContextMenu();
  const actions = getMenu(data);
  
  React.useEffect(() => {
    init();
  }, [tid]);
  
  
  React.useLayoutEffect(() => {
    if (cloudpaymentsAsc) {
      ascCloudpaymentFormRef?.current?.submit()
      clearCloudpaymentsAsc()
    }
  }, [cloudpaymentsAsc])
  
  React.useLayoutEffect(() => {
    if (language) {
      import(`locales/${language}`)
        .then(module => {
          setHistoryLang(language)
          setMSG(module[language])
          setMSGHistory(module[language])
        })
        .catch(e => {
          console.log('e', e);
        });
    } else {
      getCountryLang()
    }
  }, [language]);
  
  if (!MSGFile) {
    return null;
  }
  
  
  
  return (
    <div className="App">
      <RootLayout>
        <Router history={historie}>
          <RouterSwitch location={historie.location}>
            <Route exact path={ROUTE.ROOT} render={routeProps => {
              // console.log(isGuest)
              return !isGuest ?
                <Redirect to={ROUTE.HISTORY}/> :
                <LandingLayout darkMode={false}>
                  <PAGE.RootPage {...routeProps}/>
                </LandingLayout>
            }} />
            <Route>
              <BaseLayout darkMode={darkMode}
                header={
                  !zoom ? <PageHeader currentFragment={currentFragment} position={true} actions={actions} currentSlug={currentSlug} currentItem={currentItem} onLogoClick={handleGoHome} darkMode={darkMode} /> : null
                }
              >
                <AscCloudpayments if={cloudpaymentsAsc} ref={ascCloudpaymentFormRef} cloudpaymentsProps={cloudpaymentsAsc}/>
                <RouterSwitch location={historie.location}>
                  
                  <Route exact path={ROUTE.HISTORY} render={routeProps => withAuth(<PAGE.HistoryPage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.CLEAR_HISTORY} render={routeProps => (null)} />
                  <Route exact path={ROUTE.AUTH} render={routeProps => (<PAGE.LoginPage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.TEAMINVITE} render={routeProps => (<PAGE.TeamInvitePage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.REG} render={routeProps => (<PAGE.RegisterPage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.RESET} render={routeProps => (<PAGE.ResetPage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.PROFILE} render={routeProps => withAuth(<PAGE.ProfilePage {...routeProps} {...{darkMode}}/>)} />
                  <Route exact path={ROUTE.BILLING} render={routeProps => (withAuth(<PAGE.BillingPage {...routeProps} {...{darkMode}}/>))} />
                  <Route exact path={ROUTE.TEAM} render={routeProps => (withAuth(<PAGE.TeamPage {...routeProps} {...{darkMode}}/>))} />
                  <Route exact path={ROUTE.PLAYGROUND} component={Playground} />
                  <Route exact path={ROUTE.SLUG} render={ routeProps => (<PAGE.SlugPage {...routeProps} {...{darkMode}}/>) } />
                </RouterSwitch>
                <Notificator if={!bp.mobile}/>
              </BaseLayout>
            </Route>
          </RouterSwitch>
        </Router>
      </RootLayout>
      <Dialogs />
    </div>
  );
}
const withAuth = (Component: JSX.Element, redirectTo=ROUTE.ROOT) => {
  const AuthorizedComponent = () => {
    
    const isLoggedIn = useStoreState(state => state.isLoggedIn);
    const isGuest = useStoreState(state => state.isGuest);
    // console.log({redirectTo, isLoggedIn, isGuest})
    return isLoggedIn ? Component : <Redirect to={redirectTo}/>
  };
  return <AuthorizedComponent />;
};
