import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {ETextSize, ETextWeight, Icon, If, TextBox, Align} from 'components';
import { OnePixel } from 'const';
import {ICardItem} from 'types';
import {extractTextColor, humanFileSize, ifCond, dayjs, isAudioMIME, isVideoMIME, isImageMIME, cardDateFormat} from 'utils';
import {useStoreActions} from "easy-peasy";
import {useKeydownSlug} from "../../../hooks/useKeydownSlug";

export interface IFileView extends TThemed {
  name?: string;
  slug: string;
  link?: string | typeof React.Component;
  mime_type?: string;
  file_extension?: string;
  file_size?: number;
  if?: boolean | (() => boolean);
  demo?: boolean;
  darkMode?: boolean;
  [prop: string]: any;
}

export const FileView = React.forwardRef((props: IFileView, ref) => {
  const { name="", link, is_owner, file_size=0, file_extension="", mime_type="unknown", if:_if=true, darkMode=true, demo=false, ...rest } = props;
  const setZoom = useStoreActions((state) => state.setZoom);
  const isImage = isImageMIME(mime_type);
  const isVideo = isVideoMIME(mime_type);
  const isElse = !isImage && !isVideo;
  const ext = file_extension;
  const blocked = !name && !is_owner

  const toggleZoom = () => {
    // @ts-ignore
    setZoom(true);
  }

  if ( !ifCond(_if) ) return null;
  return (
    <TWFileView ref={ref} hasSrc={!!link && !isElse} {...rest}>
      <If if={!isElse} else={
        <TWElse blocked={blocked} href={link} download={name} name={ext} size={humanFileSize(file_size)}>
          <Icon icon={`${blocked ? "LockGray" : "FileWhite"}`} size={90}/>
        </TWElse>
      }>
        <TWMedia>
          <If if={isVideo} else={
            <TWImage onClick={toggleZoom} src={link || OnePixel} alt={name}/>
          }>
            <TWVideo src={link} controls alt={name} />
          </If>
        </TWMedia>
      </If>
    </TWFileView>
  );
});

const TWFileView = styled("div")(({ darkMode, hasSrc }) => [
  tw`overflow-hidden p-0 h-full`,
  //darkMode ? tw`shadow-panel-dark` : tw`shadow-panel-light`,
  tw`shadow-panel-dark`,
  css`justify-content: center; display: flex;`,
  hasSrc ? css`
    max-height: calc(95vh - 70px);
    max-width: 95vw;
  ` : css`
    width: 370px;
    height: 344px;
  `,
  hasSrc ? tw`rounded-md` : tw`rounded-xl`
]);

const TWMedia = styled("div")(() => [ tw`contents max-h-full max-w-full ` ]);//relative top-0 left-0 w-full h-full
const TWVideo = styled("video")(() => [ tw`grid relative items-center content-center w-full h-full`, css`justify-items: center;` ]);
const TWImage = styled("img")(({ img, zoomed }) => [
  tw`max-h-full object-contain object-center`,
  tw`cursor-zoom-in`,
  /*css`background-image: url('${img}');`*/
]);//relative max-w-full bg-contain bg-no-repeat bg-center
const TWElse = styled("a")(({ name, size, blocked }) => [
  tw`inline-grid relative items-center content-center cursor-pointer top-0 left-0 pb-20p w-full h-full`,
  tw`before:inline-block before:relative before:cursor-pointer`,//before is gradient
  tw`after:inline-block after:relative after:cursor-pointer`,
  css`justify-items: center;`,
  css`
    &:before {
      content: "${name}";
      font-size: 14px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      top: 106px;
    }
    &:after {
      content: "${!blocked && size.replace(/\./,",")}" ;
      font-size: 13px;
      font-weight: 800;
      color: white;
      text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
      top: 20px;
    }
  `
]);
