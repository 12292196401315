import {action, Action, computed, Computed, thunk, Thunk,} from "easy-peasy";
import {IStoreModel} from 'store';
import {isEqual, remove} from 'lodash';
import {EIcon, FIELD, MSG, PRODUCTS, URLS} from 'const';
import {
  IAddedTag,
  IAskSetChangeMethod,
  IAskSetClearHistory,
  IAskSetDeleteAccount,
  IAskSetDeleteAvatar,
  IAskSetDeleteUser,
  IAskSetDonate,
  IAskSetEmail,
  IAskSetHelpTranslate,
  IAskSetLanguage,
  IAskSetName,
  IAskSetPassword,
  IAskSetPaymentResult,
  IAskSetPayPlan,
  IAskSetPlans,
  IAskSetPurchaseLicenses,
  IAskSetTeamCustomDomain,
  IAskSetTeamInviteUsers,
  IAskSetTeamIP,
  IAskSetTeamPurchaseLicenses,
  IAskSetUnsubscribe,
  ICardItem,
  ICardOwn,
  ICardPaneItem,
  ICardProtected,
  ICardSlugs,
  ICardTag,
  ICardTagAdd,
  ICardTagId,
  ICardTagsSet,
  ICardUnlocked,
  ICardUnprotected,
  IGroupLinkResult,
  IHistoryFilter,
  IHistoryFragment,
  IHistoryInfo,
  IHistoryPeriod,
  IHistorySlugList,
  IMSG,
  INoFileReason,
  IShareLinkResult,
  IShowHistory
} from 'types';
import {
  copyBBCode,
  copyPreviewCode,
  copyToClipboard,
  dayjs,
  downloadFile,
  getDateISOAsKebab,
  getObjectArrayComparator,
  goto,
  isImageMIME,
  MessageList,
  str,
  Tip,
  ValidationError
} from 'utils';
import {EDialogAction, EDialogConfirm, EDialogInfo, EDialogResult, IDialogResponse} from 'pages/widgets';
import {ECardProtectType, ENoFileReason} from 'components';

export interface IHistoryModel {
  screenshots: IScreenshots
}
export interface IScreenshots {
  //state
  MSG: IMSG | null;
  list: ICardItem[];
  currentFragment: ICardItem[];// same as list, but for specific /:slug
  currentItem: (ICardItem & ICardOwn) | INoFileReason;
  controls: boolean;
  selected: string[];
  info: IHistoryInfo;
  language: string;
  headerOpened: boolean;
  calendarOpened: boolean;
  currentSlug: string;
  search?: string;
  start_date?: string;
  end_date?: string;
  shot?: boolean;
  video?: boolean;
  file?: boolean;
  offset: number;
  length: number;
  tags: string[];
  isBlocked: boolean;
  sort?: string;
  selectCurrentItem?: object;
  addedTag: IAddedTag[];
  order: "ASC" | "DESC";
  tagList: ICardTag[];
  loading: boolean;
  loadingFragment: boolean;
  //computed
  items: Computed<IScreenshots, ICardPaneItem[]>;
  ids: Computed<IScreenshots, string[]>;
  tagNameList: Computed<IScreenshots, string[]>;
  selectionMode: Computed<IScreenshots, boolean>;
  filter: Computed<IScreenshots, IHistoryFilter>;
  hasFilter: Computed<IScreenshots, boolean>;
  //actions
  reset: Action<IScreenshots, void | boolean>;
  setItems: Action<IScreenshots, ICardItem[]>;
  setSelectCurrentItem: Action<IScreenshots, object>;
  setHistoryLang: Action<IScreenshots, string>;
  setMSG: Action<IScreenshots, IMSG | null>;
  setInfo: Action<IScreenshots, IHistoryInfo>;
  setHeaderOpened: Action<IScreenshots, boolean>;
  setCalendarOpened: Action<IScreenshots, boolean>;
  setTagList: Action<IScreenshots, ICardTag[]>;
  updateTagLits: Action<IScreenshots, ICardTag[]>;
  setSelected: Action<IScreenshots, string[]>;
  setCurrentSlug: Action<any>;
  setAddedTag: Action<string[]>;
  setAddedTagToNull: Action<IScreenshots[]>;
  clearItems: Action<IScreenshots>;
  setLoading: Action<IScreenshots, boolean>;
  setIsBlocked: Action<IScreenshots, boolean>;
  setLoadingFragment: Action<IScreenshots, boolean>;
  setControls: Action<IScreenshots, boolean>;
  setFilter: Action<IScreenshots, IHistoryFilter>;
  setFragment: Action<IScreenshots, IHistoryFragment | INoFileReason>;
  setItem: Action<IScreenshots, ICardItem | null>;
  //thunks
  deselect: Thunk<IScreenshots>;
  clearFilter: Thunk<IScreenshots, void | boolean>;
  addFilter: Thunk<IScreenshots, [string, any, boolean?]>;
  removeFilter: Thunk<IScreenshots, string | string[]>;
  getSlug: Thunk<IScreenshots, string/* | void*/, any, IStoreModel>;//TODO: when void, take slug from router (if needed)
  setSlug: Thunk<IScreenshots, IHistoryFragment | INoFileReason>;
  getHistory: Thunk<IScreenshots, boolean | void, any, IStoreModel>;
  getHistoryPeriod: Thunk<IScreenshots, IHistoryPeriod, any, IStoreModel>;
  setHistory: Thunk<IScreenshots, IShowHistory>;
  moreHistory: Thunk<IScreenshots, any | void, any, IStoreModel>;
  applyItems: Thunk<IScreenshots, ICardItem[]>;
  applyDeletedItems: Thunk<IScreenshots, ICardSlugs, any, IStoreModel>;
  copyBBCode: Thunk<IScreenshots, ICardItem, any, IStoreModel>;
  copyPreviewCode: Thunk<IScreenshots, ICardItem, any, IStoreModel>;
  askDeleteItems: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  askShareItems: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  copyItemLinks: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  copyLink: Thunk<IScreenshots, string, any, IStoreModel>;
  downloadItems: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  downloadItem: Thunk<IScreenshots, ICardItem[]>;
  updateTagList: Thunk<IScreenshots, ICardTag[], any, IStoreModel>
  askSetCardTags: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;

  applySetCardTags: Thunk<IScreenshots, ICardTagsSet, any, IStoreModel>;
  changeTag: Thunk<IScreenshots, ICardTag, any, IStoreModel>;
  applyAddCardTags: Thunk<IScreenshots, ICardTagsSet, any, IStoreModel>,
  applyAppendedTag: Thunk<IScreenshots, ICardTag, any, IStoreModel>;
  applyRemovedTag: Thunk<IScreenshots, ICardTagId, any, IStoreModel>;
  setUnblockSlug: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;

  //modals
  askSetName: Thunk<IScreenshots, IAskSetName | void, any, IStoreModel>;
  askSetEmail: Thunk<IScreenshots, IAskSetEmail | void, any, IStoreModel>;
  askSetPassword: Thunk<IScreenshots, IAskSetPassword | void, any, IStoreModel>;
  askSetLanguage: Thunk<IScreenshots, IAskSetLanguage | void, any, IStoreModel>;
  askSetClearHistory: Thunk<IScreenshots, IAskSetClearHistory | void, any, IStoreModel>;
  askSetDeleteAccount: Thunk<IScreenshots, IAskSetDeleteAccount | void, any, IStoreModel>;
  askSetHelpTranslate: Thunk<IScreenshots, IAskSetHelpTranslate | void, any, IStoreModel>;
  askSetDeleteAvatar: Thunk<IScreenshots, IAskSetDeleteAvatar | void, any, IStoreModel>;

  askSetDonate: Thunk<IScreenshots, IAskSetDonate, any, IStoreModel>;
  askSetPlans: Thunk<IScreenshots, IAskSetPlans, any, IStoreModel>;
  askSetPayPlan: Thunk<IScreenshots, IAskSetPayPlan | void, any, IStoreModel>;
  askSetPaymentResult: Thunk<IScreenshots, IAskSetPaymentResult, any, IStoreModel>;

  askSetUnsubscribe: Thunk<IScreenshots, IAskSetUnsubscribe | void, any, IStoreModel>;
  askSetChangeMethod: Thunk<IScreenshots, IAskSetChangeMethod | void, any, IStoreModel>;

  askSetTeamIP: Thunk<IScreenshots, IAskSetTeamIP, any, IStoreModel>;
  askSetTeamPurchaseLicenses: Thunk<IScreenshots, IAskSetTeamPurchaseLicenses, any, IStoreModel>;
  askSetPurchaseLicenses: Thunk<IScreenshots, IAskSetPurchaseLicenses, any, IStoreModel>;
  askSetTeamCustomDomain: Thunk<IScreenshots, IAskSetTeamCustomDomain | void, any, IStoreModel>;
  askSetTeamInviteUsers: Thunk<IScreenshots, IAskSetTeamInviteUsers, any, IStoreModel>;
  askSetDeleteUser: Thunk<IScreenshots, IAskSetDeleteUser, any, IStoreModel>;

  askProtectCard: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  askUnprotectCard: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  askUnlockCard: Thunk<IScreenshots, ICardItem | ICardItem[], any, IStoreModel>;
  applyProtectCard: Thunk<IScreenshots, ICardProtected, any, IStoreModel>;
  applyUnprotectCard: Thunk<IScreenshots, ICardUnprotected, any, IStoreModel>;
  applyUnlockCard: Thunk<IScreenshots, ICardUnlocked, any, IStoreModel>;

  applyCopyLink: Thunk<IScreenshots, IGroupLinkResult, any, IStoreModel>;
  applyShareLink: Thunk<IScreenshots, IShareLinkResult, any, IStoreModel>;
  //listeners

}

const filterDefaults = {
  search: "",
  start_date: "",
  end_date: "",
  shot: false,
  video: false,
  file: false,
  offset: 0,
  length: 20,
  tags: [],
};
const sortDefaults = {
  sort: FIELD.CREATED_ON,
  order: "DESC",
};
const stateDefaults = {
  MSG: null,
  list: [],
  language: 'en',
  currentFragment: null,
  currentItem: null,
  controls: false,
  isBlocked: false,
  selected: [],
  addedTag: [],
  tagList: [],
  currentSlug: '',
  info: { total: null, start_date: null, end_date: null },
  headerOpened: false,
  calendarOpened: false,
  search: filterDefaults.search,
  start_date: filterDefaults.start_date,
  end_date: filterDefaults.end_date,
  shot: filterDefaults.shot,
  video: filterDefaults.video,
  file: filterDefaults.file,
  offset: filterDefaults.offset,
  length: filterDefaults.length,
  selectCurrentItem: {},
  tags: filterDefaults.tags,
  sort: sortDefaults.sort,
  order: sortDefaults.order as "ASC" | "DESC",
  loading: false,
  loadingFragment: false,
};
export const historyModel: IScreenshots = {
//state
  MSG: stateDefaults.MSG,
  list: stateDefaults.list,
  language: stateDefaults.language,
  currentFragment: stateDefaults.currentFragment,
  currentItem: stateDefaults.currentItem,
  controls: stateDefaults.controls,
  selected: stateDefaults.selected,
  isBlocked: stateDefaults.isBlocked,
  tagList: stateDefaults.tagList,
  info: stateDefaults.info,
  selectCurrentItem: stateDefaults.selectCurrentItem,
  headerOpened: stateDefaults.headerOpened,
  calendarOpened: stateDefaults.calendarOpened,
  search: stateDefaults.search,
  start_date: stateDefaults.start_date,
  end_date: stateDefaults.end_date,
  addedTag: stateDefaults.addedTag,
  shot: stateDefaults.shot,
  video: stateDefaults.video,
  currentSlug: stateDefaults.currentSlug,
  file: stateDefaults.file,
  offset: stateDefaults.offset,
  length: stateDefaults.length,
  tags: stateDefaults.tags,
  sort: stateDefaults.sort,
  order: stateDefaults.order as "ASC" | "DESC",
  loading: stateDefaults.loading,
  loadingFragment: stateDefaults.loadingFragment,
//computed
  items: computed((state) => {
    const items = [];
    const list = [...state.list];
    // console.log({state})
    const takeKey = (item) => getDateISOAsKebab(item[FIELD.CREATED_ON], !0, !0, !1);
    while (list.length > 0) {
      const d = takeKey(list[0]);
      const byDate = remove(list, (item) => {
        return String(item[FIELD.CREATED_ON]).indexOf(d) === 0;
      });
      const item = {
        date: d,
        dateLabel: dayjs(d + "-01").locale(state.language).format("MMMM YYYY"),
        // dateLabel: getCurrentMonth('ru'),
        items: byDate,
      };
      items.push(item);
    }
    return items;
  }),
  ids: computed(state => state.list.map((item) => item.id)),
  tagNameList: computed(state => state.tagList.map(t => t.name)),
  selectionMode: computed(state => state.selected.length > 0),
  filter: computed(state => {
    const filter = Object.keys(filterDefaults)
      .filter(k => state[k] != null && state[k] !== "")
      .reduce((ac, v) => {
        ac[v] = state[v];
        return ac;
      }, {sort: `${state.order==="DESC"?"-":"+"}${state.sort}`});
    return filter;
  }),
  hasFilter: computed(state => {
    let res = Object.keys(filterDefaults).some((k) => {
      return !isEqual(state[k], filterDefaults[k]);
    });
    return res;
  }),
//actions
  reset: action((state) => {
    return {...state, ...stateDefaults};
  }),
  setItems: action((state, list) => {
    // console.debug("setItems", state, list)
    return {...state, list};
  }),
  setIsBlocked: action((state, isBlocked) => {
    return {...state, isBlocked: isBlocked};
  }),
  setFragment: action((state, fragment={list: [], is_owner: false, next: "", prev: ""}) => {
    console.log('fragment', fragment);
    const list = fragment["list"] || [];
    const is_owner = fragment["is_owner"] || false;
    const next = fragment["next"] || "";
    const advertising = fragment["advertising"] || "";
    const prev = fragment["prev"] || "";
    const reason = fragment["reason"];

    if (reason) {
      return {...state, currentItem: {reason}};
    } else if (list.length===0) {
      return {...state, currentItem: {reason: ENoFileReason.NOT_FOUND}};
    } else if (list.length > 1) {
      return {...state, currentFragment: list, currentItem: null};
    } else if (list.length === 1) {
      return {...state, currentItem: {...list[0], is_owner, next, prev, advertising}};
    }
    if (list.length === 0) {
      return {...state, currentFragment: []};
    }
  }),
  setItem: action((state, currentItem) => {
    // console.debug("setItem", state, currentItem);
    return {...state, currentItem}
  }),
  setMSG: action((state, MSG) => { return {...state, MSG}; }),
  setInfo: action((state, info) => { return {...state, info}; }),
  setSelectCurrentItem: action((state, item) => { return {...state, selectCurrentItem: item}}),
  setHistoryLang: action((state, language) => {
    return {...state, language}; }),
  setCurrentSlug: action((state, slug) => {return {...state, currentSlug: slug}}),
  setHeaderOpened: action((state, headerOpened) => { return {...state, headerOpened}; }),
  setCalendarOpened: action((state, calendarOpened) => { return {...state, calendarOpened}; }),
  setTagList: action((state, tagList) => { return {...state, tagList}; }),
  updateTagLits: action((state, tagList) => { return {...state, tagList: tagList}; }),
  setSelected: action((state, slugs) => { return {...state, selected: slugs}; }),
  clearItems: action((state, payload?) => { return {...state, list: []}; }),
  setLoading: action((state, loading) => { return {...state, loading}; }),
  setLoadingFragment: action((state, loadingFragment) => { return {...state, loadingFragment}; }),
  setControls: action((state, controls=false) => {return {...state, controls};}),
  setAddedTag: action((state, addedTag) => {return {...state, addedTag: addedTag};}),
  setAddedTagToNull: action((state, payload) => {return {...state, addedTag: payload};}),
  setFilter: action((state, payload) => { return {...state, ...payload}; }),

//thunks
  deselect: thunk(async (action) => { await action.setSelected([]); }),
  getSlug: thunk(async (actions, slug, {getStoreActions, getState}) => {
    const state = getState();
    const filter = {...state.filter, slug};
    const allActions = getStoreActions();
    await actions.setLoadingFragment(true);
    await allActions.GET_DATA_BY_SLUG(filter);
  }),
  setSlug: thunk(async (actions, payload) => {
    await actions.setLoadingFragment(false);
    await actions.setFragment(payload);
  }),
  getHistory: thunk(async (actions, payload, {getState, getStoreActions}) => {
    const state = getState();
    const allActions = getStoreActions();
    await actions.setLoading(true);
    if (payload) await actions.reset();
    allActions.GET_HISTORY_INFO();
    await allActions.GET_HISTORY(state.filter);
  }),
  getHistoryPeriod: thunk(async (actions, params, {getState, getStoreActions}) => {
    const state = getState();
    const allActions = getStoreActions();
    await actions.setLoading(true);
    allActions.GET_HISTORY_INFO(params);
  }),
  setHistory: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    await actions.setLoading(false);
    const {list, info} = payload;
    const total = info && info.total;
    await actions.setInfo({...state.info, total});
    await actions.applyItems(list);
  }),
  moreHistory: thunk(async (actions, payload, {getState, getStoreActions}) => {
    const state = getState();
    const info = state.info;
    const total = info.total;
    if (total <= state.list.length) return false;
    const allActions = getStoreActions();
    const filter = {...state.filter};
    filter.offset = state.list.length;
    await actions.setLoading(true);
    await allActions.GET_HISTORY(filter);
    return true;
  }),
  applyItems: thunk(async (actions, items, {getState}) => {
    const state = getState();
    const list = state.list;
    const ids = state.ids;
    const setItems = actions.setItems;
    const currentItems = list;
    items.forEach((item, i) => {
      const foundIndex = ids.findIndex((id) => id === item.id);
      if (!!~foundIndex) currentItems.splice(foundIndex, 1, item);
    });
    const newItems = currentItems
      .concat(items.filter((item) => !ids.includes(item.id)))
      .sort(getObjectArrayComparator(FIELD.CREATED_ON, true));
    await setItems(newItems);
  }),
  clearFilter: thunk(async (actions, reloadHistory=false, {getState}) => {
    const state = getState();
    await actions.setFilter(filterDefaults);
    if (reloadHistory) {
      await actions.setItems([]);
      await actions.getHistory();
    }
    return {
      ...state,
      ...filterDefaults
    };
  }),
  addFilter: thunk(async (actions, [name, value, reloadHistory=false], {getState}) => {
    const state = getState();
    const filter: any = {};
    let changed = false;
    switch (name) {
      case "tags":
        filter.tags = [].concat(value);
        if (state.tags.length!==filter.tags.length) changed = true;
        break;
      case "tag":
        filter.tags = state.tags.concat(state.tags.includes(value) ? [] : value);
        if (state.tags.length!==filter.tags.length) changed = true;
        break;
      case "untag":
        filter.tags = state.tags.filter(t=>t!==value);
        if (state.tags.length!==filter.tags.length) changed = true;
        break;
      case "dates":
        filter.start_date = value[0];
        filter.end_date = value[1];
        if (filter.start_date !== state.start_date || filter.end_date !== state.end_date) changed = true;
        break;
      default:
        filter[name] = value;
        if (filter[name]!==state[name]) changed = true;
        break;
    }
    if (changed) await actions.setFilter(filter);
    if (reloadHistory) {
      await actions.setItems([]);
      await actions.getHistory();
    }
    return true;
  }),
  removeFilter: thunk(async (actions, name: string | string[], {getState}) => {
    const state = getState();
    if (name instanceof Array) {
      const tags = state.tags.filter(t => !name.includes(t));
      await actions.setFilter({tags});
    } else if (name==="dates") {
      await actions.setFilter({
        [FIELD.START_DATE]: filterDefaults[FIELD.START_DATE],
        [FIELD.END_DATE]: filterDefaults[FIELD.END_DATE]
      });
    } else {
      await actions.setFilter({[name]: filterDefaults[name]});
    }
    await actions.setItems([]);
    await actions.getHistory();
    return true;
  }),
  //ask* in command name means to show some form to user - for confirmation or to enter some data
  askDeleteItems: thunk(async (historyActions, items, { getStoreActions }) => {
    const allActions = getStoreActions();
    const haveCurrentItem = items[0]?.currentItem ? items[0]?.currentItem : items;
    const list: IHistorySlugList = [].concat(haveCurrentItem).map(it => it.slug);
    await historyActions.setIsBlocked(true);
    await allActions.dialogs.confirm([EDialogConfirm.DELETE_CARD, {}])
    .then((res: IDialogResponse) => {
      if (res.result === EDialogResult.SUBMIT) {
        allActions.dialogs.close();
        return allActions.DROP_HISTORY_FILES({list});
      } else if (res.result === EDialogResult.CLOSE) {
        return allActions.dialogs.close();
      }
    })
    .catch((err) => {
      return Promise.resolve();
    });
  }),
  setUnblockSlug: thunk(async (action, item, {getStoreActions}) => {
    const allActions = getStoreActions();
    await action.setIsBlocked(true);
    return allActions?.dialogs.action([EDialogAction.UNLOCK_FILE, {}]).then((res: IDialogResponse) => {
      if (res.result === EDialogResult.SUBMIT) {
        allActions.dialogs.close();
        const params = {list: [item[0].slug], password: res.data.password};
        //@ts-ignore
        return allActions.UNLOCK_FILE(params)
      } else if (res.result === EDialogResult.CLOSE) {
        return allActions.dialogs.close();
      }
    }).catch((err) => {
      return Promise.resolve();
    });
  }),
  applyDeletedItems: thunk(async (actions, data={list:[]}, { getState, getStoreActions }) => {
    const MSG = getState().MSG
    const slugs = data.list;
    const allActions = getStoreActions();
    const newItems = getState().list.filter((item) => !slugs.includes(item.slug));
    actions.setItems(newItems);
    allActions.pushCommonMessage(
      new Tip(null,
        MSG.MESSAGE.DELETE,
        EIcon.Trash
      )
    );
  }),
  askShareItems: thunk(async (actions, itemz, {getStoreActions, getStoreState}) => {
    const allActions = getStoreActions();
    const state = getStoreState();
    const haveCurrentItem = itemz[0]?.currentItem ? itemz[0]?.currentItem : itemz;
    const list: IHistorySlugList = [].concat(haveCurrentItem);
    const listSlug: IHistorySlugList = [].concat(haveCurrentItem).map(it => it.slug);
    actions.setSelectCurrentItem(list);
    await actions.setIsBlocked(true);
    await allActions.dialogs.info([EDialogInfo.SHARE_CARD, {onClick: (social) => {
      if (list.length===1) {
        return actions.applyShareLink({social, slug: list[0]});
      } else if (list.length>1) {
        //@ts-ignore
        return allActions.CREATE_SHARE_LINK({social, listSlug});
      }
    }}])
    // .then((res: IDialogResponse) => {
    //   return allActions.dialogs.close();
    // })
    .catch((err) => {
      return Promise.resolve();
    });
  }),
  applyShareLink: thunk(async (actions, data: any, {getStoreState}) => {
    const wholeState = getStoreState();
    const link = `${wholeState.router.root}/${data.slug?.slug}`;
    const isImage = isImageMIME(data.mime_type);
    const type = isImage ? URLS.SOCIAL_IMAGE["Soc"+data.social] : URLS.SOCIAL_VIDEO["Soc"+data.social];
    //const social = data.social;
    //url: `${wholeState.router.root}/${list[0]}`
    return goto(
      str(type, link)
    );
  }),
  copyItemLinks: thunk(async (actions, itemz, {getStoreActions}) => {
    const allActions = getStoreActions();
    const items = [].concat(itemz);
    const item: ICardItem = items[0];
    const list = items.map(it => it.slug);
    if (items.length===1) {
      await actions.copyLink(item.slug);
    } else if (items.length>1) {
      await allActions.CREATE_GROUP_LINK({list});
    }
  }),
  copyLink: thunk((actions, slug, {getStoreState, getStoreActions}) => {
    const MSG = getStoreState().MSG
    const wholeState = getStoreState();
    const allActions = getStoreActions();
    if (typeof document!=="undefined") {
      copyToClipboard(`${wholeState.router.root}/${slug}`);
      allActions.pushCommonMessage(
        new Tip(null,
          MSG.MESSAGE.COPY_LINK,
          EIcon.Link
        )
      );
    }
  }),
  copyBBCode: thunk(async (actions, data: any, {getStoreState, getStoreActions}) => {
    const state = getStoreState();
    const action = getStoreActions();
    const haveCurrentItem = data?.currentItem ? data?.currentItem : data
    copyBBCode(`${state.router.root}/${haveCurrentItem.slug}`, haveCurrentItem.link);
    action.pushCommonMessage(
      new Tip(null,
        `BBCode скопирован`,
        EIcon.Link
      )
    );
    return action.dialogs.close();
  }),
  copyPreviewCode: thunk(async (actions, data: ICardItem, {getStoreState, getStoreActions}) => {
    const state = getStoreState();
    const action = getStoreActions();
    const haveCurrentItem = data?.currentItem ? data?.currentItem : data
    copyPreviewCode(`${state.router.root}/${haveCurrentItem.slug}`, haveCurrentItem.preview);
    action.pushCommonMessage(
      new Tip(null,
        `Превью код скопирован`,
        EIcon.Link
      )
    );
    return action.dialogs.close();
  }),
  applyCopyLink: thunk(async (actions, data) => {
    await actions.copyLink(data.slug);
  }),
  downloadItems: thunk(async (actions, itemz, {getStoreState}) => {
    const allFiles = getStoreState();
    const arr3 = []
    const items: ICardItem[] = [].concat(itemz);
    allFiles.screenshots.list.forEach((item) => {
      items.forEach((item2) => {
        if (item.slug === item2.slug) {
          arr3.push(item);
        }
      });
    });
    if (typeof document!=="undefined") {
      arr3.map((item) => downloadFile(item.link, `${item.name}`));
    }
  }),
  downloadItem: thunk(async (action, item) => {
    if (typeof document!=="undefined") {
      item.map(item => downloadFile(item?.currentItem.link, `${item?.currentItem.name}`));
    }
  }),
  updateTagList: thunk(async (actions, tagList, {getStoreState, getStoreActions}) => {
    const wholeState = getStoreState();
    const allActions = getStoreActions();
    const dialogs = wholeState.dialogs;
    if (dialogs.subType===EDialogAction.SHOW_CARD_TAGS) {
      const p = dialogs.props;
      allActions.dialogs.setProps({...p, items: tagList});
    }
    await actions.setTagList(tagList);
  }),
  askSetCardTags: thunk(async (actions, itemz, {getState, getStoreActions}) => {
    const MSG = getState().MSG
    const state = getState();
    const allActions = getStoreActions();
    const items = [].concat(itemz);
    await actions.setIsBlocked(true);
    allActions.dialogs.action(
      [EDialogAction.SHOW_CARD_TAGS,
        {
      slugs: items.map(item => item.slug),
      items: state.tagList,
      selectedItems: items.reduce((ac, it) => {
        const haveCurrentItem = it?.currentItem ? it?.currentItem : it
        if (!haveCurrentItem.tags || haveCurrentItem.tags.length===0) return ac;
        return ac.concat(
          haveCurrentItem.tags
            .map(t=>t.id)
            .filter(id => !ac.includes(id))
        );
      }, []),
      onAdd: async (tag: ICardTagAdd) => {
        const errors = [];
        if (!tag.name) {
          errors.push(new ValidationError(null, MSG.ERROR.TAG_NAME.REQ, FIELD.TAG_NAME));
        }
        if (!tag.color) {
          errors.push(new ValidationError(null, MSG.ERROR.TAG_COLOR.REQ, FIELD.TAG_COLOR));
        }
        if (errors.length>0) {
          await allActions.setMessages(new MessageList(errors));
          return false;
        } else {
          await allActions.ADD_TAG(tag);
          return true;
        }
      },
      onEdit: async (tag:ICardTag) => {

        const errors = [];
        if (!tag.name) {
          errors.push(new ValidationError(null, MSG.ERROR.TAG_NAME.REQ, FIELD.TAG_NAME));
        }
        if (!tag.color) {
          errors.push(new ValidationError(null, MSG.ERROR.TAG_COLOR.REQ, FIELD.TAG_COLOR));
        }
        if (errors.length>0) {
          await allActions.setMessages(new MessageList(errors));
          return false;
        } else {
          await allActions.ALTER_TAG(tag);
          await actions.changeTag(tag)
          return true;
        }
      },
      onDelete: async (tag:ICardTagId) => {
        await allActions.DROP_TAG(tag);
        await actions.applyRemovedTag(tag)
      },
    }]).then((res: IDialogResponse) => {
      if (res.result === EDialogResult.SUBMIT) {
        allActions.dialogs.close();
        allActions.FILE_SET_TAGS({list: res.data.slugs, tags: res.data.tags});
        allActions.GET_DATA_BY_SLUG({slug: res.data.slugs[0]});
        return true
      } else if (res.result === EDialogResult.CLOSE) {
        return allActions.dialogs.close();
      }
    })
    .catch((err) => {
      return Promise.resolve();
    });
  }),

  askSetName: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_USERNAME, {onSubmit: (e, newName) => {
        allActions.dialogs.close();
        return allActions.setName({username: newName})
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetEmail: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_EMAIL, {onSubmit: (e, newEmail) => {
        allActions.dialogs.close();
        return allActions.setEmail({email: newEmail})
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetPassword: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_PASSWORD, {onSubmit: (e, newPwd) => {
        allActions.dialogs.close();
        return allActions.setPassword(newPwd)
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetLanguage: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_LANG, {onSubmit: (newLang) => {
        allActions.dialogs.close();
        return allActions.setLanguage(newLang)
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetClearHistory: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CLEAR_HISTORY, {
      onSubmit: (e, dropFiles) => {
        allActions.screenshots.getHistory()
        allActions.dropHistoryFiles(dropFiles)
        return allActions.dialogs.close();
      },
      onGetHistory: allActions.screenshots.getHistory,
      ...props}]).then(async (res: IDialogResponse) => {
      await allActions.screenshots.getHistory();
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetDeleteAccount: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.confirm([EDialogConfirm.DELETE_USER, {onSubmit: (e, user) => {
        allActions.dialogs.close();
        allActions.dropUser(user)
        return allActions.logout()
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetHelpTranslate: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.info([EDialogInfo.HELP_TRANSLATE, {onSubmit: (e) => {
        //TODO: add help translate
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetDonate: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.DONATE, {
      plan: props.plan,
      onClearErrors: allActions.clearMessagesByField(''),
      onSubmit: (e) => {
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetPlans: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.action([!props.mobile ? EDialogAction.COMPARE_PLANS : EDialogAction.COMPARE_PLANS_MOBILE, {
      onSubmit: (e) => {
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetPayPlan: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.UPGRADE_PLAN, {onSubmit: (e, paymentData) => {
        allActions.dialogs.close();
        return allActions.sendInvoice(paymentData)
      }, ...props}]).then((res: IDialogResponse) => {
      allActions.clearMessagesByField('');
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetDeleteAvatar: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.DELETE_AVATAR, {onSubmit: (e) => {
        allActions.dialogs.close();
        return allActions.dropAvatar()
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),

  askSetUnsubscribe:thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.confirm([EDialogConfirm.UNSUBSCRIBE, {onSubmit: (e, value) => {
        return allActions.dialogs.close()
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetChangeMethod: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_PAYMENT_METHOD, {
      onClearError: allActions.clearMessagesByField,
      setMessages: allActions.setMessages,
      sendInvoice: allActions.sendInvoice,
      onSubmit: (e, value) => {
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      allActions.clearMessagesByField('')
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetPaymentResult: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.info([ propz.success ? EDialogInfo.PAYMENT_SUCCESS : EDialogInfo.PAYMENT_ERROR, {
      onSubmit: (e, value) => {
        allActions.consumeUrlParams({clearHash: true, params: []})
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
        allActions.consumeUrlParams({clearHash: true, params: []})
        return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),

  askSetTeamIP: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_TEAM_IP_LIMIT, {onSubmit: (e, value) => {
        allActions.dialogs.close();
        return allActions.setTeamIp({enabled: propz.limitedIp, ip_from: value.from, ip_to: value.to})
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetTeamPurchaseLicenses: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.TEAM_PURCHASE_LICENSES, {onSubmit: (e, value) => {
        if (propz.isPaymentMethod) {
          allActions.sendInvoice({item_name: PRODUCTS.SLOT, quantity: value});
          return allActions.dialogs.close();
        } else {
          propz.setSize(propz.size)
          return propz.payLicenses({size: value});
        }
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetPurchaseLicenses: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.PURCHASE_LICENSES, {
      onSubmit: (e, value) => {
        allActions.clearMessagesByField('')
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      allActions.clearMessagesByField('')
      return allActions.dialogs.close();
    },).catch((err) => {
      allActions.clearMessagesByField('')
      return Promise.resolve();
    });
  }),
  askSetTeamCustomDomain: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz || {}
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.CHANGE_TEAM_DOMAIN, {onSubmit: (e, value) => {
        allActions.dialogs.close();
        return allActions.domainChange(value)
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetTeamInviteUsers: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.action([EDialogAction.INVITE_TEAMMATES, {onSubmit: (e, value) => {
        value.map((mail, i) => {
          if (propz.limit > i) allActions.inviteUser({email: mail})
        })
        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),
  askSetDeleteUser: thunk(async (actions, propz, {getStoreActions}) => {
    const props = propz
    const allActions = getStoreActions();
    await allActions.dialogs.confirm([EDialogConfirm.DELETE_USER, {onSubmit: (e, value) => {
        const leave = (payload) => {
          allActions.switchToProfile(true)
          allActions.leaveTeam(payload)
        }

        !props.currentMember.invited ? !props.currentMember.currentUser ?
          allActions.expelMember({username: props.currentMember.username}) :
          leave({username: props.currentMember.username}) :
          allActions.cancelInvite({email: props.currentMember.email});

        return allActions.dialogs.close();
      }, ...props}]).then((res: IDialogResponse) => {
      return allActions.dialogs.close();
    },).catch((err) => {
      return Promise.resolve();
    });
  }),

  //added & edited
  applyAppendedTag: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    const list = state.list;
    const tagList = state.tagList;
    const newItems = [];
    const foundIndex = tagList.findIndex(t => t.id===payload.id);
    if (!!~foundIndex) {
      tagList
        .slice()
        .splice(
          foundIndex,
          1,
          {...tagList[foundIndex], ...payload}
        );
    } else {
      newItems.push(payload);
    }
    await actions.updateTagList(tagList.concat(newItems));
    const foundItem = list.some(item => item.tags && !!item.tags.find(t => t.id === payload.id));
    if (!!foundItem) {
      await actions.setItems(list.map(item => {
        //if (!item.tags) item.tags = [];
        if (!item.tags) return item;
        const foundIndex = item.tags.findIndex(t => t.id === payload.id);
        if (!~foundIndex) return item;
        item.tags = item.tags.map(t => {
          if (t.id === payload.id) {
            return {...t, ...payload};
          }
          return t;
        });
        return item;//{...item};
      }));
    }
  }),
  applyRemovedTag: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    const list = state.list;
    const tagList = state.tagList;
    const foundItem = list.some(item => item.tags && !!item.tags.find(t => t.id === payload.id));
    if (!!foundItem) {
      await actions.setItems(list.map(item => {
        //if (!item.tags) item.tags = [];
        if (!item.tags) return item;
        const foundIndex = item.tags.findIndex(t => t.id === payload.id);
        if (!~foundIndex) return item;
        item.tags = item.tags.filter(t => t.id!==payload.id);
        //TODO try return item;
        return item;//{...item};
      }));
    }
    await actions.updateTagList(tagList.filter(t => t.id !== payload.id));
  }),
  changeTag: thunk(async (action, payload, {getState}) => {
    const state = getState();
    const tags = state.tagList;
    const changedTags = tags.map((el) => {
      if (el.id === payload.id) {
        el = payload
      }
      return el
    })
    await action.updateTagLits(changedTags);
  }),

  applySetCardTags: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    const list = state.list;
    const tags = payload.tags || [];
    const slugs = payload.list;
    const tagsToSet = state.tagList.filter( t => tags.includes(t.id) );
    await actions.setItems(list.map(item => {
      if (slugs.includes(item.slug)) {
        item.tags = tagsToSet;
        //return {...item};
      }
      return item;
    }));
  }),
  applyAddCardTags: thunk(async (actions, payload, {getState}) => {
    const state = getState();
    const list = state.list;
    const tags = payload.tags || [];
    const slugs = payload.list;
    const tagsToSet = state.tagList.filter( t => tags.includes(t.id) );
    await actions.setItems(list.map(item => {
      if (slugs.includes(item.slug)) {
        const tagsIds = item.tags.map(t => t.id);
        item.tags = item.tags.concat(tagsToSet.filter(t => !tagsIds.includes(t.id)));
        //return {...item};
      }
      return item;
    }));
  }),
  askProtectCard: thunk(async (actions, itemz, mdl) => {
    return setCardProtection(actions, itemz, "PROTECT", mdl);
  }),
  askUnprotectCard:thunk(async (actions, itemz, {getStoreActions}) => {
    const allActions = getStoreActions();
    console.log('itemz', itemz);
    const haveCurrentItem = itemz[0]?.currentItem ? itemz[0]?.currentItem : itemz;
    console.log('haveCurrentItem', haveCurrentItem);
    const list = [].concat(haveCurrentItem).map(it => it.slug);
    await actions.setIsBlocked(true);
    return allActions.dialogs.confirm([EDialogConfirm.UNPROTECT_CARD, {}]).then((res: IDialogResponse) => {
      if (res.result === EDialogResult.SUBMIT) {
        allActions.dialogs.close();
        return allActions.UNPROTECT_FILE({list});
      } else if (res.result === EDialogResult.CLOSE) {
        return allActions.dialogs.close();
      }
    })
    .catch((err) => {
      return Promise.resolve();
    });
  }),
  askUnlockCard:thunk(async (actions, itemz, mdl) => {
    return setCardProtection(actions, itemz, "UNLOCK", mdl);
  }),
  applyProtectCard: thunk(async (actions, data, {getState, getStoreActions}) => {
    const allActions = getStoreActions();
    const MSG = getState().MSG
    const state = getState();
    const list = state.list;
    const files = data.list;
    const slugs = files.map(it => it.slug);
    if (list.some(item => slugs.includes(item.slug))) {
      await actions.setItems(list.map(item => {
        const index = slugs.findIndex(s => s===item.slug);
        if (!!~index) {
          return {...item, ...files[index]};
        }
        return item;
      }));
      allActions.pushCommonMessage(
        new Tip(null,
          MSG.MESSAGE.LOCK,
          EIcon.Link
        )
      );
    }
  }),
  applyUnprotectCard: thunk(async (actions, data, {getState, getStoreActions}) => {
    const allActions = getStoreActions();
    const MSG = getState().MSG
    const state = getState();
    const list = state.list;
    const files = data.list;
    const slugs = files.map(it => it.slug);
    if (list.some(item => slugs.includes(item.slug))) {
      await actions.setItems(list.map(item => {
        const index = slugs.findIndex(s => s===item.slug);
        if (!!~index) {
          return {...item, ...files[index]};
        }
        return item;
      }));
      allActions.pushCommonMessage(
        new Tip(null,
          MSG.MESSAGE.UNLOCK,
          EIcon.Link
        )
      );
    }
  }),
  applyUnlockCard: thunk(async (actions, data, {getState, getStoreActions}) => {
    const allActions = getStoreActions();
    const MSG = getState().MSG
    const state = getState();
    const list = state.list;
    const file = data.file;
    await actions.setItem(file);
    if (list.some(item => file.slug===item.slug)) {
      await actions.setItems(list.map(item => {
        if (file.slug===item.slug) {
          return {...item, ...file};
        }
        return item;
      }));
      allActions.pushCommonMessage(
        new Tip(null,
          MSG.MESSAGE.UNLOCK,
          EIcon.Link
        )
      );
    }
  }),
//listeners
};

//////////////////////////
const setCardProtection = async (actions, itemz, command: "UNLOCK" | "PROTECT", { getStoreActions }) => {
  const allActions = getStoreActions();
  const haveCurrentItem = itemz[0]?.currentItem ? itemz[0]?.currentItem : itemz;
  const items = [].concat(haveCurrentItem);
  allActions.dialogs.action([EDialogAction.PROTECT_CARD, {
    slugs: items.map(item => item.slug),
    type: command==="UNLOCK" ? ECardProtectType.ACCESS : ECardProtectType.PROTECT,
  }]).then((res: IDialogResponse) => {
    console.log(res)
    if (res.result === EDialogResult.SUBMIT) {
      const errors = [];
      if (!res.data[FIELD.PWD]) {
        errors.push(new ValidationError(null, MSG.ERROR.PWD.REQ, FIELD.PWD));
      }
      if (errors.length>0) {
        allActions.setMessages(new MessageList(errors));
        return setCardProtection(actions, itemz, command, { getStoreActions });
      }
      allActions.dialogs.close();
      return allActions[`${command}_FILE`]({
        [command==="UNLOCK" ? "slug" : "list"]: res.data.slugs,
        password: res.data[FIELD.PWD]
      });
    }
    else {
      allActions.clearMessagesByField(FIELD.PWD)
      return allActions.dialogs.close();
    }
    // else if (res.result === EDialogResult.CLOSE) {
    //   console.log('close', FIELD.PWD)
    //   allActions.clearMessagesByField(FIELD.PWD)
    //   return allActions.dialogs.close();
    // }
  })
  .catch((err) => {
    console.log({err})
    return Promise.resolve();
  });
};
