import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  ETextSize, EEditInput, EPanelWidth
} from 'components';
import {cssSize, humanizeDate, ifCond, str} from 'utils';
import {FIELD} from 'const';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IDeleteAccount extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const initialValues = {
  [FIELD.PWD]: ''
}
const initialErrors = {
  [FIELD.PWD]: []
}

export const DeleteAccount = React.forwardRef((props: IDeleteAccount, ref) => {
  const { onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const setIsBlocked = useStoreActions((state) => state.screenshots.setIsBlocked);
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState(initialErrors);
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) {
      setIsBlocked(false);
      onClose(e);
    };
  }, [onClose]);
  const handleSubmit = React.useCallback((e) => {
    if (isErrors()) {
      getFormErrors()
    } else if (onSubmit instanceof Function) {
      setIsBlocked(false);
      onSubmit(e, {[FIELD.PWD]: values[FIELD.PWD]})
    };
  }, [onSubmit, values, setValues, errors, setErrors]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
  }, [values, setValues, errors, setErrors]);
  const getFormErrors = React.useCallback(() => {
    setErrors({
      [FIELD.PWD]: !values[FIELD.PWD] ? ['the field must not be empty'] : []
    })
  }, [values, setValues, errors, setErrors])
  const isErrors = React.useCallback(() => {
    for (let value in values) {
      if (!values[value]) {
        return true
      }
    }
  }, [values]);

  if ( !ifCond(_if) ) return null;
  return (
    <TWDeleteAccount ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.CONFIRM.DELETE_ACCOUNT_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={10} />
        <TextBox text={MSG.CONFIRM.DELETE_ACCOUNT_ASK} size={ETextSize.MEDIUM} inline={false} block={true}/>
        <TextBox text={MSG.CONFIRM.DELETE_ACCOUNT_WARN} size={ETextSize.MEDIUM} inline={false} block={true}/>
        <SpaceBox height={20} />
        <EditBox caption={MSG.PROFILE.PWD} errors={errors[FIELD.PWD]} value={values[FIELD.PWD]} onChange={(e) => handleChange(e, FIELD.PWD)} onBlur={(e) => handleChange(e, FIELD.PWD)} input={EEditInput.PWD} fill={true}/>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_SECONDARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.DELETE} kind={EButtonKind.CRITICAL} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWDeleteAccount>
  );
});

const TWDeleteAccount = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
