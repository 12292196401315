import {action, Action, ActionOn, actionOn, computed, Computed, thunk, Thunk, thunkOn, ThunkOn} from "easy-peasy";
import { IStoreModel } from 'store';
import {
  IUserInfo,
  IFieldMessages,
  IAPIMessageTree,
  IAPIMessages,
  IFieldErrors,
  IConsumeUrlParams,
  ISetLanguage,
  IListLanguages,
  IMSG,
  IAvatarLoad,
  ITeamInfo,
  ISetName,
  ISetPassword,
  ISetEmail,
  IDropHistory,
  IDropUser,
  IInviteUser,
  IExpelMember,
  IRoleChange,
  IDomainChange,
  IRequestTeamSize,
  IAcceptTeamInvite,
  IExtendTeam,
  ITeamAvatar,
  ISetTeamIPFilter,
  ILogo,
  IInvoiceItem,
  IPaymentMethod,
  ICloudpaymentsAsc, Zoom
} from 'types';
import {FIELD, LNG, LOCAL_PARAM, ROUTE, STORE_PARAM, URL_PARAM} from 'const';
import {concatSearchParams, MessageList, extractRouterParams, Tip, ValidationError, getToken} from 'utils';
import {ITeamIPLimit} from "../../components/form/Action";

export interface IMainModel {
  //state
  pgDarkMode: boolean;
  profile: IUserInfo;
  MSG: IMSG | null;
  languagesList: IListLanguages;
  avatarLoad: IAvatarLoad;
  logoLoad: IAvatarLoad;
  team: ITeamInfo;
  teamBuffer: ITeamInfo;
  paymentMethod: IPaymentMethod,
  invoice: IInvoiceItem[],
  cloudpaymentsAsc: ICloudpaymentsAsc,
  commonMessages: MessageList;
  fieldMessages: IFieldMessages;
  zoom: boolean;
  //computed
  plan: Computed<IStoreModel, string>;
  fieldErrors: Computed<IStoreModel, IFieldErrors>;
  apiMessages: Computed<IStoreModel, IAPIMessages>;
  apiMessageTree: Computed<IStoreModel, IAPIMessageTree>;
  logo: Computed<IStoreModel, ILogo>;
  language: Computed<IStoreModel, string>;
  darkMode: Computed<IStoreModel, boolean>;
  cid: Computed<IStoreModel, string>;
  tid: Computed<IStoreModel, string>;
  homePath: Computed<IStoreModel, string>;
  reset: Action<IStoreModel, void | boolean>;
  update: Action<IStoreModel>;
  setAvatarLoad: Action<IStoreModel, IAvatarLoad | null>
  setLogoLoad: Action<IStoreModel, IAvatarLoad | null>
  setLogoClear: Action<IStoreModel>
  setProfile: Action<IStoreModel, IUserInfo | null>;
  clearCloudpaymentsAsc: Action<IStoreModel>;
  setCloudpaymentsAsc: Action<IStoreModel, ICloudpaymentsAsc>;
  setListLanguages: Action<IStoreModel, IListLanguages | null>;
  setMSG: Action<IStoreModel, IMSG | null>;
  setTeam: Action<IStoreModel, ITeamInfo | null>;
  setTeamBuffer: Action<IStoreModel, ITeamInfo | null>;
  setPaymentMethod: Action<IStoreModel, any>;
  setInvoiceList: Action<IStoreModel, IInvoiceItem[]>;

  setZoom: Action<Zoom, boolean>;
  setMessages: Action<IStoreModel, MessageList>;
  appendMessages: Action<IStoreModel, MessageList>;
  clearAllMessages: Action<IStoreModel>;
  pushCommonMessage: Action<IStoreModel, MessageList | Tip | ValidationError | string>;
  clearCommonMessage: Action<IStoreModel, string | string[] | null>;
  clearCommonMessages: Action<IStoreModel>;
  clearFieldMessages: Action<IStoreModel>;
  clearMessagesByField: Action<IStoreModel, string|string[]>;
  clearMessagesByApiName: Action<IStoreModel, string|string[]>;
  setPgDarkMode: Action<IStoreModel, boolean>;
  removeUrlParams?: Action<IStoreModel, IConsumeUrlParams>;
  //thunks
  resetApp: Thunk<IStoreModel, void | boolean>;
  setTid: Thunk<IStoreModel, string>;
  setName: Thunk<IStoreModel, ISetName>;
  setPassword: Thunk<IStoreModel, ISetPassword>;
  setEmail: Thunk<IStoreModel, ISetEmail>;
  setLanguage: Thunk<IStoreModel, ISetLanguage>;
  dropUser: Thunk<IStoreModel, IDropUser>;
  inviteUser: Thunk<IStoreModel, IInviteUser>;
  cancelInvite: Thunk<IStoreModel, IInviteUser>;
  promoteMember: Thunk<IStoreModel, IExpelMember>;
  demoteMember: Thunk<IStoreModel, IExpelMember>;
  extendTeam: Thunk<IStoreModel, IExtendTeam>;
  expelMember: Thunk<IStoreModel, IExpelMember>;
  leaveTeam: Thunk<IStoreModel, IExpelMember>;
  uploadLogo: Thunk<IStoreModel, ITeamAvatar>;
  setTeamIp: Thunk<IStoreModel, ISetTeamIPFilter>;
  changeRole: Thunk<IStoreModel, IRoleChange>;
  domainChange: Thunk<IStoreModel, IDomainChange>;
  requestTeamSize: Thunk<IStoreModel, IRequestTeamSize>;
  acceptInvitation: Thunk<IStoreModel, IAcceptTeamInvite>;
  uploadAvatar: Thunk<IStoreModel>;
  dropAvatar: Thunk<IStoreModel>;
  dropHistoryFiles: Thunk<IStoreModel, IDropHistory>;
  getProfile: Thunk<IStoreModel>;
  // setInvoice: Thunk<IStoreModel>;
  // setPaymentMethod: Thunk<IStoreModel>;
  getLanguages: Thunk<IStoreModel>;
  getTeam: Thunk<IStoreModel>;
  consumeUrlParams: Thunk<IStoreModel, IConsumeUrlParams>;
  switchToHome: Thunk<IStoreModel, boolean>;
  switchToTeam: Thunk<IStoreModel, boolean>;
  switchToProfile: Thunk<IStoreModel, boolean>;
  switchToSignin: Thunk<IStoreModel, boolean>;
  //goHome?: Thunk<IStoreModel, boolean>;

  //listeners
  onConsume?: ActionOn<IStoreModel>
  onRouteChange: ThunkOn<IStoreModel>
}
const stateDefaults = {
  pgDarkMode: false,
  profile: null,
  MSG: null,
  avatarLoad: null,
  logoLoad: null,
  languagesList: null,
  logo: {light_logo: null, dark_logo: null},
  team: null,
  paymentMethod: null,
  invoice: null,
  countryLanguage: null,
  teamBuffer: null,
  commonMessages: [],
  fieldMessages: {},
  zoom: false,
  cloudpaymentsAsc: null,
};

export const mainModel: IMainModel = {
  //state
  
  pgDarkMode: stateDefaults.pgDarkMode,
  profile: stateDefaults.profile,
  languagesList: stateDefaults.languagesList,
  MSG: stateDefaults.MSG,
  avatarLoad: stateDefaults.avatarLoad,
  logoLoad: stateDefaults.logoLoad,
  team: stateDefaults.team,
  paymentMethod: stateDefaults.paymentMethod,
  invoice: stateDefaults.invoice,
  teamBuffer: stateDefaults.teamBuffer,
  commonMessages: stateDefaults.commonMessages,
  fieldMessages: stateDefaults.fieldMessages,
  zoom: stateDefaults.zoom,
  cloudpaymentsAsc: stateDefaults.cloudpaymentsAsc,
  //computed
  language: computed(state => {
    const profile = state.profile;
    const lang = profile && profile.language && profile.language.unique;
    let local_lang = null;
    try {
      local_lang = localStorage.getItem(LOCAL_PARAM.LANG);
    } catch (e) {}
    return lang || local_lang || '';
    // return lang || local_lang || LNG._default.unique;
  }),
  plan: computed(state => {
    const profile = state.profile;
    const plan = profile instanceof Object && "plan" in profile ? profile.plan.unique : null;
    return plan;
  }),
  fieldErrors: computed(state => {
    const result: IFieldErrors = {};
    const fieldMessages = state.fieldMessages;
    Object.keys(fieldMessages).forEach(field => {
      result[field] = fieldMessages[field].map((error) => error.message);
    });
    return result;
  }),
  apiMessages: computed( state => {
    const result: IFieldMessages = {};
    const commonMessages = state.commonMessages;
    const fieldMessages = state.fieldMessages;
    const append = (e) => {
      const current = result[e.apiName || FIELD.COMMON] || [];
      result[e.apiName || FIELD.COMMON] = current.concat(e);
    };
    commonMessages.forEach(append);
    Object.keys(fieldMessages).forEach((field) => { fieldMessages[field].forEach(append); });
    return result;
  }),
  apiMessageTree: computed(state => {
    const apiMessages = {...state.apiMessages};
    const result = {};
    Object.keys(apiMessages).forEach((apiName) => {
      const errs = apiMessages[apiName];
      const res: IFieldMessages = {};
      errs.forEach((e) => {
        const current = res[e.field || FIELD.COMMON] || [];
        res[e.field] = current.concat(e);
      });
      result[apiName] = res as IFieldMessages;
    });
    return result;
  }),
  logo: computed(state => {
    return state.team ? state.team.logo : stateDefaults.logo;
  }),
  
  darkMode: computed(state => {
    const path = state.router.path;
    const isDarkPath = path.lastIndexOf("/")===0 && path.length>1 && !Object.values(ROUTE).includes(path);
    const isPlayground = ROUTE.PLAYGROUND === path;
    return isPlayground ? state.pgDarkMode : isDarkPath;
  }),
  homePath: computed(state => {
    return state.isGuest ? ROUTE.ROOT : ROUTE.HISTORY;
  }),
  cid: computed(state => {
    let local_cid = null;
    try {
      local_cid = localStorage.getItem(LOCAL_PARAM.CID);
    } catch (e) {}
    const cid = extractRouterParams(state.router, URL_PARAM.CID);
    return cid || local_cid;
  }),
  tid: computed(state => {
    let local_tid = null;
    try {
      local_tid = localStorage.getItem(LOCAL_PARAM.JWT);
    } catch (e) {}
    return local_tid;
  }),

  //actions
  reset: action((state) => {
    return {...state, ...stateDefaults, MSG: state.MSG};
  }),
  setZoom: action((state, payload) => {
    return {...state, zoom: payload}
  }),
  update: action((state) => {
    return {...state};
  }),
  setProfile: action((state, profile) => {
    try {
      localStorage.setItem(LOCAL_PARAM.LANG, profile.language.unique);
    } catch (e) {}
    return {...state, profile, team: profile.team, teamBuffer: profile.team};
  }),
  setCloudpaymentsAsc: action((state, cloudpaymentsAsc) => {
    const root = state.router.root
    const path = state.router.path
    const currentPage = `${(root === 'http://localhost' ? 'http://localhost:3000' : root) + path}`
    return {...state, cloudpaymentsAsc: {...cloudpaymentsAsc, term_url: `${cloudpaymentsAsc.term_url}?continue=${currentPage}?product=${cloudpaymentsAsc.product}`}};
  }),
  clearCloudpaymentsAsc: action((state) => {
    return {...state, cloudpaymentsAsc: stateDefaults.cloudpaymentsAsc};
  }),
  setMSG: action((state, MSG) => {
  return {...state, MSG};
  }),
  setListLanguages: action((state, languagesList) => {
      return {...state, languagesList};
    }),
  setAvatarLoad: action((state, payload) => {
    return {...state, avatarLoad: payload};
  }),
  setLogoLoad: action((state, payload) => {
    return {...state, logoLoad: payload};
  }),
  setLogoClear: action((state) => {
    return {...state, logoLoad: null};
  }),
  setTeam: action((state, team) => {
    return {...state, team};
  }),
  setTeamBuffer: action((state, teamBuffer) => {
    return {...state, teamBuffer};
  }),
  setInvoiceList: action((state, invoiceList) => {
    return {...state, invoice: invoiceList};
  }),
  setPaymentMethod: action((state, paymentMethod) => {
    return {...state, paymentMethod};
  }),
  setMessages: action((state, messages: MessageList) => {
    state.commonMessages = messages.filter(e => !e.field);
    const fieldMessages = messages.filter(e => !!e.field);
    const result = {};
    fieldMessages.forEach((e) => {
      const current = result[e.field] || [];
      result[e.field] = current.concat(e);
    });
    state.fieldMessages = result;
    return {...state};
  }),
  appendMessages: action((state, messages: MessageList) => {
    state.commonMessages = (state.commonMessages || []).concat(messages.filter(e => !e.field));
    const fieldMessages = messages.filter(e => !!e.field);
    const result = state.fieldMessages || {};
    Object.keys(fieldMessages).forEach(field => {
      const current = result[field] || [];
      result[field] = current.concat(fieldMessages[field]);
    });
    return {...state};
  }),
  clearAllMessages: action(state => {
    return {...state, commonMessages: [], fieldMessages: {}};
  }),
  clearCommonMessages: action(state => {
    return {...state, commonMessages: []};
  }),
  pushCommonMessage: action(( state, payload?: MessageList | Tip | ValidationError | string ) => {
    if (payload) state.commonMessages = (state.commonMessages || []).concat(
      (payload===payload+"" || payload instanceof String)
        ? new Tip(null, payload as string)
        : payload as (MessageList | Tip | ValidationError)
    );
    return {...state};
  }),
  clearCommonMessage: action(( state, messageId?: string | string[] | null ) => {
    if (!messageId) {
      state.commonMessages.pop();
    } else {
      state.commonMessages = (state.commonMessages || []).filter(m => ![].concat(messageId).includes(m.id));
    }
    return {...state};
  }),
  clearFieldMessages: action(state => {
    return {...state, fieldMessages: {}};
  }),
  clearMessagesByField: action((state, field?: string|string[]) => {
    if (!field || !field.length) {
      return {...state, fieldMessages: {}}
    };
    const fields = [].concat(field);
    if (fields.length>0) {
      fields.forEach(fld => {
        if (fld in state.fieldMessages) delete state.fieldMessages[fld];
      });
    }
    return {...state};
  }),
  clearMessagesByApiName: action((state, apiName: string|string[]) => {
  //TODO
    return {...state};
  }),
  
  setPgDarkMode: action((state, pgDarkMode) => {
    return {...state, pgDarkMode};
  }),

  //thunks
  resetApp: thunk(async (actions, payload, {getState}) => {
    await actions.screenshots.reset();
    await actions.reset();
  }),
  setTid: thunk(async (actions, token, { getState }) => {
    const state = getState();
    try {
      if (token) {
        await localStorage.setItem(LOCAL_PARAM.JWT, token);
        window.location.reload()
        //await actions.connect(token);
      } else {
        await localStorage.removeItem(LOCAL_PARAM.JWT);
        await localStorage.removeItem(LOCAL_PARAM.LANG);
        await actions.disconnect();
      }
    } catch (e) {
      console.log('e', e)
    }
    return {...state};
  }),
  
  setName: thunk(async (actions, payload, { getState }) => {
    actions.SET_USER_NAME(payload);
  }),
  setPassword: thunk(async (actions, payload, { getState }) => {
    actions.SET_USER_PASSWORD(payload);
  }),
  setEmail: thunk(async (actions, payload, { getState }) => {
    actions.SET_USER_EMAIL(payload);
  }),
  setLanguage: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    const profile = state.profile;
    try {
      localStorage.setItem(LOCAL_PARAM.LANG, payload.unique);
    } catch (e) {}
    if (profile) actions.SET_USER_LANGUAGE(payload);
    actions.update()
  } ),
  // setLanguage: thunk(async (actions, payload, { getState }) => {
  //   actions.SET_USER_LANGUAGE(payload);
  // }),
  dropUser: thunk(async (actions, payload, { getState }) => {
    actions.DROP_USER(payload);
  }),
  inviteUser: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.teamBuffer, invites: [...state.teamBuffer.invites, {target_email: payload.email, user: null}]})
    await actions.INVITE_USER(payload);
  }),
  cancelInvite: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, invites: state.team.invites.filter((user) => user.target_email !== payload.email)})
    await actions.CANCEL_TEAM_INVITATION(payload);
  }),
  expelMember: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, members: state.team.members.filter((user) => user.username !== payload.username)})
    await actions.EXPEL_TEAM_MEMBER(payload);
  }),
  leaveTeam: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, members: state.team.members.filter((user) => user.username !== payload.username)})
    await actions.LEAVE_TEAM();
  }),
  uploadLogo: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    
    await actions.UPLOAD_TEAM_AVATAR(payload);
  }),
  setTeamIp: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, ip_limit: {from: payload.ip_from || state.team.ip_limit.from, to: payload.ip_to || state.team.ip_limit.to, enable: payload.enabled}})
    await actions.SET_TEAM_IP_FILTER(payload);
  }),
  promoteMember: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, members: state.team.members.map((user) => {
         if (user.username === payload.username) {
           return {...user, team_admin: true}
         } else return user
      })})
    await actions.PROMOTE_TEAM_MEMBER(payload);
  }),
  demoteMember: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, members: state.team.members.map((user) => {
        if (user.username === payload.username) {
          return {...user, team_admin: false}
        } else return user
      })})
    await actions.DEMOTE_TEAM_MEMBER(payload);
  }),
  extendTeam: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, size: state.team.size + payload.extension_size})
    await actions.EXTEND_TEAM(payload);
  }),
  changeRole: thunk(async (actions, payload, { getState }) => {
    await actions.TEAM_ROLE_CHANGE(payload);
  }),
  domainChange: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    await actions.setTeamBuffer({...state.team, domain: payload.domain})
    await actions.DOMAIN_CHANGE(payload);
  }),
  requestTeamSize: thunk(async (actions, payload, { getState }) => {
    await actions.REQUEST_TEAM_SIZE(payload);
  }),
  acceptInvitation: thunk(async (actions, payload, { getState }) => {
    await actions.ACCEPT_INVITATION(payload);
  }),
  dropHistoryFiles: thunk(async (actions, payload, { getState }) => {
    actions.DROP_HISTORY(payload);
  }),
  dropAvatar: thunk(async (actions) => {
    actions.DROP_AVATAR();
  }),
  uploadAvatar: thunk(async (actions) => {
    actions.UPLOAD_AVATAR();
  }),
  getProfile: thunk(async (actions, payload, { getState }) => {
    actions.GET_TAGS();
    await actions.GET_USER_INFO();
  }),
  getLanguages: thunk(async (actions, payload, { getState }) => {
    actions.GET_LANGUAGES();
  }),
  getTeam: thunk(async (actions, payload, { getState }) => {
    await actions.GET_TEAM_INFO();
  }),
  consumeUrlParams: thunk(async (actions, payload={}, {getState}) => {
    const state = getState();
    const router = state.router;
    const loc = router.location;
    const replace = actions.router.replace;
    const to = payload && payload.to || null;
    const params = payload && payload.params || [];
    const leave = payload && payload.leave || [];
    const clearOnly = payload && payload.clearOnly;
    const clearHash = payload && payload.clearHash;
    const urlParams = extractRouterParams(router);
    const urlParamNames = Object.keys(urlParams);
    const cid = urlParams[URL_PARAM.CID];
    if (cid && !clearOnly && ( params.length===0 || params.includes(URL_PARAM.CID) )) {
      try { localStorage.setItem(LOCAL_PARAM.CID, cid); } catch (e) {}
    }
    const paramsToRemove = (params.length===0 ? urlParamNames : params).filter(p => !leave.includes(p));
    const search = concatSearchParams(urlParams, paramsToRemove);
    const hash = clearHash ? "" : loc.hash;
    const pathname = to ? { pathname: to } : {};
    await replace({to: {...loc, ...pathname, hash, search} });
    //return {...state};
  }),
  switchToHome: thunk(async (actions, consumeParams: boolean = false, { getState }) => {
    const replace = actions.router.replace;
    const removeUrlParams = actions.consumeUrlParams;
    actions.getProfile()
    await (consumeParams
      ? removeUrlParams({clearHash: true, params: []})
      : null
    );
    replace({to: getState().homePath});
  }),
  switchToProfile: thunk(async (actions, consumeParams: boolean = false, { getState }) => {
    const replace = actions.router.replace;
    const removeUrlParams = actions.consumeUrlParams;
    actions.getProfile()
    await (consumeParams
        ? removeUrlParams({clearHash: true, params: []})
        : null
    );
    replace({to: ROUTE.PROFILE});
  }),
  switchToTeam: thunk(async (actions, consumeParams: boolean = false, { getState }) => {
    const replace = actions.router.replace;
    const removeUrlParams = actions.consumeUrlParams;
    actions.getProfile()
    await (consumeParams
        ? removeUrlParams({clearHash: true, params: []})
        : null
    );
    replace({to: ROUTE.TEAM});
  }),
  switchToSignin: thunk(async (actions, consumeParams: boolean = false, { getState }) => {
    const replace = actions.router.replace;
    const removeUrlParams = actions.consumeUrlParams;
    actions.getProfile()
    await (consumeParams
        ? removeUrlParams({clearHash: true, params: []})
        : null
    );
    replace({to: ROUTE.AUTH});
  }),
  //listeners
  onRouteChange: thunkOn(
    (actions) => [actions.router.push, actions.router.replace],
    async (actions, target) => {
      await actions.clearFieldMessages();
    }
  ),
  // onConsume: actionOn(
  //   (actions) => [
  //     actions.consumeCid,
  //     actions.consumeTokenAndEmail,
  //     actions.consumeParams,
  //   ],
  //   (state, target) => {
  //     const [consumeCid] = target.resolvedTargets;
  //   }
  // ),

};
