import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  Buttons,
  EButtonKind,
  EButtonsAlign,
  EPanelCaptionKind, EPanelWidth,
  ETextAlign,
  ETextColor,
  ETextSize,
  If,
  Interval,
  Line,
  Panel,
  PanelFooter,
  SpaceBox,
  TextBox
} from 'components';
import {declension, getDateJSAsKebab, humanFileSize, ifCond, throttle} from 'utils';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IClearHistory extends TThemed {
  from?: Date;
  objects?: number;
  space?: number;
  ask?: boolean;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

let timer = 0

export const ClearHistory = React.forwardRef((props: IClearHistory, ref) => {
  const { onClose, from:_from, onSubmit, objects=0, space=0, ask:_ask=false, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
  const MSG = useStoreState(s=>s.MSG);
  const info = useStoreState(s=>s.screenshots.info);
  const onGetHistory = useStoreActions(a=>a.screenshots.getHistoryPeriod);
  const bp = useMediaBreakpoints();
  // const from = _from instanceof Date ? _from.getTime() : 0;
  // console.log(onGetHistory())
  const now = Date.now()
  const startDate = new Date(0).getTime()
  const from = new Date(info?.start_date).getTime();
  const to = ((new Date(info?.end_date).getDay() - new Date(info?.start_date).getDay() >= 3)  ? from + Math.round((new Date(info?.end_date).getTime() - from)/3) : new Date(info?.end_date).getTime());
  // const to = new Date(info.end_date).getTime();
  const [inter, setInter] = React.useState([from, to]);
  const [ask, setAsk] = React.useState(_ask);
  const handleClose = React.useCallback((e) => {
    if (ask) {
      setAsk(false);
    } else {
      if (onClose instanceof Function) onClose(e);
    }
  }, [onClose, ask, setAsk]);
  const handleChange = React.useCallback((vals) => throttle(() => {
    
    if (vals && (inter[0]!==vals[0] || inter[1]!==vals[1])) {
      setInter(vals)
    };
    //TODO: command CALC_STORAGE_BY_DATE
  }, 100)(),[inter, setInter]);
  
  React.useEffect(() => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      onGetHistory({start_date: getDateJSAsKebab(new Date(inter[0])), end_date: getDateJSAsKebab(new Date(inter[1]))})
    }, 200);
  }, [inter]);
  
  const handleDelete = React.useCallback((e) => {
    if (!ask) {
      setAsk(true);
    } else {
      if (onSubmit instanceof Function) onSubmit(e, {
        list: [],
        period: {
          "start_date": getDateJSAsKebab(new Date(inter[0])),
          "end_date": getDateJSAsKebab(new Date(inter[1]))
        }
      });
    }
  }, [ask, setAsk]);

  useKeydown(handleDelete, handleClose, {inter, ask});

  if ( !ifCond(_if) ) return null;
  return (
    <TWClearHistory>
      <Panel align="center" onClose={handleClose} width={bp.mobile ? EPanelWidth.MOB : EPanelWidth.SM} height={ask ? 221 : 495} caption={MSG.HISTORY.CLEAR} captionKind={EPanelCaptionKind.SM} ref={ref} darkMode={darkMode} {...rest}>
        <If if={!ask} else={
          <>
            <SpaceBox height={10} />
            <TextBox text={MSG.HISTORY.CONFIRM_DELETE} />
            <TextBox text={MSG.HISTORY.UNRESTORABLE} />
            <SpaceBox height={20} />
          </>
        }>
          <If if={info?.start_date !== info?.end_date} else={
            <SpaceBox height={80} />
          }>
            <SpaceBox height={30} />
            <div tw="flex">
              <TextBox text={getDateJSAsKebab(new Date(inter[0]))} color={ETextColor.SECONDARY}/>
              <SpaceBox width="stretch" inline={true}/>
              {/*<TextBox text={MSG.TODAY} color={ETextColor.SECONDARY}/>*/}
            </div>
            <SpaceBox height={10} />
            <Interval from={inter[0]} to={inter[1]} onChange={handleChange} min={new Date(info?.start_date).getTime() || startDate} max={new Date(info?.end_date).getTime() || now} step={86400000}/>
            <SpaceBox height={10} />
            <TextBox text={getDateJSAsKebab(new Date(inter[1]))} color={ETextColor.SECONDARY}/>
            <SpaceBox height={30} />
          </If>
          <TextBox text={info?.total || objects} size={ETextSize.XXLARGER} align={ETextAlign.CENTER} block={true} fillX={true} />
          <TextBox text={declension(info?.total || objects, MSG.HISTORY.TO_BE_REMOVED)} color={ETextColor.SECONDARY} align={ETextAlign.CENTER} block={true} fillX={true}/>
          <SpaceBox height={30} />
          <TextBox text={humanFileSize(info.size) || humanFileSize(space)} size={ETextSize.XXLARGER} align={ETextAlign.CENTER} block={true} fillX={true} />
          {/*<TextBox text={info.total ? humanFileSize(info.total) : '0'} size={ETextSize.XXLARGER} align={ETextAlign.CENTER} block={true} fillX={true} />*/}
          {/*<TextBox text={MSG.HISTORY.TO_BE_REMOVED} color={ETextColor.SECONDARY} align={ETextAlign.CENTER} block={true} fillX={true}/>*/}
          {/*<SpaceBox height={30} />*/}
          {/*<TextBox text={info.size ? humanFileSize(info.size) : '0'} size={ETextSize.XXLARGER} align={ETextAlign.CENTER} block={true} fillX={true} />*/}
          <TextBox text={MSG.HISTORY.TO_BE_FREED} color={ETextColor.SECONDARY} align={ETextAlign.CENTER} block={true} fillX={true}/>
          <SpaceBox height={30} />
        </If>

        <PanelFooter>
          <Line />
          <SpaceBox height={20} />
          <Buttons align={EButtonsAlign.RIGHT} gap={10}>
            <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_SECONDARY} onClick={handleClose}/>
            <Button caption={MSG.ACTIONS.DELETE} kind={EButtonKind.CRITICAL} onClick={handleDelete}/>
          </Buttons>
        </PanelFooter>
      </Panel>
    </TWClearHistory>
  );
});

const TWClearHistory = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
