import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {Align, EAlign, EIconSize, EJustify, EProgressKind, ETextColor, Icon, If, Progress, TextBox} from 'components';
import {ifCond} from 'utils';

export interface ISnack extends TThemed {
  id?: string;
  kind?: ESnackKind;
  text?: string;
  icon?: string;
  persist?: boolean;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum ESnackKind {
  MOBILE, DESKTOP
}

export const Snack = React.forwardRef((props: ISnack, ref) => {
  const { id, icon, text="", kind=ESnackKind.DESKTOP, persist=false, onClose, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [visible, setVisible] = React.useState(ifCond(_if));
  const [paused, setPaused] = React.useState(false);
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) {
      onClose(e, id);
    } else {
      setVisible(false);
    }
  }, [setVisible, onClose]);
  const handleOnHover = React.useCallback(() => {
    setPaused(true);
  }, [paused, setPaused]);
  const handleOnLeave = React.useCallback(() => {
    setPaused(false);
  }, [paused, setPaused]);
  if ( !visible || !ifCond(_if) ) return null;
  return (
    <TWSnack id={`snack-${id}`} {...{ref, kind, darkMode}}
             onMouseEnter={handleOnHover}
             onMouseLeave={handleOnLeave}
             {...rest}
    >
        <TWSnackInner kind={kind}>
          <If if={!persist && ESnackKind.MOBILE!==kind} else={
            <Align>
              <Icon if={!!icon} icon={icon} size={EIconSize.MD} />
            </Align>
          }>
            <Progress value={100} max={100} min={0} animate={true}
                      from={100} to={0} time={3000} reverse={true} kind={EProgressKind.R}
                      paused={paused} onTimeOut={handleClose} persist={persist}
            >
              <Align absolute={true}>
                <Icon if={!!icon} icon={icon} size={EIconSize.MD} />
              </Align>
            </Progress>
          </If>
          <TextBox text={text} darkMode={darkMode} color={ETextColor.PRIMARY}/>
          <Align bgHover={"colors.accent.100"} onClick={handleClose} rounded={true}>
            <Icon icon="CloseGray" size={12}/>
          </Align>
        </TWSnackInner>
        <Align absolute={true} if={!persist && ESnackKind.MOBILE===kind} height={EProgressKind.SM} bottom={0} align={EAlign.BOTTOM} justify={EJustify.STRETCH}>
          <Progress value={100} max={100} min={0} from={100} to={0} time={3000} reverse={true}
                    kind={EProgressKind.SM} animate={true}
                    paused={paused} onTimeOut={handleClose}
          />
        </Align>
    </TWSnack>
  );
});

const TWSnack = styled("aside")(({ theme, darkMode, kind }) => [
  tw`inline-block relative clear-both mx-10p z-9999`,
  // tw`mobile:h-60p mobile:min-w-screen mobile:rounded-none`,
  kind === ESnackKind.DESKTOP ?
    tw`h-70p w-auto min-w-250p rounded-xl`: //rounded
    tw`h-60p w-375p`, //plain
  kind === ESnackKind.DESKTOP ?
    ( darkMode ? tw`shadow-snack-dark bg-ghost` : tw`shadow-snack-light bg-ghost` ) :
    ( darkMode ? tw`shadow-msnack-dark bg-twilight` : tw`shadow-msnack-light bg-ghost` ),
  kind === ESnackKind.DESKTOP ? (
    css`
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
    `
  ): (
    css`
      -webkit-backdrop-filter: blur(${darkMode ? 5 : 12}px);
      backdrop-filter: blur(${darkMode ? 5 : 12}px);
    `
  )
]);
const TWSnackInner = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`grid items-center content-center align-middle w-full h-full z-9999`,
  kind === ESnackKind.DESKTOP ?
    tw`p-10p`:
    tw`pl-15p pr-25p`,
  css`
    grid-template-columns: ${ESnackKind.DESKTOP === kind ? '50px minmax(120px, 1fr) 50px' : '25px 1fr 10px'};
    grid-column-gap: ${10}px;
    & > i {
      text-align: center;
      justify-self: center;
      height: 100%;
    }
  `
]);
