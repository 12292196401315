import React from "react";
import {useStoreState} from "../store";

export function useKeydownSlug(handlePrev: Function, handleNext: Function, handleESC: Function, {next, prev}: any) {
  const cond = useStoreState(state => state.screenshots.isBlocked);
  React.useEffect(() => {
    const handleEsc = async (event) => {
      if (!cond) {
        if (event.code === "Escape") {
          await handleESC(event);
        }
        if (event.code === "ArrowLeft") {
          if (prev) {
            await handlePrev(event, next);
          }
        }
        if (event.code === 'ArrowRight') {
          if (next) {
            await handleNext(event, prev);
          }
        }
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [handleNext, handlePrev, handleESC, next, prev, cond]);
}