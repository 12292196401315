import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  Buttons,
  EButtonKind,
  EButtonPadding,
  EButtonsAlign,
  EIcon,
  EPanelCaptionKind,
  EPanelWidth,
  ESwatchItemType,
  ESwatchType,
  Panel,
  SpaceBox,
  Swatch
} from 'components';
import {URLS} from 'const';
import {callIfExists, ifCond, isImageMIME, isVideoMIME, str} from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useStoreActions} from "hooks";
import {useKeydownModal} from "../../../hooks/useKeydownModal";

export interface ICardShare extends TThemed {
  url?: any;
  onClick?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const CardShare = React.forwardRef((props: ICardShare, ref) => {
  const { url, test, onClick, onClose, raw=false, darkMode=false, demo=false, if:_if=true, style, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const item = useStoreState((state) => state.screenshots.selectCurrentItem);
  const setIsBlocked = useStoreActions((state) => state.screenshots.setIsBlocked);
  const [copyPreviewCode, copyBbCode] = useCardShareActions();
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    setIsBlocked(false);
    callIfExists(onClose, e);
  }, [onClose]);
  const handleClick = React.useCallback((social) => { callIfExists(onClick, social.replace(/^Soc/,'')); }, [onClick]);
  const haveCurrentItem = item[0]?.currentItem ? item[0]?.currentItem : item[0];
  const isImage = isImageMIME(haveCurrentItem?.mime_type);
  const urls = isImage ? URLS.SOCIAL_IMAGE : URLS.SOCIAL_VIDEO
  const SwatchLinkProps = {
    type: ESwatchType.LINKS,
    iconSize: 40,
    items: Object.keys(urls).map(
      (s,key) => (
        { key,
          type: ESwatchItemType.ICON,
          value: s,
          icon: s,
          ...( url? {
              href: str(urls[s], url)
            } : {
              onClick: handleClick
            }
          ),
          //TODO: replace to MSG
          hint: s.replace(/^Soc/,'')
        }
      )
    )};

  const copyBB = () => {
    copyBbCode(item[0]);
    setIsBlocked(false);
  };

  const copyPreview = () => {
    copyPreviewCode(item[0]);
    setIsBlocked(false);
  }

  useKeydownModal(null, handleClose, null, {});

  if ( !ifCond(_if) ) return null;
  return (
    <TWCardShare ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.ACTION.SHARE_HDR} padding={20} width={bp.mobile ? 345 : EPanelWidth.SM} captionKind={EPanelCaptionKind.SM} icon={EIcon.Share} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={30} />
        <Swatch {...SwatchLinkProps} item={item[0]}/>
        <SpaceBox height={30} />
        <Buttons align={EButtonsAlign.ADJUST}>
          <Button caption={MSG.ACTION.SHARE_COPY_BB_CODE} onClick={copyBB} width={133} kind={EButtonKind.OUTLINED_PRIMARY} padding={15}/>
          <Button caption={MSG.ACTION.SHARE_COPY_PREVIEW_CODE} onClick={copyPreview}  width={165} kind={EButtonKind.OUTLINED_PRIMARY} padding={15}/>
        </Buttons>
      </Panel>
    </TWCardShare>
  );
});

const TWCardShare = styled("div")(() => [ css`label { ${tw`hover:opacity-90`} }` ]);

const useCardShareActions = () => {
  // @ts-ignore
  const copyBbCode = useStoreActions((state) => state.screenshots.copyBBCode);
  // @ts-ignore
  const copyPreview = useStoreActions((state) => state.screenshots.copyPreviewCode);
  return [copyPreview, copyBbCode]
}
