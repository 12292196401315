import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  TextBox,
  SpaceBox,
  Line,
  Billing,
  ProfileChangeName,
  ProfileChangeEmail,
  ProfileChangePwd,
  ProfileChangeLang, ClearHistory, DeleteAccount, HelpTranslate, BillingCancelSub, BillingChangeMethod, Modal
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {MSG, PLAN, PRODUCTS} from 'const';
import {useStoreActions, useStoreState} from "../../../store";

export interface IBillingPage extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const ProfileProps = {
  username: "User name", email: "email@joxi.ru", avatar: "", passwordLength: 8,
  language: {unique: "english", name: "English"}, isLoggedIn: true,
  plan: {unique: "free", name: "Free"},
  min: 0, quota_total: 1024*1024*1024, quota_usage: 856*1024*1024, count_item: 2318,
};




export const BillingPage = React.forwardRef((props: IBillingPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const invoice = useStoreState(s=>s.invoice);
  const paymentMethod = useStoreState(s=>s.paymentMethod);
  const getInvoice = useStoreActions(a=>a.getInvoice);
  const getPaymentMethod = useStoreActions(a=>a.getPaymentMethod);
  
  
  // amount: 400
  // created_at: "2021-08-12T11:21:30.673638Z"
  // currency: "USD"
  // error_reason: null
  // id: 70
  // item: "plan:pro"
  // payed_at: "2021-08-12T11:21:46.541867Z"
  // quantity: 1
  // status: "payed"
  // type: "normal"
  
  
  
  const paymentHistory = invoice?.slice(0, 5).map((item) => {
    
    return {date: new Date(item.created_at), price: {
      amount: item.amount/100,
        currency: item.currency},
      paid: item.status === 'payed',
      plan: {
        name: item.item === PRODUCTS.PRO ? PLAN.PRO.name
          : item.item === PRODUCTS.TEAM ? PLAN.TEAM.name
            : item.item === PRODUCTS.FREE ? PLAN.FREE.name
              : item.item === PRODUCTS.SLOT ? `${item.quantity} team licenses`
                : item.item === PRODUCTS.VERIFICATION ? `Verification` : 'Unknown'}
      }
      
  })
  
  
  const BillingProps = {
    method: paymentMethod ? `•••• •••• •••• ${paymentMethod.card_number}` : null,
    nextBill: new Date(2020, 4, 21),
    history: paymentHistory || []
  };
  
  React.useLayoutEffect(() => {
    getInvoice()
    getPaymentMethod()
  }, []);
  
  
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWBillingPage ref={ref} darkMode={darkMode} {...rest}>
      <Billing profile={ProfileProps} billing={BillingProps}/>
      <TWCopyright>
        {MSG.COPYRIGHT}
      </TWCopyright>
    </TWBillingPage>
  );
});

const TWBillingPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative w-full h-full flex flex-col items-center justify-center`,
  tw`tablet:bg-white tablet:mt-0`,
  tw`mobile:bg-white mobile:mt-0`,
  css`
  min-height: 500px;
`
]);

const TWCopyright = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #5f6c76;
`
]);
