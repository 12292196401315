import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {Auth, ESign, ESocAuthType} from 'components';
import {ifCond, MessageList} from 'utils';
import api from 'api';
import {useStoreActions, useStoreState} from 'hooks';
import {FIELD, ROUTE} from 'const';

export interface IRegisterPage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const RegisterPage = React.forwardRef((props: IRegisterPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [mode, setMode] = React.useState(ESign.UP);
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const push = useStoreActions(actions => actions.router.push);
  const doRegister = useStoreActions(actions => actions.register);
  const clearMessagesByField = useStoreActions(actions => actions.clearMessagesByField);
  const clearFieldMessages = useStoreActions(actions => actions.clearFieldMessages);

  const handleSubmit = React.useCallback(async (e, values) => {
    clearFieldMessages();
    switch (mode) {
      case ESign.UP:
        const registerRes = await doRegister([values[FIELD.EMAIL], values[FIELD.PWD]]);
        if (registerRes !== false) {
          setMode(ESign.UP_SENT);
        }
        break;
      case ESign.UP_SENT:
        push({to: ROUTE.AUTH});
        break;
    }
  }, [mode, setMode, push, doRegister]);
  const handleSignUpClick = (e) => { push({to: ROUTE.REG}); };
  const handleSignInClick = (e) => { push({to: ROUTE.AUTH}); };
  const handleSocAuthClick = (e, soc: ESocAuthType) => { };
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWRegisterPage ref={ref} darkMode={darkMode} {...rest}>
      <Auth mode={mode} errors={fieldErrors} onClearError={clearMessagesByField}
            onSubmit={handleSubmit}
            onSignUpClick={handleSignUpClick}
            onSignInClick={handleSignInClick}
            onSocAuthClick={handleSocAuthClick}
      />
    </TWRegisterPage>
  );
});

const TWRegisterPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative h-full w-full flex flex-col items-center justify-center`,
  tw`mobile:bg-white mobile:h-full mobile:flex mobile:flex-col mobile:justify-center mobile:mt-0`,
  css`min-height: 600px`
]);
