import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  Button,
  Buttons, EButtonKind,
  EButtonsAlign,
  EPanelWidth,
  ETextAlign,
  Line, Modal,
  Panel,
  PanelFooter,
  Profile,
  SpaceBox,
  TextBox
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {ROUTE} from 'const';
import {useStoreActions, useStoreState} from "hooks";

export interface IProfilePage extends TThemed {
  
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}



export const ProfilePage = React.forwardRef((props: IProfilePage, ref) => {
  
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
  const profile = useStoreState(state => state.profile)
  const languagesList = useStoreState(state => state.languagesList)
  const MSG = useStoreState(s=>s.MSG);
  // const path = useStoreState(state => state.router.path);
  // const push = useStoreActions(a=>a.router.push);
  // const setName = useStoreActions(a=>a.setName);
  // const setEmail = useStoreActions(a=>a.setEmail);
  // const setPassword = useStoreActions(a=>a.setPassword);
  // const dropHistoryFiles = useStoreActions(a=>a.dropHistoryFiles);
  const getHistory = useStoreActions(a=>a.screenshots.getHistory);
  const getLanguages = useStoreActions(a=>a.getLanguages);
  const getProfile = useStoreActions(a=>a.getProfile);
  // const doLogout = useStoreActions(a=>a.logout);
  // const dropAvatar = useStoreActions(a=>a.dropAvatar);
  // const uploadAvatar = useStoreActions(a=>a.uploadAvatar);
  // const getHistoryPeriod = useStoreActions(a=>a.screenshots.getHistoryPeriod);
  const info = useStoreState(state=>state.screenshots.info);
  //
  const [path] = useProfileState()
  const {push, setName, setEmail, setPassword, setLanguage, dropHistoryFiles, dropUser, doLogout, dropAvatar, uploadAvatar, getHistoryPeriod} = useProfileActions()
  //
  React.useLayoutEffect(() => {
    getLanguages()
    // if (path===ROUTE.PROFILE) {
    //   // @ts-ignore
    //   getHistory(false)
    // }
  }, []);
  //
  // console.log(useProfileActions())
  
  const ProfileProps = {
    username: profile?.username || '',
    email: profile?.email || '',
    avatar: profile?.avatar || '',
    passwordLength: 8,
    language: profile?.language || {unique: "", name: ""},
    plan: profile?.plan || {unique: "", name: "", quota_total: 0, max_upload_size: 0},
    min: 0,
    quota_used: profile?.quota_used || '',
    count_item: info?.total || 0,
  };
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWProfilePage ref={ref} darkMode={darkMode} {...rest}>
      <Profile profile={ProfileProps}
               languagesList={languagesList}
               path={path}
               push={push}
               setName={setName}
               setEmail={setEmail}
               setPassword={setPassword}
               dropHistoryFiles={dropHistoryFiles}
               dropUser={dropUser}
               doLogout={doLogout}
               dropAvatar={dropAvatar}
               uploadAvatar={uploadAvatar}
               getHistory={getHistoryPeriod}
               setLanguage={setLanguage}
               info={info}
      />
      <TWCopyright>
        {MSG.COPYRIGHT}
      </TWCopyright>
    </TWProfilePage>
  );
});

const TWProfilePage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative w-full flex flex-col items-center justify-center mt-20p`,
  tw`tablet:bg-white tablet:mt-0`,
  tw`mobile:bg-white mobile:mt-0`,
  css`
    min-height: calc(100vh - 90px);
`
]);

const TWCopyright = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  margin: 20px 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #5f6c76;
`
]);

const useProfileState = () => {
  const info = useStoreState(state=>state.screenshots.info);
  const profile = useStoreState(state => state.profile)
  const path = useStoreState(state => state.router.path);
  return [path];
};

const useProfileActions = () => {
  const push = useStoreActions(a=>a.router.push);
  const setName = useStoreActions(a=>a.setName);
  const setEmail = useStoreActions(a=>a.setEmail);
  const setPassword = useStoreActions(a=>a.setPassword);
  const setLanguage = useStoreActions(a=>a.setLanguage);
  const getLanguages = useStoreActions(a=>a.getLanguages);
  const dropHistoryFiles = useStoreActions(a=>a.dropHistoryFiles);
  const dropUser = useStoreActions(a=>a.dropUser);
  const doLogout = useStoreActions(a=>a.logout);
  const dropAvatar = useStoreActions(a=>a.dropAvatar);
  const uploadAvatar = useStoreActions(a=>a.uploadAvatar);
  const getHistoryPeriod = useStoreActions(a=>a.screenshots.getHistoryPeriod);
  const getHistory = useStoreActions(actions => actions.screenshots.getHistory);
  return {
    push,
    setName,
    setEmail,
    setPassword,
    setLanguage,
    getLanguages,
    dropHistoryFiles,
    dropUser,
    doLogout,
    dropAvatar,
    uploadAvatar,
    getHistoryPeriod,
    getHistory
  };
};
