import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  EIcon,
  EIconSize,
  EPanelCaptionKind,
  Icon,
  LinkButton,
  Panel,
  SpaceBox,
  TextBox
} from 'components';
import {URLS} from 'const';
import { ifCond } from 'utils';
import {useStoreState} from "../../../store";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IHelpTranslate extends TThemed {
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const HelpTranslate = React.forwardRef((props: IHelpTranslate, ref) => {
  const { onClose, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);

  useKeydown(null, handleClose);

  if ( !ifCond(_if) ) return null;
  return (
    <TWHelpTranslate ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.INFO.HELP_TRANS_HDR} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={10} />
        <TextBox text={MSG.INFO.HELP_TRANS_WARN} inline={false} block={true}/>
        <SpaceBox height={20} />
        <div tw="grid grid-flow-col grid-cols-aa1">
          <Icon icon={EIcon.DownloadGray} size={EIconSize.MD} />
          <SpaceBox width={10} />
          <LinkButton text={MSG.INFO.HELP_TRANS_LOC_LINK} href={URLS.LOCALIZATION} />
        </div>
      </Panel>
    </TWHelpTranslate>
  );
});

const TWHelpTranslate = styled("div")(() => [tw``, css`` ]);
