import React from 'react';
import tw, {css, styled} from 'twin.macro';
import MaskedInput from 'react-text-mask'
import {cssSize, ifCond} from 'utils';
import {Button, EComboKind, EIcon, ETextColor, ETextSize, IButton, Icon, If, TextBox} from 'components';
import {MSG} from 'const';

export interface IEditBox extends TThemed {
  caption?: string | number | null;
  value?: string | number | null;
  currentValue?: string | number | null;
  showPwdBtn?: boolean;
  forgotPwdBtn?: boolean;
  onForgotPwdClick?: Function;
  button?: IButton;
  errors?: string[] | null;
  kind?: EEditKind;
  input?: EEditInput;
  mask?: Array<RegExp | string>;
  fill?: boolean;
  placeholderChar?: string
  inline?: boolean;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum EEditKind {
  SM, LG
}
export enum EEditInput {
  TEXT, SEARCH, PWD, EMAIL, NUMBER, URL,
}
export const CEditInput = "TEXT, SEARCH, PASSWORD, EMAIL, NUMBER, URL".split(", ")
  .map(t => t.toLowerCase());

export const TextareaBox = React.forwardRef((props: IEditBox, _ref) => {
  const { caption, value, currentValue=value, mask, kind=EEditKind.LG, input=EEditInput.TEXT, inline=true, fill=false, width, button,
    showPwdBtn, placeholderChar, forgotPwdBtn, onForgotPwdClick, errors=[], onChange, onBlur, onPaste, darkMode=false, demo=false, if:_if=true,
    ...rest
  } = props;
  const hasButton = !!(button && ifCond(button.if));
  const [type, setType] = React.useState(CEditInput[input]);
  const [text, setText] = React.useState(currentValue);
  const ref = React.useRef(_ref);
  const handleBlur = React.useCallback((e) => {
    if (text !== e.target.value) setText(e.target.value);
    if (onBlur instanceof Function) onBlur(e);
  }, [onBlur, setText, text]);
  const handlePaste = React.useCallback((e) => {
    if (text !== e.target.value) setText(e.target.value);
    if (onPaste instanceof Function) onPaste(e);
  }, [onPaste, setText, text]);
  const handleForgotPwdClick = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (onForgotPwdClick instanceof Function) onForgotPwdClick(e);
  }, [onForgotPwdClick]);
  const handleButtonClick = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (button && button.onClick instanceof Function) button.onClick(e);
  }, [button]);
  const handleEyeClick = React.useCallback((e, show) => {
    e.stopPropagation();
    e.preventDefault();
    setType( CEditInput[ show ? EEditInput.TEXT : EEditInput.PWD ] );
  }, [setType]);
  React.useLayoutEffect(() => {
    // if (text !==currentValue) setText(currentValue);
  }, [currentValue, text, setText]);
  if ( !ifCond(_if) ) return null;
  return (
    <>
      <TWPlaceholder {...{kind,text,caption,errors,inline, fillX: fill, width, hasValue: !!text, hasButton}}>
        <If if={!!mask && mask.length>0} else={
          <TWEditBox ref={ref}
                     {...{
                       type, kind, caption, errors,
                       darkMode, hasValue: !!text,
                       hasButton, showPwdBtn, forgotPwdBtn
                     }}
                     onPaste={handlePaste}
                     onBlur={handleBlur}
                     onChange={onChange}
                     defaultValue={text}
                     placeholder={ ( kind===EEditKind.SM && caption ) || undefined }
                     value={value}
                     autoComplete="new-password"
                     {...rest}
          />
        }>
          <MaskedInput
            mask={mask}
            guide={!!mask}
            keepCharPositions={false}
            placeholderChar = {placeholderChar}
            onPaste={handlePaste}
            onBlur={handleBlur}
            onChange={onChange}
            defaultValue={text}
            render={(inputRef, inputProps) => (
              <TWEditBox ref={inputRef}
                {...{
                  type, kind, caption, errors,
                  darkMode, hasValue: !!text,
                  hasButton, showPwdBtn, forgotPwdBtn
                }}
                placeholder={ ( kind===EEditKind.SM && caption ) || undefined }
                autoComplete="new-password"
                {...inputProps}
                {...rest}
              />
            )}
          />
        </If>
        <If if={!!showPwdBtn}>
          <TWShowPassword hasButton={hasButton} forgotPwdBtn={forgotPwdBtn} kind={kind}>
            <Icon if={type===CEditInput[EEditInput.PWD]} size={30} icon={EIcon.EyeClosedGray} className="button-pwd-show" onClick={(e) => handleEyeClick(e, true)}/>
            <Icon if={type===CEditInput[EEditInput.TEXT]} size={30} icon={EIcon.EyeGray} className="button-pwd-hide" onClick={(e) => handleEyeClick(e, false)}/>
          </TWShowPassword>
        </If>

        <If if={!!forgotPwdBtn}>
          <TWForgotPassword hasButton={hasButton} showPwdBtn={showPwdBtn} kind={kind} onClick={handleForgotPwdClick}>
            <TextBox text={MSG.PROFILE.FORGOT_PWD} color={ETextColor.SECONDARY} colorHover={ETextColor.BLUE}
                     underline={true} underlineHover={false} size={ETextSize.SMALL}
            />
          </TWForgotPassword>
        </If>

        <If if={hasButton}>
          <TWButton kind={kind}>
            <Button {...(button || {})} onClick={handleButtonClick} />
          </TWButton>
        </If>
      </TWPlaceholder>

    </>
  );
});
const TWPlaceholder = styled("label")(({ theme, darkMode, disabled, kind, fillX, inline, width, caption, hasValue, errors, hasButton, showPwdBtn, forgotPwdBtn }) => [
  tw`relative outline-none cursor-text`,
  inline ? tw`inline-block` : tw`block`,
  fillX && tw`w-full`,
  width && css`${cssSize(width, "width")}`,
  kind === EComboKind.LG && errors.length>0 && tw`-mb-10p`,
  //before
  kind === EEditKind.LG ? tw`before:absolute after:inline-block` : tw`before:hidden after:hidden`,
  tw`before:absolute before:overflow-hidden before:text-light-secondary before:text-normal before:z-20 before:cursor-text`,
  css`
    &:before {
      transition: top var(--d-o, .3s), font-size var(--d-o, .3s), height var(--d-o, .3s);
      content: "${caption}";
      text-overflow: ellipsis;
    }
  `,
  tw`before:left-15p before:top-16p before:h-18p before:text-normal`,//no text
  tw`focus-within:before:top-8p focus-within:before:h-17p focus-within:before:text-small`,//focus
  hasValue && tw`before:top-8p before:h-17p before:text-small`, // text
  //after
  errors.length>0 && tw`after:relative after:bg-error after:text-left after:text-white after:font-sans after:font-semibold after:text-xsmall
    after:rounded-xs after:rounded-t-none after:px-15p after:pt-10p after:pb-px after:-top-10p after:w-full after:z-0`,
  errors.length>0 && css`
    &:after {
      content: "${errors.join('\n')}";
    }
  `
]);
const TWEditBox = styled("textarea")(({ theme, darkMode, disabled, kind, caption, hasValue, height, errors, hasButton, showPwdBtn, forgotPwdBtn }) => [
    tw`relative outline-none border border-solid w-full placeholder-light-secondary z-10`,
    tw`font-sans text-light-primary text-normal pt-0`,
    hasValue ? tw`bg-accent-101 border-accent-101` : tw`bg-accent-100 border-accent-100`,
    errors.length>0 && tw`border-error`,
    errors.length>0 && (kind === EEditKind.SM && tw`text-error`),
    ...(kind === EEditKind.SM ?
    [
      tw`h-40p rounded-lg pl-20p`, // Normal @ kind SM
      hasButton ? (showPwdBtn ? (forgotPwdBtn ? tw`pr-250p` : tw`pr-150p`) : tw`pr-90p`) : tw`pr-20p`,
    ] : [
      tw`h-50p rounded-xs px-15p`, //Normal @ kind LG
      hasValue && tw`pt-25p`,
      showPwdBtn ? (forgotPwdBtn ? tw`pr-180p` : tw`pr-80p`) : tw`pr-15p`,
    ]),
    //:hover
    errors.length<1 && tw`hover:bg-accent-101 hover:border-accent-101`,
    errors.length>0 && tw`hover:border-error`,
    //:focus
    tw`focus:bg-white focus:border-button-go`,
    kind === EEditKind.LG && tw`focus:pt-25p`,
    errors.length>0 && tw`bg-white focus:border-error`,
    //:after errors
    ...([]),
    css`
      /*--focus: 2px rgba(0, 94, 197, .3);*/
      word-wrap: break-word;
      white-space: pre-wrap;
      height: 200px;
      resize: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
    `,
    kind === EEditKind.SM && css`
    
    `
  ]
);
const TWButton = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`inline-block absolute right-0 z-10`,
  css``
]);
const TWForgotPassword = styled("div")(({ theme, darkMode, disabled, kind, hasButton, showPwdBtn }) => [
  tw`inline-grid absolute items-center content-center right-0 z-20 pl-10p pr-20p text-light-secondary cursor-pointer`,
  kind===EEditKind.SM ? tw`h-40p` : tw`h-50p`,
  css``
]);
const TWShowPassword = styled("div")(({ theme, darkMode, disabled, kind, hasButton, forgotPwdBtn }) => [
  tw`inline-grid absolute items-center right-0 z-20 pl-10p pr-20p h-full`,
  kind===EEditKind.SM ? tw`h-40p` : tw`h-50p`,
  hasButton && tw`right-90p`,
  css`
  
  `
]);
