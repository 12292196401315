import React from "react";
export function useCheckBox (initial, handler, id=null, onlyTrustedOnChange=false) {
  const [marked, setMarked] = React.useState(initial);
  const handleChecked = React.useCallback((e) => {
    if ((!onlyTrustedOnChange || e.isTrusted) && handler instanceof Function) {
      const allowDefault = handler(e, id, e.target.checked);
      if (allowDefault === false) {
        e.preventDefault();
        return false;
      }
    }
    if (e.target.checked !== marked) setMarked(e.target.checked);
  }, [marked, setMarked, handler, id, onlyTrustedOnChange]);
  React.useEffect(() => {
    setMarked(initial);
  },[initial, setMarked]);
  return [marked, setMarked, handleChecked];
}