import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {keyframes} from 'styled-components';
import {Timer, ifCond, percentage} from 'utils';
import {If} from 'components';

export interface IProgress extends TThemed {
  value: number;
  max?: number;
  min?: number;
  //color?: EProgressColor;
  kind?: EProgressKind;
  from?: number;//for plain only
  to?: number;//for plain only
  reverse?: boolean;//true for rounded only
  time?: number;
  animate?: boolean;
  paused?: boolean;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum EProgressColor { light = "sky", dark = "cornflower" }
export enum EProgressKind { SM = 1, MD, LG = 5, R }

export const Progress = React.forwardRef((props: IProgress, ref) => {
  const { children, value, max=100, min=0, from=0, to=value, reverse, kind=EProgressKind.LG, time=0, paused, animate=false,
    darkMode=false, demo=false, if:_if=true, onTimeOut, ...rest } = props;
  let percent = percentage(value, min, max);
  const fromPercent = percentage(from, min, max);
  const toPercent = percentage(to, min, max);
  let timer = React.useRef(null);
  const plain = keyframes`
    0% { width: ${!animate ? percent : fromPercent}%; }
    100% { width: ${!animate ? percent : toPercent}%; }
  `;
  const rotor = keyframes`
    0% { transform: rotate(${reverse ? 180 : 0}deg); }
    100% { transform: rotate(${reverse ? -180 : 360}deg); }
  `;
  //TODO check & replace content of rotatingBow with commented lines
  //transform: rotate(${(1.8 - 0.45)*(from/perc1)}deg);
  //transform: rotate(${(1.8 - 0.45)*(to/perc1)}deg);
  const rotatingBow = keyframes`
    0% {transform: rotate(${reverse ? 135 : -45}deg);}
    100% {transform: rotate(${reverse ? -45 : 135}deg);}
  `;
  const staticBow = keyframes`
    0% {opacity: ${+(!!reverse)};}
    24.9% {opacity: ${+(!!reverse)};}
    25% {opacity: ${+(!!reverse)};}
    49.9% {opacity: ${+(!!reverse)};}
    50% {opacity:${+(!reverse)};}
    74.9% {opacity: ${+(!reverse)};}
    75% {opacity: ${+(!reverse)};}
    100% {opacity: ${+(!reverse)};}
  `;

  /*const handleOnHover = React.useCallback(() => {
    timer.current && timer.current.remaining && timer.current.pause();
  }, [timer]);
  const handleOnLeave = React.useCallback(() => {
    timer.current && timer.current.resume();
  }, [timer]);*/
  React.useEffect(() => {
    if (!animate) return;
    if (paused) {
      timer.current && timer.current.remaining && timer.current.pause();
    } else {
      timer.current && /*timer.remaining &&*/ timer.current.resume();
    }
  }, [timer, paused]);

  React.useEffect(() => {
    if (time && animate) {
      timer.current = new Timer(() => {
        if (onTimeOut instanceof Function) onTimeOut()
      }, time);
    }
    return () => {timer.current = undefined;}
  }, [time, onTimeOut, animate]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWProgressContainer>
      <If if={kind===EProgressKind.R} else={
        <TWProgress {...{ref, kind, darkMode, value, max, min, time, percent, animate}}
                    from={animate ? fromPercent : null}
                    to={animate ? toPercent : null} {...rest}
        >
          <TWProgressGauge {...{kind, darkMode, value, max, min, time, percent, animate}}
                           from={animate ? fromPercent : null}
                           to={animate ? toPercent : null} {...rest}
                           keyframes={plain}
                           paused={paused}
                           style={{ animationPlayState: paused ? 'paused' : 'running' }}
          />
          {/*children*/}
        </TWProgress>
      }>
        <TWRProgress {...{ref, kind, darkMode, value, max, min, percent}}
                    time={time+300}
                    from={animate ? fromPercent : null}
                    to={animate ? toPercent : null} {...rest}
        >
          <TWRProgressBorder />
          <TWRProgressRotor keyframes={rotor} time={time+300} paused={paused} animate={animate} style={{ animationPlayState: paused ? 'paused' : 'running' }}>
            <TWRProbressBow keyframes={rotatingBow} time={time+300} paused={paused} isStatic={false} animate={animate} style={{ animationPlayState: paused ? 'paused' : 'running' }}/>
          </TWRProgressRotor>
          {children}
          <TWRProbressBow keyframes={staticBow} time={time+300} paused={paused} isStatic={true} style={{ animationPlayState: paused ? 'paused' : 'running' }}/>

        </TWRProgress>
      </If>
    </TWProgressContainer>
  );
});
const TWProgressContainer = styled("div")(({ theme, darkMode, disabled }) => [
  tw`block relative w-full h-full`,
  css``
]);
const TWProgress = styled("div")(({ theme, darkMode, kind, percent, time, animate, from, to }) => [
  tw`block relative w-full bottom-0`,
  kind===EProgressKind.LG && tw`bg-accent-100 rounded-xs h-5p`,
  kind===EProgressKind.MD && tw`h-2p`,
  kind===EProgressKind.SM && ( darkMode ? tw`h-px` : tw`h-px` ),
  kind===EProgressKind.SM && css`
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  `
]);
const TWProgressGauge = styled("div")(({ theme, darkMode, kind, percent, paused, keyframes, time, animate, from, to }) => [
  tw`inline-block absolute w-full bottom-0`,
  //tw`after:w-full`,
  css`
    animation-play-state: ${paused ? "paused" : "running"};
  `,
  animate ? css`
    animation-play-state: ${paused ? "paused" : "running"};
    animation: ${keyframes} ${time}ms linear 1;
    -webkit-animation: ${keyframes} ${time}ms linear 1;
    width: ${to}%;
    content: '';
  ` : css`
    width: ${percent}%;
    content: '';
  `,
  kind===EProgressKind.LG && tw`rounded-xs h-5p`,
  [EProgressKind.MD, EProgressKind.LG].includes(kind) && tw`bg-sky`,
  kind===EProgressKind.MD && tw`h-2p`,
  kind===EProgressKind.SM && (
    darkMode ? tw`bg-cornflower h-px` : tw`bg-sky h-px`
  ),
]);

const TWRProgress = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative border border-solid border-transparent overflow-hidden rounded-full w-50p h-50p`, css``
]);
const TWRProgressBorder = styled("div")(({ theme, darkMode, disabled }) => [
  tw`absolute border border-solid border-gray-350 overflow-hidden rounded-full w-48p h-48p z-0`, css``
]);
const TWRProgressRotor = styled("div")(({ theme, darkMode, keyframes, time, paused, animate }) => [
  tw`absolute w-half h-full right-0 overflow-hidden origin-left-center z-10`,
  animate && css`
    animation-play-state: ${paused ? "paused" : "running"};
    animation: ${keyframes} ${time+300}ms steps(2) infinite;
    -webkit-animation: ${keyframes} ${time+300}ms steps(2) infinite;
  `
]);
const TWRProbressBow = styled("div")(({ theme, darkMode, keyframes, time, paused, isStatic, animate }) => [
  tw`absolute h-full right-0 border border-solid border-sky rounded-half z-20`,
  css`
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation-play-state: ${paused ? "paused" : "running"};
  `,
  isStatic ? tw`w-full` : tw`w-double`,
  isStatic ? css`
    transform: rotate(135deg);
    animation: ${keyframes} ${time+300}ms steps(2) infinite;
    -webkit-animation: ${keyframes} ${time+300}ms steps(2) infinite;
  ` : css`
    animation: ${keyframes} ${(time/2) + 150}ms linear infinite;
    -webkit-animation: ${keyframes} ${(time/2) + 150}ms linear infinite;
  `
]);





// const TWRProgressBG = styled("div")(({ theme, darkMode, disabled }) => [
//   tw`absolute bg-ghost rounded-half m-px w-48p h-48p`,
//   css`
//   `
// ]);
// const TWRProgressRotator = styled("div")(({ theme, darkMode, disabled, keyframes, time }) => [
//   tw`absolute bg-sky w-110p h-55p -top-55p -left-30p origin-55p-55p transition-all duration-300`,
//   css`
//     animation-name: ${keyframes};
//     animation-duration: ${time}ms;
//     animation-timing-function: linear;
//   `
// ]);
