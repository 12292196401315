const ROOT = "/";

const HISTORY = `${ROOT}history`;
const CLEAR_HISTORY = `${ROOT}history/clear`;
const SLUG = `${ROOT}:slug`;

const PLAYGROUND = `${ROOT}playground`;
const PROFILE = `${ROOT}profile`;

const TEAM = `${ROOT}team`;

const BILLING = `${ROOT}billing`;

const UPGRADE = `${ROOT}upgrade`;
const TO_PRO = `${UPGRADE}/pro`;
const TO_TEAM = `${UPGRADE}/team`;

const FACEBOOK_LOGIN = (url) => `https://api-beta.joxi.net/connect/facebook?continue_uri=https://api-beta.joxi.net/oauth2/auth&client_id=1_win&response_type=code&redirect_uri=${url}/login`;
const FACEBOOK_SUCCESS_LOGIN = (code) => `https://api-beta.joxi.net/oauth2/token?grant_type=authorization_code&code=${code}`

const AUTH = `${ROOT}login`;
const TEAMINVITE = `${ROOT}team-invite`;
const CONFIRM = `${AUTH}`;

const REG = `${ROOT}register`;

const RESET = `${ROOT}reset`;
const PWD = `${RESET}`;

export { ROOT, HISTORY, CLEAR_HISTORY, PROFILE, TEAM, BILLING, SLUG, AUTH, CONFIRM, REG, RESET, PWD, UPGRADE, TO_PRO, TO_TEAM, PLAYGROUND, TEAMINVITE, FACEBOOK_LOGIN, FACEBOOK_SUCCESS_LOGIN };
