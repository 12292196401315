import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ITextBox } from 'components';
import {IAPIResponse, IAxiosResponse} from 'types';
import {CURRENCY, FIELD, LOCAL_PARAM, MIME_CATEGORIES, MSG, PORTAL} from 'const';
import _, { debounce, throttle } from 'lodash';
import { parseISO } from 'date-fns';
import { dayjs } from './dayjs';
import { MessageList, ValidationError } from './classes';

const getToken = () => localStorage.getItem(LOCAL_PARAM.JWT);

function classModel (classMap={}) {
  return Object.keys(classMap)
  .map(cl => {
    const flag = classMap[cl];
    const res = flag instanceof Function ? flag(cl, flag, classMap) : !!flag;
    return res ? cl : "";
  }).filter(f => !!f).join(" ");
}
function injectPropsIntoChildren (children, addedProps: Function | object) {
  return React.Children.map(children, (child: any, index) => {
    if (!isElementWithProps(child)) return child;
    return React.cloneElement(child, addedProps instanceof Function ? addedProps(child, index) : {...addedProps, index});
  });
}
function debounceHandler (handler, time, ...args) {
  const callback = debounce(handler, time);
  return function(e) {
    if (e) e.persist();
    return callback(e, ...args);
  }
}
export function debounced (handler, time, ...args) {
  const callback = debounce(handler, time);
  return function (...argsInitial) {
    return callback(...argsInitial, ...args);
  };
}
function isElementWithProps(something) { return React.isValidElement(something) && something.props; }
function cssSize (s?: string | number, cssprop?: string) {
  const size = s == null ? null : (  +s===s ? s+"px" : s  );
  return size ? (
    cssprop ? `${cssprop}: ${size};` : size
  ) : "";
}
function extractTextProps(t?: string | ITextBox | Array<string | ITextBox>, takeSecond:boolean=false): ITextBox {
  let res: ITextBox;
  if (!t) {
    res = { if: false };
  } else if (t+""===t) {
    res = { text: t };
  } else if (t instanceof Array) {
    let text = "", _t:Array<string | ITextBox> = t.slice();
    if (_t[0]+""===_t[0]) { text = _t.splice(0,1)[0] as string; }
    if (_t[0]+""===_t[0]) { let _text = _t.splice(0,1)[0] as string; text = takeSecond ? _text : text; }
    let obj = {
      ...(_t.length>0 && _t[0] instanceof Object ? _t[0] as object : {}),
      ...(takeSecond && _t.length>1 && _t[0] instanceof Object ? _t[1] as object : {})
    } as ITextBox;
    obj.text = text + (obj.text || "");
    res = obj;
  } else {
    res = {...t as ITextBox};
  }
  return res;
}
function downloadFile(url: string, filename: string) {
    fetch(url, {
      mode: "cors",
    })
      .then(resp => resp.blob())
      .then(blobObject => {
        const blob = window.URL.createObjectURL(blobObject);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
      });


  // const a: HTMLAnchorElement = document.createElement('a');
  // a.href = url;
  //
  // a.target = "_blank";
  // a.download = filename || 'download';
  // const clickHandler = () => {
  //   setTimeout(() => {
  //     a.removeEventListener('click', clickHandler);
  //   }, 150);
  // };
  // a.addEventListener('click', clickHandler, false);
  // a.click();
  return true;
}
function goto(url: string) {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;

  a.target = "_blank";
  const clickHandler = () => {
    setTimeout(() => {
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}
function copyToClipboard(text: string) {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return text;
}

function copyBBCode(text: string, link: string) {
  const el = document.createElement('textarea');
  text = text.replace(text, `[URL=${text}[IMG]${link}[/IMG][/URL]`);
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return text;
}

function copyPreviewCode(text: string, src: string) {
  const el = document.createElement('textarea');
  text = text.replace(text, `<a href="${text}" target="_blank"><img src="${src}"></a>`);
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return text;
}

function encodeSvg (reactElement) {
  return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
}
function ifCond (cond?: boolean | Function): boolean { if (cond instanceof Function) return !!cond(); else return cond !== false; }
function Timer(callback: Function, delay: number, paused: boolean = false) {
  const sTO = window.setTimeout;
  const cTO = window.clearTimeout;
  let timerId, start = delay;
  this.remaining = delay;
  this.pause = function() {
    cTO(timerId);
    this.remaining -= Date.now() - start;
  };
  this.resume = function() {
    start = Date.now();
    cTO(timerId);
    timerId = sTO(callback, this.remaining);
  };
  if (!paused) this.resume();
}
//Usage:
// const timer = new Timer(function() {
//   alert("Done!");
// }, 1000);

function percentage (val:number=0, min:number=0, max:number=100) {
  if (val >= max) {
    return 100;
  }
  return (max!=0) ? val*100/(max-min) : 100;
}
function extractTextColor(col:string, theme) {
  if (col.indexOf("colors.")===0) {
    const props = col.split(".");
    return props.reduce((ac, c) => {
      if (c in ac) return ac[c];
      throw new Error(`There's no such key ${c} in theme`);
    }, theme);
  }
  return col;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
  //Максимум и минимум включаются
}

function humanFileSize(bytes, dp=1) {
  const thresh = 1024;
  const r = 10**dp;
  let u = -1;
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (Math.abs(bytes) < thresh) { return bytes + ' B'; }
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + ' ' + units[u];
}

function formatPrice (price: {amount?: number, currency?: string}, reverse=false) {
  const res = [ price.amount || 0 , CURRENCY[price.currency || "USD"] ];
  if (reverse) res.reverse();
  return res.join("").replace('.', ',');
}

function str (templ, ...vars) {
  let res = vars.reduce(
    (ac, v, i) => ac.replace(new RegExp(`%%${i+1}%%`, "g"), v==null? "" : v),
    templ
  );
  return res;
}

function humanizeDate (d: Date, format=MSG.DATE.BILLING.HISTORY, locale = 'en') {
  return dayjs(d).locale(locale).format(format);
}
function extractRouterParams(router, take?: string | string[], splitWith="=") {
  const location = (router && router.location) || {};
  const search = location.search || "";
  return parseSearchParams(search, take, splitWith);
}
function parseSearchParams(search, take?: string | string[], splitWith="=") {
  const params = search.replace(/^\?/, "")
    .split("&")
    .filter(Boolean)
    .reduce((ac, param) => ({...ac,
      [param.split("=")[0]]: param.split(splitWith)[1]
    }), {});
  return take ? ( take instanceof Array ? take.map(t => params[t]) : params[take] ) : params;
}
function concatSearchParams(params={}, exceptions: string[]=[], concatWith="=") {
  return Object.keys(params).filter(p => !exceptions.includes(p)).reduce(
    (ac, key) => {
      return params[key] ?
        ac.concat("&" + key + concatWith + (params[key] || "")) : ac
    },
    "?"
  ).replace(/^\?$/, "").replace(/^\?&/, "?");
}

// function fieldInvalidError(msg: string, field?: string, apiName?: string) {
//   return Promise.resolve(new ValidationError(msg, field, apiName));
// }

function collectFieldErrors(errorList: ValidationError[] | Array<{id: string, message: string, field?: string}> | {[key: string]: string | string[];}, apiName?: string) {
  // Array of ValidationError or similar object
  if (errorList instanceof Array) {
    return new MessageList( (errorList as (Array<{id: any, message: string, field?: string, apiName?: string}>)).map(
      (err) => new ValidationError(err.id, apiErrorText(err.message, err.field), err.field, err.apiName)
    ));
  } else {
  // { [field]: text, ... }
    return new MessageList( Object.keys(errorList).reduce(
      (ac, field) => {
        const errors = errorList[field];
        const messages = [].concat(errors);
        return [...ac, ...messages.map(msg => new ValidationError(null, msg, field, apiName))];
      }, []
    ));
  }
}


function processApiResponse (apiResponse: Promise<IAPIResponse | Error>, api, forceField?) {
  return apiResponse.then(splitAnswer).catch((rej) => collectApiErrors(rej, api.name, forceField));
}

function processPaymentResponse (apiResponse: Promise<IAPIResponse | Error>, api, forceField?) {
  return apiResponse.then(splitAnswer).catch((rej) => rej);
}

function processAxiosResponse (apiResponse, api, forceField?) {
  return apiResponse.then(splitAnswer).catch((rej) => collectApiErrors(rej, api.name, forceField));
}

function validateStatus(status) { return status < 500 }
function splitAnswer(res) { return res.status > 202 ? Promise.reject(getData(res)) : Promise.resolve(getData(res)); }
function getData(res) { return res.data || {}; }
function collectApiErrors(err, apiName, forceField=null) {
  // >= 500
  if (err instanceof Error) {
    const field = forceField || (err as any).field;
    const errors = [ new ValidationError(null, err.message, field, apiName) ];
    // Return #1
    return Promise.resolve(new MessageList(errors));
  }
  // 201 - 499
  const errors = [];
  const target = err || {};
  // { error: text }
  if (target[FIELD.ERROR]) {
    const msg = target[FIELD.ERROR];
    errors.push(new ValidationError(null, apiErrorText(msg, apiName), forceField, apiName));
  }
  const otherErrors = Object.keys(target).filter(t => t!==FIELD.ERROR);
  // { [field]: text, ... }
  otherErrors.forEach((e) => {
    if (Object.values(FIELD).includes(e)) errors.push(new ValidationError(null, apiErrorText(target[e], apiName), forceField || e, apiName));
    else console.error(e +": "+target[e]);
  });
  // Return #2
  return Promise.resolve(new MessageList(errors));
}
function getFieldErrorsOnly(errors: MessageList = []) {
  return errors.filter(e => e.field && e.field !==FIELD.COMMON );
}
function hasFieldErrors(errors: MessageList = []) {
  return getFieldErrorsOnly(errors).length>0;
}
function apiErrorText(message, group?) {
  
  return ( group ? MSG.ERROR.API[group][message] : MSG.ERROR.API[message] ) || message || "";
}




export const resetDate = (date: Maybe<Date>, noTime: Maybe<boolean> = false, asUTC: Maybe<boolean> = true) => {
  //resets time and/or timezone of Date
  if (date instanceof Date){
    const newDate = new Date(date);
    [
      "FullYear","Month","Date",
      "Hours","Minutes","Seconds","Milliseconds"
    ].forEach(
      (d,i) => newDate[ "set" + ( asUTC ? "UTC" : "" ) + d ]( noTime && i > 2 ? 0 : newDate[ "get"+d ]() )
    );
    return newDate;
  }
  return null;
};
export const getDateAsISO = (date:Maybe<Date>) => {
  return date instanceof Date ? date.toISOString() : null;
};
export const getDateJSAsISOZ = (date:Maybe<Date>, noTime: Maybe<boolean> = false): Maybe<string> => {
  return getDateJSAsISO(date, noTime, true);
};
export const getDateJSAsISO = (date:Maybe<Date>, noTime: Maybe<boolean> = false, asUTC: Maybe<boolean> = false): Maybe<string> => {
  return getDateAsISO(resetDate(date, noTime, asUTC));
};
export const compareDateJSAsISOZ = (dateLeft: Maybe<Date>, dateRight: Maybe<Date>, noTime: Maybe<boolean> = false): number => {
  if (!dateLeft && !dateRight) return 0;
  if (!dateLeft) return 1;
  if (!dateRight) return -1;
  const date1 = getDateJSAsISOZ(dateLeft,true);
  const date2 = getDateJSAsISOZ(dateRight,true);
  return (-1 + [date1>date2,date1===date2,date1<date2].indexOf(true));
};
export const getDateISOAsJSZ = (date:Maybe<string>, noTime: Maybe<boolean> = false): Maybe<Date> => {
  return getDateISOAsJS(date, noTime, true);
};
export const getDateISOAsJS = (date:Maybe<string>, noTime: Maybe<boolean> = false, asUTC: Maybe<boolean> = false): Maybe<Date> => {
  if (date == null) return null;
  const d = parseISO(date);
  if (d+"" === "Invalid Date") return null;
  return resetDate(d, noTime, asUTC);
};
export const getDateKebabAsJSZ = (date:Maybe<string>, noTime: Maybe<boolean> = false): Maybe<Date> => {
  return getDateKebabAsJS(date, noTime, true);
};
export const getDateKebabAsJS = (date:Maybe<string>, noTime: Maybe<boolean> = false, asUTC: Maybe<boolean> = false): Maybe<Date> => {
  const d = getDateKebabAsISO(date);
  return getDateISOAsJS(d, noTime, asUTC);
};
export const getDateKebabAsISOZ = (date:Maybe<string>, noTime: Maybe<boolean> = false): Maybe<string> => {
  return getDateKebabAsISO(date, noTime, true);
};
export const getDateKebabAsISO = (date:Maybe<string>, noTime: Maybe<boolean> = false, asUTC: Maybe<boolean> = false): Maybe<string> => {
  if (date == null) return null;
  let HH = "0", MM = "0", tz = new Date().getTimezoneOffset();
  if (!asUTC) {
    HH = leadingZero( (+HH) - (+(tz/60).toFixed()) );
    MM = leadingZero( (+MM) - (tz%60) );
  }
  let d = date+`T${HH}:${MM}:00.000Z`;
  return d;
};
export const ddmmyyyy = (date:Maybe<string> | Maybe<Date>, asInCurrentTZ: Maybe<boolean> = false): string => {
  if (!date) return "";
  if (date instanceof Date) {
    const asKebab =  getDateJSAsISO(date, asInCurrentTZ);
    const bits = asKebab.split(/\D/);
    return `${bits[2]}.${bits[1]}.${bits[0]}`;
  }
  const asDate = getDateISOAsJS(date, true);
  const splitted = date.split(/\D/);
  return `${asInCurrentTZ? splitted[2] : leadingZero(asDate.getDate())}.${asInCurrentTZ? splitted[1] : leadingZero(asDate.getMonth()+1)}.${asInCurrentTZ? splitted[0] : asDate.getFullYear()}`;
};
// export const getDateJSAsKebabZ = (date:Maybe<Date>): Maybe<string> => {
//   if (date == null) return null;
//   return ""+date.getUTCFullYear()+"-"+leadingZero(date.getUTCMonth()+1)+"-"+leadingZero(date.getUTCDate());
// };
export const getDateJSAsKebab = (date:Maybe<Date>, year:boolean=true, month:boolean=true, day:boolean=true): Maybe<string> => {
  if (date == null) return null;
  return (
    [].concat(year? date.getFullYear() : [])
      .concat(month? leadingZero(date.getMonth()+1) : [])
      .concat(day? leadingZero(date.getDate()) : [])
  ).join("-");
};
export const getDateISOAsKebab = (date:Maybe<string>, year:boolean=true, month:boolean=true, day:boolean=true): Maybe<string> => {
  if (date == null) return null;
  const [yyyy, mm, dd] = date.replace(/T.+$/, "").split("-");

  return (
    [].concat(year? yyyy : [])
      .concat(month? mm : [])
      .concat(day? dd : [])
  ).join("-");
};

export function leadingZero(num: number): string{
  if (Math.abs(num)<10) return ""+(num < 0 ? "-0" : "0")+num;
  return ""+num;
}

export function getTimeFromSeconds(s: number | string, alwaysShowHours: boolean = false) {
  let sec_num: number = +parseInt(s+"", 10);
  let hours: string | number   = Math.floor(sec_num / 3600);
  let minutes: string | number = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds: string | number = sec_num - (hours * 3600) - (minutes * 60);
  const res = [];
  if ((alwaysShowHours || hours > 0) && hours < 10){
    hours = "0"+hours;
  }
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  if (hours) res.push(hours);
  res.push(minutes);
  res.push(seconds);
  return res.join(":");
}

function getObjectArrayComparator(sortFields, desc = false, nullLast = false, getter = null) {
  const sortBy = [].concat(sortFields);
  const g = getter || (v => v);
  return function (lt, gt) {
    const comp = (fld) => {
      const f = fld.split(/\s/);
      const c = f[0];
      const d = desc || String(f[1]).toLowerCase()==="desc";

      if (lt == null && gt == null) return 0;
      //if (lt == null) return (!!nullLast === !!d) ? -1 : 1;
      //if (gt == null) return (!!nullLast === !!d) ? 1 : -1;

      const a = g(lt && lt[c], c, lt);
      const b = g(gt && gt[c], c, gt);

      if (a == null && b == null) return 0;
      if (a == null) return (!!nullLast === !!d) ? -1 : 1;
      if (b == null) return (!!nullLast === !!d) ? 1 : -1;

      if (a<b) return !d ? -1 : 1;
      else if (a>b) return !d ? 1 : -1;
      else return 0;
    };
    return sortBy.reduce((res, c) => {
      return res === 0 ? comp(c) : res;
    },0);
  };
}

function declension(value, words){
  value = Math.abs(value) % 100;
  let num = value % 10;
  if(value > 10 && value < 20) return words[2];
  if(num > 1 && num < 5) return words[1];
  if(num == 1) return words[0];
  return words[2];
}

/**
 * @param {type} arr
 *	[
 *		{
 *			CHILDOBJID:15005,
 *			PARENTOBJID:24045,
 *			EXAMPLEOBJ:1
 *		},
 *		{
 *			CHILDOBJID:120,
 *			PARENTOBJID:16001,
 *			EXAMPLEOBJ:2
 *		}
 *	]
 *
 * @param {type} divideBy
 *	{
 *		manualInput:{
 *			CHILDOBJID:15005,
 *			PARENTOBJID:24045
 *		},
 *		self:{
 *			CHILDOBJID:120,
 *			PARENTOBJID:16001
 *		}
 *	}
 *
 * @returns {service.divideMapListByParams.commonAnonym$4}
 *	{
 *		manualInput:[
 *			{
 *				CHILDOBJID:15005,
 *				PARENTOBJID:24045,
 *				EXAMPLEOBJ:1
 *			}
 *		],
 *		self:[
 *			{
 *				CHILDOBJID:120,
 *				PARENTOBJID:16001,
 *				EXAMPLEOBJ:2
 *			}
 *		]
 *	}
 */
function splitListByParams (items, splitBy, nullObj){
  const res = {};
  const divideBy = splitBy || {};
  const dKeys = Object.keys(divideBy);
  if (!dKeys.length) return {};
  const findAndPutItem = function (map){
    for (let d=0; d<dKeys.length; d++){
      const item = divideBy[dKeys[d]];
      const iKeys = Object.keys(item);
      let iEquals = true;
      const k = dKeys[d];
      if (!res[k]) res[k] = [];
      for (let i=0; i<iKeys.length; i++){
        if (typeof item[iKeys[i]] == 'function' && !item[iKeys[i]](iKeys[i],map[iKeys[i]],map)){
          iEquals = false;
        } else if (typeof item[iKeys[i]] != 'function' && map[iKeys[i]]!==item[iKeys[i]]){
          iEquals = false;
          break;
        }
      }
      if (iEquals){
        res[k].push(map);
      }
    }
    //return res;
  };
  const list = items instanceof Array ? items : [];
  if (!list.length) return arguments.length>2 ? nullObj : (function(){findAndPutItem({}); return res;})();
  for (let x=0;x<list.length;x++){
    findAndPutItem(list[x]);
  }
  return res;
}
const chunk = (arr: any[], n: number) => {
  const result = [];
  let i = 0;
  while (i < arr.length / n) {
    result.push(arr.slice(i * n, i * n + n));
    i += 1;
  }

  return result;
};

const range = (n1: number, n2?: number) => {
  const result = [];
  let first = !n2 ? 0 : n1;
  let last = n2;

  if (!last) {
    last = n1;
  }

  while (first < last) {
    result.push(first);
    first += 1;
  }
  return result;
};
const repeat = (el: any, n: number) => {
  return range(n).map(() => el);
};
const lpad = (val: string, length: number, char: string = '0') =>
  val.length < length ? char.repeat(length - val.length) + val : val;
const ifExistCall = (func?: (...args: any[]) => void, ...args: any[]) =>
  func && func(...args);
function isArrayPlain(arr) {
  return !arr.some(a => a instanceof Object);
}
export function isArrayEqual(x, y) {
  if (isArrayPlain(x) && isArrayPlain(y)) {
    return _.isEqual(x.slice().sort(), y.slice().sort());
    //@ts-ignore
  } else if (isArrayPlain(x) ^ isArrayPlain(y)) {
    return false;
  }
  return _(x.slice().sort()).differenceWith(y.slice().sort(), _.isEqual).isEmpty();
}
export function inCaseEmptyArray(arr, ifEmpty) {if (!arr && arr.length==0) {return ifEmpty;} else {return arr;}}
export function callIfExists (func, ...args) {
  if (func instanceof Function) return func(...args);
  return null;
}
function isImageMIME(mime) {
  return MIME_CATEGORIES.image.includes(mime);
}
function isVideoMIME(mime) {
  return MIME_CATEGORIES.video.includes(mime);
}
function isAudioMIME(mime) {
  return MIME_CATEGORIES.audio.includes(mime);
}

export {
  isAudioMIME, isVideoMIME, isImageMIME,
  debounce, throttle,
  chunk, range, repeat, lpad, ifExistCall,
  classModel,
  injectPropsIntoChildren,
  //isElementWithProps,
  cssSize,
  extractTextProps,
  encodeSvg,
  ifCond,
  Timer,
  //fieldInvalidError,
  collectFieldErrors,
  processApiResponse,
  processPaymentResponse,
  processAxiosResponse,
  validateStatus,
  collectApiErrors,
  getFieldErrorsOnly,
  hasFieldErrors,
  //ApiError,
  percentage,
  extractTextColor,
  getRandomInt,
  humanFileSize,
  formatPrice,
  str,
  humanizeDate,
  extractRouterParams,
  parseSearchParams,
  concatSearchParams,
  getObjectArrayComparator,
  debounceHandler,
  getToken,
  downloadFile,
  copyToClipboard,
  goto,
  declension,
  copyBBCode,
  copyPreviewCode
};
export * from './classes';
export * from './dayjs';
