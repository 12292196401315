import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  Card,
  ComputedContextMenu,
  ContextMenuTarget,
  EJustify,
  ETagColor,
  ETagType,
  SpaceBox,
  Sticky,
  TagLabel, IContextMenuItem
} from 'components';
import {ifCond} from 'utils';
import {ICardPaneItem} from 'types';
import { CONTEXT_MENU } from 'const';

export interface ICardPane extends TThemed {
  items: ICardPaneItem[];
  getItemActions?: (ICardItem) => IContextMenuItem[];
  total?: number;
  loading?: boolean;
  headerOffset?: number;
  hasMenu?: boolean;
  selectable?: boolean;
  selectionMode?: boolean;
  selected?: string[];
  onView?: Function;
  onSelect?: Function;
  onTagClick?: Function;
  onAddTagClick?: Function;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const CardPane = React.forwardRef((props: ICardPane, ref) => {
  const { total=0, items=[], hasMenu=false, getItemActions, loading=false, headerOffset, selected, onSelect, onTagClick,
    onAddTagClick, selectionMode=false, onView, darkMode=false, demo=false, if:_if=true, children, ...rest
  } = props;
  const [_selected, _setSelected] = React.useState(selected || []);
  const chosen = selected ? selected : _selected;
  const _selectionMode = selected ? selectionMode : chosen.length>0;
  const isChecked = React.useCallback((v) => chosen.includes(v), [chosen]);
  const handleChecked = React.useCallback((e, slug, check) => {
    let newChecked = [];
    if (check) {
      if (!isChecked(slug)) newChecked = [...chosen, slug];
    } else {
      if (isChecked(slug)) newChecked = [...chosen.filter(c => c !== slug)];
    }
    if ( selected ) onSelect(e, newChecked, slug);
    else _setSelected(newChecked);
  }, [selected, chosen, _setSelected, isChecked, onSelect]);
  const handleAddTags = React.useCallback((item) => {
    if (onAddTagClick instanceof Function) onAddTagClick(item);
  }, [onAddTagClick]);
  const stickerRef = React.useRef([]);
  const getContextItems = React.useCallback(getItemActions, [getItemActions]);
  const actions = selectionMode ? getItemActions(selected.map( slug => ({slug}) )) : getContextItems;

  if ( !ifCond(_if) ) return null;
  return (
    <TWCardPane ref={ref} {...rest}>
      {items.map((item, key) => {
        const {items: subitems, date, dateLabel} = item;
        return (
          <React.Fragment key={key}>
            <Sticky stickerRef={stickerRef} stickyOffset={headerOffset} justify={EJustify.CENTER} height={20}>
              <Align justify={EJustify.CENTER} height={20}>
                {/*{console.log({dateLabel})}*/}
                <TagLabel text={dateLabel} tw={'capitalize'} color={ETagColor.GHOST} type={ETagType.SECTION}/>
              </Align>
            </Sticky>
            <SpaceBox height={10} />
            <TWCardPaneItem>
              {subitems.map((subitem, subkey) => {
                return (
                  <ContextMenuTarget key={subkey}
                                     for={CONTEXT_MENU.CARD}
                                     contextData={{item: subitem}}
                                     disabled={!hasMenu || !!selectionMode}
                                     renderAs={Card}
                                     hasMenu={hasMenu}
                                     index={subkey+1000}
                                     {...subitem}
                                     onTagClick={onTagClick}
                                     onAddTagClick={() => handleAddTags(subitem)}
                                     onChange={handleChecked}
                                     onView={onView}
                                     checked={isChecked(subitem.slug)}
                                     selectionMode={_selectionMode}
                  >
                    {/*<Card hasMenu={hasMenu} index={subkey+1000} {...subitem} onTagClick={onTagClick} onChange={handleChecked} checked={isChecked(subitem.id)} selectionMode={_selectionMode}/>*/}
                  </ContextMenuTarget>
                );
              })}
              {/*<ContextMenu id={subitem.slug} actions={getContextItems} contextData={subitem}/>*/}
            </TWCardPaneItem>
          </React.Fragment>
        );
      })}
      {children}
      <ComputedContextMenu id={CONTEXT_MENU.CARD} actions={actions}/>
    </TWCardPane>
  );
});

const TWCardPane = styled("section")(({ theme, darkMode, disabled }) => [
  tw`pb-90p`,
  css``
]);

const TWCardPaneItem = styled("section")(({theme}) => [
  tw`grid row-gap-24p items-center content-center justify-center w-full px-30p pb-20p mx-auto`,
  css`
    justify-items: center;
    grid-column-gap: 22px;
  `,
  //breakpoints
  tw`grid-cols-7
     hsix:grid-cols-6
     hfive:grid-cols-5
     hfour:grid-cols-4
     hthree:grid-cols-3
     htwo:grid-cols-2
     hone:grid-cols-1
  `,
]);

