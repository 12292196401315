import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {ETextColor, Icon, TextBox} from 'components';
import {extractTextProps, ifCond} from 'utils';

export interface ILinkButton {
  href?: string;
  text?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  caption?: string;
  noColor?: boolean;
  noUnderline?: boolean;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const FileButton = React.forwardRef((props: ILinkButton, ref) => {
  const { prepend, append, demo=false, if:_if=true, caption, icon, text, noColor=false, noUnderline=false, ...rest } = props;
  const TextProps = {...extractTextProps(caption || text)};
  if ( !ifCond(_if) ) return null;
  return (
    <TWFileButton type={'file'} {...{ref, noColor, noUnderline,...rest}}/>
    
  );
});

const TWFileButton = styled("input")(({ noColor=false, noUnderline=false }) => [
    tw`inline-grid hidden grid-flow-col content-center items-center cursor-pointer hover:cursor-pointer`,
    !noColor && tw`text-button-bf`,
    !noUnderline && tw`hover:underline`,
    css``
  ]
);

interface IWithHrefProps {
  Node: React.ReactNode;
  href?: string | {href?: string; [prop: string]: any;};
  hrefNode?: typeof React.Component;
  tabIndex?: number;
}

export const withHref = (Node: React.ReactNode, href: string | {href?: string; key?: string | number; [prop: string]: any;}, hrefComp?:typeof React.Component, tabIndex?: number) => {
  const A = href && hrefComp ? hrefComp : TWFileButton;
  const hrefProps = href+""===href ? {href} : (href || {} as any);
  const key = href instanceof Object ? href.key || Date.now() : href;
  return hrefProps.href ? ( <A {...hrefProps} tabIndex={tabIndex} key={key}>{Node}</A> ) : <>{Node}</>;
};
