import React from 'react';
import tw, {css, styled} from 'twin.macro';
import ReactTooltip from 'react-tooltip';
import {
  EIconSize,
  ETagColor,
  ETagType,
  ETextAlign,
  ETextColor,
  ETextSize,
  EIcon,
  Icon,
  If,
  SpaceBox,
  TagLabel,
  TextBox, CardPaneLoader,
} from 'components';
import {ICardItem} from 'types';
import {
  extractTextColor,
  humanFileSize,
  ifCond,
  dayjs,
  isAudioMIME,
  isVideoMIME,
  isImageMIME,
  cardDateFormat,
  callIfExists
} from 'utils';
import {MIME_CATEGORIES, OnePixel, PLAN} from 'const';
import {useCheckBox, useStoreState} from 'hooks';

export interface ICard extends TThemed, TLoadable, ICardItem {
  checked?: boolean;
  showMenu?: Function;
  hasMenu?: boolean;
  hasSelection?: boolean;
  hasTags?: boolean;
  selectionMode?: boolean;
  onChange?: Function;
  onTagClick?: Function;
  onAddTagClick?: Function;
  onView?: Function;
  caption?: string;
  status?: string;
  currentSlug: string;
  /*  id: string;
    slug: string;
    name: string;
    description?: string;
    created_date: Date | string;
    last_viewed_date?: Date | string;
    preview?: string;
    bgcolor?: string;
    link?: string;
    file_size?: number;
    file_extension?: string;
    mime_type?: string;
    protected?: number;
    hash?: string;
    tags?: ICardTag[];
  */
  if?: boolean | (() => boolean);
  demo?: boolean;

  [prop: string]: any;
}

export const Card = React.forwardRef((props: ICard, ref) => {
  const {
    currentSlug,
    id,
    slug,
    caption = props.name,
    blocked,
    name,
    status,
    description,
    created_date,
    preview,
    bgcolor: bg,
    link: href,
    tags = [],
    file_size = 0,
    file_extension = "",
    mime_type = "unknown",
    protected: locked = 0,
    checked = false,
    onView,
    onChange,
    onAddTagClick,
    onTagClick,
    onlyTrustedOnChange = false,
    selectionMode = false,
    plan = PLAN.NONE.unique,
    index = 1000,
    showMenu,
    hasSelection = true,
    hasTags = false,
    hasMenu = false,
    darkMode = false,
    demo = false,
    if: _if = true,
    ...rest
  } = props;
  const MSG = useStoreState(s => s.MSG);
  const isImage = isImageMIME(mime_type);
  const isVideo = isVideoMIME(mime_type);
  const isElse = !isImage && !isVideo;
  const ext = file_extension;
  const created = cardDateFormat(created_date, MSG.LANG_UNIQUE, MSG);
  const bgcolor = !isElse ? bg : "colors.gray.550";
  const [marked, setMarked, handleChecked] = useCheckBox(checked, onChange, slug, onlyTrustedOnChange);
  const handleView = React.useCallback(() => {
    if (blocked) {
      return null;
    }
    callIfExists(onView, slug)
  }, [onView]);
  const handleTagClick = React.useCallback((e, cardTag) => {
    callIfExists(onTagClick, e, cardTag);
  }, [onTagClick]);
  const handleShowRestTags = React.useCallback((e, cardId) => {
    //TODO: show rest tags
  }, []);
  const handleMenuOpen = React.useCallback((e) => {
    if (showMenu instanceof Function) showMenu(e);
  }, [showMenu]);

  if (!ifCond(_if)) return null;
  return (
    <TWCard blocked={blocked} slug={currentSlug} id={id} plan={plan} darkMode={darkMode} tabIndex={index} {...rest}>
      <TWBG blocked={blocked} className="card-background" bgcolor={bgcolor} selectionMode={selectionMode} onClick={handleView}>
        <If if={!blocked} else={
          <TWBlock>
            <Icon icon="LockGray" size={90} />
          </TWBlock>
        }>
          {/*<MenuButton />*/}
          <If if={status === 'completed'} else={
            <TWLoaderContiner>
              <CardPaneLoader center darkMode={darkMode} />
            </TWLoaderContiner>
          }>
            <If if={!isElse} else={
              <TWElse name={ext} size={humanFileSize(file_size)} onClick={handleView}>
                <Icon icon="FileWhite" size={90} />
              </TWElse>
            }>
              <TWMedia onClick={handleView}>
                <TWImage src={OnePixel} img={preview} alt={name} />
                <If if={isVideo}>
                  <TWVideo><Icon icon="PlayWhite" size={90} /></TWVideo>
                </If>
              </TWMedia>
              {/*<TWGradient />*/}
            </If>
          </If>
        </If>
      </TWBG>
      <If if={!blocked}>
        <TWDrawer className="card-drawer">
          <SpaceBox height={10} />
          <TWCaption>
            <Icon if={!!locked} icon={EIcon.LockGray} size={19} />
            <TWCaptionLink>
              <TextBox text={caption} size={ETextSize.MEDIUM} color={ETextColor.PRIMARY} fillX={true} ellipsis={true}
                       block={true} inline={false} index={index} onClick={handleView} />
            </TWCaptionLink>
          </TWCaption>
          <SpaceBox height={4} />
          <TWStamp>
            <TextBox text={created} size={ETextSize.SMALL} color={ETextColor.SECONDARY} fillX={true} ellipsis={true}
                     block={true} inline={false} />
          </TWStamp>
          <SpaceBox height={hasTags ? 5 : 10} />
          <TWTagLayout>
            <TWTagRibbon if={[PLAN.PRO.unique, PLAN.TEAM.unique].includes(plan)}>
              <If if={!blocked}>
                {tags.length < 1 &&
                  <TWAddTagButton clickable={tags.length < 1} onClick={onAddTagClick}>
                    <Icon if={tags.length < 1} size={EIconSize.MD} icon="AddCircleGray"
                          className="button-tag-add-normal" />
                    <Icon if={tags.length < 1} size={EIconSize.MD} icon="AddCircle" className="button-tag-add-hover" />
                    <TextBox if={tags.length < 1} text={MSG.SCREENSHOT_CARD.ADD_TAG} fillY={true}
                             color={ETextColor.SECONDARY} size={ETextSize.SMALL} className="button-tag-add-text" />
                  </TWAddTagButton>
                }
                {tags.slice(0, 3).map((tag, key) => {
                  return (
                    <TWTag key={key} onClick={(e) => handleTagClick(e, tag)}>
                      <TagLabel customHeight={23} color={tag.color} type={ETagType.LABEL} text={tag.name}
                                hint={tag.name} id={tag.id} minmax={[60, 80]} align={ETextAlign.CENTER} />
                      {false && (key < 2 || tags.length > 3) && <div tw="inline-block relative w-5p" />}
                    </TWTag>
                  );
                })}
                {tags.length > 3 ? (<>
                  <TWTag onClick={(e) => handleShowRestTags(e, id)} data-for={`${id}-tooltip`} data-tip={id}
                         data-event="click focus">
                    <TagLabel customHeight={23} color={ETagColor.INFO} type={ETagType.LABEL}
                              text={`+${tags.slice(3, tags.length).length}`} minmax={[45, 60]}
                              align={ETextAlign.CENTER} />
                  </TWTag>
                  <ReactTooltip id={`${id}-tooltip`} className="tag-tooltip" border={false} clickable={true}
                                globalEventOff="click" effect="float" place="bottom" type="light" isCapture={true}
                                scrollHide={true}
                                getContent={dataTip => (<TWTagTooltip>
                                  {tags.slice(3, tags.length).map((tag, key) => {
                                    return (<TWTag key={key} onClick={(e) => handleTagClick(e, tag)}>
                                      <TagLabel customHeight={23} color={tag.color} type={ETagType.LABEL}
                                                text={tag.name} hint={tag.name} id={tag.id} minmax={[60, 80]}
                                                align={ETextAlign.CENTER} />
                                    </TWTag>);
                                  })}
                                </TWTagTooltip>)}
                  />
                </>) : null}
                {tags.length > 0 &&
                  <TWAddTagButton clickable={tags.length > 0} onClick={onAddTagClick}>
                    <Icon if={tags.length > 0} size={EIconSize.MD} icon="AddCircleGray"
                          className="button-tag-add-normal" />
                    <Icon if={tags.length > 0} size={EIconSize.MD} icon="AddCircle" className="button-tag-add-hover" />
                  </TWAddTagButton>
                }
              </If>
            </TWTagRibbon>
          </TWTagLayout>
        </TWDrawer>
      </If>
      <If if={hasSelection}>
        <TWCheckBox ref={ref} type="checkbox" checked={marked} onChange={handleChecked} selectionMode={selectionMode} />
      </If>
      <If if={!selectionMode}>
        <If if={!!hasMenu}>
          <TWContextMenuButton className="card-menu-button">
            <Icon icon="EllipsisWhite" size={EIconSize.MD} onClick={handleMenuOpen} />
          </TWContextMenuButton>
        </If>
        <TWContextMenu className="card-menu-button" items={[]} />
      </If>
    </TWCard>
  );
});

const TWContextMenuButton = styled("div")(({theme, darkMode, disabled}) => [
  tw`hidden absolute justify-center content-center cursor-pointer w-54p h-40p p-10p top-0 right-0`
]);

const TWLoaderContiner = styled("div")(({theme, darkMode, disabled}) => [
  tw`w-full absolute`,
  css`
    top: 80%;
  `
]);

const TWContextMenu = styled("div")(({theme, darkMode, disabled}) => [
  tw`hidden absolute`,
  tw``,
  css``
]);

const TWCard = styled("article")(({theme, darkMode, disabled, plan, slug, blocked}) => [
  tw`inline-block relative shadow-card-light rounded-md outline-none
     transition-shadow duration-500
     flex-none w-full
  `,
  !blocked && tw`bg-white`,
  !blocked && tw`overflow-hidden`,
  !blocked && slug && css`box-shadow: 0 5px 30px 0 rgb(0 11 43 / 50%);`,
  tw`hover:shadow-card-light-hover`,
  css`
    &:hover > div.card-background /*, :focus>div.card-background*/ {
      ${tw`after:opacity-50`}
    }

    &:hover > div.card-menu-button /*, :focus>div.card-menu-button*/ {
      ${tw`inline-flex`}
    }
  `,
  //TWCheckBox
  css`
    &:hover > input[type=checkbox] /*, :focus>input[type=checkbox]*/ {
      ${tw`inline-block`}
    }
  `,
]);
const TWBG = styled("div")(({theme, darkMode, disabled, bgcolor, blocked}) => [
  tw`relative w-full outline-none cursor-pointer`,
  css`
    padding-top: calc(300 / 320 * 100%);
  `,
  !blocked && css`background-color: ${extractTextColor(bgcolor, theme)};`,
  tw`after:inline-block after:absolute after:shadow-card-gradient
    after:rounded-md after:opacity-0
    after:transition-opacity after:duration-300
    after:w-full after:top-0 after:left-0
  `,
  css`
    &:after {
      height: 71%;
      background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
      content: '';
    }
  `
]);

const TWBlock = styled("div")(() => [tw`inline-block absolute top-0 left-0 w-full h-full cursor-pointer`,
  css`display: flex;
    background: #2c3036;
    border-radius: 16px;
    box-shadow: 0 5px 30px 0 rgb(0 11 43 / 50%) !important;
    justify-content: center;
    align-items: center`]);
const TWMedia = styled("div")(() => [tw`inline-block absolute top-0 left-0 w-full h-full cursor-pointer`]);
const TWVideo = styled("div")(() => [tw`inline-grid absolute items-center content-center w-full h-full`, css`justify-items: center;`]);
const TWImage = styled("img")(({img}) => [tw`absolute w-full h-full bg-contain bg-no-repeat bg-center`, css`background-image: url('${img}');`]);
const TWElse = styled("div")(({name, size}) => [
  tw`inline-grid absolute items-center content-center top-0 left-0 w-full h-full cursor-pointer`,
  tw`before:inline-block before:relative`,//before is gradient
  tw`after:inline-block after:relative`,
  css`justify-items: center;`,
  css`
    &:before {
      content: "${name}";
      font-size: 14px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      top: 106px;
    }

    &:after {
      content: "${size.replace(/\./, ",")}";
      font-size: 13px;
      font-weight: 800;
      color: white;
      text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
      top: 20px;
    }
  `
]);

const TWDrawer = styled("div")(() => [tw`w-full`, css``]);
const TWCaption = styled("section")(() => [tw`relative grid grid-flow-col col-gap-10p overflow-hidden px-15p h-19p`,
  css`
    text-overflow: ellipsis;
    grid-template-columns: auto;
    grid-auto-columns: 1fr;

    & > a {
      overflow: hidden;
    }
  `
]);
const TWCaptionLink = styled("a")(() => [tw`hover:underline focus:underline`, css``]);
const TWStamp = styled("section")(() => [tw`block relative px-15p h-17p capitalize`,]);
const TWTagLayout = styled("section")(() => [
  tw`relative left-0 bottom-0 mr-auto w-auto`,
  css``
]);
const TWTagRibbon = styled("div")(() => [tw`relative grid grid-flow-col items-center content-center px-15/2p w-fit h-40p`,
  css`
    grid-auto-columns: auto;
    grid-column-gap: 5px;
  `
]);
const TWAddTagButton = styled("div")(({
                                        theme,
                                        clickable
                                      }) => [tw`inline-grid relative grid-cols-auto grid-flow-col col-gap-10p px-5/2p py-10p`, clickable && tw`cursor-pointer`,
  css`
    &:not(:hover) > .button-tag-add-hover {
      display: none !important;
    }

    &:hover > .button-tag-add-normal {
      display: none !important;
    }

    &:hover > .button-tag-add-text {
      color: ${theme.colors.sky} !important;
    }
  `
]);
const TWTag = styled("div")(() => [tw`inline-block relative h-full cursor-pointer py-10p`]);
const TWTagTooltip = styled("div")(({
                                      theme,
                                      darkMode,
                                      disabled
                                    }) => [tw`inline-grid grid-cols-5xa px-15/2p gap-x-1`, css`& label {
  width: auto;
}`]);

const TWCheckBox = styled("input")(({color, text, size, selectionMode}) => [
  tw`hidden absolute rounded-md outline-none align-top cursor-pointer border-0 m-0 z-5`,
  tw`top-0 left-0 w-40p h-40p`,
  ...(selectionMode ? [//full size checkbox at selection mode
    tw`inline-block w-full h-full`,
    tw`checked:border-2 checked:border-accent-700 checked:border-solid checked:bg-accent-804`,
    tw`checked:before:-top-2p checked:before:-left-2p`,
    tw`checked:after:-top-2p checked:after:-left-2p`,
  ] : []),

  tw`disabled:hidden`,//disabled
  //:before is circle
  tw`before:block before:absolute before:rounded-full`,
  tw`before:border-2 before:border-solid before:border-white`,
  tw`before:w-21p before:h-21p before:m-10p checked:before:bg-accent-805`,
  //:after is v mark
  tw`after:block after:absolute after:opacity-0
     after:border-2 after:border-solid after:border-white after:border-t-0 after:border-l-0
     after:transform after:rotate-20
     after:w-5p after:h-10p after:ml-18p after:mt-15p
  `,
  tw`checked:after:opacity-100 checked:after:transform checked:after:rotate-45`,
  css`
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background .3s, box-shadow .2s;

    &:before {
      content: '';
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }

    &:after {
      content: '';
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }

    &:checked {
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
  `
]);
