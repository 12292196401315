import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  Buttons,
  EButtonKind,
  EPanelCaptionKind,
  EPanelWidth,
  ETextSize,
  Line,
  Panel,
  SpaceBox,
  TextBox
} from 'components';
import {humanizeDate, ifCond, str} from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IBillingCancelSub extends TThemed {
  date: Date;
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const BillingCancelSub = React.forwardRef((props: IBillingCancelSub, ref) => {
  const { date, onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleSubmit = React.useCallback((e) => {
    if (onSubmit instanceof Function) onSubmit(e);
  }, [onSubmit]);

  useKeydown(handleSubmit, handleClose);

  if ( !ifCond(_if) ) return null;
  return (
    <TWBillingCancelSub ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.CONFIRM.CANCEL_SUB_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={10} />
        <TextBox text={MSG.CONFIRM.CANCEL_SUB_ASK} size={ETextSize.MEDIUM} inline={false} block={true}/>
        <TextBox text={str(MSG.CONFIRM.CANCEL_SUB_WARN, humanizeDate(date))} size={ETextSize.MEDIUM} inline={false} block={true}/>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_SECONDARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.UNSUB} kind={EButtonKind.CRITICAL} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWBillingCancelSub>
  );
});

const TWBillingCancelSub = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
