import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  LinkButton, PanelHeader, EEditInput, ETextWeight, ETextSize, EEditKind, EPanelWidth
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {FIELD} from 'const';
import {TextareaBox} from "../../base/Field/TextateaBox";
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface ITeamPurchaseLicenses extends TThemed {
  limit?: number;
  onSubmit?: Function;
  onClose?: Function;
  size?: number;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const TeamPurchaseLicenses = React.forwardRef((props: ITeamPurchaseLicenses, ref) => {
  const { onClose, onSubmit, size, raw=false, darkMode=false, demo=false, limit=0, if:_if=true, ...rest } = props;
  const [errors, setErrors] = React.useState([])
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const [values, setValues] = React.useState(10);
  const userPrice = {amount: 7.22, currency: 'USD'}
  const handleSubmit = React.useCallback((e) => {
    if (values && +values > 0) {
      if (onSubmit instanceof Function) onSubmit(e, +values);
    } else {
      setErrors(['Number of licenses is required'])
    }
    
  }, [onSubmit, values, setValues]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value
    setErrors([])
    setValues((value.match(/\d+/) && value.match(/\d+/)[0]) || '')
  }, [setValues, values]);

  useKeydown(handleSubmit, handleClose);

  if ( !ifCond(_if) ) return null;
  return (
    <TWTeamPurchaseLicenses ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.ACTION.PURCHASE_LICENSES} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <TextBox text={MSG.ACTION.END_PERIOD} />
        <SpaceBox height={10} />
        <TextBox text={MSG.ACTION.COST} />
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={30} />
        <PanelHeader kind={EPanelCaptionKind.SM} text={MSG.ACTION.HOW_MANY}/>
        <SpaceBox height={20} />
        <TWPriceCounter>
          <EditBox width={89} errors={errors} value={values} tw={'pt-0'} kind={EEditKind.LG} noFocus fontWeight={ETextWeight.SEMIBOLD} fontSize={21} onChange={(e) => handleChange(e, FIELD.LINK)} fill={true}/>
          <TextBox tw={'ml-20p mt-10p'} text={`X ${userPrice.amount}$ = ${(userPrice.amount*values).toFixed(2)}$`} size={21} weight={ETextWeight.SEMIBOLD} />
        </TWPriceCounter>
        <SpaceBox height={20}/>
        <Line />
        <SpaceBox height={20}/>
        <Buttons>
          <Button caption={MSG.ACTION.PURCHASE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWTeamPurchaseLicenses>
  );
});

const TWTeamPurchaseLicenses = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWPriceCounter = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex items-start`,
  css``
]);

const TWInvitationList = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid relative grid-cols-full grid-flow-row row-gap-10p w-full`,
  css``
]);
