import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/global.css';
import 'tailwindcss/dist/base.min.css'
import { StoreProvider } from "easy-peasy";
import { Application } from 'pages';
import {Fonts, Tailwind} from 'components';
import { store } from "store";
import * as serviceWorker from './serviceWorker';
//
ReactDOM.render(

      <Tailwind>
        <React.StrictMode>
          <Fonts href={[
            "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap",
          ]}/>
          <StoreProvider store={store}>
            <Application />
          </StoreProvider>
        </React.StrictMode>
      </Tailwind>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
