import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Avatar,
  Button,
  DeleteUser,
  EButtonKind,
  EIconSize,
  ETagColor,
  ETagType,
  ETextAlign,
  ETextColor,
  ETextWeight,
  Icon,
  If,
  IProfile,
  Line,
  LinkButton,
  Logo,
  Modal,
  Panel,
  PanelHeader, PurchaseLicenses,
  SpaceBox,
  Switch,
  TagLabel,
  TeamCustomDomain,
  TeamInviteUsers,
  TeamIPLimit,
  TextBox
} from 'components';
import {cssSize, declension, extractTextProps, ifCond} from 'utils';
import {PLAN, PRODUCTS, ROLE} from 'const';
import {useStoreActions, useStoreState} from "../../../store";
import {TeamPurchaseLicenses} from "../Action/TeamPurchaseLicenses";
import {FileButton} from "../../base/Button/FileButton";
import {useMediaBreakpoints} from "../../../hooks";

export interface ITeamSettings extends TThemed {
  teamSettings: any;
  profile?: IProfile
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const TeamSettings = React.forwardRef((props: ITeamSettings, ref) => {
  const { teamSettings={}, darkMode=false, demo=false, if:_if=true, profile, promoteMember
    = () => {}, cancelInvite= () => {}, inviteUser= () => {}, demoteMember= () => {}, expelMember= () => {}, domainChange=()=>{}, extendTeam=()=>{}, leaveTeam = () => {}, setTeamIp = () => {}, uploadLogo = () => {}, logoLoad,  ...rest } = props;
  const { teamOnly=false, ipLimit={}, members=[], summaryPayment, domain="", logo={}, licensesAllowed=0 } = teamSettings;
  const MSG = useStoreState(s=>s.MSG);
 
  const [currentMember, setCurrentMember] = React.useState({username: '', email: '', invited: false, currentUser: false});
  const { from: fromIp, to: toIp, limited: limitedIp } = ipLimit;
  const { dark: darkLogo, light: lightLogo } = logo;
  
  
  const memberProfile = members.filter((member) => member.username === profile.username)[0] || {}
  const isOwner = memberProfile.role && (memberProfile.role.unique===ROLE.OWNER)
  const isAdmin = memberProfile.role && (memberProfile.role.unique===ROLE.ADMIN)
  const isMember = memberProfile.role && (memberProfile.role.unique===ROLE.MEMBER)
  const limit = licensesAllowed - members.length
  
  const askSetTeamIP = useStoreActions(a=>a.screenshots.askSetTeamIP);
  const askSetTeamPurchaseLicenses = useStoreActions(a=>a.screenshots.askSetTeamPurchaseLicenses);
  const askSetPurchaseLicenses = useStoreActions(a=>a.screenshots.askSetPurchaseLicenses);
  const askSetTeamCustomDomain = useStoreActions(a=>a.screenshots.askSetTeamCustomDomain);
  const askSetTeamInviteUsers = useStoreActions(a=>a.screenshots.askSetTeamInviteUsers);
  const askSetDeleteUser = useStoreActions(a=>a.screenshots.askSetDeleteUser);
  
  const loadLink = useStoreState(state=>state.logoLoad);
  const putAvatar = useStoreActions(a=>a.putAvatar);
  const setLogoClear = useStoreActions(a=>a.setLogoClear);
  const switchToProfile = useStoreActions(a=>a.switchToProfile);
  const sendInvoice = useStoreActions(a=>a.sendInvoice);
  const paymentMethod = useStoreState(s=>s.paymentMethod);
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const clearMessagesByField = useStoreActions(actions => actions.clearMessagesByField);
  const [logoColor, setLogoColor] = React.useState('light');
  const [size, setSize] = React.useState(10);
  const [avatarFile, setAvatarFile] = React.useState(null);
  const fileInput = React.useRef(null)
  const bp = useMediaBreakpoints();
  
  const onChangePhotoClick = (color) => {
    setLogoColor(color)
    fileInput.current.click()
  }
  
  const onSelectPhoto = async (e) => {
    // await console.log({file: e.currentTarget.files[0], link: avatarLoad.link})
    if (e.currentTarget.files[0]) {
      uploadLogo({type: logoColor})
      let file = e.currentTarget.files[0];
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function() {
        setAvatarFile(reader.result)
      };
      reader.onerror = function() {
        console.error(reader.error);
      };
    }
  };
  
  const leave = (payload) => {
    switchToProfile(true)
    leaveTeam(payload)
  }
  
  React.useEffect(() => {
    if (loadLink && avatarFile) {
      putAvatar({image: avatarFile, link: loadLink.link, MSG})
      setAvatarFile(null)
      setLogoClear()
    }
  }, [loadLink, avatarFile])
  
  // React.useEffect(() => {
  //   console.log(logoLoad, 'logoLoad')
  // }, [logoLoad])
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWTeamSettings ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.TEAM.SECURITY} mobileCenter={true} padding={!bp.mobile ? 30 : 10} width={!bp.mobile ? 700 : 'auto'} noShadow={!bp.desktop} align={ETextAlign.CENTER}>
        <SpaceBox height={22} width="100%"/>
        <Line />
        <Row caption={MSG.TEAM.TEAM_ONLY} mobile = {bp.mobile} captionWidth={170} actionContent={
          <Switch if={isOwner} checked={!!teamOnly} leftText={[MSG.TEAM.OFF,MSG.TEAM.ON]}/>
        }>
          <TextBox text=" " color={ETextColor.PRIMARY}/>
        </Row>
        <Line />
        <Row caption={MSG.TEAM.IP_LIMIT} mobile = {bp.mobile} height={bp.mobile && 68} captionWidth={170} actionContent={
          <Switch if={isOwner} checked={!!limitedIp} onClick={() => {setTeamIp({enabled: !limitedIp, ip_from: fromIp, ip_to: toIp})}} leftText={[MSG.TEAM.OFF,MSG.TEAM.ON]}/>}>
          <TWIPLimit>
            <TextBox text={fromIp && toIp ? `${fromIp} - ${toIp}` : "" } color={ETextColor.PRIMARY}/>
            <SpaceBox width={10} inline={true} />
            <LinkButton if={isOwner} text={MSG.TEAM.CHANGE_IP} onClick={(e) => {askSetTeamIP({limitedIp})}}/>
          </TWIPLimit>
        </Row>
        <Line />
        <SpaceBox height={40}/>
        <div tw="flex content-center">
          <div tw="inline-block">
            <PanelHeader text={MSG.TEAM.MEMBERS} />
            <TextBox text={`${MSG.TEAM.USED_BY} ${ members.length } ${MSG.TEAM.OUT_OF} ${ licensesAllowed } ${declension(licensesAllowed, MSG.TEAM.LICENSES)}`} color={ETextColor.SECONDARY} inline={false} block={true}/>
          </div>
          <SpaceBox width="stretch" inline={true} />
          <div tw="inline-grid grid-flow-col col-gap-10p items-center">
            
            
            <Button if={summaryPayment && isOwner && !bp.mobile} caption={MSG.TEAM.PURCHASE_LICENSES} onClick={() => askSetTeamPurchaseLicenses({details: {item_name: PRODUCTS.SLOT, quantity: size}, setSize, payLicenses: askSetPurchaseLicenses, size, isPaymentMethod: !!paymentMethod})} kind={EButtonKind.OUTLINED_PRIMARY} />
            <If if={isOwner || isAdmin}>
              <Button caption={MSG.TEAM.INVITE_USERS} onClick={(e) => {askSetTeamInviteUsers({limit, isOwner})}} kind={EButtonKind.OUTLINED_PRIMARY}/>
            </If>
          </div>
        </div>
        <SpaceBox height={24}/>
        <Line />
        {teamSettings.members && teamSettings.members.map(({ avatar, email, username, name, plan, neededPayment, invitation, role }, i) => {
          return (<>
            <Row key={i} mobile={bp.mobile} height={100} isUser={true} captionContent={
              <Avatar avatar={avatar || ''}/>
            } captionWidth={40} actionContent={
              role.unique===ROLE.OWNER ? <If if={!bp.mobile}><TextBox text={MSG.TEAM.OWNER} /></If> :
              <TWRowActions>
                
                <If if={!bp.mobile}>
                  <If if={!invitation && isOwner}><Switch if={role.unique===ROLE.ADMIN || role.unique===ROLE.MEMBER} checked={role.unique===ROLE.ADMIN} leftText={[MSG.TEAM.MEMBER,MSG.TEAM.ADMIN]} onChange = {() => {role.unique===ROLE.ADMIN ? demoteMember({username}) : promoteMember({username})}}/></If>
                  <TagLabel if={!!invitation} type={ETagType.FLAG} color={ETagColor.ASHEN} text={MSG.TEAM.INVITATION_PENDING}/>
                </If>
                
                {/*<LinkButton if={!!neededPayment} text="Pay now"/>*/}
                <If if={memberProfile.username === username || (isOwner || (isAdmin && (role.unique===ROLE.MEMBER || role.unique===ROLE.GUEST)))}>
                  <TWDeleteButton onClick={(e) => {askSetDeleteUser({currentMember: {username: username, invited: invitation, email, currentUser: memberProfile.username === username}})}}>
                    <Icon icon="TrashGray" size={EIconSize.MD} className="button-member-delete-normal"/>
                    <Icon icon="TrashRed" size={EIconSize.MD} className="button-member-delete-hover"/>
                  </TWDeleteButton>
                </If>
              </TWRowActions>
            }>
              <If if={bp.mobile}>
                <TagLabel if={!!invitation} type={ETagType.FLAG} color={ETagColor.ASHEN} text={MSG.TEAM.INVITATION_PENDING}/>
              </If>
              <TextBox if={!!name} text={`${name} ${!bp.mobile ? '-' : ''} `} weight={ETextWeight.SEMIBOLD}/>
              <SpaceBox if={!!name} width={4} inline={true} />
              <TextBox text={email} color={ETextColor.SECONDARY}/>
              <TextBox text={plan && plan.name} color={ETextColor.PRIMARY}/>
              <If if={bp.mobile}>
                <TextBox if={role.unique===ROLE.OWNER} tw={'mt-7p'} text={MSG.TEAM.OWNER} />
                <If if={!invitation && isOwner}><Switch reverse={true} isUser={true} if={role.unique===ROLE.ADMIN || role.unique===ROLE.MEMBER} checked={role.unique===ROLE.ADMIN} leftText={[MSG.TEAM.MEMBER,MSG.TEAM.ADMIN]} onChange = {() => {role.unique===ROLE.ADMIN ? demoteMember({username}) : promoteMember({username})}}/></If>
              </If>
            </Row>
            <Line />
          </>);
        })}
        <SpaceBox height={40}/>
        <PanelHeader align={bp.mobile ? ETextAlign.CENTER : ETextAlign.LEFT} text={MSG.TEAM.CUSTOM_DOMAIN} />
        <SpaceBox height={20}/>
        <Line />
        <Row caption={MSG.TEAM.CUSTOM_DOMAIN} isDomain={true} height={70} captionWidth={160} actionContent={
          <LinkButton if={isOwner} text={MSG.TEAM.SET_DOMAIN} onClick={(e) => {askSetTeamCustomDomain()}}/>
        }>
          <TextBox text={domain}/>
        </Row>
        <Line />
        <TWLogoContainer>
          <SpaceBox height={40}/>
          <PanelHeader text={MSG.TEAM.YOUR_LOGO} />
          <SpaceBox height={10}/>
          <TextBox text={MSG.TEAM.LOGO_DESC} align = {bp.mobile ? ETextAlign.CENTER : ETextAlign.LEFT} width={bp.mobile ? 'auto' : 400} tw={'inline-block'}/>
          <SpaceBox height={7}/>
          <TextBox text={MSG.TEAM.USE_PNG} color={ETextColor.SECONDARY}/>
          <SpaceBox height={30}/>
          <TWLogoGrid mobile = {bp.mobile}>
            <FileButton ref={fileInput} onChange={(e) => onSelectPhoto(e)}/>
            <TWLogo><Logo src={lightLogo}/></TWLogo>
            <div tw="flex"><TextBox text={MSG.TEAM.HISTORY_PAGE}/><SpaceBox width="stretch" inline={true}/><LinkButton text={MSG.TEAM.CHANGE_LOGO} onClick={() => onChangePhotoClick('light')}/></div>
            <TWLogo darkMode={true}><Logo src={darkLogo} darkMode={true}/></TWLogo>
            <div tw="flex"><TextBox text={MSG.TEAM.SCREENSHOT_PAGE}/><SpaceBox width="stretch" inline={true}/><LinkButton text={MSG.TEAM.CHANGE_LOGO} onClick={() => onChangePhotoClick('dark')}/></div>
          </TWLogoGrid>
          <SpaceBox height={20}/>
          <Button if={summaryPayment && isOwner} fill={true} caption={MSG.TEAM.PURCHASE_LICENSES} onClick={() => askSetTeamPurchaseLicenses({details: {item_name: PRODUCTS.SLOT, quantity: size}, setSize, payLicenses: askSetPurchaseLicenses, size, isPaymentMethod: !!paymentMethod})} kind={EButtonKind.OUTLINED_PRIMARY} />
          <SpaceBox height={10}/>
        </TWLogoContainer>
        
      </Panel>
    </TWTeamSettings>
  );
});

interface IRow {
  caption?: string;
  captionWidth?: number;
  actionWidth?: number | string;
  captionContent?: React.ReactNode;
  children: React.ReactNode;
  actionContent: React.ReactNode;
  if?: boolean | (() => boolean);
  height?: number;
  isUser?: boolean;
  mobile?: boolean;
  isDomain?: boolean;
}
const Row = (props: IRow) => {
  const { caption, captionContent, captionWidth=100, isUser=false, isDomain=false, height, mobile, actionWidth="auto", children, actionContent, if:_if=true } = props;
  const CaptionProps = captionContent ? null : {
    ...extractTextProps(caption)
  };
  if (!ifCond(_if)) return null;
  return (<TWRow captionWidth={captionWidth} isDomain={isDomain} height={height} isUser={isUser} mobile={mobile} actionWidth={actionWidth}>
    <TWCaptionCol isUser={isUser}>{captionContent ? captionContent :
      <TextBox color={ETextColor.SECONDARY} {...CaptionProps}/>
    }</TWCaptionCol>
    <TWValueCol isDomain={isDomain}>{children}</TWValueCol>
    <TWActionCol isUser={isUser}>{actionContent}</TWActionCol>
  </TWRow>);
};
const TWHeader = styled("div")(({ theme, darkMode, disabled }) => [ tw`flex text-center justify-center`, css`` ]);
const TWTeamSettings = styled("div")(({ theme, darkMode, disabled }) => [ tw`w-full h-full`, css`` ]);
const TWIPLimit = styled("div")(({ theme, darkMode, disabled }) => [ tw`mobile:col-start-1 mobile:mt-5p mobile:row-start-2 mobile:text-left mobile:justify-self-start`, css`` ]);
const TWRow = styled("section")(({captionWidth, actionWidth, height, mobile, isUser, isDomain}) => [
  tw`grid relative w-full items-center content-center col-gap-15p`,
  height && mobile ? css`height: ${height}px; ` : css`height: 50px; `,
  !isUser && (mobile ? css`grid-template-columns: auto 1fr;` : css`grid-template-columns: ${captionWidth}px 1fr ${cssSize(actionWidth)};`),
  isDomain && (mobile ? css`grid-template-columns: ${captionWidth}px 1fr ${cssSize(actionWidth)};` : css`grid-template-columns: ${captionWidth}px 1fr ${cssSize(actionWidth)};`),
  isUser && (mobile ? css`grid-template-columns: ${captionWidth}px 1fr ${cssSize(actionWidth)};` : css`grid-template-columns: ${captionWidth}px 1fr ${cssSize(actionWidth)}; `),
]);
const TWCaptionCol = styled("article")(({isUser}) => [
  tw`inline-grid relative h-full`,
  isUser && tw`mobile:items-start mobile:content-start`,
  css`justify-self: start;` ]);
const TWValueCol = styled("article")(({isDomain}) => [
  tw`inline-grid relative h-full justify-start grid-flow-col items-center content-center`,
  tw`mobile:grid-flow-row`,
  isDomain && tw`mobile:col-start-1 mobile:row-start-2 mobile:mt-5p`,
  css`justify-self: start;` ]);
const TWActionCol = styled("article")(({isUser}) => [
  tw`inline-grid relative h-full text-right justify-end grid-flow-col items-center content-center`,
  isUser && tw`mobile:items-end mobile:content-end`,
  !isUser && tw`mobile:row-start-1 mobile:col-start-2`,
  css`justify-self: end;`

]);
const TWRowActions = styled("div")(() => [ tw`inline-grid grid-flow-col col-gap-20p items-center content-center`,
  css`
    grid-template-columns: auto;
    grid-auto-columns: auto;
  `
]);
const TWAvatar = styled("img")(() => [ tw`inline-block relative w-40p h-40p` ]);
const TWDeleteButton = styled("div")(() => [ tw`pr-15p pl-5p cursor-pointer`,
  css`
    &:not(:hover)>.button-member-delete-hover {
      display: none !important;
    }
    &:hover>.button-member-delete-normal {
      display: none !important;
    }
  `
]);
const TWLogoGrid = styled("div")(({ theme, darkMode, disabled, mobile }) => [
  tw`grid grid-flow-col col-gap-50p row-gap-10p`,
  tw`mobile:grid-flow-row`,
  mobile ?
    css`
    grid-template-columns: 100%;
    grid-template-rows: 70px auto;
  `
    : css`
    grid-template-columns: 209px 209px;
    grid-template-rows: 72px auto;
  `
]);
const TWLogoContainer = styled("div")(({ theme, darkMode, disabled }) => [
  tw`mobile:text-center`,
  css`
  
  `
]);
const TWLogo = styled("div")(({ darkMode }) => [
  tw`grid items-center content-center text-center rounded-xs border border-solid py-5p`,
  darkMode ? tw`border-black bg-black` : tw`border-gray-350`,
  css`justify-items: center;`
]);
