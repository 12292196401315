import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {EScrollDir, Scrollable} from 'components';
import {ifCond} from 'utils';

export interface IMain extends TThemed {
  scroll?: EScrollDir;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Main = React.forwardRef((props: IMain, ref) => {
  const { children, scroll=EScrollDir.BOTH, darkMode=false, zoom, demo=false, if:_if=true, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWMain zoom={zoom} ref={ref} darkMode={darkMode} {...rest}>
      {children}
    </TWMain>
  );
});

const TWMain = styled("main")(({ theme, zoom, darkMode, disabled }) => [
  tw`relative pt-70p w-full h-screen`,
  tw`mobile:pt-50p`,
  zoom && css`
    display: flex !important;
    margin: 0 auto;
    align-items: center !important;
  `
]);
