import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line, Scrollable } from 'components';
import { cssSize, ifCond, str } from 'utils';
import {ICardTag} from 'types';

export interface ITagList extends TThemed {
  items?: ICardTag[];
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const TagList = React.forwardRef((props: ITagList, ref) => {
  const { items=[], raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWTagList ref={ref} darkMode={darkMode} {...rest}>
      <Scrollable>
        {}
      </Scrollable>
    </TWTagList>
  );
});

const TWTagList = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
