import React from 'react';
import {ifCond} from 'utils';

export interface IIf extends TThemed {
  if?: boolean | (() => boolean);
  else?: React.ReactNode;
  elseWhen?: boolean | (() => boolean);
  children?: React.ReactNode;
//  [prop: string]: any;
}

export const If = (props: IIf) => {
  const { children, else:_else=null, elseWhen=true, if:_if=true } = props;
  if ( !ifCond(_if) && ifCond(elseWhen)) return <>{_else}</>;
  return (<>
    {children}
  </>);
};