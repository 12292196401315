import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {TextBox, SpaceBox, Line, Profile, TeamSettings, ENoFileReason, NoFileView} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {useStoreActions, useStoreState} from "../../../store";

export interface ITeamPage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const ProfileProps = {
  username: "User name", email: "email@joxi.ru", avatar: "", passwordLength: 8,
  language: {unique: "english", name: "English"}, isLoggedIn: true,
  plan: {unique: "free", name: "Free"},
  min: 0, quota_total: 1024*1024*1024, quota_usage: 856*1024*1024, count_item: 2318,
};

export interface IMembers {
  avatar: string,
  email: string,
  neededPayment: {amount: string, currency: string} | null,
  role: {unique: string, name: string},
  username: string,
  invitation: boolean,
}

export interface ITeamSettingsProps {
  teamOnly?: boolean,
  ipLimit?: { from: string, to: string, limited: boolean },
  summaryPayment?: { amount: number, currency: string },
  licensesAllowed?: number,
  members: IMembers[],
  domain?: string,
  logo?: {dark: string, light: string}
}


export const TeamPage = React.forwardRef((props: ITeamPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const team = useStoreState(state => state.team)
  const MSG = useStoreState(s=>s.MSG);
  // const co = {
  //   domain: null
  //   invites: []
  //   0: {target_email: "kri-kri_1994@mail.ru", user: {…}}
  // 1: {target_email: "anton-tretyakov@smartigy.ru", user: null}
  // 2: {target_email: "anton23490@gmail.com", user: {…}}
  // length: 3
  // __proto__: Array(0)
  // ip_limit: {enable: false, from: null, to: null}
  // logo: {light_logo: null, dark_logo: null}
  // members: Array(1)
  // 0: {username: "ecspertiza", email: "ecspertiza@yandex.ru", first_name: "", last_name: "", team_admin: true, …}
  // length: 1
  // __proto__: Array(0)
  // owner: {username: "ecspertiza", email: "ecspertiza@yandex.ru"}
  // }
  
  const inviteUser = useStoreActions(a=>a.inviteUser);
  const cancelInvite = useStoreActions(a=>a.cancelInvite);
  const promoteMember = useStoreActions(a=>a.promoteMember);
  const demoteMember = useStoreActions(a=>a.demoteMember);
  const expelMember = useStoreActions(a=>a.expelMember);
  const domainChange = useStoreActions(a=>a.domainChange);
  const extendTeam = useStoreActions(a=>a.extendTeam);
  const leaveTeam = useStoreActions(a=>a.leaveTeam);
  const setTeamIp = useStoreActions(a=>a.setTeamIp);
  const uploadLogo = useStoreActions(a=>a.uploadLogo);
  
  const profile = useStoreState(state => state.profile)
  const logoLoad = useStoreState(state => state.logoLoad)
  
  
  const sortMembers = (users) => {
    const unpaid = users.filter((user) => user.neededPayment)
    const invited = users.filter((user) => user.invitation && !user.neededPayment)
    const members = users.filter((user) => (user.role.unique === 'member' || user.role.unique === 'admin') && !user.invitation)
    const owner = users.filter((user) => user.username === team.owner.username)
    const sortArr = [...unpaid, ...invited, ...members, ...owner]
    return sortArr
  }
  
  const getSummaryPayment = (users: IMembers[]) => {
    let summary = 0
    users.map((user) => {
      if (user.neededPayment) summary += +user.neededPayment.amount
    })
    return summary ? {amount: summary, currency: 'USD'} : null
  }
  
  const membersList: any = team ?
    sortMembers([
      ...team.invites.map((invited) =>
        ({avatar: invited.user ? invited.user.avatar : '',
          email: invited.user ? invited.user.email : invited.target_email,
          neededPayment: !invited.user ? {amount: "5", currency: "USD"} : null,
          username: invited.user ? invited.user.username : '',
          role: invited.user ? (invited.user.team_admin ? {unique: "admin", name: "Admin"} : {unique: "member", name: "Member"}) : {unique: "guest", name: "Guest"},
          name: (invited.user && invited.user.first_name && invited.user.last_name) ? `${invited.user.first_name} ${invited.user.last_name}` : '',
          invitation: invited.target_email })),
      ...team.members.map((member) =>
        ({avatar: member.avatar,
          email: member.email,
          role: !(team.owner.username === member.username) ? member.team_admin ? {unique: "admin", name: "Admin"} : {unique: "member", name: "Member"} : {unique: "owner", name: "Owner"},
          name: (member.first_name && member.last_name) ? `${member.first_name} ${member.last_name}` : '',
          username: member.username,
          invitation: false,
        })),
    ])
    : []
  
  
  const TeamSettingsProps: ITeamSettingsProps = {
    teamOnly: false,
    ipLimit: team ? { from: team.ip_limit.from, to: team.ip_limit.to, limited: team.ip_limit.enable } : { from: "", to: "", limited: false },
    summaryPayment: getSummaryPayment(membersList),
    licensesAllowed: team ? team.size : 0,
    members: membersList ,
    domain: team ? team.domain : "",
    logo: team ? {dark: team.logo.dark_logo, light: team.logo.light_logo}  : {dark: "", light: ""},
  };
  
  if (!(profile && profile.plan.unique === "team")) return <NoFileView reason={ENoFileReason.NOT_FOUND} darkMode={darkMode}/>

  if ( !ifCond(_if) ) return null;
  return (
    <TWTeamPage ref={ref} darkMode={darkMode} {...rest}>
      <TeamSettings teamSettings={TeamSettingsProps}
                    inviteUser={inviteUser}
                    cancelInvite={cancelInvite}
                    promoteMember={promoteMember}
                    demoteMember={demoteMember}
                    expelMember={expelMember}
                    domainChange={domainChange}
                    profile={profile}
                    leaveTeam={leaveTeam}
                    extendTeam={extendTeam}
                    setTeamIp={setTeamIp}
                    uploadLogo={uploadLogo}
                    logoLoad={logoLoad}
      />
      <TWCopyright>
        {MSG.COPYRIGHT}
      </TWCopyright>
    </TWTeamPage>
  );
});

const TWTeamPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative w-full flex flex-col items-center justify-center`,
  tw`tablet:bg-white tablet:mt-0`,
  tw`mobile:bg-white mobile:mt-0`,
  css`
    margin-top: 20px;
    min-height: calc(100vh - 90px);
`
]);

const TWCopyright = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  margin: 20px 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #5f6c76;
`
]);
