import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {Calendar, EPanelCaptionKind, EPanelWidth, Line, Panel, SpaceBox} from 'components';
import {cssSize, ifCond, dayjs} from 'utils';
import {EIcon, MSG} from 'const';
import {useStoreState} from "../../../store";

export interface ICalendarPanel extends TThemed {
  selectedRange?: [string, string];
  startDate?: string;
  endDate?: string;
  onRangeChange?: Function;
  onClose?: Function;
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const CalendarPanel = React.forwardRef((props: ICalendarPanel, ref) => {
  const { startDate, endDate, disabledRange, onRangeChange, onClose, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const locale = useStoreState(s=>s.language);
  const initialStartDate = startDate ? dayjs(startDate) : undefined;
  const initialEndDate = endDate ? dayjs(endDate) : undefined;
  const [dates, setDates] = React.useState([startDate, endDate]);
  const handleRangeChange = React.useCallback((_startDate, _endDate) => {
    if (_startDate && _endDate && onRangeChange instanceof Function) {
      const state = [dayjs(_startDate).format("YYYY-MM-DD"), dayjs(_endDate).format("YYYY-MM-DD")];
      const res = onRangeChange(...state);
      if (res!==false) setDates(state);
      if (onClose instanceof Function) onClose();
    }
  }, [onRangeChange]);
  React.useLayoutEffect(() => {
    if (dates[0]!==startDate || dates[1]!==endDate) setDates([startDate, endDate]);
  }, [setDates, startDate, endDate, dates]);
  React.useLayoutEffect(() => {
    if (typeof window!=="undefined") window.dispatchEvent(new Event('resize'));
  }, []);
  if ( !ifCond(_if) ) return null;
  return (
    <TWCalendarPanel ref={ref} darkMode={darkMode} {...rest}>
      <Panel icon={EIcon.Calendar} caption={MSG.CHOOSE_PERIOD} captionKind={EPanelCaptionKind.SM} onClose={onClose} width={EPanelWidth.AUTO} noShadow={true}>
        <SpaceBox height={15}/>
        <Line />
        <Calendar
          locale={locale}
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          disabledRange={disabledRange}
          onChange={handleRangeChange}
        />
      </Panel>
    </TWCalendarPanel>
  );
});

const TWCalendarPanel = styled("div")(({ theme, darkMode, disabled, top, left, right, bottom }) => [
  tw`z-50 shadow-menu-light rounded-md`,
  css`
    ${cssSize(top, "top")}
    ${cssSize(left, "left")}
    ${cssSize(right, "right")}
    ${cssSize(bottom, "bottom")}
  `
]);
