import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  Buttons, EButtonKind,
  ECardProtectType,
  EditBox,
  EEditInput,
  EEditKind,
  EIcon,
  EPanelCaptionKind,
  EPanelWidth, Line,
  Panel,
  SpaceBox
} from 'components';
import {FIELD} from 'const';
import {callIfExists, ifCond} from 'utils';
import {IFieldErrors} from 'types';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydownModal} from "../../../hooks/useKeydownModal";

export interface IUnblockSlug extends TThemed {
  slugs?: string[] | string;
  type?: EUnblockSlugType;
  onSubmit?: Function;
  onClose?: Function;
  errors?: IFieldErrors;
  onClearError?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export enum EUnblockSlugType {
  UNLOCK_FILE,
}

export const UnlockFile = React.forwardRef((props: IUnblockSlug, ref) => {
  const { slugs=[], errors={}, type=EUnblockSlugType.UNLOCK_FILE, onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const [values, setValues] = React.useState({});
  const handleClose = React.useCallback(async (e) => {
    callIfExists(onClose, e);
  }, [onClose]);
  const handleSubmit = React.useCallback(async (e) => {
    callIfExists(onSubmit, e, { [FIELD.PWD]: values[FIELD.PWD] });
  }, [onSubmit, values]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
  }, [setValues, values]);
  const Props = {
    [EUnblockSlugType.UNLOCK_FILE]: {
      caption: MSG.ACTION.UNBLOCK_SCREEN,
      pwd: MSG.ACTION.PROTECT_ENTER_A_PASSWORD,
      button: {
        caption: MSG.ACTIONS.UNBLOCK_SCREEN,
        onClick: handleSubmit,
        padding: 20,
        width: 95
      },
    },
  };
  useKeydownModal(handleSubmit, handleClose, handleChange, {values});

  if ( !ifCond(_if) ) return null;
  return (
    <TWUnblockSlug ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={Props[type].caption} width={bp.mobile ? EPanelWidth.MOB : EPanelWidth.SM} captionKind={EPanelCaptionKind.SM} icon={EIcon.Lock} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <EditBox caption={Props[type].pwd} kind={EEditKind.SM} errors={errors[FIELD.PWD]} autofocus={true} onChange={(e) => handleChange(e, FIELD.PWD)}
                 input={EEditInput.PWD} showPwdBtn={true} fill={true}
        />
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.UNBLOCK_SCREEN} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_SECONDARY} onClick={handleClose}/>
        </Buttons>
      </Panel>
    </TWUnblockSlug>
  );
});

const TWUnblockSlug = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);