import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {ifCond} from 'utils';

export interface IModal extends TThemed {
  onClose?: Function;
  closeOnMisclick?: boolean;//false
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Modal = React.forwardRef((props: IModal, ref) => {
  const { children, onClose, closeOnMisclick, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [animated, setAnimated] = React.useState(false);
  const hiddenInitial = !ifCond(_if);
  const [hidden, setHidden] = React.useState(hiddenInitial);
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) {

      onClose(e);
    }
  }, [onClose, setHidden, hiddenInitial]);

  const prevHidden = React.useRef(hidden);
  const modal = React.useRef(null);
  
  React.useEffect(() => {
    if (prevHidden.current === hiddenInitial) {
      if (animated) {
      } else {
      
      }
    } else {//hidden changed
      if (prevHidden.current === true) {
        //was hidden, showing
        prevHidden.current = !prevHidden.current;
        setHidden(hiddenInitial);
        setTimeout(() => setAnimated(true), 50);

      } else {
        //was shown, hiding
        prevHidden.current = !prevHidden.current;
        setAnimated(false);
        setTimeout(() => setHidden(hiddenInitial), 200);
      }
    }
  }, [animated, setAnimated, hiddenInitial]);
  if ( hidden ) return null;
  return (
    <TWModal ref={ref} darkMode={darkMode} {...rest}>
      <TWModalBackground onClick={onClose}/>
      <TWAnimated animated={animated}>
        {children}
      </TWAnimated>
    </TWModal>
  );
});




const TWModal = styled("div")(() => [
  tw`fixed bg-twilight left-0 top-0 z-50 w-full h-full`,
  css`
    
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    min-height: 100vh;
    padding: 50px 0;
    overflow-y: auto;
    text-align: center;
    
    &::before {
      content: "";
      display: inline-block;
      font-size: 0;
      height: 100%;
      vertical-align: middle;
      width: 0;
      box-sizing: border-box;
    }
  `
]);

const TWAnimated = styled("div")(({ theme, darkMode, animated }) => [
  tw`z-100`,
  tw`mobile:w-full`,
  css`
  display:inline-block;
  vertical-align:middle;
  text-align: left;
`
]);

const TWModalBackground = styled("div")(() => [
  tw`absolute left-0 top-0 w-full`,
  css`
    min-height: 100%;
    //min-height: 100vh;
    height: auto;
    overflow: hidden;
  `
]);
