import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { ETextAlign } from 'components';
import LogoLight from 'assets/logo.png';
import LogoDark from 'assets/logo-dark.png';
import {cssSize, ifCond} from 'utils';

export interface ILogo extends TThemed {
  src?: string;
  top?: string | number;
  bottom?: string | number;
  left?: string | number;
  right?: string | number;
  size?: ELogoSize | number;
  absolute?: boolean;
  align?: ETextAlign;
  alt?: string;
  onClick?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum ELogoSize {
  NORMAL = 60, SMALL = 50
}

export const Logo = React.memo((props: ILogo) => {
  const { src, onClick, darkMode=false, size=ELogoSize.NORMAL, align, alt, if:_if=true, demo=false, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWLogo clickable={!!onClick} onClick={onClick} align={align} alt={alt} height={size+"px"} size={size} src={src || (darkMode ? LogoDark : LogoLight)} {...rest}/>
  );
}, (prev, next) => (
  prev.src === next.src &&
  ifCond(prev.if) === ifCond(next.if) &&
  prev.onClick===next.onClick &&
  prev.size===next.size
));

const TWLogo = styled("div")(({ darkMode, src, size, clickable, top, bottom, right, left, absolute }) => [
  tw`inline-block absolute mr-20p mobile:mr-10p`,
  !absolute && tw`relative`,
  clickable && tw`cursor-pointer`,
  css`
    background-image: url('${src}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: ${size}px;
    width: ${77}px;
    ${cssSize(top, "top")}
    ${cssSize(bottom, "bottom")}
    ${cssSize(left, "left")}
    ${cssSize(right, "right")}
  `
]);