import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  LinkButton, EPanelWidth
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {FIELD, URLS} from 'const';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface ITeamCustomDomain extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const TeamCustomDomain = React.forwardRef((props: ITeamCustomDomain, ref) => {
  const { onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const [errors, setErrors] = React.useState([])
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const [values, setValues] = React.useState({[FIELD.CUSTOM_DOMAIN]: ''});
  const handleChange = React.useCallback((e, fld) => {
    setErrors([])
    const value = e.target.value;
    setValues({...values, [fld]: value});
  }, [setValues, values]);
  const handleSubmit = React.useCallback((e) => {
    if (values[FIELD.CUSTOM_DOMAIN]) {
      if (onSubmit instanceof Function) onSubmit(e, values);
    } else {
      setErrors(['Domain name is required'])
    }
    
  }, [onSubmit, values]);

  useKeydown(handleSubmit, handleClose, values);

  if ( !ifCond(_if) ) return null;
  return (
    <TWTeamCustomDomain ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.ACTION.CUSTOM_DOMAIN_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={10} />
        <TextBox text={MSG.ACTION.CUSTOM_DOMAIN_HINT} />
        <SpaceBox height={20} />
        <EditBox caption={MSG.ACTION.CUSTOM_DOMAIN_ENTER} errors={errors} onChange={(e) => handleChange(e, FIELD.CUSTOM_DOMAIN)} onBlur={(e) => handleChange(e, FIELD.CUSTOM_DOMAIN)} fill={true}/>
        <SpaceBox height={20} />
        <TextBox text={MSG.ACTION.CUSTOM_DOMAIN_DESC1} />
        <TextBox text={MSG.ACTION.CUSTOM_DOMAIN_DESC2} />
        <SpaceBox height={20} />
        <LinkButton text={MSG.ACTION.CUSTOM_DOMAIN_INSTRUCTIONS} href={URLS.CUSTOM_DOMAIN_INSTRUCTIONS} />
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.SAVE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWTeamCustomDomain>
  );
});

const TWTeamCustomDomain = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
