import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  Button,
  EAlign,
  EButtonKind,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight,
  LinkButton,
  TextBox
} from 'components';
import {OnePixel} from 'const';
import {ifCond} from 'utils';
import {useStoreState} from "../../../store";

export interface INoFileView extends TThemed {
  alt?: string;
  reason?: string;
  onAction?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export enum ENoFileReason {
  NO_FILE, NO_RESULT, FORBIDDEN, NOT_FOUND
}

export const NoFileView = React.forwardRef((props: INoFileView, ref) => {
  const MSG = useStoreState(s=>s.MSG);
  const { alt="", reason, if:_if=true, darkMode=true, demo=false, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (<Align>
    <div>
      <TWNoFileView ref={ref} darkMode={darkMode} {...rest}>
        <TWImg src={OnePixel} alt={alt} />
      </TWNoFileView>
      {({
        [ENoFileReason.NO_FILE]: <NoCard darkMode={darkMode} MSG={MSG}/>,
        [ENoFileReason.NO_RESULT]: <NoResults darkMode={darkMode} MSG={MSG}/>,
        [ENoFileReason.FORBIDDEN]: <NoRights darkMode={darkMode} MSG={MSG}/>,
        [ENoFileReason.NOT_FOUND]: <NotFound darkMode={darkMode} MSG={MSG}/>,



      })[reason]}
    </div>
  </Align>);
});

const Oops = ({darkMode, text}) => (
  
  <TWOops>
    <TextBox text="oops..." size={ETextSize.XXLARGER} align={ETextAlign.CENTER} weight={ETextWeight.SEMIBOLD} color={ETextColor.PRIMARY} darkMode={darkMode} fillX={true}/>
    <TextBox text={text} size={ETextSize.LARGE} color={ETextColor.PRIMARY} darkMode={darkMode} fillX={true}/>
  </TWOops>
);
const NoRights = ({darkMode=false, MSG}) => (<Oops text={MSG.PAGE_ERROR.ONLY_FOR_TEAM} darkMode={darkMode}/>);
const NoResults = ({darkMode=false, MSG}) => (<Oops text={MSG.PAGE_ERROR.NO_SCREENSHOTS} darkMode={darkMode}/>);
const NotFound = ({darkMode=false, MSG}) => (<Oops text={MSG.PAGE_ERROR.LOST_PAGE} darkMode={darkMode}/>);
const NoCard = ({darkMode=false, MSG}) => (
  <TWNoCard>
    <TextBox text={<>{["You have no screenshots yet.", <br key={1}/>, `${MSG.PAGE_ERROR.DOWNLOAD} ${MSG.BRAND} ${MSG.PAGE_ERROR.FOR_COMPUTER}.`]}</>} size={ETextSize.LARGE} color={ETextColor.PRIMARY} align={ETextAlign.CENTER} darkMode={darkMode}/>
    <Button kind={EButtonKind.PRIMARY} caption={`${MSG.PAGE_ERROR.DOWNLOAD} ${MSG.BRAND}`}/>
    <div style={{height:106}}/>
    <TextBox fillX={true} text={[
      MSG.PAGE_ERROR.AVAILABLE_FOR, ...(
        [{text:"Windows"},{text:"MacOS"},{text:"Linux"},{text:"Chrome"},{text:"Opera",delim:" and "},{text:"FireFox",delim:"."}]
        .map((lnk, i, a) => (
          <> <LinkButton key={i} text={lnk.text} href="https://google.com"/>{lnk.delim || ", "} </>
        ))
      )
    ]}/>
  </TWNoCard>
);

const TWNoCard = styled("div")(({ theme, disabled, src }) => [
  tw`grid grid-flow-row mt-30p row-gap-30p justify-center`,
  css`
    justify-items: center;
  `
]);
const TWOops = styled("div")(({ theme, disabled, src }) => [
  tw`grid grid-flow-row mt-24p row-gap-10p justify-center`,
  css`
    justify-items: center;
  `
]);
const TWImg = styled("img")(({ theme, disabled }) => [
  tw`w-full h-full`,
  css``
]);
const TWNoFileView = styled("div")(({ darkMode, src }) => [
  tw`overflow-hidden p-0 mx-auto`,
  darkMode ? tw`bg-accent-450` : tw`bg-gray-290`,
  //darkMode ? tw`shadow-panel-dark` : tw`shadow-panel-light`,
  css`
    width: 370px;
    height: 344px;
  `,
  tw`rounded-xl`
]);
