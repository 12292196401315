import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {EBreakpoint, ETextAlign, ETextColor, ETextSize, ETextWeight, ITextBox, TextBox, If} from 'components';
import {extractTextProps, ifCond} from 'utils';

export interface ITagLabel extends TThemed {
  id?: string;
  disabled?: boolean;
  color?: ETagColor;
  type?: ETagType;
  text?: string | ITextBox;
  hint?: string;
  align?: ETextAlign;
  minmax?: [number, number];
  fill?: boolean;
  icon?: React.ReactNode;
  onClick?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum ETagColor {
  GHOST = "ghost", TWILIGHT = "twilight", CORAL = "coral", FOX = "fox", GOLD = "gold",
  FOREST = "forest", JADE = "jade", CYAN = "cyan", ROYAL = "royal", VIOLET = "violet",
  ORCHID = "orchid", CRIMSON = "crimson", GRASS = "grass", ASHEN = "ashen",
  SKY = "sky", SUCCESS = "success", ERROR = "error", INFO = "info", TRANSPARENT="transparent"
}
export enum ETagType {
  FLAG = "flag", LABEL = "label", CHIP = "chip", SECTION = "section", SWITCH = "switch", BANNER = "banner"
}

export const TagLabel = React.forwardRef((props: ITagLabel, ref) => {
  const { disabled=false, if:_if=true, text="", hint, fill=false, align=ETextAlign.LEFT, minmax=[0,0], color=ETagColor.ASHEN, type=ETagType.LABEL, icon, darkMode=false, demo=false, customHeight=0, ...rest } = props;
  const textProps = {
    ...(type===ETagType.FLAG ? { size: ETextSize.SMALL, weight: ETextWeight.BOLD, align } : {}),
    ...(type===ETagType.LABEL ? { size: ETextSize.SMALL, weight: ETextWeight.SEMIBOLD, align } : {}),
    ...(type===ETagType.CHIP ? { size: ETextSize.SMALL, weight: ETextWeight.REGULAR, align } : {}),
    ...(type===ETagType.SECTION ? { size: ETextSize.NORMAL, weight: ETextWeight.REGULAR, align } : {}),
    ...(type===ETagType.SWITCH ? { size: ETextSize.SMALL, weight: ETextWeight.SEMIBOLD, align } : {}),
    ...(type===ETagType.BANNER ? { size: ETextSize.LARGE, weight: ETextWeight.SEMIBOLD, align: ETextAlign.CENTER } : {}),
    ...(color === ETagColor.GHOST ||
        color === ETagColor.TRANSPARENT ||
        color === ETagColor.INFO ? { color: ETextColor.SECONDARY } : { color: ETextColor.WHITE }),
    block: true,
    ...extractTextProps(text)
  };
  if ( !ifCond(_if) ) return null;
  return (
    <TWTagLabel ref={ref} disabled={disabled} type={type} color={color} minmax={minmax} title={hint} customHeight={customHeight} {...rest}>
      <TextBox {...textProps} ellipsis={true} hideBreakpoint={ETagType.CHIP===type ? EBreakpoint.MOBILE : null}/>
      {icon}
    </TWTagLabel>
  );
});//extractTextProps

const TWTagLabel = styled("label")(({ theme, color, type, fill, minmax:[minWidth, maxWidth], bordered, customHeight }) => [
    css`
      background-color: ${color in theme.colors ? theme.colors[color] : color};
      grid-auto-columns: auto;
      cursor: pointer;
      transition: padding-left .2s, padding-right .2s;
      ${minWidth ? `min-width: ${minWidth}px;` : ''}
      ${maxWidth ? `max-width: ${maxWidth}px;
                    text-overflow: ellipsis;
      ` : ''}
    `,
    tw`grid grid-cols-auto grid-flow-col align-top items-center`,
    tw`mobile:pl-10p pr-10p `,
    fill ? tw`w-full` : tw`w-fit`,
    type===ETagType.FLAG && tw`rounded-sm h-20p px-10p`,// py-3p
    type===ETagType.LABEL && tw`rounded-md h-20p px-10p`,// py-3p
    type===ETagType.CHIP && tw`rounded-md h-30p pl-15p pr-10p`,
    type===ETagType.CHIP && bordered && tw`border border-solid border-gray-350 cursor-pointer`,
    // color===ETagColor.TRANSPARENT && tw`border border-solid border-gray-350`,
    type===ETagType.SECTION && tw`rounded-lg h-20p px-9p object-contain`,// py-1p
    type===ETagType.SECTION && css`
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    `,
    type===ETagType.SWITCH && tw`rounded-md h-30p px-14p`,// py-7p
    type===ETagType.BANNER && tw`rounded-xl h-70p px-30p w-full`,// py-25p
    customHeight && css`height: ${customHeight}px`,
  ]
);
