import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line } from 'components';
import { cssSize, ifCond, str } from 'utils';

export interface ILandingLayout extends TThemed {
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const LandingLayout = React.forwardRef((props: ILandingLayout, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, children, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWLandingLayout ref={ref} darkMode={darkMode} {...rest}>
      {children}
    </TWLandingLayout>
  );
});

const TWLandingLayout = styled("div")(({ theme, darkMode, disabled }) => [
  tw`w-full h-screen`,
  css``
]);
