import React from 'react';
import tw, { styled, css } from 'twin.macro';

export interface IRootLayout extends TThemed {
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  [prop: string]: any;
}

export const RootLayout = React.forwardRef((props: IRootLayout, ref) => {
  const { children, ...rest } = props;
  return (
    <TWRootLayout ref={ref} {...rest}>
      {children}
    </TWRootLayout>
  );
});

const TWRootLayout = styled("div")(({ theme }) => [
    tw`w-full h-full m-0 p-0`,
    css``
  ]
);
