import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  AscCloudpayments,
  Button,
  EButtonKind,
  EditBox,
  EEditInput,
  EEditKind,
  EPanelCaptionKind,
  EPanelWidth,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight, If,
  Line,
  Panel,
  Radio,
  RadioGroup,
  Slider,
  SpaceBox,
  TextBox
} from 'components';
import {IFieldErrors, IObject, IPlan} from 'types';
import {FIELD, PLAN, SETTINGS, PUBLIC_ID, PRODUCTS} from 'const';
import {collectFieldErrors, debounceHandler, declension, formatPrice, ifCond, str, throttle} from 'utils';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {createCryptogram} from "../../../utils/createCryptogram";
import {useKeydown} from "../../../hooks/useKeydown";


export interface IUpgradePlan extends TThemed {
  plan: IPlan;
  // errors?: IFieldErrors;
  onClose?: Function;
  onSubmit?: Function;
  onClearErrors?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

// const initialValues = {
//   [FIELD.CARD]: '4242 4242 4242 4242',
//   [FIELD.EXPIRES]: '12/30',
//   [FIELD.CVC]: '111',
//   [FIELD.HOLDER_NAME]: 'Ivan Ivanov',
// }

const initialValues = {
  [FIELD.CARD]: '',
  [FIELD.EXPIRES]: '',
  [FIELD.CVC]: '',
  [FIELD.HOLDER_NAME]: '',
}

export const UpgradePlan = React.forwardRef((props: IUpgradePlan, ref) => {
  const { onClose, onSubmit, plan, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  
  const errors = useStoreState(state => state.fieldErrors);
  const setMessages = useStoreActions(actions => actions.setMessages);
  const onClearError = useStoreActions(actions => actions.clearMessagesByField);
  
  const sendInvoice = useStoreActions(a=>a.sendInvoice);
  const bp = useMediaBreakpoints();
  const periods = ["yearly", "monthly"];
  const methods = ["card", "paypal"];
  const teamSizeMin = 5;
  const teamSizeMax = 100;
  
  const [period, setPeriod] = React.useState(periods[0]);
  const [method, setMethod] = React.useState(methods[0]);
  const [teamSize, setTeamSize] = React.useState(teamSizeMin);
  const paymentMethod = useStoreState(s=>s.paymentMethod);
  const handleSwitch = React.useCallback((val, selected, name) => {
    if (!!~name.search(/^billing-period-/) && period!==val) setPeriod(val);
    if (!!~name.search(/^billing-method-/) && method!==val) setMethod(val);
  },[period, setPeriod, method, setMethod]);
  // const handleChangeCard = React.useCallback((val, selected, name) => {
  //   setValues({...values, [name]: val})
  // },[values, setValues]);
  
  const handleChangeSize = React.useCallback((vals) => throttle(() => {
    if (vals && teamSize!==vals[0]) setTeamSize(vals[0]);
  }, 100)(),[teamSize, setTeamSize]);
  const handleClose = React.useCallback((e) => {
    onClearError('')
    if (onClose instanceof Function) onClose(e) }, [onClose]);
  const [values, setValues] = React.useState(initialValues);
  const handleSubmit = React.useCallback((e) => {
    
    if (!paymentMethod) {
      const validationErrors = {}
  
      if (values[FIELD.CARD].replace(/' '/g, '').length < 16) {
        validationErrors[FIELD.CARD] = MSG.ERROR.CARD.REQ
      }
  
      if (values[FIELD.EXPIRES].length < 5) {
        validationErrors[FIELD.EXPIRES] = MSG.ERROR.EXPIRES.REQ
      }
  
      if (values[FIELD.CVC].length < 3) {
        validationErrors[FIELD.CVC] = MSG.ERROR.CVC.REQ
      }
  
      if (!values[FIELD.HOLDER_NAME]) {
        validationErrors[FIELD.HOLDER_NAME] = MSG.ERROR.HOLDER_NAME.REQ
      }
  
      if (!Object.keys(validationErrors).length) {
        const cryptogram = createCryptogram({
          cardNumber: values[FIELD.CARD],
          expDateMonth:  values[FIELD.EXPIRES].split('/')[0],
          expDateYear:  values[FIELD.EXPIRES].split('/')[1],
          cvv:  values[FIELD.CVC],
          name:  values[FIELD.HOLDER_NAME]
        })
        if (!cryptogram.success && cryptogram?.messages?.cardNumber) {
          validationErrors[FIELD.CARD] = MSG.ERROR.CARD.INCORRECT
        }
        if (!cryptogram.success && (cryptogram?.messages?.expDateMonth || cryptogram?.messages?.expDateYear)) {
          validationErrors[FIELD.EXPIRES] = MSG.ERROR.EXPIRES.INCORRECT
        }
        if (!cryptogram.success && cryptogram?.messages?.cvv) {
          validationErrors[FIELD.CVC] = MSG.ERROR.CVC.INCORRECT
        }
        if (!cryptogram.success && cryptogram?.messages?.name) {
          validationErrors[FIELD.HOLDER_NAME] = MSG.ERROR.HOLDER_NAME.INCORRECT
        }
      }
      if (Object.keys(validationErrors).length) {
        setMessages(collectFieldErrors(validationErrors))
      } else {
        const cryptogram = createCryptogram({
          cardNumber: values[FIELD.CARD],
          expDateMonth:  values[FIELD.EXPIRES].split('/')[0],
          expDateYear:  values[FIELD.EXPIRES].split('/')[1],
          cvv:  values[FIELD.CVC],
          name:  values[FIELD.HOLDER_NAME]
        })
        const paymentData = {item_name: plan.unique === PLAN.PRO.unique ? PRODUCTS.PRO : PRODUCTS.TEAM, quantity: plan.unique === PLAN.PRO.unique ? 1 : 1, cryptogram: cryptogram.packet, cardholder_name: values[FIELD.HOLDER_NAME]}
        if (onSubmit instanceof Function) onSubmit(e, paymentData);
      }
    } else {
      const paymentData = {item_name: plan.unique === PLAN.PRO.unique ? PRODUCTS.PRO : PRODUCTS.TEAM, quantity: plan.unique === PLAN.PRO.unique ? 1 : 1}
      if (onSubmit instanceof Function) onSubmit(e, paymentData);
    }
  }, [onSubmit, values, setValues, paymentMethod]);
  
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
    if (onClearError instanceof Function) {
      onClearError(fld)
    };
  }, [values, setValues]);
  const isYearly = period===periods[0], isMonthly = !isYearly,
        isCard = methods[0] === method, isPayPal = !isCard;
  const isTeam = plan.unique===PLAN.TEAM.unique;
  const planParams = isTeam ? PLAN.TEAM : PLAN.PRO;
  const periodPrice = isYearly ? planParams.yearly : planParams.monthly;
  const maxPeriodPrice = periodPrice.amount * (isYearly ? 12 : 1);

  useKeydown(handleSubmit, handleClose);

  if ( !ifCond(_if) ) return null;
  return (
    <TWUpgradePlan ref={ref} darkMode={darkMode} {...rest}>
      
      <Panel caption={str(MSG.UPGRADE_PLAN.UPGRADE_TO_BRAND, planParams.name)} captionKind={EPanelCaptionKind.LGB} icon={planParams.icon} iconSize={planParams.size} iconOffsetY={planParams.offsetY} onClose={handleClose} width={bp.mobile ? 345 : EPanelWidth.SM}>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={30} />
        <TextBox text={MSG.UPGRADE_PLAN.CHOOSE_BILLING_CYCLE} size={ETextSize.LARGER}/>
        <SpaceBox height={30} />
        <RadioGroup name={MSG.UPGRADE_PLAN.BILLING_PERIOD + "-" + Date.now()} as={TWPeriod} value={period} onChange={handleSwitch}>
          <Radio value={periods[0]}><TextBox text={MSG.YEARLY}/></Radio>
          <Radio value={periods[1]}><TextBox text={MSG.MONTHLY}/></Radio>
        </RadioGroup>
        <SpaceBox height={30} />
        <TextBox text={`${ formatPrice( { ...periodPrice, amount: maxPeriodPrice }, true ) }`}
                 size={ETextSize.XXLARGER} weight={ETextWeight.SEMIBOLD}
        />
        <TextBox
          text={`${ isTeam ? " "+MSG.UPGRADE_PLAN.PER_USER : "" } / ${ isYearly ? MSG.YEAR : MSG.MONTH }`}
          size={ETextSize.XLARGER}
        />
        <TextBox if={ isYearly }
          text={`${ formatPrice({...periodPrice}, true) } ${ isTeam ? " "+MSG.UPGRADE_PLAN.PER_USER+' / ' : MSG.UPGRADE_PLAN.PER }  ${ MSG.MONTH }`}
          color={ETextColor.SECONDARY} inline={false} block={true}
        />
        <SpaceBox if={ isYearly } height={20} />
        <TextBox if={ isYearly }
                 text={str(MSG.UPGRADE_PLAN.YEARLY_BENEFIT,
                   formatPrice(planParams.benefit, true),
                   planParams.benefit.percent,
                   planParams.benefit.months
                 )}
                 color={ETextColor.SUCCESS}
        />
        <SpaceBox height={30} />
        <Line if={isTeam} />
        <SpaceBox if={isTeam} height={30} />
        <TextBox if={isTeam} text={MSG.UPGRADE_PLAN.CHOOSE_NUMBER_USERS} size={ETextSize.LARGER}/>
        <SpaceBox if={isTeam} height={20} />

        <Slider if={isTeam} min={teamSizeMin} max={teamSizeMax}
                step={1} value={teamSize} onChange={handleChangeSize}
        />

        <SpaceBox if={isTeam} height={16} />
        <TextBox if={isTeam} text={<>
          {`$${maxPeriodPrice} x `}
          <TextBox if={isTeam} text={`${teamSize} ${declension(teamSize, MSG.UPGRADE_PLAN.X_USERS)}`}
                   size={24}
                   // size={28}
                   weight={ETextWeight.SEMIBOLD}/>
          {` = $${ (Math.round(maxPeriodPrice * teamSize * 100 )*0.01).toFixed(2) } / ${ isYearly ? MSG.YEAR : MSG.MONTH }`}
        </>} size={ETextSize.LARGE}/>
        <SpaceBox if={isTeam} height={30} />
        <Line />
        <SpaceBox height={30} />
        <TextBox text={MSG.UPGRADE_PLAN.CHOOSE_PAYMENT_METHOD} size={ETextSize.LARGER}/>
        {/*<SpaceBox height={30} />*/}
        {/*<RadioGroup name={MSG.UPGRADE_PLAN.BILLING_METHOD + "-" + Date.now()} as={TWPeriod} value={method} onChange={handleChange}>*/}
        {/*  <Radio value={methods[0]}><TextBox text={MSG.CREDIT_CARD}/></Radio>*/}
        {/*  <Radio value={methods[1]}><TextBox text={MSG.PAYPAL}/></Radio>*/}
        {/*</RadioGroup>*/}
        
        
        <TextBox if={isPayPal} text={MSG.UPGRADE_PLAN.PAYPAL_NOTE}/>
        <If if={!paymentMethod}>
          <SpaceBox height={30} />
          <EditBox mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} if={isCard} caption={MSG.PAYMENT.CARD_NUMBER} kind={EEditKind.LG} input={EEditInput.TEXT} inline={false} fill={true} onChange={(e) => handleChange(e, FIELD.CARD)} errors={errors[FIELD.CARD]}/>
          <SpaceBox if={isCard} height={10} />
          <div tw="grid grid-flow-col col-gap-10p">
            <EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, '/', /\d/, /\d/,]} caption={MSG.PAYMENT.EXPIRES} kind={EEditKind.LG} input={EEditInput.TEXT} width={'auto'} onChange={(e) => handleChange(e, FIELD.EXPIRES)} errors={errors[FIELD.EXPIRES]}/>
            <EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, /\d/,]} caption={MSG.PAYMENT.CVC} kind={EEditKind.LG} input={EEditInput.TEXT} width={'auto'} onChange={(e) => handleChange(e, FIELD.CVC)} errors={errors[FIELD.CVC]}/>
            {/*<EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} caption={MSG.PAYMENT.ZIP} kind={EEditKind.LG} input={EEditInput.TEXT} width={!bp.mobile ? 97 : 87}*/}
            {/*         onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.ZIP)}, SETTINGS.inputDelay)}/>*/}
          </div>
          <SpaceBox if={isCard} height={10} />
          <EditBox if={isCard} caption={MSG.PAYMENT.HOLDER_NAME} placeholderChar={'\u2000'} kind={EEditKind.LG} input={EEditInput.TEXT} inline={false} fill={true} onChange={(e) => handleChange(e, FIELD.HOLDER_NAME)} errors={errors[FIELD.HOLDER_NAME]}/>
          
        </If>
        <SpaceBox height={20} />
        <Button kind={EButtonKind.PRIMARY} caption={MSG.UPGRADE_PLAN.UPGRADE_NOW} fill={true} onClick={handleSubmit}/>
      </Panel>
    </TWUpgradePlan>
  );
});

const TWUpgradePlan = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWPeriod = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid col-gap-10p items-center content-center w-auto h-18p`,
  css`grid-template-columns: repeat(2, 116px);`
]);
