import React from 'react';
import { Helmet } from 'react-helmet';

export interface IFont {
  href: string[] | string;
}

export const Fonts = (props: IFont) => {
  const { href } = props;
  const hrefs = [].concat(href || []);
  const rel = "stylesheet";

  if (!hrefs.length) {
    return null;
  } else {
    return <Helmet link={hrefs.map(href => ({rel, href}))}/>;
  }
};