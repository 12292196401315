import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {ifCond} from 'utils';

export interface IListBox extends TThemed {
  //unmargin?: number;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const ListBox = React.forwardRef((props: IListBox, ref) => {
  const { children, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWListBox ref={ref} darkMode={darkMode} {...rest}>
      <TWScroll>
        {children}
      </TWScroll>
    </TWListBox>
  );
});

export const ListItem = React.forwardRef((props: IListBox, ref) => {
  const { darkMode=false, demo=false, if:_if=true, ...rest } = props;
  if ( !ifCond(_if) ) return null;
  return (
    <TWListItem ref={ref} darkMode={darkMode} {...rest}/>
  );
});

const TWListItem = styled("section")(({ theme, darkMode, disabled }) => [
  tw`grid grid-flow-row grid-cols-1`,
  css``
]);

const TWListBox = styled("article")(({ theme, darkMode, disabled }) => [
  tw`w-full`,
  css``
]);

const TWScroll = styled("div")(({ theme, darkMode, dir }) => [
  tw`w-full h-full`,
  tw`overflow-x-auto`,
  css``
]);