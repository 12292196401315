import React from "react";
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  CalendarPanel,
  CheckBox,
  EAlign,
  EIcon,
  EIconSize,
  EJustify,
  ETextAlign,
  ETextSize,
  EVAlign,
  Icon,
  If,
  Line,
  SearchBox,
  SpaceBox,
  TagSwitch,
  TextBox,
  ETextColor, Portal, EButtonKind, Button,
} from 'components';
import {useMediaBreakpoints, usePubSub, useRect, useStoreActions, useStoreState, useWindowRect} from 'hooks';
import {FIELD, PLAN, PORTAL, PUBSUB, ROUTE, URL_PARAM} from "const";
import {cssSize, dayjs} from "utils";
import {useHistoryContextMenu} from ".";

interface IHistoryHeader extends TThemed {
  [prop: string]: any;
}

function useHistoryHeaderState() {
  const darkMode = useStoreState(state => state.darkMode);
  const profile = useStoreState(state => state.profile);
  const selectionMode = useStoreState(state => state.screenshots.selectionMode);
  const selected = useStoreState(state => state.screenshots.selected);
  const tagList = useStoreState(state => state.screenshots.tagList);
  const search = useStoreState(state => state.screenshots.search);
  const startDate = useStoreState(state => state.screenshots.start_date);
  const headerOpened = useStoreState(s=>s.screenshots.headerOpened);
  const endDate = useStoreState(state => state.screenshots.end_date);
  const tags = useStoreState(state => state.screenshots.tags);
  const shot = useStoreState(state => state.screenshots.shot);
  const video = useStoreState(state => state.screenshots.video);
  const file = useStoreState(state => state.screenshots.file);
  const info = useStoreState(state => state.screenshots.info);
  const hasFilter = useStoreState(state => state.screenshots.hasFilter);
  const calendarOpened = useStoreState(s=>s.screenshots.calendarOpened);
  return [darkMode, selectionMode, selected, tagList, search, startDate, endDate, tags, shot, video, file, info, hasFilter, calendarOpened, headerOpened, profile] as any;
}

function useHistoryHeaderActions() {
  const clearFilter = useStoreActions(actions => actions.screenshots.clearFilter);
  const addFilter = useStoreActions(actions => actions.screenshots.addFilter);
  const removeFilter = useStoreActions(actions => actions.screenshots.removeFilter);
  const setHeaderOpened = useStoreActions(actions => actions.screenshots.setHeaderOpened);
  const getHistory = useStoreActions(actions => actions.screenshots.getHistory);
  const setCalendarOpened = useStoreActions(a=>a.screenshots.setCalendarOpened);
  return [clearFilter, addFilter, removeFilter, setHeaderOpened, getHistory,setCalendarOpened] as any[];
}

export const HistoryHeader = (props: IHistoryHeader) => {
  const [darkMode, selectionMode, selected, tagList, search, startDate, endDate, tags, shot, video, file, info, hasFilter, calendarOpened, headerOpened, profile] = useHistoryHeaderState();
  
  const [clearFilter, addFilter, removeFilter, setHeaderOpened, getHistory, setCalendarOpened] = useHistoryHeaderActions();
  const getMenu = useHistoryContextMenu(true);
  const actions = getMenu(selected.map( slug => ({slug}) ));
  const hStartDate = info[FIELD.START_DATE];
  const hEndDate = info[FIELD.END_DATE];
  const bp = useMediaBreakpoints();
  const calRef = React.useRef();
  const calRect = useRect(calRef);
  const windowRect = useWindowRect();
  const handleOpen = React.useCallback(() => {setHeaderOpened(true)}, [setHeaderOpened]);
  const handleClose = React.useCallback(() => {setHeaderOpened(false)}, [setHeaderOpened]);
  const routerLocation = useStoreState(s=>s.router.location);
  const push = useStoreActions(a=>a.router.push);
  const consumeUrlParams = useStoreActions(a=>a.consumeUrlParams);
  const matchParams = useStoreState(state => state.router.params);
  const handleCloseCalendar = React.useCallback(() => { setCalendarOpened(false); }, [setCalendarOpened]);
  const handleFilterChange = React.useCallback((e, filterName, filterValue, checkedFlag?) => {
    addFilter([filterName, filterValue, true]);
  }, [addFilter]);
  usePubSub<typeof handleFilterChange.arguments>(PUBSUB.HISTORY.FILTER_CHANGE, (topic, data) => handleFilterChange(data[0],data[1],data[2],data[3]));
    
  React.useEffect(() => {
    
    const createQuery = () => {
      let query = []
      
      if (shot) {
        query = [...query, `${URL_PARAM.SHOT}=true`]
      }
      if (video) {
        query = [...query, `${URL_PARAM.SCREENCAST}=true`]
      }
      if (file) {
        query = [...query, `${URL_PARAM.FILE}=true`]
      }
      if (search) {
        query = [...query, `${URL_PARAM.SEARCH}=${search}`]
      }
      if (startDate) {
        query = [...query, `${URL_PARAM.START_DATE}=${startDate}`]
      }
      if (endDate) {
        query = [...query, `${URL_PARAM.END_DATE}=${endDate}`]
      }
      if (tags.length) {
        query = [...query, `${URL_PARAM.TAGS}=${tags.join('&')}`]
      }
      if (!hasFilter) {
        return ''
      }
      return `${query.join('&')}`
      
    }
    
    createQuery() ?
      push({to: `/history${`?${createQuery()}`}`,})
      : consumeUrlParams({clearHash: true, params: []})
    
  }, [shot, video, file, search, tags, startDate, endDate, hasFilter])
  
  React.useEffect(() => {
    
    const path = routerLocation.search
    const shot = matchParams[URL_PARAM.SHOT]
    const screencast = matchParams[URL_PARAM.SCREENCAST]
    const file = matchParams[URL_PARAM.FILE]
    const startDate = matchParams[URL_PARAM.START_DATE]
    const endDate = matchParams[URL_PARAM.END_DATE]
    const tags = path.match(/tags/gm) ? path.split('tags=')[1].split('&') : []
    const search = matchParams[URL_PARAM.SEARCH]
    
    if (shot) {
      ((e) => handleFilterChange(e, "shot", true))()
    }
    if (screencast) {
      ((e) => handleFilterChange(e, "video", true))()
    }
    if (file) {
      ((e) => handleFilterChange(e, "file", true))()
    }
    if (startDate && endDate) {
      ((e) => handleFilterChange(e, "dates", [startDate, endDate]))()
    }
    if (tags.length) {
      tags.map((tag) => ((e) => handleFilterChange(e, "tag", decodeURI(tag)))())
    }
    if (search) {
      ((e) => handleFilterChange(e, "search", decodeURI(search)))()
    }
    
  }, [false])
  
  const MSG = useStoreState(s=>s.MSG);
  
  return selectionMode ? (
    <TWMassOps breakpoint={bp}>
      {actions.map((act, key) => (
        <TWMassOp key={key} onClick={act.onClick} darkMode={darkMode}>
          <Icon icon={act.icon} size={EIconSize.MD}/>
          <TextBox if={!bp.mobile} text={act.text} color={!!act.inactive ? ETextColor.SECONDARY : ETextColor.PRIMARY}/>
        </TWMassOp>
      ))}
    </TWMassOps>
    ):(
    <TWFilter id={PORTAL.FILTER}>
      <Align align={EAlign.TOP} justify={EJustify.CENTER}>
        <SearchBox
          caption={MSG.HISTORY.SEARCH}
          value={search}
          onClick={() => consumeUrlParams({clearHash: true, params: []})}
          onInput={(e) => handleFilterChange(e, "search", e.target.value)}
          onFocus={handleOpen}
          isHeaderOpened={headerOpened}
          // fill={!!bp.desktop ? true : !!bp.tablet ? "100%" : "100%"}
          fill={true}
          chips={hasFilter ? [['Reset filter', () => {
            clearFilter(true)
          }]] : []}
        />
          <TWDropDownHeader mobile={bp.mobile}>
            <If if={(!bp.mobile || headerOpened) && (profile?.plan?.unique === PLAN.PRO.unique || profile?.plan?.unique === PLAN.TEAM.unique)} else={<div></div>}>
            <div>
              <TWDateAndMediaFilter>
                <TWMedia>
                  <CheckBox text={bp.desktop ? MSG.HISTORY.SCREENSHOT : "Shot"} value={"shot"} onChange={handleFilterChange} checked={shot}/>
                  <CheckBox text={bp.desktop ? MSG.HISTORY.SCREENCAST : "Video"} value={"video"} onChange={handleFilterChange} checked={video}/>
                  <CheckBox text={bp.desktop ? MSG.HISTORY.FILE : "File"} value={"file"} onChange={handleFilterChange} checked={file}/>
                </TWMedia>
                <TWCalendar ref={calRef} onClick={() => (!calendarOpened) && setCalendarOpened(true)}>
                  <TWCalendarLabel>
                    <If if={!!startDate || !!endDate} else={<TextBox text={MSG.HISTORY.WHOLE_TIME} align={ETextAlign.RIGHT}/>}>
                      <TextBox text={[]
                        .concat(startDate ? dayjs(startDate).format(MSG.DATE.HISTORY.CALENDAR_LABEL) : [])
                        .concat(endDate ? dayjs(endDate).format(MSG.DATE.HISTORY.CALENDAR_LABEL) : [])
                        .join(" - ")
                      } align={ETextAlign.RIGHT}
                      />
                      <SpaceBox inline width={5}/>
                      <Icon icon={EIcon.CloseGray} align={EVAlign.BASE} size={10} onClick={(e) => {e.stopPropagation(); removeFilter("dates");}}/>
                    </If>
                  </TWCalendarLabel>
                  <Icon icon={EIcon.CalendarGray} align={EVAlign.BASE} size={EIconSize.MD}/>
                </TWCalendar>
              </TWDateAndMediaFilter>
              <If if={calendarOpened}>
                <TWDateFilter
                  top={calRect.top + calRect.height}
                  right={windowRect.width - calRect.left - calRect.width - 15}
                >
                  <CalendarPanel
                    onClose={handleCloseCalendar}
                    startDate={startDate}
                    endDate={endDate}
                    disabledRange={[hStartDate, hEndDate]}
                    isRange={true}
                    onRangeChange={(dateStart, dateEnd) => handleFilterChange({}, "dates", [dateStart, dateEnd])}
                  />
                </TWDateFilter>
              </If>
              <If if={tagList.length>0}>
                <Line darkMode={darkMode}/>
                <SpaceBox height={30} />
                <TWTagFilter>
                  {tagList.map((tag, key) => {
        
                    return (
                      <TWTag key={tag.id} title={tag.name}>
                        <TagSwitch color={tag.color} text={tag.name}
                                   value={tag.name}
                                   checked={!!~tags.findIndex(t=>t===tag.name)}
                                   onChange={(e, v, ch) => handleFilterChange(e, !!ch ? "tag" : "untag", tag.name, ch)}
                        />
                      </TWTag>
                    );
                  })}
                </TWTagFilter>
                <SpaceBox height={30} />
              </If>
            </div>
            </If>
            <If if={bp.mobile}>
              <TWMobileButtons>
                <SpaceBox width={'100%'} height={40} inline={true}/>
                <Button caption={'Search'} onClick={handleClose} kind={EButtonKind.PRIMARY} width={'100%'}/>
                <SpaceBox width={'100%'} height={20} inline={true}/>
                <Button caption={'Cancel'} onClick={() => {handleClose(); clearFilter()}} kind={EButtonKind.OUTLINED_PRIMARY} width={'100%'}/>
                <SpaceBox width={'100%'} height={25} inline={true}/>
              </TWMobileButtons>
            </If>
          </TWDropDownHeader>
      </Align>
    </TWFilter>
  );
};

const TWMassOps = styled("div")(({ theme, darkMode, disabled, breakpoint }) => [
  tw`relative inline-flex -top-5p content-center`,
  //mobile gap 30
  //tablet gap 10
  //desktop gap 0
  css``
]);

const TWMobileButtons = styled("div")(({ theme, darkMode, disabled, breakpoint }) => [
  tw``,
  css``
]);

const TWMassOp = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative grid grid-flow-col items-center content-center
  cursor-pointer rounded-xl bg-transparent px-20p h-50p`,
  !darkMode ? tw`hover:bg-accent-100` : tw`hover:bg-accent-900`,
  tw`tablet:px-10p`,
  tw`mobile:h-40p mobile:px-15p`,
  css`grid-column-gap: 10px`
]);

const TWFilter = styled("div")(({ theme, darkMode, disabled }) => [
  tw`inline-block pl-48p pr-20p w-auto w-full justify-center items-center content-center`,
  tw`mobile:pl-20p mobile:pr-20p`,
  css`
    
    max-width: 682px;
`
]);

const TWDropDownHeader = styled("div")(({ theme, darkMode, disabled, mobile }) => [
  tw`relative w-full flex flex-col`,
  tw`desktop:pl-52p`,
  // tw`tablet:pl-0`,
  tw`mobile:px-20p justify-between`,
  mobile && css`
  height: calc(100vh - 80px);
`,
  css``
]);

const TWDateAndMediaFilter = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative grid items-center w-full h-80p`,
  css`
    grid-template-columns: auto 1fr;
    grid-column-gap: 15px;
  `
]);

const TWMedia = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid grid-flow-col items-center w-full h-full`,
  css`
    grid-auto-columns: auto;
    grid-column-gap: 15px;
  `
]);

const TWCalendar = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid grid-flow-col items-center rounded-lg cursor-pointer leading-none px-20p min-h-40p h-auto`,
  tw`hover:bg-accent-100`,
  tw`mobile:hidden`,
  css`
    justify-self: flex-end;
    justify-items: flex-end;
    grid-auto-columns: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
  `
]);

const TWCalendarLabel = styled("div")(({ theme, darkMode, disabled }) => [
  tw`inline-block`,
  css``
]);

const TWTagFilter = styled("div")(({ theme, darkMode, disabled }) => [
  tw`-my-15/2p -mx-5p`, css``
]);
const TWTag = styled("div")(() => [
  tw`inline-block relative h-full py-15/2p px-5p max-w-120p`
]);

const TWDateFilter = styled("div")(({ left, right, top, bottom }) => [
  tw`fixed`,
  css`
    z-index: 999;
    ${cssSize(top, "top")}
    ${cssSize(left, "left")}
    ${cssSize(right, "right")}
    ${cssSize(bottom, "bottom")}
  `
]);
