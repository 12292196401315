import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { ifCond, injectPropsIntoChildren } from "utils";
import {ITextBoxProps} from 'components';

export interface IRadioGroup {
  name: string;
  value?: string | number | boolean;
  as?: typeof React.Component;
  onChange?: Function;
  children?: React.ReactChildren;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export interface IRadio {
  value?: string | number | boolean;
  text?: ITextBoxProps;
  reverse?: boolean;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Radio = React.forwardRef((props: IRadio, ref) => {
  const { demo=false, if:_if=true, value, text, name, onChange, selected, reverse=false, children, ...rest } = props;
  const handleChange = React.useCallback((e) => {
    const val = e.target.value;
    if (onChange instanceof Function && val===value) {
      onChange(value, selected, name);
    }
  }, [onChange, name, selected, value]);
  const checked = (value === selected);

  if ( !ifCond(_if) ) return null;
  return (
    <TWRadioLabel>
      {!!reverse && children}
      <TWRadio ref={ref} {...rest} name={name} onChange={handleChange} checked={checked}
        role="radio" type="radio" aria-checked={checked} value={value}
      />
      {!reverse && children}
    </TWRadioLabel>
  );
});
export const RadioGroup = React.forwardRef((props: IRadioGroup, ref) => {
  const { name, value, as:AsComponent, onChange, children, ...rest } = props;
  const RadioGroupComponent = AsComponent ? AsComponent : TWRadioGroup;
  const decoratedChildren = injectPropsIntoChildren(children, {...{ name, selected: value, onChange }});
  return <RadioGroupComponent ref={ref} role="radiogroup" {...rest}>{decoratedChildren}</RadioGroupComponent>;
});

const TWRadioLabel = styled("label")(() => [
  tw`grid col-gap-10p items-center content-center cursor-pointer p-0 m-0 w-full h-full`,
  css`grid-template-columns: 16px 90px;`
]);
const TWRadio = styled("input")(() => [
    tw`inline-block relative rounded-full cursor-pointer outline-none
       bg-inherit border-accent-300 border border-solid align-middle
       active:border-accent-300
    `,
    tw`hover:border-accent-700`,
    tw`disabled:cursor-not-allowed disabled:opacity-90
       disabled:checked:border-accent-200`,


    tw`checked:after:bg-accent-700
       hover:checked:after:bg-accent-700 checked:after:opacity-100`,
    tw``,

    tw`after:block after:rounded-full after:opacity-0 after:m-3p`,
    tw`checked:after:w-8p checked:after:h-8p`,
    tw`w-16p h-16p`,
    css`
      --focus: 2px rgba(0, 94, 197, .3);/*(39, 94, 254, .3);*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:after {
        content:'';
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
    `
  ]
);

const TWRadioGroup = styled("div")(({theme}) => [

]);