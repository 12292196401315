import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {CardPane, CardPaneLoader, ENoFileReason, FileView, If, NoFileView, Align, SlugPane} from 'components';
import {ifCond} from 'utils';
import {usePubSub, useStoreActions, useStoreState} from 'hooks';
import {v1 as uuidv1} from 'uuid';
import {PORTAL, PUBSUB, ROUTE, URL_PARAM} from 'const';
import {SlugFooter} from './footer';
import {ZoomFile} from "../../../components/composite/FileView/ZoomFile";

export interface ISlugPage extends TThemed {
  onNext?: Function;
  onPrev?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const SlugPage = React.forwardRef((props: ISlugPage, ref) => {
  const {match, raw = false, darkMode = false, demo = false, if: _if = true, ...rest} = props;
  const [root, loadingFragment, currentFragment, currentItem, /*cardItems,*/ path, headerOpened, tagList, wsInitialized, controls] = useSlugState();
  const {params: matchParams} = match || {params: null};
  const setCurrentSlug = useStoreActions(state => state.screenshots.setCurrentSlug) as any;
  const cond = useStoreState(state => state.screenshots.isBlocked);
  const {slug: currentSlug} = matchParams || {slug: null};
  const [getSlug, goto, setControls, setItem] = useSlugActions();
  const [init, setInit] = React.useState(false);
  const router = useStoreState(state => state.router);

  const handleView = React.useCallback((slug) => {
    setControls(false);
    console.debug("handleView")
    setItem(currentFragment.find(f => f.slug === slug));
    setInit(false)
    goto({to: ROUTE.SLUG.replace(":slug", slug)} as any);
  }, [goto, currentFragment, setControls, setItem]);
  const handleTagClick = usePubSub(PUBSUB.HISTORY.FILTER_CHANGE);
  const handleNext = React.useCallback((e, slug) => {
    getSlug(slug)
    goto({to: ROUTE.SLUG.replace(":slug", slug)} as any);
  }, [goto]);
  const handlePrev = React.useCallback((e, slug) => {
    getSlug(slug)
    goto({to: ROUTE.SLUG.replace(":slug", slug)} as any);
  }, [goto]);
  const handleBack = React.useCallback(() => {
    setControls(false);
    setItem(null);
  }, [setItem, setControls]);
  const headerEl = document.querySelector("#" + PORTAL.FILTER);
  const headerRect = headerEl && headerEl.getBoundingClientRect() || {height: 50};
  const cardHeaderOffset = headerOpened ? headerRect.height + 30 : 80;
  const advertising = {
    top: currentItem ? currentItem.advertising ? currentItem.advertising.top ? currentItem.advertising.top.url : null : null : null,
    bottom: currentItem ? currentItem.advertising ? currentItem.advertising.bottom ? currentItem.advertising.bottom.url : null : null : null,
    left: currentItem ? currentItem.advertising ? currentItem.advertising.left ? currentItem.advertising.left.url : null : null : null,
    right: currentItem ? currentItem.advertising ? currentItem.advertising.right ? currentItem.advertising.right.url : null : null : null,
  }

  const zoom = useStoreState(s => s.zoom);
  const profile = useStoreState(state => state.profile);
  const isLoggedIn = useStoreState(s => s.isLoggedIn);
  const getToken = useStoreActions(actions => actions.tempAuth);
  const setToken = useStoreActions(actions => actions.setTid);
  const removeUrlParams = useStoreActions(actions => actions.consumeUrlParams);
  const setFragment = useStoreActions(actions => actions.screenshots.setFragment);
  const cid = useStoreState(s => s.cid);

  const tempAuth = async () => {
    if (!isLoggedIn && cid) {
      await getToken(cid).then((token) => {
        setToken(token)
        removeUrlParams({clearHash: true, params: []})
      })
    }
  };

  const backHistory = () => {
    if (cond) {
      return null;
    }
    if (profile) {
      goto({to: ROUTE.HISTORY} as any)
    }
  };

  React.useLayoutEffect(() => {
    tempAuth();
  }, []);

  React.useEffect(() => {
    if (currentSlug) {
      getSlug(currentSlug);
    }
  }, [currentSlug]);

  // React.useLayoutEffect(() => {
  //   if (currentItem && currentSlug!==currentItem.slug) {
  //   //   setItem(null);
  //     // setInit(false);
  //   }
  // }, [currentSlug, currentItem, setItem]);

  React.useEffect(() => {
    if (currentSlug && wsInitialized && !init) {
      setCurrentSlug(currentSlug);
      getSlug(currentSlug);
      setInit(true);
    }
  }, [currentSlug, getSlug, wsInitialized, init, setInit]);

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      setFragment([]);
      setCurrentSlug('');
    }
  }, []);

  if (!ifCond(_if) || !currentSlug) return null;
  return (
        <TWSlugPage ref={ref} zoom={zoom} darkMode={darkMode} viewMode={!!currentItem} {...rest}>
          {zoom ? <ZoomFile darkMode={darkMode} {...(currentItem || {})} /> :
          <TWDivContainer>
            <If if={!!init && !loadingFragment} else={
              <div>
                <CardPaneLoader center darkMode={darkMode} />
              </div>
            }>
              <If if={currentFragment instanceof Array && currentFragment.length > 1 && !currentItem} else={
                <If if={currentItem} else={
                  <NoFileView reason={(currentItem || {}).reason || ENoFileReason.NOT_FOUND} darkMode={darkMode} />
                }>
                  <TWSlugContainer left={advertising.left} right={advertising.right} bottom={advertising.bottom}
                                   top={advertising.top}>
                    {
                      advertising.top && <TWAdvertisingHorizontal src={advertising.top} top />
                    }
                    {
                      advertising.bottom && <TWAdvertisingHorizontal src={advertising.bottom} bottom />
                    }
                    {

                      advertising.right && <TWAdvertisingVertical src={advertising.right} right />
                    }
                    {

                      advertising.left && <TWAdvertisingVertical src={advertising.top} left />
                    }
                    {
                      advertising.bottom && <TWAdvertisingMobile src={advertising.bottom} />
                    }
                    <Align>
                      <FileView is_owner={currentItem?.is_owner} darkMode={darkMode} {...(currentItem || {})} />
                    </Align>
                  </TWSlugContainer>
                </If>
              }>
                  <SlugPane
                    currentSlug={currentSlug}
                    darkMode={darkMode}
                    items={currentFragment}
                    onTagClick={(e, tag) => handleTagClick([e, "tag", tag.name])}
                    onView={handleView}
                    headerOffset={cardHeaderOffset}
                    root={root}
                  >
                    <CardPaneLoader if={!!loadingFragment} darkMode={darkMode} />
                  </SlugPane>
                </If>
                <If if={!currentItem && !currentSlug && !!init}>
                  {/* <TWBackdrop /> */}
                  <TWSlugContainer left={advertising.left} right={advertising.right} bottom={advertising.bottom}
                                   top={advertising.top}>
                    <Align>
                      <FileView is_owner={currentItem?.is_owner} darkMode={darkMode} {...(currentItem || {})} />
                    </Align>
                  </TWSlugContainer>
                </If>
              </If>

            {/* <If if={currentItem}> */}
            {/*в if был controls, но при перезагрузке из-за controls=false поле пропадает*/}
            {/*if={currentItem && currentItem.is_owner && controls}*/}
            <SlugFooter if={currentItem && currentItem.is_owner}
                        backHistory={backHistory}
                        caption={currentItem && currentItem.name}
                        created_date={currentItem && currentItem.created_date}
                        next={currentItem && currentItem.next}
                        prev={currentItem && currentItem.prev}
                        tags={currentItem && currentItem.tags}
                        onNext={handleNext} onPrev={handlePrev} onClose={() => goto({to: ROUTE.HISTORY} as any)}
                        onTagClick={handleTagClick} />
            {/* </If> */}
          </TWDivContainer>}
        </TWSlugPage>
  );
});

const TWSlugPage = styled("div")(({theme, darkMode, zoom, disabled, viewMode}) => [
  tw`relative flex-col w-full h-full`,
  !zoom ? css`
    display: grid;
    grid-template-rows: 1fr;
  ` : css`display: flex !important;`,
  zoom && css`
    justify-content: center !important; 
    align-items: center !important;
    height: unset!important; 
    background-color: #2c3036 !important;`
]);

const TWBackdrop = styled("div")(({theme, darkMode, disabled}) => [
  tw`absolute left-0 top-0 bg-twilight w-full h-full`,
  css`
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  `
]);

// tw`desktop:pt-${bottom ? '130px' : '10px'}
// desktop:pr-${right ? '340px' : '130px'}
// desktop:pb-${bottom ? '130px' : '10px'}
// desktop:pl-${right ? '340px' : '130px'}`,
// tw`tablet:${top ? '130px' : '10px'} tablet:pr-50p tablet:pb-${bottom ? '130px' : '10px'} tablet:pl-50p`,
// tw`mobile:pt-10p mobile:pr-50p mobile:pb-${bottom ? '110px' : '10px'} mobile:pl-50p`,

const TWSlugContainer = styled("div")(({theme, darkMode, disabled, top, left, right, bottom}) => [
  tw`flex w-full items-center relative`,

  css`
    @media (max-width: 767px) {
      padding: 10px 50px ${bottom ? '110px' : '10px'} 50px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      padding: ${top ? '130px' : '10px'} 0px ${bottom ? '130px' : '10px'} 0;
    }
    @media (min-width: 1025px) {
      padding: ${top ? '130px' : '10px'} ${right ? '340px' : '130px'} ${bottom ? '130px' : '10px'} ${left ? '340px' : '130px'};
    }
    max-height: calc(100vh - 140px);
    background: #2c3036;
    height: 100%;
    width: 100%;
  `
]);

const TWAdvertisingHorizontal = styled("img")(({theme, darkMode, disabled, top, bottom}) => [
  tw`block absolute z-10`,
  css`
    width: 729px;
    height: 90px;
    background-color: #383d45;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  `,
  top && css`top: 20px`,
  bottom && css`bottom: 20px`
]);

const TWAdvertisingVertical = styled("img")(({theme, darkMode, disabled, right, left}) => [
  tw`block absolute z-10`,
  css`
    width: 240px;
    height: 400px;
    background-color: #383d45;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  right && css`right: 33px`,
  left && css`left: 33px`
]);

const TWDivContainer = styled("div")(({}) => [
  tw`last:justify-end`,
  css`
    display: flex;
    flex-direction: column;
  `
]);

const TWAdvertisingMobile = styled("img")(({theme, darkMode, disabled}) => [
  tw`hidden absolute z-10`,
  css`
    width: 100%;
    height: 100px;
    bottom: 0;
    background-color: #383d45;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      display: inline-block;
    }
  `
]);


const useSlugActions = () => {
  const getSlug = useStoreActions(actions => actions.screenshots.getSlug);
  const goto = useStoreActions(actions => actions.router.push);
  const setControls: (boolean) => void = useStoreActions(actions => actions.screenshots.setControls);
  const setItem = useStoreActions(actions => actions.screenshots.setItem);
  const setSlug = useStoreActions(actions => actions.screenshots.setSlug);
  return [getSlug, goto, setControls, setItem, setSlug];
};

const useSlugState = () => {
  const root = useStoreState(state => state.router.root);
  const loadingFragment = useStoreState(state => state.screenshots.loadingFragment);
  //const cardItems = useStoreState(state => state.screenshots.items);
  const path = useStoreState(state => state.router.path);
  const headerOpened = useStoreState(state => state.screenshots.headerOpened);
  const tagList = useStoreState(state => state.screenshots.tagList);
  const currentFragment = useStoreState(state => state.screenshots.currentFragment);
  const currentItem = useStoreState(state => state.screenshots.currentItem);
  const controls = useStoreState(state => state.screenshots.controls);
  const wsInitialized = useStoreState(state => state.wsInitialized);

  return [root, loadingFragment, currentFragment, currentItem as any, /*cardItems,*/ path, headerOpened, tagList, wsInitialized, controls];
};

//export * from './header';
