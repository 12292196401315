import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line } from 'components';
import { ifCond, str } from 'utils';
import {Landing} from 'pages/widgets/Landing';

export interface IRootPage extends TThemed {
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const RootPage = React.forwardRef((props: IRootPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWRootPage ref={ref} darkMode={darkMode} {...rest}>
      <Landing />
    </TWRootPage>
  );
});

const TWRootPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative min-h-full w-full`,
  css``
]);


export * from './billing';
export * from './history';
export * from './login';
export * from './no-access';
export * from './not-found';
export * from './playground';
export * from './profile';
export * from './register';
export * from './reset';
export * from './slug';
export * from './team';
export * from './team-invite';
export * from './upgrade';
