import React from 'react';
import tw, {css, styled} from 'twin.macro';
import { clone, property } from 'lodash';
import {cssSize, debounceHandler, ifCond} from 'utils';
import {EVAlign, EEditInput, EIcon, EIconSize, ETagColor, ETagType, Icon, TagLabel} from 'components';

export interface ISearchBox extends TThemed {
  onClick?: Function
  caption?: string | number | null;
  value?: string | number | null;
  chips?: Array<[string, Function]>;
  fill?: boolean | string | number;
  inline?: boolean;
  onInput?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  isHeaderOpened: boolean;
  [prop: string]: any;
}


export const SearchBox = React.forwardRef((props: ISearchBox, _ref) => {
  const { caption, onClick, value, inline=true, fill=false, width, chips=[],
    onInput, darkMode=false, demo=false, if:_if=true, isHeaderOpened=false,
    ...rest
  } = props;
  const ref = React.useRef<any>(_ref);

  //@ts-ignore
  const handleChange = React.useCallback((e) => {
    if (onInput instanceof Function) onInput(e);
  },[onInput]);
  const handleChipClick = React.useCallback((e, chip) => {
    if (onClick instanceof Function) onClick(e)
    if (chip instanceof Array && chip.length>1 && chip[1] instanceof Function) chip[1](e, chip[0]);
  }, [chips]);
  React.useEffect(() => {
    
    if (ref.current && ref.current.value) ref.current.value = value;
  }, [value]);
  if ( !ifCond(_if) ) return null;
  return (
      <TWPlaceholder {...{caption,inline, fillX: fill, width, isHeaderOpened}}>
        <Icon size={EIconSize.MD} icon={EIcon.SearchGray} />
        <TWEditBox ref={ref}
                   {...{
                     type: EEditInput.TEXT, caption,
                     darkMode,
                   }}
                    //@ts-ignore
                   onInput={debounceHandler(handleChange, 500)}
                   defaultValue={value}
                   placeholder={ caption || undefined }
                   autoComplete="new-password"
                   {...rest}
        />
        {chips.map(chip => {
          return (
            <TagLabel type={ETagType.CHIP}
                      color={ETagColor.GHOST}
                      onClick={(e) => handleChipClick(e, chip)}
                      text={chip[0]}
                      value={chip[0]}
                      icon={<div tw="pl-15p mobile:pl-0"><Icon icon={EIcon.CloseGray} size={10} align={EVAlign.BASE}/></div>}
                      key={chip[0]}
                      bordered
            />
          );
        })}
      </TWPlaceholder>
  );
});
const TWPlaceholder = styled("div")(({ theme, darkMode, disabled, kind, fillX, inline, width, caption, hasValue, isHeaderOpened }) => [
  tw`grid-flow-col grid-cols-a1 items-center relative outline-none cursor-text rounded-lg h-40p pl-20p pr-5p`,
  tw`border-accent-100`,
  inline ? tw`inline-grid` : tw`grid`,
  fillX===true ? tw`w-full` : (fillX!==false && css`${cssSize(fillX, "width")}`),
  width && css`${cssSize(width, "width")}`,
  css`
  background-color: rgb(95 108 118 / 10%);
  &:hover {
    background-color: rgb(95 108 118 / 15%); 
    transition: background .3s, border-color .3s, box-shadow .2s;
  }
  `,
  isHeaderOpened && css`
  &:focus-within {
      background-color: #fff;
      border: solid 1px #afb8c0;
    }
  `
]);
const TWEditBox = styled("input")(({ theme, darkMode, disabled, kind, caption, hasValue }) => [
    tw`relative outline-none w-full placeholder-light-secondary z-10`,
    tw`font-sans text-light-primary text-normal pt-0`,
    tw`bg-transparent border-none`,
    tw`h-40p pl-10p`, // Normal @ kind SM
    tw`pr-20p`,
    css`
      /*--focus: 2px rgba(0, 94, 197, .3);*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
    `,
  ]
);
const TWChip = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`inline-block relative right-0 z-10`,
  css``
]);
