import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  ComboBox, EPanelWidth
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {FIELD} from 'const';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IProfileChangeLang extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const initialValues = {
  [FIELD.LANG]: []
}
const initialErrors = {
  [FIELD.LANG]: []
}

export const ProfileChangeLang = React.forwardRef((props: IProfileChangeLang, ref) => {
  const { onClose, onSubmit, languages = {list: []}, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const profile = useStoreState(s=>s.profile);
  const currentLang = useStoreState(s=>s.profile?.language?.unique);
  console.log({profile, currentLang, languages})
  const bp = useMediaBreakpoints();
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState(initialErrors);
  const languagesOptions = languages ? languages.list.map((language) => ({value: language.unique, text: language.name})) : []
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleSubmit = React.useCallback((e) => {
    if (isErrors()) {
      getFormErrors()
    } else if (onSubmit instanceof Function) {
      const unique = languagesOptions.filter((language) => language.text === values.language)[0].value
      onSubmit({unique})
    };
  }, [onSubmit, values, setValues, errors, setErrors]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
  }, [values, setValues, errors, setErrors]);
  const getFormErrors = React.useCallback(() => {
    
    setErrors({
      [FIELD.LANG]: !values[FIELD.LANG].length ? ['the field must not be empty'] : []
    })
  }, [values, setValues, errors, setErrors])
  const isErrors = React.useCallback(() => {
    for (let value in values) {
      if (!values[value].length) {
        return true
      }
    }
  }, [values]);

  useKeydown(handleSubmit, handleClose, values);
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWProfileChangeLang ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.PROFILE.CHANGE_LANG} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <ComboBox caption={MSG.PROFILE.LANG}
                  errors={errors[FIELD.LANG]}
                  items={languagesOptions}
                  onBlur={(e) => handleChange(e, FIELD.LANG)}
                  value={currentLang}
                  fill={true}/>
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.SAVE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWProfileChangeLang>
  );
});

const TWProfileChangeLang = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
