import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line } from 'components';
import { cssSize, ifCond, str } from 'utils';

export interface IPlaygroundPage extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const PlaygroundPage = React.forwardRef((props: IPlaygroundPage, ref) => {
  const { raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWPlaygroundPage ref={ref} darkMode={darkMode} {...rest}>

    </TWPlaygroundPage>
  );
});

const TWPlaygroundPage = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

export * from './header';
