import React, {useState} from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  Card,
  ComputedContextMenu,
  ContextMenuTarget,
  EJustify,
  ETagColor,
  ETagType,
  SpaceBox,
  Sticky,
  TagLabel, IContextMenuItem
} from 'components';
import {ifCond} from 'utils';
import {ICardPaneItem} from 'types';
import { CONTEXT_MENU } from 'const';

export interface ISettingPane extends TThemed {
  items: ICardPaneItem[];
  getItemActions?: (ICardItem) => IContextMenuItem[];
  total?: number;
  loading?: boolean;
  headerOffset?: number;
  hasMenu?: boolean;
  selectable?: boolean;
  selectionMode?: boolean;
  selected?: string[];
  onView?: Function;
  onSelect?: Function;
  onTagClick?: Function;
  onAddTagClick?: Function;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const SettingPane = React.forwardRef((props: ISettingPane, ref) => {
  const { total=0, items=[], hasMenu=false, getItemActions, loading=false, headerOffset, selected, onSelect, onTagClick,
    onAddTagClick, selectionMode=false, onView, darkMode=false, demo=false, if:_if=true, children, ...rest
  } = props;

  const [selectedPlan, setSelectedPlan] = useState('free')
  
  const onSelectPlan = (name) => () => {
    setSelectedPlan(name)
  }
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWSettingPane ref={ref} {...rest}>
      <TWTitle>
        Profile
      </TWTitle>
      <TWUserData>
        <TWField>
          <TWFieldName>
            Name
          </TWFieldName>
          <TWFieldPhoto/>
          <TWFieldButton>
            Change photo <span>or</span> Remove current photo
          </TWFieldButton>
        </TWField>
        <TWField>
          <TWFieldName>
            Name
          </TWFieldName>
          <TWFieldData>
            Dmitry Ivanochek
          </TWFieldData>
          <TWFieldButton>
            Change name
          </TWFieldButton>
        </TWField>
        <TWField>
          <TWFieldName>
            Email
          </TWFieldName>
          <TWFieldData>
            ivanochek@gmail.com
          </TWFieldData>
          <TWFieldButton>
            Change email
          </TWFieldButton>
        </TWField>
        <TWField>
          <TWFieldName>
            Password
          </TWFieldName>
          <TWFieldData>
            ●●●●●●
          </TWFieldData>
          <TWFieldButton>
            Change password
          </TWFieldButton>
        </TWField>
        <TWField>
          <TWFieldName>
            Language
          </TWFieldName>
          <TWFieldData>
            English
          </TWFieldData>
          <TWFieldAddLanguage>
            Add your language
          </TWFieldAddLanguage>
          <TWFieldButton>
            Change language
          </TWFieldButton>
        </TWField>
      </TWUserData>
      <TWTitle>
        Usage
      </TWTitle>
      <TWUsage>
        <TWProgressBar max="100" value="30"/>
        <TWUsageData>
          <TWUsageMemory>
            856 Mb from 1 024 Mb used
          </TWUsageMemory>
          <TWUsageItems>
            2 318 item
          </TWUsageItems>
          <TWUsageButton>
            Clear history
          </TWUsageButton>
        </TWUsageData>
      </TWUsage>
      <TWTitle>
        Subscription Plan
      </TWTitle>
      <TWBilling>
        <TWBillingPlans>
          <TWBillingTariff onClick={onSelectPlan('free')}>
           <TWBillingInfo>
             <span>
               Free
             </span>
             Current plan
           </TWBillingInfo>
           
         </TWBillingTariff>
          <TWBillingTariff onClick={onSelectPlan('pro')}>
            <TWBillingIcon>
    
            </TWBillingIcon>
            <TWBillingInfo>
             <span>
               Pro
             </span>
              3$ per month
            </TWBillingInfo>
          </TWBillingTariff>
          <TWBillingTariff onClick={onSelectPlan('team')}>
            <TWBillingIcon>
    
            </TWBillingIcon>
            <TWBillingInfo>
             <span>
               Team
             </span>
              5$ per user/month
            </TWBillingInfo>
          </TWBillingTariff>
        </TWBillingPlans>
        <TWBillingButton>
          Сompare plans
        </TWBillingButton>
      </TWBilling>
      <TWTitle>
        Dellete account
      </TWTitle>
      <TWDeleteAccount>
        <TWDeleteAccountDescription>
          All your files will be permanently deleted
        </TWDeleteAccountDescription>
        <TWDeleteAccountButton>
          Dellete account
        </TWDeleteAccountButton>
      </TWDeleteAccount>
     
    </TWSettingPane>
  );
});

const TWSettingPane = styled("section")(({ theme, darkMode, disabled }) => [
  tw`bg-white sm:rounded-lg`,
  css`
width: 700px;
box-shadow: 0 5px 25px 0 rgba(90, 90, 125, 0.2);
padding: 29px;
`
]);

const TWUserData = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
margin-bottom: 40px;
margin-top: 22px;
`
]);

const TWUsage = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
margin-bottom: 41px;
margin-top: 20px;
`
]);

const TWBilling = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
margin-bottom: 40px;
margin-top: 20px;
`
]);

const TWDeleteAccount = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`

`
]);

const TWDeleteAccountDescription = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
font-size: 13px;
color: #5f6c76;
margin-top: 5px;
margin-bottom: 15px;
`
]);

const TWDeleteAccountButton = styled("button")(({ theme, darkMode, disabled }) => [
  tw`flex items-center justify-center`,
  css`
  cursor: pointer;
  width: 154px;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #ccd3d9;
  font-size: 13px;
  color: #353d43;
  :hover {
  text-decoration: underline;
  }
`
]);

const TWBillingButton = styled("button")(({ theme, darkMode, disabled }) => [
  tw`flex items-center justify-center w-full`,
  css`
  margin-top: 14px;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #ccd3d9;
  font-size: 13px;
  color: #0070e9;
  :hover {
  text-decoration: underline;
  }
`
]);

const TWBillingPlans = styled("div")(({ theme, darkMode, disabled }) => [
  tw`flex`,
  css`
height: 70px;
border-radius: 18px;
border: solid 1px #ccd3d9;
`
]);

const TWBillingInfo = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  font-size: 13px;
  color: #5f6c76;
  
  span {
  font-size: 14px;
  font-weight: 600;
  color: #353d43;
  display: block;
  }
`
]);

const TWBillingTariff = styled("div")(({ theme, darkMode, disabled, isSelected }) => [
  tw`flex`,
  css`
  height: 100%;
  width: 33%;
  padding: 17px 20px;
  
  :nth-child(2){
  width: calc(33% - 2px);
  border-left: solid 1px #ccd3d9;
  border-right: solid 1px #ccd3d9;
  }
`
]);

const TWBillingIcon = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  margin-right: 14px;
  height: 30px;
  width: 30px;
  background: #ccd3d9;
`
]);

const TWUsageData = styled("div")(({ theme, darkMode, disabled }) => [
  tw`w-full`,
  css`
margin-top: 18px;
height: 5px;
position:relative;
`
]);

const TWUsageMemory = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
position:absolute;
color: #262e34;
left: 0;
`
]);

const TWUsageButton = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
position:absolute;
color: #0070e9;
text-align:right;
cursor: pointer;
right: 0;
`
]);

const TWUsageItems = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
position:absolute;
color: #262e34;
text-align:right;
right: 118px;
`
]);

const TWProgressBar = styled("progress")(({ theme, darkMode, disabled }) => [
  tw`w-full`,
  css`
border-radius: 5.5px;
height: 5px;
::-webkit-progress-bar {
border-radius: 5.5px;
background: #f5f5f7;
}
::-webkit-progress-value {
border-radius: 5.5px;
background: #0070e9;
}
`
]);

const TWTitle = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
color: #4a4a4a;
font-size: 19px;
`
]);

const TWField = styled("div")(({ theme, darkMode, disabled }) => [
  tw` h-50p w-full`,
  css`
position:relative;
border-top: 1px solid #ccd3d9;
:last-child {
border-bottom: 1px solid #ccd3d9;
}
font-size: 13px;
`
]);

const TWFieldName = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 2px;
color: #5f6c76;
`
]);

const TWFieldData = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 101px;
color: #262e34;

`
]);
const TWFieldPhoto = styled("div")(({ theme, darkMode, disabled }) => [
  tw`bg-gray-350 sm:rounded-lg`,
  css`
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 101px;
width: 40px;
height: 40px;

`
]);
const TWFieldButton = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
cursor: pointer;
text-align:right;
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 0px;
color: #0070e9;
span {
cursor: default;
color: #5f6c76;
}
`
]);

const TWFieldAddLanguage = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
cursor: pointer;
text-align:right;
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 135px;
color: #0070e9;
`
]);
