import {PUBLIC_ID} from "../const";

export const createCryptogram = ({cardNumber, expDateMonth, expDateYear, cvv, name}) => {
  const cp = window.cp;
  const crypto = new cp.Checkout(PUBLIC_ID);
  return crypto.createCryptogramPacket({
    cardNumber: cardNumber,
    expDateMonth: expDateMonth,
    expDateYear: expDateYear,
    cvv: cvv,
    name: name
  })
}
