import axios from "axios";
import {URLS, RX, LNG, MSG, FIELD} from 'const';
import {
  collectFieldErrors,
  MessageList,
  processApiResponse,
  processAxiosResponse,
  processPaymentResponse,
  validateStatus
} from 'utils';

export const restAuth = axios.create({
  baseURL: URLS.API_AUTH,
  responseType: "json",
  validateStatus
});

export const restBilling = axios.create({
  baseURL: URLS.API_BILLING,
  responseType: "json",
  validateStatus
});


const api = {
  login: {
    name: "login",
    url: "login/",
    method: "post",
    send: function (login, password) {
      const {url, method} = this;
      const params = {} as any;
      // if (login) {
      //   const match = login.match(RX.email);
      //   if (match) {
      //     params[FIELD.EMAIL] = match[0];
      //   } else {
      //     params[FIELD.USER] = login;
      //   }
      // }
      /////////BEGIN VALIDATION////////
      const errors = {};
      if (!login) { errors[FIELD.LOGIN] = MSG.ERROR.LOGIN.REQ; }
      if (!password) {
        errors[FIELD.PWD] = MSG.ERROR.PWD.REQ;
      } else if ( password.length<4 || !!~password.search(/\s/) ) {
        errors[FIELD.PWD] = MSG.ERROR.PWD.INCORRECT
      }
      if (Object.keys(errors).length) { return collectFieldErrors(errors); }
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.LOGIN]: login, [FIELD.PWD]: password, ...params }), this )
        .then(
          (data) => {
            console.debug(data, (data instanceof MessageList), typeof data);
            if (data instanceof MessageList) return data;
            const success = data.success;
            const token = data.key;
            //f09333c93f7f594e4d91d577af89f56e5b0951a7
            return { result: token };
          }
        );
    }
  },
  token: {
    name: "token",
    url: "token/",
    method: "get",
    send: function (payload) {
      const {url, method} = this;
      return processApiResponse( restAuth[method](url, { headers: {'X-CID': payload}}), this )
        .then(
          (data) => {
            if (data instanceof MessageList) return data;
            const success = data.success;
            const token = data.key;
            console.log(token)
            //f09333c93f7f594e4d91d577af89f56e5b0951a7
            return { result: token };
          }
        );
    }
  },
  logout: {
    name: "logout",
    url: "logout/",
    method: "post",
    send: function () {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, {}), this );
    }
  },
  logout2: {
    name: "logout",
    url: "logout/",
    method: "get",
    send: function () {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, {}), this );
    }
  },
  getCountry: {
    send: function () {
      return processApiResponse( restAuth["get"]('https://ipapi.co/json/', {}), this );
    }
  },
  forgot: {
    name: "forgot",
    url: "password/forgot/",
    method: "post",
    send: function (email) {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      if (!email) { return collectFieldErrors({[FIELD.EMAIL]:MSG.ERROR.EMAIL.REQ}); }
      else if (!email.match(RX.email)) { return collectFieldErrors({[FIELD.EMAIL]:MSG.ERROR.EMAIL.INCORRECT}); }
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.EMAIL]: email }), this, FIELD.EMAIL );
    }
  },
  validate: {
    name: "validate",
    url: "password/forgot/validate/",
    method: "post",
    send: function (token) {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.TOKEN]: token }), this );
    }
  },
  change: {
    name: "change",
    url: "password/forgot/change/",
    method: "post",
    send: function (token, password) {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      if (!password) {
        return collectFieldErrors({[FIELD.PWD]: MSG.ERROR.PWD.REQ});
      } else if ( password.length<4 || !!~password.search(/\s/) ) {
        return collectFieldErrors({[FIELD.PWD]: MSG.ERROR.PWD.INCORRECT});
      }
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.TOKEN]: token, [FIELD.PWD]: password }), this );
    }
  },
  register: {
    name: "register",
    url: "registration/",
    method: "post",
    send: function (email, password, lang?, cid?) {
      const {url, method} = this;
      const params = {} as any;
      if (cid) params[FIELD.CID] = cid;
      const language = lang || LNG._default.unique;
      const username = email && email.match(RX.email) && email.match(RX.email)[0];
      /////////BEGIN VALIDATION////////
      const errors = {};
      if (!email) { errors[FIELD.EMAIL] = MSG.ERROR.EMAIL.REQ; }
      else if (!email.match(RX.email)) {errors[FIELD.EMAIL] = MSG.ERROR.EMAIL.INCORRECT; }
      if (!password) {
        errors[FIELD.PWD] = MSG.ERROR.PWD.REQ;
      } else if ( password.length<4 || !!~password.search(/\s/) ) {
        errors[FIELD.PWD] = MSG.ERROR.PWD.INCORRECT;
      }
      if (Object.keys(errors).length) { return collectFieldErrors(errors); }
      /////////END VALIDATION//////////
      return processApiResponse(
        restAuth[method](url, { [FIELD.USER]:username, [FIELD.EMAIL]:email, [FIELD.PWD]:password, [FIELD.LANG]:language, ...params })
        , this
      );
    }
  },
  confirm: {
    name: "confirm",
    url: "registration/confirm/",
    method: "post",
    send: function (token) {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.TOKEN]: token }), this )
        .then(
          (data) => {
            if (data instanceof MessageList) return data;
            const success = data.success;
            const token = data.auth_token || data.key;
            //f09333c93f7f594e4d91d577af89f56e5b0951a7
            return { result: token };
          }
      );
    }
  },
  resend: {
    name: "resend",
    url: "registration/confirmation/resend/",
    method: "post",
    send: function (email) {
      const {url, method} = this;
      /////////BEGIN VALIDATION////////
      if (!email) { return collectFieldErrors({[FIELD.EMAIL]: MSG.ERROR.EMAIL.REQ}); }
      else if (!email.match(RX.email)) { return collectFieldErrors({[FIELD.EMAIL]:MSG.ERROR.EMAIL.INCORRECT}); }
      /////////END VALIDATION//////////
      return processApiResponse( restAuth[method](url, { [FIELD.EMAIL]:email }), this );
    }
  },
  getPaymentMethod: {
    name: "getPaymentMethod",
    url: "paymentmethod/",
    method: "get",
    send: function (token) {
      const {url, method} = this;
      return processApiResponse( restBilling[method](url, {headers: {'Authorization': `Bearer ${token}`}}), this );
    }
  },
  postPaymentMethod: {
    name: "postPaymentMethod",
    url: "paymentmethod/",
    method: "post",
    send: function (cryptogram, cardholder_name, token) {
      const {url, method} = this;
      return processApiResponse( restBilling[method](url, {cryptogram, cardholder_name }, {headers: {'Authorization': `Bearer ${token}`}}),  this );
    }
  },
  removePaymentMethod: {
    name: "removePaymentMethod",
    url: "paymentmethod/remove/",
    method: "post",
    send: function (token) {
      const {url, method} = this;
      return processApiResponse( restBilling[method](url, {}, {headers: {'Authorization': `Bearer ${token}`}}),  this );
    }
  },
  getInvoice: {
    name: "getInvoice",
    url: "invoice/",
    method: "get",
    send: function (token) {
      const {url, method} = this;
      return processApiResponse( restBilling[method](url, {headers: {'Authorization': `Bearer ${token}`}}), this );
    }
  },
  postInvoice: {
    name: "postInvoice",
    url: "invoice/",
    method: "post",
    send: function (item_name, quantity, cryptogram, cardholder_name, token) {
      const {url, method} = this;
      const props = (cryptogram && cardholder_name) ?
        { item_name, quantity, cryptogram, cardholder_name } :
        { item_name, quantity}
      return processPaymentResponse( restBilling[method](url, props, {headers: {'Authorization': `Bearer ${token}`}}),  this );
    }
  },
  
  cloudpaymentsAsc: {
    name: "cloudpayments",
    method: "post",
    send: function ({transaction_id, pa_req, acs_url, term_url}) {
      const {method} = this;
      return processApiResponse( restBilling[method](acs_url, {transaction_id, pa_req, term_url }),  this );
    }
  },
  putAvatar: {
    name: "put avatar",
    method: "put",
    send: function ({link, image}) {
      const {method} = this;
      return processAxiosResponse(axios.put(link, image) , {name: 'put avatar'});
    }
  },
};


export default api;
