import { lowerFirst } from 'lodash';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import DayUTC from 'dayjs/plugin/utc';
import DayCalendar from 'dayjs/plugin/calendar';
import DayLocFormat from 'dayjs/plugin/localizedFormat';
import DayLocaleData from 'dayjs/plugin/localeData';
import DayUpdateLoc from 'dayjs/plugin/updateLocale';
import DayAdvanced from 'dayjs/plugin/advancedFormat';
import DayWeekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';
import { range, chunk, repeat } from 'utils';
import {MIME_CATEGORIES, MSG} from 'const';
import {getDate} from "./dateFns";

dayjs.extend(DayUTC);
dayjs.extend(DayCalendar);
dayjs.extend(DayLocaleData);
dayjs.extend(DayLocFormat);
dayjs.extend(DayUpdateLoc);
dayjs.extend(DayAdvanced);
dayjs.extend(DayWeekday);
dayjs.locale('en');

const getMonthShort = (locale: any) => {
  dayjs.locale(locale);
  return range(0, 12).map(v =>
    dayjs()
      .localeData()
      .monthsShort(dayjs().month(v))
  );
};

const getWeekDays = (locale: any) => {
  dayjs.locale(locale);
  return range(7).map(v =>
    dayjs()
      .localeData()
      .weekdaysShort(dayjs().weekday(v))
  );
};

const getToday = (locale: any) => {
  return dayjs()
    .locale(locale)
    .format('LL');
};

const getCurrentMonth = (locale: any) => {
  return dayjs()
    .locale(locale)
    .format('MMMM YYYY');
};

const getDayMatrix = (year: number, month: number): string[][] => {
  const date = dayjs()
    .year(year)
    .month(month);

  const startOfMonth = date.startOf('month').date();
  const endOfMonth = date.endOf('month').date();

  const startDay = date.startOf('month').day();
  const remain = (startDay + endOfMonth) % 7;

  return chunk(
    [
      ...repeat(' ', startDay),
      ...range(startOfMonth, endOfMonth + 1).map(v => `${v}`),
      ...(7 - remain === 7 ? [] : repeat(' ', 7 - remain)),
    ],
    7
  );
};

const getMonthMatrix = (locale: any) => {
  return chunk(getMonthShort(locale), 3);
};

const getYearMatrix = (year: number): string[][] => {
  return chunk(range(year - 4, year + 5).map(v => `${v}`), 3);
};

const isDayEqual = (day1?: dayjs.Dayjs, day2?: dayjs.Dayjs) => {
  if (!day1 || !day2) return false;
  return dayjs(day1).isSame(day2, 'date');
};

const isDayAfter = (day1: dayjs.Dayjs, day2: dayjs.Dayjs) => {
  return dayjs(day1).isAfter(day2, 'date');
};

const isDayBefore = (day1: dayjs.Dayjs, day2: dayjs.Dayjs) => {
  return dayjs(day1).isBefore(day2, 'date');
};

const isDayRange = (date: dayjs.Dayjs, start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {
  if (!start || !end) return false;

  return isDayAfter(date, start) && isDayBefore(date, end);
};

const formatDate = (date: dayjs.Dayjs | undefined, format: string) => {
  if (date === undefined) return '';
  return dayjs(date).format(format);
};

// dayjs.updateLocale('ru', {
//   calendar: {
//     lastDay: '[вчера в] hh:mm:ss',
//     sameDay: '[сегодня в] hh:mm:ss',
//     nextDay: '[завтра в] hh:mm:ss',
//     lastWeek: function () { return this.format((this.day()===1 ? '[в]' : '[во]') + ' dddd [в] hh:mm:ss') },
//     nextWeek: 'LLL:ss',
//     sameElse: 'LLL:ss'
//   }
// });

function cardDateFormat(date: string, locales, MSG) {
  if (locales === 'en') {
    const d = dayjs(date).locale(locales);
    return lowerFirst(d.format(MSG.DATE.HISTORY.CREATED_ON));
  }
  if (locales === 'ru') {
    return getDate(date);
  }
}


export { dayjs, getMonthShort, getWeekDays, getToday,
  formatDate, isDayRange, isDayBefore, isDayAfter,
  isDayEqual, getYearMatrix, getMonthMatrix, getDayMatrix,
  cardDateFormat, getCurrentMonth
};
