import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {EVAlign, withHref} from 'components';
import {extractTextColor, cssSize, ifCond} from 'utils';

export interface ITextBox extends TThemed {
  color?: ETextColor; // primary
  colorHover?: ETextColor; // as color
  size?: ETextSize | number; // normal
  weight?: ETextWeight; // weight
  align?: ETextAlign;
  valign?: EVAlign;
  strike?: boolean;
  underline?: boolean;
  underlineHover?: boolean;
  italic?: boolean;
  height?: number | string;
  width?: number | string;
  ellipsis?: boolean;
  inline?: boolean; // true
  block?: boolean; // false
  text?: React.ReactNode;
  fillX?: boolean;
  fillY?: boolean;
  href?: string;
  hrefComponent?: typeof React.Component;
  hideBreakpoint?: EBreakpoint;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export type ITextBoxProps = string | ITextBox | Array<string | ITextBox>;
export interface ITWTextBox extends Omit<ITextBox, 'text'> {
  display?: string;
}
export enum EBreakpoint {
  MOBILE = "mobile", TABLET = "tablet", DESKTOP = "desktop"
}
export enum ETextColor {
  PRIMARY = "primary", // default
  SECONDARY = "secondary",
  SUCCESS = "success", ERROR = "error", BLUE = "sky",
  WHITE = "white", INHERIT = "inherit"
}
export enum ETextSize {
  INHERIT = "inherit",
  SMALL = 12, // '12px'
  NORMAL = 13, // '13px' default
  MEDIUM = 14, // '14px'
  LARGE = 16, // '16px'
  LARGER = 19, // '19px'
  XLARGER = 21, // '21px'
  XXLARGER = 32, // '32px'
}
export enum ETextAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  ADJUST = "justify",
}
export enum ETextWeight {
  INHERIT = "inherit",
  REGULAR = 400,
  //MEDIUM = 500,
  SEMIBOLD = 600,
  BOLD = 700
}

export const TextBox = React.forwardRef((props: ITextBox, ref) => {
  const { href, hrefComponent, colorImportant, color=ETextColor.PRIMARY, colorHover=(props.color || ETextColor.PRIMARY), size=ETextSize.NORMAL, italic=false,
    strike=false, underline=false, underlineHover=props.underline || false, height, width, if:_if=true, align=ETextAlign.LEFT, weight=ETextWeight.REGULAR, fillX=false, fillY=false,
    darkMode=false, inline=true, block=false, text, children, index, hideBreakpoint, ...rest
  } = props;
  const display = ( ( inline && ["inline"] ) || [] )
    .concat( ( block && ["block"] ) || [] )
    .concat( ( !block && fillY && ["grid"] ) || [] )
    .join("-") || "flex";
  const content = text == null ? children : text;
  if ( !ifCond(_if) ) return null;
  return withHref(<TWTextBox {...{ref, color, colorHover, strike, underline, underlineHover, colorImportant, italic, size, align, weight, display, fillX, fillY, darkMode, height, width, hideBreakpoint, onClick: props.onClick, href, ...rest}}>{content}</TWTextBox>, href, hrefComponent, index);
});

const TWTextBox = styled("div")(
  ({darkMode, theme, color, colorHover, underline, underlineHover, strike, size, align, valign, colorImportant, italic,
     weight, height, width, display, fillX, fillY, ellipsis, hideBreakpoint, onClick, href}) => [
    css`
      display: ${display};
      text-align: ${align};
      font-weight: ${weight};
      color: ${!!colorImportant ? "#bfc5ce !important" : extractTextColor(color, theme)};
      ${italic ? "font-style: italic;" : ""}
      ${cssSize(size, "font-size")};
      ${cssSize(height, "height")}
      ${cssSize(width, "width")}
      ${cssSize(valign, "valign")}
      &:hover {
        color: ${extractTextColor(colorHover, theme)};
      }
    `,
    tw`font-sans leading-snug`,
    (onClick || href) && tw`cursor-pointer hover:cursor-pointer`,
    underline ? tw`underline` : tw`no-underline`,
    underlineHover ? tw`hover:underline` : tw`hover:no-underline`,
    strike && tw`line-through`,
    fillX && tw`w-full`,
    fillY && tw`h-full content-center`,
    color===ETextColor.PRIMARY && (darkMode ? tw`text-dark-primary` : tw`text-light-primary`),//tw`text-light-primary dark:text-dark-primary`
    color===ETextColor.SECONDARY && (darkMode ? tw`text-dark-secondary` : tw`text-light-secondary`),//tw`text-light-secondary dark:text-dark-secondary`
    color===ETextColor.SUCCESS && tw`text-success`,
    color===ETextColor.ERROR && tw`text-error`,
    color===ETextColor.WHITE && tw`text-white`,
    color===ETextColor.BLUE && tw`text-sky`,

    colorHover===ETextColor.PRIMARY && (darkMode ? tw`hover:text-dark-primary` : tw`hover:text-light-primary`),//tw`text-light-primary dark:text-dark-primary`
    colorHover===ETextColor.SECONDARY && (darkMode ? tw`hover:text-dark-secondary` : tw`hover:text-light-secondary`),//tw`text-light-secondary dark:text-dark-secondary`
    colorHover===ETextColor.SUCCESS && tw`hover:text-success`,
    colorHover===ETextColor.ERROR && tw`hover:text-error`,
    colorHover===ETextColor.WHITE && tw`hover:text-white`,
    colorHover===ETextColor.BLUE && tw`hover:text-sky`,
    hideBreakpoint===EBreakpoint.MOBILE && tw`mobile:hidden`,
    hideBreakpoint===EBreakpoint.TABLET && tw`tablet:hidden`,
    hideBreakpoint===EBreakpoint.DESKTOP && tw`desktop:hidden`,
    ellipsis && css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `
  ]
);
