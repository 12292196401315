import React from "react";

export function useKeydownModal(onSubmit: Function, onClose: Function, handleChange?: Function, value?: any) {
  React.useEffect(() => {
    const handleEsc = async (event) => {
      if (event.code === "Escape") {
        await onClose(event);
      }
      if (event.code === "Enter") {
        if (!onSubmit) {
          await onClose(event);
          return null;
        }
        await onSubmit(event);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [value, onClose, onSubmit, handleChange]);
};
