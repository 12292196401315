import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Align,
  Button,
  Buttons,
  EAlign,
  EButtonKind,
  EditBox,
  EEditKind,
  EIcon,
  EIconSize,
  EJustify,
  EPanelCaptionKind,
  EPanelWidth,
  ESwatchColor,
  ESwatchItemSize,
  ESwatchItemType,
  ESwatchType,
  EVAlign,
  Icon,
  Line,
  LinkButton,
  Panel,
  PanelFooter,
  SpaceBox,
  Swatch,
  SwatchColorItems,
  TextBox
} from 'components';
import {FIELD, SETTINGS} from 'const';
import {callIfExists, debounceHandler, ifCond, isArrayEqual} from 'utils';
import {ICardTag, ICloudpaymentsAsc, IFieldErrors} from 'types';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";

export interface IAscCloudpayments extends TThemed {
  cloudpaymentsProps: ICloudpaymentsAsc | null;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const AscCloudpayments = React.forwardRef((props: IAscCloudpayments, ref) => {
  const { cloudpaymentsProps=null, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
  if ( !ifCond(_if) || !cloudpaymentsProps ) return null;
  return (
    <TWAscForm ref={ref} darkMode={darkMode} {...rest} action={cloudpaymentsProps?.acs_url} method="POST">
      <input type="hidden" name="PaReq" value={cloudpaymentsProps?.pa_req}/>
      <input type="hidden" name="MD" value={cloudpaymentsProps?.transaction_id}/>
      <input type="hidden" name="TermUrl" value={cloudpaymentsProps?.term_url}/>
    </TWAscForm>
  );
});

const TWAscForm = styled("form")(({ theme, darkMode, disabled }) => [
  tw`w-0 h-0 overflow-hidden`,
  css`opacity: 0`
]);
