import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  EPanelWidth
} from 'components';
import { cssSize, ifCond } from 'utils';
import {websocketModel} from "../../../store/model";
import {useStoreState} from "../../../store";
import {FIELD} from 'const';
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IProfileChangeName extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  username?: string,
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}


const initialErrors = {
  [FIELD.USER]: []
}

export const ProfileChangeName = React.forwardRef((props: IProfileChangeName, ref) => {
  const { onClose, onSubmit, username = '', raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const initialValues = {
    [FIELD.USER]: username
  }
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState(initialErrors);
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleSubmit = React.useCallback((e) => {
    if (isErrors()) {
      getFormErrors()
    } else if (onSubmit instanceof Function) {
      onSubmit(e, values[FIELD.USER])
    };
  }, [onSubmit, values, setValues, errors, setErrors]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
  }, [values, setValues, errors, setErrors]);
  const getFormErrors = React.useCallback(() => {
    setErrors({
      [FIELD.USER]: !values[FIELD.USER] ? ['the field must not be empty'] : []
    })
  }, [values, setValues, errors, setErrors])
  const isErrors = React.useCallback(() => {
    for (let value in values) {
      if (!values[value]) {
        return true
      }
    }
  }, [values])

  useKeydown(handleSubmit, handleClose, values);

  if ( !ifCond(_if) ) return null;
  return (
    <TWProfileChangeName ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.PROFILE.CHANGE_NAME} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <EditBox
          errors={errors[FIELD.USER]}
          value={values[FIELD.USER]}
          caption={MSG.PROFILE.NAME}
          onChange={(e) => handleChange(e, FIELD.USER)}
          onBlur={(e) => handleChange(e, FIELD.USER)}
          fill={true}
        />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.SAVE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWProfileChangeName>
  );
});

const TWProfileChangeName = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
