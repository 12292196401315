import {Store, Action, action, actionOn, ActionOn, Computed, computed} from 'easy-peasy';
import { Middleware } from 'redux';
import { History, Location, Action as HistoryAction } from 'history';
import { STORE_PARAM } from 'const';
import {parseSearchParams} from 'utils';

let isReduxTraveling = false;

export type TRouterState = {
  location: Location;
  action: HistoryAction;
  route: Action<TRouterState, TRouterState>;
  push: Action<TRouterState, { to: string | Location }>;
  // push: Action<TRouterState, { to: string | Location }>;
  replace: Action<TRouterState, { to: string | Location }>;
  root: Computed<TRouterState, string>;
  path: Computed<TRouterState, string>;
  params: Computed<TRouterState, {[key: string]: any;}>;
  hash: Computed<TRouterState, string>
}
export interface IRouterModel {
  router?: TRouterState;
}
type ReduxHistoryContext = {
  routerModel: TRouterState
  routerMiddleware: Middleware
  createReduxHistory: (store: Store) => History
}

// https://github.com/ctrlplusb/easy-peasy/issues/304
export function createReduxHistoryContext(options: { history: History, reduxTravelling?: boolean, routerReducerKey?: string }): ReduxHistoryContext {
  let { history, routerReducerKey, reduxTravelling } = options;
  if (!routerReducerKey) routerReducerKey = STORE_PARAM.ROUTER_KEY;
  console.log("===history===>", history);
  return ({
    routerModel: {
      location: history.location,
      action: history.action,
      route: action((state, payload) => {
        return {
          ...state,
          location: payload.location,
          action: payload.action
        }
      }),
      push: action((state, payload) => {
        return ({ ...state });
      }),
      replace: action((state, payload) => {
        return ({...state});
      }),
      root: computed(state => {
        if (typeof window !== "undefined") {
          return `${window.location.protocol}//${window.location.hostname}`;
        }
        return "";
      }),
      path: computed(state => (state.location.pathname || "").replace(/\/$/, "")),
      params: computed(state => {
        const { search } = state.location;
        const p = parseSearchParams(search);
        return p;
      }),
      hash: computed(state => {
        return state.location.hash || "";
      })
    },
    routerMiddleware: (store) => {
      return (next) => {
        return (action) => {
          switch(action.type) {
            case `@action.${routerReducerKey}.push`:
              history.push(action.payload.to);
              break;
            case `@action.${routerReducerKey}.replace`:
              history.replace(action.payload.to);
              break;

            case `@@router/CALL_HISTORY_METHOD`:
              let method = action.payload && action.payload.method || '';
              switch(method) {
                case 'push':
                case 'replace':
                case 'go':
                case 'goBack':
                case 'goForward':
                  history[method].call(history, ...action.payload.args);
              }
              break;
          }

          return next(action);
        }
      }
    },
    createReduxHistory: (store: Store) => {
      history.listen((({location, action}) => {
        if ( !isReduxTraveling ) {
          let actions = store.getActions();
          if ( routerReducerKey! in actions ) {
            actions[routerReducerKey!].route({ location, action, })

          }
        }
        isReduxTraveling = false;
      }) as any);

      if ( reduxTravelling === true ) {
        store.subscribe(() => {
          let state = store.getState();
          if ( routerReducerKey! in state ) {
            let router: TRouterState = state[routerReducerKey!];
            if ( router.location.key !== history.location.key ) {
              isReduxTraveling = true;
              history.push(router.location);
              isReduxTraveling = false;
            }
          }
        });
      }

      return history;
    }
  })
}
