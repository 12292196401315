import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {TagLabel, CheckBox, ITextBox, ETagColor, ETagType} from 'components';
import {useCheckBox} from 'hooks';
import { ifCond } from 'utils';

export interface ITagSwitch {
  //TagLabel props
  color?: ETagColor;
  type?: ETagType;
  text?: string | ITextBox;
  fill?: boolean;
  //CheckBox props
  checked?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  onChange?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const TagSwitch = React.forwardRef((props: ITagSwitch, ref) => {
  const { checked=false, color, value, onChange, onlyTrustedOnChange, type=ETagType.SWITCH, text, fill, demo=false, if:_if=true, className, style: styleProp, ...checkBoxProps } = props;
  const [marked, setMarked, handleChecked] = useCheckBox(checked, onChange, value, onlyTrustedOnChange);
  const style = {...(styleProp || {}), ...{paddingLeft: (marked ? 14 : 25), paddingRight: (marked ? 14 : 24)}};
  if ( !ifCond(_if) ) return null;
  return (
    <TagLabel ref={ref} {...{color, type, text, fill}} className={className} style={style} icon={
      <TWCheckBox type="checkbox" color={color} checked={marked} onChange={handleChecked} {...checkBoxProps} />
    }/>
  );
});



const TWCheckBox = styled("input")(({ color, text }) => [
  tw`inline-block relative outline-none align-top cursor-pointer border-0 m-0`,
  //tw`bg-inherit`,
  tw`disabled:cursor-not-allowed disabled:opacity-90`,//disabled
  color===ETagColor.GHOST ? tw`after:border-light-secondary` : tw`after:border-white`,
  tw`after:border-2 after:border-solid checked:after:opacity-100 after:opacity-0
      after:transform after:rotate-20 checked:after:transform checked:after:rotate-45 
      after:absolute after:block after:top-px after:left-7p after:border-t-0 after:border-l-0
      after:w-5p after:h-9p`,//after
  tw`checked:w-14p checked:h-14p checked:ml-7p w-0 h-14p ml-0 `,
  css` 
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s, width .2s, height .2s, margin-left .2s;
      &:after {
        content: '';
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
  `
]);