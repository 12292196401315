import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  EButtonKind, EIcon,
  EIconSize,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight,
  Icon,
  TextBox,
  If, Avatar, EVAlign
} from 'components';
import { IFunctions } from 'types';
import {PLAN, ROLE, ROUTE} from 'const';
import {useBrowser, useMediaBreakpoints, useStoreState} from 'hooks';
import {ifCond, str} from 'utils';

export enum EMenuUnique {
  screenshots,
  settings,
  download,
  browser_Opera,
  browser_Firefox,
  browser_Chrome,
  about,
  signOut,
  signIn,
  signUp,
  upgrade,
  donate,
  profile,
  team,
  billing,
}
export interface IMenu extends TThemed {
  profile?: any;
  isGuest?: boolean;
  isLoggedIn?: boolean;
  onClick?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Menu = React.forwardRef((props: IMenu, ref) => {
  
  const { profile, isGuest, isLoggedIn, onClick, onClose, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const { avatar, username, plan=PLAN.NONE } = profile || {} as any;
  const MSG = useStoreState(s=>s.MSG);
  const path = useStoreState(s=>s.router.path);
  const bp = useMediaBreakpoints();
  const [ BrowserIcon, BrowserName ] = useBrowser();
  
  const handleClick = React.useCallback((e, menuItemName) => {
    if (onClick instanceof Function) onClick(e, menuItemName);
  }, [onClick]);
  const handleClose = React.useCallback(() => {
    if (onClose instanceof Function) onClose();
  }, [onClose]);
  if ( !ifCond(_if) ) return null;
  return (
    <TWMenu ref={ref} {...rest}>
      <TWProfileSection>
        <TWItemIcon>
          <Avatar avatar={avatar} />
        </TWItemIcon>
        <TWItemContent>
          <TextBox text={profile && profile.type === 'temp' ? MSG.AUTH.UNAUTHORIZED : isLoggedIn ? (username || MSG.AUTH.UNKNOWN) : (username || MSG.AUTH.UNAUTHORIZED)} size={ETextSize.MEDIUM} weight={ETextWeight.SEMIBOLD} inline={false} block={true}/>
          <TextBox text={str(MSG.BRAND_,plan.name || PLAN.FREE.name)} size={ETextSize.SMALL} color={ETextColor.SECONDARY} inline={false} block={true} />
        </TWItemContent>
        <If if={bp.mobile}>
          <TWClose onClick={handleClose}>
            <Icon icon={EIcon.CloseGray} size={10} align={EVAlign.BASE}/>
          </TWClose>
        </If>
      </TWProfileSection>
      <div tw="relative ml-auto bg-gray-350 ml-70p h-px"/>
      <TWMenuOptions mobile={bp.mobile}>
        <TWMenuList>
          <If if={!isGuest}>
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.screenshots)}>
              <TWItemIcon> <Icon icon={EIcon.History} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.MENU.HISTORY} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.settings)}>
              <TWItemIcon> <Icon icon={EIcon.Sun} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.MENU.SETTINGS} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
            <If if={bp.mobile}>
              <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.profile)}>
                <TWItemIcon>  </TWItemIcon>
                <TWItemContent> <TextBox text={MSG.MENU.MY_SETTINGS} color={path === ROUTE.PROFILE ? ETextColor.BLUE : ETextColor.PRIMARY} inline={false} block={true}/> </TWItemContent>
              </TWMenuItem>
              <If if={!!profile?.team}>
                <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.team)}>
                  <TWItemIcon>  </TWItemIcon>
                  <TWItemContent> <TextBox color={path === ROUTE.TEAM ? ETextColor.BLUE : ETextColor.PRIMARY} text={MSG.MENU.TEAM_SETTINGS} inline={false} block={true}/> </TWItemContent>
                </TWMenuItem>
              </If>
              <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.billing)}>
                <TWItemIcon> </TWItemIcon>
                <TWItemContent> <TextBox text={MSG.MENU.BILLING} color={path === ROUTE.BILLING ? ETextColor.BLUE : ETextColor.PRIMARY} inline={false} block={true}/> </TWItemContent>
              </TWMenuItem>
            </If>
          </If>
          <If if={bp.desktop && (BrowserName === 'Chrome' || BrowserName === 'Firefox' || BrowserName === 'Opera' || BrowserName === 'Yandex')}>
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.download)}>
              <TWItemIcon> <Icon icon={EIcon.DownloadRounded} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.MENU.DOWNLOAD_APP} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
            <TWMenuItem onClick={(e) => handleClick(e, `browser_${BrowserName}`)}>
              <TWItemIcon> <Icon icon={BrowserIcon} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={str(MSG.MENU.ADD_TO, BrowserName)} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
          </If>
          <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.about)}>
            <TWItemIcon> <Icon icon={MSG.ICON} size={EIconSize.MD}/> </TWItemIcon>
            <TWItemContent> <TextBox text={MSG.MENU.ABOUT} inline={false} block={true}/> </TWItemContent>
          </TWMenuItem>
          <If if={!isLoggedIn} else={
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.signOut)}>
              <TWItemIcon> <Icon icon={EIcon.LogOut} width={25} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.AUTH.LOG_OUT} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
          }>
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.signUp)}>
              <TWItemIcon> <Icon icon={EIcon.Enter} size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.MENU.SIGN_UP_FREE} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
            <TWMenuItem onClick={(e) => handleClick(e, EMenuUnique.signIn)}>
              <TWItemIcon> <Icon icon={ EIcon.LogIn } size={EIconSize.MD}/> </TWItemIcon>
              <TWItemContent> <TextBox text={MSG.AUTH.SIGN_IN} inline={false} block={true}/> </TWItemContent>
            </TWMenuItem>
          </If>
        </TWMenuList>
        <If>
          <TWButtonSection>
            <Button if={plan.unique===PLAN.FREE.unique} caption={MSG.MENU.UPGRADE_BRAND} onClick={(e) => handleClick(e, EMenuUnique.upgrade)} kind={EButtonKind.PRIMARY} />
            <Button if={plan.unique===PLAN.PRO.unique && (path === ROUTE.BILLING || path === ROUTE.PROFILE) && !profile.team} caption={MSG.MENU.UPGRADE_BRAND_TEAM} onClick={(e) => handleClick(e, EMenuUnique.upgrade)} kind={EButtonKind.OUTLINED_PRIMARY} />
            <TextBox if={(plan.unique===PLAN.FREE.unique || (plan.unique===PLAN.PRO.unique && (path === ROUTE.BILLING || path === ROUTE.PROFILE))) && !profile.team} text={MSG.OR} align={ETextAlign.CENTER} fillX={true} fillY={true} inline={false} block={true}/>
            <Button caption={MSG.UPGRADE_PLAN.DONATE_BRAND} onClick={(e) => handleClick(e, EMenuUnique.donate)} kind={EButtonKind.OUTLINED_PRIMARY} />
          </TWButtonSection>
        </If>
      </TWMenuOptions>
    </TWMenu>
  );
});

const TWMenu = styled("article")(({ darkMode }) => [
  tw`relative w-270p h-auto rounded-b-sm bg-white z-30`,
  tw`mobile:min-w-screen mobile:min-h-screen mobile:overflow-y-scroll`,
  darkMode ? tw`shadow-menu-dark` : tw`shadow-menu-light`,
]);
const TWClose = styled("div")(({ darkMode }) => [
  tw`absolute right-34p top-20p cursor-pointer`,
]);
const TWMenuOptions = styled("div")(({ mobile }) => [
  tw`flex flex-col justify-between w-full`,
  mobile && css`height: calc(100vh - 70px)`
]);
const TWProfileSection = styled("section")(() => [ tw`grid grid-rows-full col-gap-10p items-center content-center h-70p`, css`grid-template-columns: 60px 1fr;` ]);
const TWItemIcon = styled("section")(() => [ tw`grid pl-20p`, css`justify-self: center;` ]);
const TWItemContent = styled("section")(() => [ tw`pr-20p`, ]);
const TWAvatar = styled("img")(() => [ tw`inline-block relative w-40p h-40p`, ]);
const TWMenuList = styled("section")(() => [ tw`grid grid-flow-row grid-rows-auto w-full pt-10p pb-18p`, ]);
const TWMenuItem = styled("section")(() => [ tw`grid grid-rows-full col-gap-10p items-center content-center h-40p w-full bg-white cursor-pointer hover:bg-accent-100`, css`grid-template-columns: 60px 1fr;` ]);
const TWButtonSection = styled("section")(() => [ tw`grid grid-flow-row grid-rows-auto row-gap-10p px-20p pb-30p`, ]);
