import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  EButtonKind, EIcon,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight,
  Icon, If,
  Panel,
  SpaceBox,
  Switch,
  TextBox
} from 'components';
import {PLAN} from 'const';
import {formatPrice, ifCond, str} from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";

export interface IComparePlansMobile extends TThemed {
  onUpgradePro: any;
  onUpgradeTeam: any;
  team?: boolean;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const ComparePlansMobile = React.forwardRef((props: IComparePlansMobile, ref) => {
  const { onClose, raw=false, darkMode=false, demo=false, team=false, onUpgradePro = () => {}, onUpgradeTeam = () => {}, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const profile = useStoreState(s=>s.profile);
  const [teamPlan, setTeamPlan] = React.useState(team);
  const [yearly, setYearly] = React.useState(false);
  const PRICES = {
    PRO: [formatPrice(PLAN.PRO.monthly), formatPrice(PLAN.PRO.yearly)],
    TEAM: [formatPrice(PLAN.TEAM.monthly), formatPrice(PLAN.TEAM.yearly)],
  };
  const PeriodProps = {
    leftText: [MSG.MONTHLY, {color: ETextColor.PRIMARY, weight: ETextWeight.SEMIBOLD}, {color: ETextColor.SECONDARY} ],
    rightText: [MSG.YEARLY, {color: ETextColor.SECONDARY, weight: ETextWeight.SEMIBOLD}, {color: ETextColor.PRIMARY} ]
  };
  const handleClose = React.useCallback((e) => { if (onClose instanceof Function) onClose(e) }, [onClose]);
  const handleChange = React.useCallback((e, check) => {
    if (check !== yearly) setYearly(check);
  }, [yearly, setYearly]);
  const handleUpgradePro = React.useCallback(() => onUpgradePro(), []);
  const handleUpgradeTeam = React.useCallback(() => onUpgradeTeam(), []);
  if ( !ifCond(_if) ) return null;
  return (
    <TWComparePlans ref={ref} darkMode={darkMode} {...rest}>
      <Panel onClose={handleClose} noRoundBorders={bp.mobile} width={'100%'} padding={0} align={ETextAlign.CENTER}>
        <TWHeader><TextBox text={MSG.UPGRADE_PLAN.HEADER} width={'auto'} tw={'mx-10p'} weight={ETextWeight.SEMIBOLD} size={ETextSize.LARGE} align={ETextAlign.CENTER} block={true}/></TWHeader>
        <SpaceBox height={45} />
        <TWOptions>
          <TWIconCol onClick={() => (setTeamPlan(false))} proPlan={!teamPlan}>
            <TWIcon>
              <Icon icon={EIcon.Pro} size={40}/>
            </TWIcon>
            <TextBox text={`${MSG.BRAND} ${MSG.PLAN.PRO}`} weight={ETextWeight.SEMIBOLD} size={ETextSize.LARGE} align={ETextAlign.CENTER} block={true}/>
          </TWIconCol>
          <TWIconCol onClick={() => (setTeamPlan(true))} teamPlan={teamPlan}>
            <TWIcon>
              <Icon icon={EIcon.Team} size={45}/>
            </TWIcon>
            <TextBox text={`${MSG.BRAND} ${MSG.PLAN.TEAM}`} weight={ETextWeight.SEMIBOLD} size={ETextSize.LARGE} align={ETextAlign.CENTER} block={true}/>
          </TWIconCol>
        </TWOptions>
        <TWFeats>
          <If if={teamPlan} else={
            <TWFeatsCol>
              <SpaceBox height={20}/>
              <TextBox text={`${MSG.BRAND} ${PLAN.PRO.name}`} weight={ETextWeight.SEMIBOLD}
                       size={ETextSize.XLARGER} align={ETextAlign.CENTER} fillX={true} block={true}
              />
              <SpaceBox height={22}/>
              {MSG.UPGRADE_PLAN.PRO_FEATS.map((f,i) => {
                return (<TextBox key={i} text={f}
                                 inline={false} block={true} fillX={true}
                  // height="1.79em"
                                 tw={'mt-5p'}
                                 align={ETextAlign.CENTER}
                />);
              })}
            </TWFeatsCol>
          }>
            <TWFeatsCol>
              <SpaceBox height={20}/>
              <TextBox text={`${MSG.BRAND} ${PLAN.TEAM.name}`} weight={ETextWeight.SEMIBOLD}
                       size={ETextSize.XLARGER} align={ETextAlign.CENTER} fillX={true} block={true}
              />
              <SpaceBox height={22}/>
              {MSG.UPGRADE_PLAN.TEAM_FEATS.map((f,i) => {
                return (<TextBox key={i} text={f}
                                 weight={i===0 ? ETextWeight.BOLD : ETextWeight.REGULAR}
                                 inline={false} block={true} fillX={true}
                                 height="1.79em" align={ETextAlign.CENTER}
                />);
              })}
            </TWFeatsCol>
          </If>
        </TWFeats>
        <SpaceBox height={45} />
        <TWSwitchRibbon><Switch checked={yearly} onChange={handleChange} {...PeriodProps}/>
          <If if={teamPlan} else={
            <TWPrice>
              <div tw="text-center">
                <TextBox text={PRICES.PRO[+yearly]} size={ETextSize.XXLARGER} weight={ETextWeight.BOLD} />
                <TextBox text={` / ${MSG.MONTH_BRIEF}`} size={ETextSize.LARGER}/>
              </div>
            </TWPrice>
          }>
            <TWPrice>
              <div tw="text-center">
                <TextBox text={PRICES.TEAM[+yearly]} size={ETextSize.XXLARGER} weight={ETextWeight.BOLD} />
                <TextBox text={` / ${MSG.MONTH_BRIEF}`} size={ETextSize.LARGER}/>
              </div>
            </TWPrice>
          </If>
        </TWSwitchRibbon>
        <TWFeatsCol>
          <SpaceBox width={'100%'} height={30} />
          <If if={teamPlan} else={
            <TWUpgradePlanBtn>
              <Button tw={'block m-auto'} width={'100%'} if={profile?.plan?.unique !== PLAN.PRO.unique || profile?.plan?.unique !== PLAN.TEAM.unique} caption={str(MSG.UPGRADE_PLAN.UPGRADE_TO, PLAN.PRO.name)} kind={EButtonKind.PRIMARY} onClick={handleUpgradePro}/>
            </TWUpgradePlanBtn>
          }>
            <TWUpgradePlanBtn>
              <Button tw={'block m-auto'} width={'100%'} if={profile?.plan?.unique !== PLAN.TEAM.unique} caption={str(MSG.UPGRADE_PLAN.UPGRADE_TO, PLAN.TEAM.name)} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleUpgradeTeam}/>
            </TWUpgradePlanBtn>
          </If>
          <SpaceBox width={'100%'} height={30} />
        </TWFeatsCol>
      </Panel>
    </TWComparePlans>
  );
});

const TWUpgradePlanBtn = styled("div")(() => [ tw`w-full px-10p`, css`` ]);
const TWComparePlans = styled("div")(() => [ tw``, css`` ]);
const TWPrice = styled("div")(() => [ tw`flex items-center`, css`` ]);
const TWHeader = styled("div")(() => [ tw`pt-60p text-center`, css`` ]);
const TWFeats = styled("div")(() => [ tw`flex justify-center`, css`` ]);
const TWOptions = styled("div")(() => [ tw`grid grid-cols-2`, css`justify-items: center;` ]);
const TWIcon = styled("div")(() => [ tw`grid items-center content-center w-full h-60p`, css`justify-items: center;` ]);
const TWFeatsCol = styled("div")(() => [ tw``, css`` ]);
const TWIconCol = styled("div")(({proPlan, teamPlan}) => [
  tw`w-full h-full flex flex-col justify-center items-center cursor-pointer h-100p`,
  proPlan && tw`border-accent-300 border-t border-r border-solid`,
  teamPlan && tw`border-accent-300 border-t border-l border-solid`,
  (!teamPlan && !proPlan) && tw` bg-accent-100 border-accent-300 border-b border-solid`,
  css`` ]);
const TWSwitchRibbon = styled("div")(() => [ tw`flex justify-between bg-accent-100 px-15p h-70p`, css`` ]);
