import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {keyframes} from 'styled-components';
import {cssSize, ifCond, injectPropsIntoChildren} from 'utils';
import {ETextAlign, ETextColor, ETextSize, ETextWeight, If, SpaceBox, TextBox} from 'components';

export interface IButton extends TThemed {
  kind: EButtonKind | number;
  fill?: boolean; // false
  width?: number | string;
  caption?: string;
  disabled?: boolean;
  padding?: EButtonPadding;
  minWidth?: number | string;
  href?: string;
  onClick?: Function;
  if?: boolean | (() => boolean);
  darkMode?: boolean;
  demo?: boolean;
  [prop: string]: any;
}
export enum EButtonKind {
  LINK="link",
  LINK_CURRENT="link-current",
  PRIMARY="primary",
  OUTLINED_PRIMARY="outlined-primary",
  OUTLINED_SECONDARY="outlined-secondary",
  CRITICAL="critical"
}
export enum EButtonPadding {
  MD = 30, SM = 25, XS = 20
}

export const Button = React.forwardRef((props: IButton, ref) => {
  const { demo=false, caption, darkMode=false, disabled=false, if:_if=true, fill=false, width="auto", padding=EButtonPadding.MD, href, kind="primary", ...rest } = props;
  const classNames = [`btn-${kind}`];
  const [coords, setCoords] = React.useState(null);
  const handleDown = React.useCallback((e) => {
    if (disabled || coords) return;
    const btn = e.currentTarget.getBoundingClientRect();
    const top = e.clientY - btn.top;// - btn.height;
    const left = e.clientX - btn.left;// - (btn.width/2) + 8;
    setCoords({ top, left } as {top:number, left: number});
    //if (onClick instanceof Function) onClick(e);
  }, [disabled, setCoords, coords]);

  if ( !ifCond(_if) ) return null;
  return (
    <TWButton
      ref={ref}
      disabled={disabled}
      kind={kind}
      fillX={fill}
      width={width}
      padding={padding}
      className={classNames}
      onMouseDown={handleDown}
      darkMode={darkMode}
      {...rest}
    >
      <TextBox className="button-caption" text={caption} color={ETextColor.INHERIT} weight={ETextWeight.INHERIT} size={ETextSize.INHERIT} fillX={true} fillY={true} inline={false} block={false} align={ETextAlign.CENTER}/>
      <TWRippleContainer padding={padding}>
        <If if={!!coords && !disabled}>
          <TWRipple kind={kind} coords={coords} disabled={disabled} onAnimationEnd={()=>setCoords(null)}/>
        </If>
      </TWRippleContainer>
    </TWButton>
  );
});
const TWButton = styled('button')(({ theme, disabled, kind, fillX, padding, width, minWidth }) => [
    tw`relative rounded-lg px-30p h-40p text-medium font-semibold font-sans
       outline-none focus:outline-none overflow-hidden align-middle
    `,
    [EButtonKind.OUTLINED_PRIMARY, EButtonKind.OUTLINED_SECONDARY].includes(kind) && (
      css`&:hover:not(:disabled)>.button-caption{${tw`underline`}}`
    ),
    [EButtonKind.LINK].includes(kind) && (
      css`&:hover:not(:disabled)>.button-caption{${tw`underline`}}
`
    ),
  [EButtonKind.LINK_CURRENT].includes(kind) && (
    css`&:hover:not(:disabled)>.button-caption{${tw`underline`}}
`
  ),
    css`
      padding-left: ${padding}px;
      padding-right: ${padding}px;
      ${cssSize(minWidth, "min-width")}
    `,
    fillX ? tw`w-full` : css`
      ${cssSize(width, "width")}
    `,
    ({
      [EButtonKind.PRIMARY]: [
        tw`bg-button-bf hover:bg-button-bfh active:bg-button-bfa
          text-button-bft hover:text-button-bfht active:text-button-bfat
          disabled:bg-button-bfd disabled:text-button-bfdt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.bfar}; }`
      ],
      [EButtonKind.OUTLINED_PRIMARY]: [
        tw`border-solid border-2
          border-button-bo hover:border-button-boh active:border-button-boa
          text-button-bot hover:text-button-boht active:text-button-boat
          disabled:border-button-bod disabled:text-button-bodt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.boar}; }`
      ],
      [EButtonKind.OUTLINED_SECONDARY]: [
        tw`border-solid border-2
          border-button-go hover:border-button-goh active:border-button-goa
          text-button-got hover:text-button-goht active:text-button-goat
          disabled:border-button-god disabled:text-button-godt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.goar}; }`
      ],
      [EButtonKind.LINK]: [
        tw`bg-button-rfat hover:bg-button-goar active:bg-button-goa
          text-button-bl hover:text-button-bl active:text-button-bl
          disabled:bg-button-bfd disabled:text-button-bfdt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.goar}; }`
      ],
      [EButtonKind.LINK_CURRENT]: [
        tw`bg-button-rfat hover:bg-button-goar active:bg-button-goa
          text-button-bot hover:text-button-boht active:text-button-boat
          disabled:bg-button-bfd disabled:text-button-bfdt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.goar}; }`
      ],
      [EButtonKind.CRITICAL]: [
        tw`bg-button-rf hover:bg-button-rfh active:bg-button-rfa
          text-button-rft hover:text-button-rfht active:text-button-rfat
          disabled:bg-button-rfd disabled:text-button-rfdt
        `,
        //css`&> .ripple { background-color: ${theme.colors.button.rfar}; }`
      ]
    })[kind],
    disabled && tw``,
    css`
      &> .ripple {
        width: 20px;
        height: 20px;
        position: absolute;
        display: block;
        content: "";
        border-radius: 9999px;
        opacity: 1;
        z-index: 10;
        animation: 1.2s ease 1 forwards ripple-effect;
      }
    `,
    css`&>.button-caption { ${tw`relative -top-px left-0 z-10`} }`
  ]
);
const rippleEffect = keyframes`
  from { opacity: 1; transform: scale(0); }
  to { opacity: 0; transform: scale(35); }
`;
const easedRippleEffect = keyframes`
  0% { transform: scale(1); opacity: 1; }
  25% { opacity: 1; }
  50% { transform: scale(10); opacity: 0.375; }
  100% { transform: scale(35); opacity: 0; }
`;
const TWRippleContainer = styled("div")(({ theme, darkMode, disabled, padding }) => [
  tw`relative z-0 -top-40p h-full`,
  css`
    width: calc(100% + ${cssSize(padding)} + ${cssSize(padding)} );
    ${cssSize(-padding, "margin-left")}
  `
]);
const TWRipple = styled("span")(({ theme, darkMode, disabled, kind, coords }) => [
  disabled && tw`hidden`,
  tw`absolute inline-block rounded-half -mt-15p -ml-15p w-30p h-30p opacity-0 z-0`,
  ({
    [EButtonKind.PRIMARY]: tw`bg-button-bfar`,
    [EButtonKind.OUTLINED_PRIMARY]: tw`bg-button-boar`,
    [EButtonKind.OUTLINED_SECONDARY]: tw`bg-button-goar`,
    [EButtonKind.CRITICAL]: tw`bg-button-rfar`,
  })[kind],
  false && css`animation: 1.2s ease 1 forwards ${easedRippleEffect};`,
  css`animation: ${rippleEffect} 1s;`,
  coords && css`
    ${cssSize(coords.top, "top")}
    ${cssSize(coords.left, "left")}
  `
]);

////////////////////////////////////////////////////////////////////////////////

export interface IButtons {
  gap?: number | string;
  align?: EButtonsAlign;
  disabled?: boolean;
  children?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum EButtonsAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  ADJUST = "justify",
}

export const Buttons = React.forwardRef((props: IButtons, ref) => {
  const { gap=10, left, right, align=EButtonsAlign.RIGHT, disabled=false, if:_if=true, children, ...rest } = props;
  const decoratedChildren = children && disabled ? injectPropsIntoChildren(children, {...{ disabled }}) : children;
  const lefty = [EButtonsAlign.RIGHT, EButtonsAlign.CENTER].includes(align);
  const righty = [EButtonsAlign.LEFT, EButtonsAlign.CENTER].includes(align);
  const count = React.Children.count(children);
  if ( !ifCond(_if) ) return null;
  return (<TWButtons {...{gap, align, disabled, count, lefty, righty}} {...rest}>
    <SpaceBox if={lefty && !left} inline={true} width="stretch"/>
    {lefty && left}
    {decoratedChildren}
    {righty && right}
    <SpaceBox if={righty && !right} inline={true} width="stretch"/>
  </TWButtons>);
});

const TWButtons = styled("div")(({ gap, align, count, lefty, righty }) => [
  tw`inline-grid grid-flow-col items-center content-center w-full`,
  css`
    grid-template-columns: ${lefty ? "1fr" : ""} repeat(${count}, minmax(89px, auto)) ${righty ? "1fr" : ""};
    ${cssSize(gap,"grid-column-gap")}
    ${cssSize(align, "justify-items")}
  `
]);
