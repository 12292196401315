import * as React from 'react';
import {EIcon, ETextAlign, ETextWeight, Icon, TextBox} from 'components';
import tw, {css, styled} from 'twin.macro';

interface Props {
  /** Prev button click event */
  onPrev?: () => void;
  /** Next button click event */
  onNext?: () => void;
  /** Calenar Title Click Event */
  onTitleClick?: () => void;
  /** Prev Icon show or Hide */
  prevIcon?: boolean;
  /** Next icon show or hide */
  nextIcon?: boolean;
  /** Title to show in calendar  */
  title?: string;
  locale?: string;
}

const defaultProps = {
  title: '',
};

const CalendarHead: React.FunctionComponent<Props> = ({
  onPrev,
  onNext,
  prevIcon,
  nextIcon,
  title,
  onTitleClick,
}) => {
  return (
    <div className="calendar__head">
      <div className="calendar__head--prev">
        {prevIcon && (
          <button onClick={onPrev} className="calendar__head--button">
            <Icon icon={EIcon.ChevronLeftGray} size={12}/>
          </button>
        )}
      </div>
      <h2 className="calendar__head--title"
          style={{textTransform: "capitalize"}}
          onClick={onTitleClick}>
        <TextBox text={title} weight={ETextWeight.SEMIBOLD} align={ETextAlign.CENTER} fillX={true} block={true}/>
      </h2>
      <div className="calendar__head--next">
        {nextIcon && (
          <button onClick={onNext} className="calendar__head--button">
            <Icon icon={EIcon.ChevronRightGray} size={12}/>
          </button>
        )}
      </div>
    </div>
  );
};

CalendarHead.defaultProps = defaultProps;

export default CalendarHead;
