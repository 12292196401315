import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {CheckBox, EIcon, EIconSize, ETagColor, Icon, withHref, TextBox, SpaceBox, If} from 'components';
import {useSplitEffect} from 'hooks';
import {callIfExists, cssSize, ifCond, isArrayEqual, inCaseEmptyArray} from 'utils';
import Scrollbar from "react-scrollbars-custom";

export interface ISwatch extends TThemed {
  type: ESwatchType;
  items: ISwatchItem[];
  value?: string | number | null | Array<string|number>;
  allowNone?: boolean;
  multiple?: boolean;
  noData?: any;
  errors?: string[] | null;
  listHeight?: number | string;//360
  listAction?: ISwatchAction;
  itemSize?: ESwatchItemSize;
  onChange?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export interface ISwatchAction {
  action: (any) => ISwatchItem;//takes items[i]
  icon?: string;
  iconHover?: string;
}
export enum ESwatchType {
  SELECT = "select", LINKS = "links", LIST = "list"
}
export enum ESwatchColor {
  CORAL = "coral", FOX = "fox", GOLD = "gold", FOREST = "forest", JADE = "jade",
  CYAN = "cyan", ROYAL = "royal", VIOLET = "violet", ORCHID = "orchid", CRIMSON = "crimson"
}
export enum ESwatchItemSize {
  SM = 25, MD = 30, LG = 40
}
export interface ISwatchItem {
  id: string;
  type: ESwatchItemType;
  hint?: string;
  value?: any;
  color?: string | ESwatchColor;//oneof color, icon
  icon?: string;//oneof color, icon
  href?: string;
  onClick?: Function;
}
export enum ESwatchItemType {
  COLOR = "color", ICON = "href"
}
export const SwatchColorItems = Object.values(ESwatchColor).map( c => ({type: ESwatchItemType.COLOR, value: c, color: c, id: c}) );

export const Swatch = React.forwardRef((props: ISwatch, ref) => {
  const { type, items=[], value, item, errors=[], multiple=false, allowNone=false, itemSize=ESwatchItemSize.SM,
    noData, listAction, children, onChange, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const [checked, setChecked] = React.useState(value==null ? [] : [].concat(value));
  const isChecked = React.useCallback((what) => checked.includes(what), [checked]);
  React.useEffect(() => {
    if (!isArrayEqual( checked, [].concat(value || []) )) {
      setChecked([].concat(value || []));
    }
  }, [value]);
  const handleChange = React.useCallback((e, ch, what) => {
    if (multiple) {
      let newChecked = [];
      if ( ch && !isChecked(what) )  newChecked = [...checked,what];
        else if ( !ch && isChecked(what) )  newChecked = [...checked.filter(c => c !== what)];
      if ( onChange instanceof Function )  onChange(e, what, newChecked, checked[0]);
      setChecked(newChecked);
    } else {
      if ( ch ) {
        if ( onChange instanceof Function ) onChange(e, what, checked[0]);
        setChecked([what]);
      } else if ( !allowNone ) return false;
    }
  }, [checked, setChecked, isChecked, onChange, allowNone, multiple]);
  const handleAction = React.useCallback((e, item) => {
    callIfExists(listAction.action, item);
  }, [listAction]);

  if ( !ifCond(_if) ) return null;
  return ( type === ESwatchType.LIST
      ? <TWSwatchList ref={ref} darkMode={darkMode} className="custom-scrollbar" {...rest}>
          <Scrollbar>
        {items.map(({ type, id, hint, value: val, color}, key) => {
          return (<React.Fragment key={key}>
            <TWSwatchListItem>
              <TWLabel>
                <TWColor color={color}
                         title={hint}
                         itemSize={itemSize}
                >
                  <TWCheckBox
                    checked={isChecked(val)}
                    type="checkbox"
                    onChange={ (e) => handleChange(e,e.target.checked, val) }
                  />
                </TWColor>
                <SpaceBox inline width={10} />
                <TextBox text={hint}/>
                <SpaceBox inline width="stretch" />
              </TWLabel>
              <Icon icon={listAction && listAction.icon} iconHover={listAction && listAction.iconHover} onClick={(e) => handleAction(e, {type, hint, id, value, color})}/>
            </TWSwatchListItem>
            </React.Fragment>
          );
        })}
        {items.length>0 ? null : children}
      </Scrollbar>
      </TWSwatchList>
    : <TWSwatch length={items.length}
        ref={ref}
        darkMode={darkMode}
        errors={errors}
        {...rest}
    >
      {items.map(({ type, hint, value:val, id, color, icon, href, onClick}, key) => {
        if (type === ESwatchItemType.COLOR) {

          return <React.Fragment key={key}>{withHref(
            <TWColor color={color}
              title={hint}
              itemSize={itemSize}
              style={href ? {cursor:"pointer"} : undefined}
              onClick={() => callIfExists(onClick, val, id, color, href)}
            >
              { !href && !onClick &&
                <TWCheckBox checked={isChecked(val)}
                  type="checkbox"
                  onChange={ (e) => handleChange(e,e.target.checked, val) }
                />
              }
            </TWColor>
            , {href, key}
          )}</React.Fragment>;

        } else if (type === ESwatchItemType.ICON) {

          return <React.Fragment key={key}>{withHref(
            <Icon icon={icon}
              key={key}
              as={"label"}
              hint={hint}
              size={EIconSize.LG}
              style={href ? {cursor:"pointer"} : undefined}
              onClick={() => callIfExists(onClick, val, id, color, href)}
            >
              { !href && !onClick &&
                <TWCheckBox checked={isChecked(val)}
                  type="checkbox"
                  onChange={ (e) => handleChange(e,e.target.checked, val) }
                />
              }
            </Icon>
            , {href, key}
          )}</React.Fragment>;
        }
        return null;
      })}
    </TWSwatch>
  );
});

const TWColor = styled("label")(({ theme, disabled, color, itemSize }) => [
  tw`inline-block rounded-full`,
  css`
    background-color: ${theme.colors[color]};
    height: ${itemSize}px;
    width: ${itemSize}px;
  `,
]);


const TWSwatch = styled("div")(({theme, darkMode, length, type, errors}) => [
    tw`grid grid-flow-col justify-center w-full`,
    css`
      grid-auto-columns: 1fr;
      grid-template-columns: repeat(${length}, 1fr);
      justify-items: center;
    `,
    errors.length>0 && tw`pb-2p rounded-sm border-b border-solid border-error`
  ]
);
const TWSwatchList = styled("div")(({theme, darkMode, length, type, listHeight=360}) => [
    tw`grid grid-flow-row grid-cols-full grid-rows-full items-start content-start justify-start left-0 right-0 overflow-x-visible -mx-30p w-full`,//overflow-y-auto
    css`
      justify-items: start;
      ${cssSize(listHeight, "height")};
      width: calc(100% + 60px);
    `,
    css`
      /*scrollbars*/
        scrollbar-color: #5f6c76 transparent;
        scrollbar-width: thin;
      &::-webkit-scrollbar {
        ${tw`bg-transparent w-5p`}
      }
      &::-webkit-scrollbar-track {
        ${tw`bg-transparent`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`rounded-md h-50p`}
        border-style: solid;
        border-color: rgba(95,108,118,0.3);
        background-color: rgba(95,108,118,0.3);
      }
    `,
    css`
      &:not(:hover) .ScrollbarsCustom-Track { opacity: 0 !important; }
      .ScrollbarsCustom-Track {
        background-color: ${theme.colors.transparent} !important;
        transition: opacity .3s !important;
        width: 5px !important;
        right: 10px !important;
      }
      .ScrollbarsCustom-Thumb {
        background-color: ${theme.colors.light.secondary} !important;
        opacity: 0.3 !important;
        border-radius: 10px !important;
      }
      &:hover .ScrollbarsCustom-Wrapper {
        right: 0 !important;
      }
      
    `
  ]
);

const TWSwatchListItem = styled("div")(({ theme, disabled, itemSize=40 }) => [
  tw`relative flex content-center items-center pr-30p w-full`,
  tw`hover:bg-accent-100 cursor-pointer`,
  css`
    ${cssSize(itemSize, "height")};
    
  `,
  css`
    &:not(:hover) > i {
      display: none !important;
    }
  `
]);

const TWLabel = styled("label")(({ theme, darkMode, disabled }) => [
  tw`inline-flex items-center content-center flex-auto cursor-pointer pl-30p h-full`,
  css``
]);
const TWCheckBox = styled("input")(({ color, text, size }) => [
  tw`inline-block relative rounded-full outline-none align-top cursor-pointer border-0 m-0`,
  //tw`bg-inherit`,
  tw`disabled:cursor-not-allowed disabled:opacity-90`,//disabled
  color===ETagColor.GHOST ? tw`after:border-light-secondary` : tw`after:border-white`,
  tw`after:border-2 after:border-solid checked:after:opacity-100 after:opacity-0
      after:transform after:rotate-20 checked:after:transform checked:after:rotate-50 
      after:absolute after:block after:border-t-0 after:border-l-0
      after:w-5p after:h-10p`,//after  after:top-px after:left-7p
  tw`w-full h-full`,//14p
  css` 
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
        margin-left: calc(50% - 2px);
        margin-top: calc(50% - 6px);
      }
      &:checked {
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
  `
]);