import * as React from 'react';
import tw, { styled, css } from 'twin.macro';
import {ETextAlign, ITextBoxProps, TextBox} from 'components';
import {useCheckBox} from 'hooks';
import {ifCond} from 'utils';

export interface ICheckBox extends TThemed {
  checked?: boolean;
  reverse?: boolean;
  value?: any; //value to transmit into onChange
  disabled?: boolean;
  raw?: boolean;
  mark?: any; //typeof React.Component;
  text?: ITextBoxProps;
  onChange?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const CheckBox = React.forwardRef((props: ICheckBox, ref) => {
  const { checked=false, disabled=false, if:_if=true, text="", value, onChange, onlyTrustedOnChange=false, reverse=false, raw=false, mark: Mark, darkMode=false, demo=false, ...rest } = props;
  const caption = [].concat(text).concat(text+""===text || (text instanceof Array && text.length<2) ? text : []);
  const [marked, setMarked, handleChecked] = useCheckBox(checked, onChange, value, onlyTrustedOnChange);
  if ( !ifCond(_if) ) return null;
  return (
    raw && Mark
    ? <Mark ref={ref} checked={marked} disabled={disabled} text={caption[+marked]} reverse={reverse} onChange={handleChecked} type="checkbox" {...rest}/>
    : <TWCheckBoxLabel ref={ref} reverse={reverse}>
      <TextBox if={reverse} text={caption[+marked]} align={ETextAlign.RIGHT}/>
        <TWCheckBox checked={marked} disabled={disabled} text={caption[+marked]} reverse={reverse} onChange={handleChecked} type="checkbox" {...rest}/>
      <TextBox if={!reverse} text={caption[+marked]} align={ETextAlign.LEFT}/>
    </TWCheckBoxLabel>
  );
});
const TWCheckBoxLabel = styled("label")(({reverse}) => [
    tw`flex cursor-pointer align-middle items-center`,
    tw`disabled:cursor-not-allowed`,
    reverse ? tw`justify-end` : tw`justify-start`
  ]
);
const TWCheckBox = styled("input")(({ reverse, text }) => [
    tw`inline-block relative outline-none align-top cursor-pointer border border-solid m-0`,
    tw`bg-inherit border-accent-200`,
    tw`hover:border-accent-700 disabled:hover:border-accent-200`,
    tw`disabled:bg-accent-100 disabled:cursor-not-allowed disabled:opacity-90`,//disabled
    tw`checked:bg-accent-700 checked:border-accent-700 disabled:checked:bg-accent-200 
      disabled:checked:border-accent-200`,//checked
    tw`after:border-white after:border-2 after:border-solid checked:after:opacity-100 after:opacity-0
      after:transform after:rotate-20 checked:after:transform checked:after:rotate-45 
      after:absolute after:block after:top-px after:left-4p after:border-t-0 after:border-l-0
      after:w-5p after:h-9p`,//after
    tw`w-14p h-14p rounded-xxs`,
    text && (reverse ? tw`ml-10p` : tw`mr-10p`),
    css` 
      --focus: 2px rgba(0, 94, 197, .3);/*(39, 94, 254, .3);*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:after {
        content: '';
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
  `]
);