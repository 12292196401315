import React from 'react';
import ReactDOM from 'react-dom';
import tw, {css, styled} from 'twin.macro';
import { Router, Route, Switch as RouterSwitch, Redirect, Link, withRouter } from "react-router-dom";
import {
  Avatar,
  Button,
  EButtonKind,
  EMenuUnique,
  If,
  Menu,
  SpaceBox,
  Portal,
  Logo,
  ELogoSize,
  ProfileChangeName,
  ProfileChangeEmail,
  ProfileChangePwd,
  ProfileChangeLang,
  ClearHistory,
  DeleteAccount,
  HelpTranslate,
  Billing,
  BillingCancelSub,
  BillingChangeMethod,
  Modal,
  ComparePlans,
  UpgradePlan,
  Plug,
  SettingsNav,
  ComparePlansMobile, EIcon, Icon, EIconSize, TextBox, ETextColor,
} from 'components';
import {PlaygroundHeader, HistoryHeader, useHistoryContextMenu} from 'pages/root';
import {callIfExists, ifCond} from 'utils';
import {MSG, PLAN, PORTAL, PRODUCTS, ROUTE, URL_PARAM} from 'const';
import {useMediaBreakpoints, useStoreActions, useStoreState} from 'hooks';
import useOnclickOutside from "react-cool-onclickoutside";
import * as PAGE from "../root";
import {history as historie} from "../../store";
import {SuccessfulPayment} from "../../components/form/Payment/SuccessfulPayment";
import {ErrorPayment} from "../../components/form/Payment/ErrorPayment";
import {ICardItem} from "../../types";
import {isEmpty} from "lodash";

export interface IPageHeader extends TThemed {
  children?: React.ReactNode;
  onLogoClick?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const ComparePlansProps = {
  profile: { plan: PLAN.FREE }
};

const useHeaderActions = () => {
  const doLogout = useStoreActions(a=>a.logout);
  const doLogin = useStoreActions(a=>a.login);
  const doConfirm = useStoreActions(a=>a.confirm);
  const push = useStoreActions(a=>a.router.push);
  const path = useStoreState(a=>a.router.path);
  const router = useStoreState(a=>a.router);
  const replace = useStoreActions(a=>a.router.replace);
  const setHeaderOpened = useStoreActions(a=>a.screenshots.setHeaderOpened);
  const setCalendarOpened = useStoreActions(a=>a.screenshots.setCalendarOpened);
  const clearFieldMessages = useStoreActions(actions => actions.clearFieldMessages);
  const consumeUrlParams = useStoreActions(actions => actions.consumeUrlParams);
  const getProfile = useStoreActions(actions => actions.getProfile);
  return {doLogout, doLogin, doConfirm, push, path, router, replace, setHeaderOpened, setCalendarOpened, clearFieldMessages, consumeUrlParams, getProfile};
};

const useHeaderState = () => {
  const logo = useStoreState(s => s.logo);
  const profile = useStoreState(s=>s.profile);
  const MSG = useStoreState(s=>s.MSG);
  const isGuest = useStoreState(s=>s.isGuest);
  const isLoggedIn = useStoreState(s=>s.isLoggedIn);
  const headerOpened = useStoreState(s=>s.screenshots.headerOpened);
  const matchParams = useStoreState(state => state.router.params);
  const selectionMode = useStoreState(state => state.screenshots.selectionMode);
  return {logo, profile, MSG, isGuest, isLoggedIn, headerOpened, matchParams, selectionMode};
};

// const useHeaderModals = (mass = false) => {
//   const askSetName = useStoreActions(actions => actions.screenshots.askSetName);
//   return React.useCallback(() => {
//     const actions = [];
//     actions.push({onClick: () => {askSetName('test')}})
//     return actions;
//   }, [askSetName]);
// };

export const PageHeader = React.forwardRef((props: IPageHeader, ref) => {
  const { onLogoClick, currentFragment, position, actions, currentItem, currentSlug, raw=false, darkMode=false, demo=false, if:_if=true, children, ...rest } = props;
  
  const {logo, profile, MSG, isGuest, isLoggedIn, headerOpened, matchParams, selectionMode} = useHeaderState();
  const {doLogout, doLogin, doConfirm, push, path, router, replace, setHeaderOpened, setCalendarOpened, clearFieldMessages, consumeUrlParams, getProfile} = useHeaderActions();
  // const getHeaderActions = useHistoryContextMenu(false);
  const askSetPlans = useStoreActions(a=>a.screenshots.askSetPlans);
  const askSetPayPlan = useStoreActions(a=>a.screenshots.askSetPayPlan);
  const askSetPaymentResult = useStoreActions(a=>a.screenshots.askSetPaymentResult);
  const askSetDonate = useStoreActions(a=>a.screenshots.askSetDonate);
  const bp = useMediaBreakpoints();
  const reason = matchParams[URL_PARAM.REASON]
  const product = matchParams[URL_PARAM.PRODUCT]
  const result = matchParams[URL_PARAM.RESULT]
  
  
  const header = result === 'success' ? (
    product === PRODUCTS.PRO ? MSG.INFO.UPGRADED_PRO_SUCCESS :
    product === PRODUCTS.TEAM ? MSG.INFO.UPGRADED_TEAM_SUCCESS :
      product === PRODUCTS.VERIFICATION ? MSG.CARD_SUCCESS.VERIFICATION.message :
        product === PRODUCTS.SLOT ? MSG.CARD_SUCCESS.SLOT.message : '') :
    result === 'error' ? MSG.CARD_ERRORS[reason].reason : ''
  const desc = result === 'error' ? MSG.CARD_ERRORS[reason].message : ''
  const icon = result === 'error' ? EIcon.CloseCircle : result === 'success' ? (
    product === PRODUCTS.PRO ? MSG.CARD_SUCCESS.PRO.icon :
      product === PRODUCTS.TEAM ? MSG.CARD_SUCCESS.TEAM.icon :
        product === PRODUCTS.VERIFICATION ? MSG.CARD_SUCCESS.VERIFICATION.icon
          : product === PRODUCTS.SLOT ? MSG.CARD_SUCCESS.SLOT.icon :
          '') : EIcon.CloseCircle
  
  const { avatar } = profile || {} as any;
  const [openMenu, setOpenMenu] = React.useState(false);
  
  const [modalType, setModalType] = React.useState();
  const avatarRef = useOnclickOutside(() => { if (openMenu && !bp.mobile) setOpenMenu(false); });
  const headerRef = useOnclickOutside(() => { if (headerOpened) {
    setHeaderOpened(false);
    setCalendarOpened(false);
  } });
  const handleGo = React.useCallback((to, rep=false) => {
    if (rep) replace({to}); else push({to});
    setOpenMenu(false);
  }, [push, replace, setOpenMenu]);
  const handleMenuItemClick = React.useCallback(async (e, name) => {
    switch (name) {
      case EMenuUnique.screenshots:
        handleGo(ROUTE.HISTORY);
        break;
      case EMenuUnique.settings:
        handleGo(ROUTE.PROFILE);
        break;
      case EMenuUnique.profile:
        handleGo(ROUTE.PROFILE);
        break;
      case EMenuUnique.team:
        handleGo(ROUTE.TEAM);
        break;
      case EMenuUnique.billing:
        handleGo(ROUTE.BILLING);
        break;
      case EMenuUnique.download:
        break;
      case EMenuUnique.about:
        break;
      case EMenuUnique.signOut:
        doLogout();
        break;
      case EMenuUnique.signUp:
        handleGo(ROUTE.REG);
        break;
      case EMenuUnique.signIn:
        handleGo(ROUTE.AUTH);
        break;
      case EMenuUnique.upgrade:
        askSetPlans({
          mobile: bp.mobile,
          onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
          onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
          ...ComparePlansProps})
        break;
      case EMenuUnique.donate:
        askSetDonate({
          plan: profile.plan.unique
        })
        setOpenMenu(false);
        break;
      case EMenuUnique.browser_Opera:
        break;
      case EMenuUnique.browser_Chrome:
        break;
      case EMenuUnique.browser_Firefox:
        break;
      default:
        break;
    }
  }, [handleGo, doLogout, profile]);
  
  React.useEffect(() => {
    if (result) {
      askSetPaymentResult({header, reason: desc, success: result === 'success', icon})
      getProfile()
    }
  }, [result])
  React.useEffect(() => {
    if (bp.mobile) document.body.style.overflow = headerOpened ? 'hidden' : 'auto'
  }, [headerOpened])
  
  React.useEffect(() => {
    if (bp.mobile) document.body.style.overflow = openMenu ? 'hidden' : 'auto'
  }, [openMenu])
  const hasFilter = useStoreState(state => state.screenshots.hasFilter);
  const mainAction = React.useMemo(() => {
    if (!!selectionMode || bp.mobile) {return {if: false};}
    if (isGuest) {
      return {caption: MSG.MENU.DOWNLOAD_BRAND, if: true, onClick: (e) => handleMenuItemClick(e, EMenuUnique.download)};
    } else if (!isLoggedIn) {
      return {caption: MSG.MENU.SIGN_UP_FREE, if: true, onClick: (e) => handleMenuItemClick(e, EMenuUnique.signUp)};
    } else if (isLoggedIn && profile?.plan.unique === PLAN.FREE.unique) {
      return {caption: MSG.MENU.UPGRADE_BRAND, if: true, kind:EButtonKind.PRIMARY , onClick: (e) => handleMenuItemClick(e, askSetPlans({
          mobile: bp.mobile,
          onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
          onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
          ...ComparePlansProps}))};
    }
    else if ((isLoggedIn && profile?.plan.unique === PLAN.PRO.unique) && (path===ROUTE.PROFILE || path===ROUTE.BILLING) && !profile?.team) {
      return {caption: MSG.MENU.UPGRADE_BRAND_TEAM, if:true, kind:EButtonKind.OUTLINED_PRIMARY , onClick: (e) => handleMenuItemClick(e,
          askSetPlans({
          mobile: bp.mobile,
          onUpgradeTeam: () => {console.log(PLAN.TEAM.unique); askSetPayPlan({plan: PLAN.TEAM})},
          onUpgradePro: () => {console.log(PLAN.PRO.unique); askSetPayPlan({plan: PLAN.PRO})},
          ...ComparePlansProps}))};
    }
    else {
      clearFieldMessages()
      return {if: false};
    }
  }, [isGuest, isLoggedIn, handleMenuItemClick, profile, selectionMode, bp]);
  const isButton = mainAction.if
  /*<TWPageHeader ref={ref} darkMode={darkMode} {...rest}>*/
  if ( !ifCond(_if) ) return null;
  return (
    <TWPageHeader position={selectionMode} slug={!!currentSlug} selectionMode={selectionMode} headerOpened={headerOpened} logo={(!headerOpened || !bp.mobile) && !(bp.mobile && hasFilter && (profile?.plan?.unique === PLAN.PRO.unique || profile?.plan?.unique === PLAN.TEAM.unique))} mobile={bp.mobile} avatar={isGuest || selectionMode} isFree={!(profile?.plan?.unique === PLAN.PRO.unique || profile?.plan?.unique === PLAN.TEAM.unique)} button={isButton} ref={headerRef}>
      <If if={(!headerOpened || !bp.mobile) && !(bp.mobile && hasFilter && (profile?.plan?.unique === PLAN.PRO.unique || profile?.plan?.unique === PLAN.TEAM.unique))}>
        {(!selectionMode) &&
        <div tw="min-w-80p">
          <Logo src={darkMode ? logo.dark_logo : logo.light_logo} top={0} left={0} absolute size={!bp.mobile ? ELogoSize.NORMAL : ELogoSize.SMALL}
                darkMode={darkMode} onClick={(e) => callIfExists(onLogoClick, e)}/>
        </div>
        }
      </If>
      <RouterSwitch location={historie.location}>
        <Route exact path={ROUTE.HISTORY} component={HistoryHeader}/>
        <Route exact path={ROUTE.CLEAR_HISTORY} component={Plug}/>
        <Route path={ROUTE.AUTH} component={Plug}/>
        <Route path={ROUTE.REG} component={Plug}/>
        <Route path={ROUTE.RESET} component={Plug}/>
        <Route path={ROUTE.TEAM} component={bp.mobile ? Plug : SettingsNav}/>
        <Route path={ROUTE.PROFILE} component={bp.mobile ? Plug : SettingsNav}/>
        <Route path={ROUTE.BILLING} component={bp.mobile ? Plug : SettingsNav}/>
        <Route path={ROUTE.PLAYGROUND} component={PlaygroundHeader}/>
        <Route path={ROUTE.SLUG} component={Plug}/>
      </RouterSwitch>
      
      <Modal if={!!modalType} closeOnMisclick={!!modalType} onClose={() => {setModalType('')}}>
        {/*{modalType === 'plans' && (bp.mobile ? <ComparePlansMobile {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/> : <ComparePlans {...ComparePlansProps} onClose={() => setModalType('')} onUpgradePro={() => setModalType('upgrade-pro')} onUpgradeTeam={() => setModalType('upgrade-team')}/>)}*/}
        {/*{modalType === 'upgrade-pro' && <UpgradePlan plan={PLAN.PRO} onClose={() => setModalType('plans')}/>}*/}
        {/*{modalType === 'upgrade-team' && <UpgradePlan plan={PLAN.TEAM} onClose={() => setModalType('plans')}/>}*/}
        {/*{modalType === 'pay-success' && <SuccessfulPayment header={header} icon={icon} onClose={() => {*/}
        {/*  consumeUrlParams({clearHash: true, params: []})*/}
        {/*  setModalType('')*/}
        {/*}}/>}*/}
        {/*{modalType === 'pay-error' && <ErrorPayment header={header} reason={desc} icon={icon} onClose={() => {*/}
        {/*  consumeUrlParams({clearHash: true, params: []})*/}
        {/*  setModalType('')*/}
        {/*}}/>}*/}
      </Modal>
      <div style={{display: 'flex'}}>
        <If if={!!currentItem && !!currentSlug || !!currentFragment || !!profile}>
          <div style={{display: 'flex'}}>
            <If if={!!currentSlug || (!!currentFragment && !!currentSlug)}>
              <TWMassOps breakpoint={bp}>
                {actions.map((act, key) => (
                  <TWMassOp key={key} onClick={act.onClick} darkMode={darkMode}>
                    <Icon icon={act.icon} size={EIconSize.MD}/>
                    <TextBox colorImportant={true} if={!bp.mobile} text={act.text} color={!!act.inactive ? ETextColor.PRIMARY : ETextColor.PRIMARY}/>
                  </TWMassOp>
                ))}
              </TWMassOps>
            </If>
            <If if={!bp.mobile && isButton}>
              <TWButtons>
                <Button {...mainAction} />
              </TWButtons>
            </If>
            <If if={!headerOpened || !bp.mobile}>
              <Avatar if={!isGuest && !selectionMode} avatar={avatar} asDropDown={true} onClick={(e) => setOpenMenu(true)} />
            </If>
          </div>
        </If>
      </div>
      <Portal element={PORTAL.UNDER_HEADER}>
        <If if={openMenu}>
          <TWMenu>
            <Menu profile={profile} ref={avatarRef} isGuest={isGuest} isLoggedIn={isLoggedIn} darkMode={darkMode}
                  onClick={handleMenuItemClick} onClose={() => setOpenMenu(false)}/>
          </TWMenu>
        </If>
      </Portal>
    </TWPageHeader>
  );
});
//grid grid-cols-a1a grid-flow-col
const TWPageHeader = styled("div")(({ theme, darkMode, headerOpened, position, mobile, button, avatar, logo, selectionMode, isFree, slug}) => [
  tw`relative flex pt-10p h-60p w-full justify-items-center overflow-hidden`,
  (headerOpened && !isFree) && tw`h-auto`,
  tw`mobile:pt-5p mobile:h-50p mobile:overflow-auto `,
  headerOpened && tw`mobile:min-h-screen mobile:h-auto mobile:block mobile:pb-30p`,
  mobile && css`::-webkit-scrollbar { width: 0; }; -ms-overflow-style: none;`,
  !position ? css`justify-content: space-between !important;` : css`justify-content: center`,
  !selectionMode && (avatar ? css`grid-template-columns: ${logo && '80px'} 1fr auto` : button ? css`grid-template-columns: ${logo && '80px'} 1fr auto 60px` : css`grid-template-columns: ${logo && '80px'} 1fr 60px;`)
]);

const TWMassOps = styled("div")(({ theme, darkMode, disabled, breakpoint }) => [
  tw`relative inline-flex -top-5p content-center`,
  //mobile gap 30
  //tablet gap 10
  //desktop gap 0
  css`margin-right: 18px`
]);

const TWMassOp = styled("div")(({ theme, darkMode, disabled }) => [
  tw`relative grid grid-flow-col items-center content-center
  cursor-pointer rounded-xl bg-transparent px-20p h-50p`,
  !darkMode ? tw`hover:bg-accent-100` : tw`hover:bg-accent-900`,
  tw`tablet:px-10p`,
  tw`mobile:h-40p mobile:px-15p`,
  css`grid-column-gap: 10px;`
]);

const TWMenu = styled("div")(() => [
  tw`inline-block absolute top-0 right-30p`,
  tw`mobile:fixed mobile:right-0 tablet:right-15p`,
  css`` ]);

const TWButtons = styled("div")((path) => [
  tw`mr-20p`,
  css`` ]);
