import { useEffect } from 'react';
import PubSub from 'pubsub-js';

export const usePubSub = <T>(topic: string, handler?: (topic: string, data?: T) => any, deps: any[] = []) => {
  useEffect(() => {
    if (!handler) {
      return;
    }

    const token = PubSub.subscribe(topic, handler);

    return () => {
      if (!token) {
        return
      }

      PubSub.unsubscribe(token);
    };
  }, [topic, handler, ...deps]);

  return (data?: T) => {
    return PubSub.publish(topic, data);
  }
};