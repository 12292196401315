import React from "react";
import { OnePixel } from 'const';
import tw, {css, styled} from 'twin.macro';
import {useStoreActions} from "../../../store";

export interface IFileView extends TThemed {
  name?: string;
  slug: string;
  link?: string | typeof React.Component;
  mime_type?: string;
  file_extension?: string;
  file_size?: number;
  if?: boolean | (() => boolean);
  demo?: boolean;
  darkMode?: boolean;
  toggleZoom: Function;
  [prop: string]: any;
}

export const ZoomFile = (props: IFileView) => {
  const { name="", link, file_size=0, file_extension="", mime_type="unknown", if:_if=true, darkMode=true, demo=false, ...rest } = props;
  const setZoom = useStoreActions((state) => state.setZoom);

  const toggleZoom = () => {
    // @ts-ignore
    setZoom(false)
  }

  return (
    <TWImage onClick={toggleZoom} src={link || OnePixel} />
  )
}

const TWImage = styled("img")(({ img, zoomed, height }) => [
  tw`max-h-full object-contain object-center`,
  tw`cursor-zoom-out`,
  css `
    max-width: unset !important;
    min-width: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: #2c3036;
    padding-bottom: 20px;
    position: relative;
    background-repeat: no-repeat;
  `
  /*css`background-image: url('${img}');`*/
]);