import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {
  Button,
  EButtonKind, EIcon,
  ETextAlign,
  ETextColor,
  ETextSize,
  ETextWeight,
  Icon, If,
  Panel,
  SpaceBox,
  Switch,
  TextBox
} from 'components';
import {PLAN} from 'const';
import {formatPrice, ifCond, str} from 'utils';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IComparePlans extends TThemed {
  onUpgradePro: any;
  onUpgradeTeam: any;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const ComparePlans = React.forwardRef((props: IComparePlans, ref) => {
  const { onClose, raw=false, darkMode=false, demo=false, onUpgradePro = () => {}, onUpgradeTeam = () => {}, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const getPaymentMethod = useStoreActions(a=>a.getPaymentMethod);
  const profile = useStoreState(s=>s.profile);
  const bp = useMediaBreakpoints();
  const [yearly, setYearly] = React.useState(false);
  const PRICES = {
    PRO: [formatPrice(PLAN.PRO.monthly), formatPrice(PLAN.PRO.yearly)],
    TEAM: [formatPrice(PLAN.TEAM.monthly), formatPrice(PLAN.TEAM.yearly)],
  };
  const PeriodProps = {
    leftText: [MSG.MONTHLY, {color: ETextColor.PRIMARY, weight: ETextWeight.SEMIBOLD}, {color: ETextColor.SECONDARY} ],
    rightText: [MSG.YEARLY, {color: ETextColor.SECONDARY, weight: ETextWeight.SEMIBOLD}, {color: ETextColor.PRIMARY} ]
  };
  const handleClose = React.useCallback((e) => { if (onClose instanceof Function) onClose(e) }, [onClose]);
  const handleChange = React.useCallback((e, check) => {
    if (check !== yearly) setYearly(check);
  }, [yearly, setYearly]);
  const handleUpgradePro = React.useCallback(() => onUpgradePro(), []);
  const handleUpgradeTeam = React.useCallback(() => onUpgradeTeam(), []);

  useKeydown(profile?.plan?.unique !== PLAN.TEAM.unique ? handleUpgradeTeam : handleUpgradePro, handleClose);

  React.useLayoutEffect(() => {
    getPaymentMethod()
  }, [])
  if ( !ifCond(_if) ) return null;
  return (
    <TWComparePlans ref={ref} darkMode={darkMode} {...rest}>
      <Panel onClose={handleClose} width={670} align={ETextAlign.CENTER}>
        <TWHeader><TextBox text={MSG.UPGRADE_PLAN.HEADER} width={440} weight={ETextWeight.SEMIBOLD} size={ETextSize.XLARGER} align={ETextAlign.CENTER} block={true}/></TWHeader>
        <SpaceBox height={45} />
        <TWFeats>
          <TWFeatsCol>
            <TWIcon>
              <Icon icon={EIcon.Pro} size={60}/>
            </TWIcon>
            <SpaceBox height={20}/>
            <TextBox text={`${MSG.BRAND} ${PLAN.PRO.name}`} weight={ETextWeight.SEMIBOLD}
                     size={ETextSize.XLARGER} align={ETextAlign.CENTER} fillX={true} block={true}
            />
            <SpaceBox height={22}/>
            {MSG.UPGRADE_PLAN.PRO_FEATS.map((f,i) => {
              return (<TextBox key={i} text={f}
                               inline={false} block={true} fillX={true}
                               // height="1.79em"
                               tw={'mt-5p'}
                               align={ETextAlign.CENTER}
              />);
            })}
          </TWFeatsCol>
          <TWFeatsCol>
            <TWIcon> <Icon icon={EIcon.Team} size={80}/> </TWIcon>
            <SpaceBox height={20}/>
            <TextBox text={`${MSG.BRAND} ${PLAN.TEAM.name}`} weight={ETextWeight.SEMIBOLD}
                     size={ETextSize.XLARGER} align={ETextAlign.CENTER} fillX={true} block={true}
            />
            <SpaceBox height={22}/>
            {MSG.UPGRADE_PLAN.TEAM_FEATS.map((f,i) => {
              return (<TextBox key={i} text={f}
                               weight={i===0 ? ETextWeight.BOLD : ETextWeight.REGULAR}
                               inline={false} block={true} fillX={true}
                               height="1.79em" align={ETextAlign.CENTER}
              />);
            })}
          </TWFeatsCol>
        </TWFeats>
        <SpaceBox height={45} />
        <TWSwitchRibbon><Switch checked={yearly} onChange={handleChange} {...PeriodProps}/></TWSwitchRibbon>
        <TWFeats>
          <TWFeatsCol>
            <SpaceBox height={yearly ? 20 : 40} />
            <div tw="text-center">
              <TextBox text={PRICES.PRO[+yearly]} size={ETextSize.XXLARGER} weight={ETextWeight.BOLD} />
              <TextBox text={` / ${MSG.MONTH_BRIEF}`} size={ETextSize.LARGER}/>
            </div>
            <SpaceBox if={!!yearly} height={10} />
            <div tw="text-center">
              <TextBox if={!!yearly} text={`${PRICES.PRO[+yearly]} / ${MSG.MONTH_BRIEF}`} strike={true} weight={ETextWeight.SEMIBOLD}/>
              <SpaceBox if={!!yearly} inline={true} width={4} />
              <TextBox if={!!yearly} text={str(MSG.UPGRADE_PLAN.BENEFIT, formatPrice(PLAN.PRO.benefit), PLAN.PRO.benefit.percent)} weight={ETextWeight.SEMIBOLD}/>
            </div>
            <SpaceBox height={yearly ? 28 : 40} />
            <Button tw={'block m-auto'} if={profile?.plan?.unique !== PLAN.PRO.unique && profile?.plan?.unique !== PLAN.TEAM.unique} caption={str(MSG.UPGRADE_PLAN.UPGRADE_TO, PLAN.PRO.name)} kind={EButtonKind.PRIMARY} onClick={handleUpgradePro}/>
            <SpaceBox height={10} />
          </TWFeatsCol>
          <TWFeatsCol>
            <SpaceBox height={yearly ? 20 : 40} />
            <div tw="text-center">
              <TextBox text={str(PRICES.TEAM[+yearly], ` / ${MSG.MONTH_BRIEF}`)} size={ETextSize.XXLARGER} weight={ETextWeight.BOLD}/>
              <TextBox text={` / ${MSG.MONTH_BRIEF}`} size={ETextSize.LARGER}/>
            </div>
            <SpaceBox if={!!yearly} height={10} />
            <div tw="text-center">
              <TextBox if={!!yearly} text={`${PRICES.TEAM[+yearly]} / ${MSG.MONTH_BRIEF}`} strike={true} weight={ETextWeight.SEMIBOLD}/>
              <SpaceBox if={!!yearly} inline={true} width={4} />
              <TextBox if={!!yearly} text={str(MSG.UPGRADE_PLAN.BENEFIT, formatPrice(PLAN.TEAM.benefit), PLAN.TEAM.benefit.percent)} weight={ETextWeight.SEMIBOLD}/>
            </div>
            <SpaceBox height={yearly ? 28 : 40} />
            <Button tw={'block m-auto'} if={profile?.plan?.unique !== PLAN.TEAM.unique} caption={str(MSG.UPGRADE_PLAN.UPGRADE_TO, PLAN.TEAM.name)} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleUpgradeTeam}/>
            <SpaceBox height={10} />
          </TWFeatsCol>
        </TWFeats>
      </Panel>
    </TWComparePlans>
  );
});

const TWComparePlans = styled("div")(() => [ tw``, css`` ]);
const TWHeader = styled("div")(() => [ tw`pt-30p text-center`, css`` ]);
const TWFeats = styled("div")(() => [ tw`grid grid-cols-2`, css`justify-items: center;` ]);
const TWIcon = styled("div")(() => [ tw`grid items-center content-center w-full h-80p`, css`justify-items: center;` ]);
const TWFeatsCol = styled("div")(() => [ tw``, css`` ]);
const TWSwitchRibbon = styled("div")(() => [ tw`grid items-center content-center bg-accent-100 -mx-30p h-70p`, css`justify-items: center;` ]);
