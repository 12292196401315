import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { keyframes } from 'styled-components';
import { TextBox, SpaceBox, Line, Icon, EIcon } from 'components';
import { cssSize, ifCond, str } from 'utils';
import { MSG } from 'const';
import Loader from 'assets/loader-gradient.png';

export interface ICardPaneLoader extends TThemed {
  if?: boolean | (() => boolean);
  center?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const CardPaneLoader = React.forwardRef((props: ICardPaneLoader, ref) => {
  const { center=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;

  if ( !ifCond(_if) ) return null;
  return (
    <TWCardPaneLoader ref={ref} center={center} darkMode={darkMode} {...rest}>
      <TWSpinner src={Loader} alt=""/>
    </TWCardPaneLoader>
  );
});

const TWCardPaneLoader = styled("div")(({ theme, darkMode, center=false }) => [
  tw`absolute overflow-hidden left-0 w-full h-50p`,
  center ? tw`-mt-70p mobile:-mt-60p` : tw`bottom-30p`,
  center && css`
    top: calc(50% - 25px);
  `
]);

const TWSpinner = styled("img")(({ theme, darkMode, disabled }) => [
  tw`relative rounded-half w-full h-50p object-contain object-center`,
  css`
    animation: ${spinningEffect} 1s linear infinite;
  `,
  css`
    &:before{
      content: '';
        background:
          linear-gradient(0deg,   hsla(0, 0%, 100%, 1  ) 50%, hsla(0, 0%, 100%, 0.9) 100%)   0%   0%,
          linear-gradient(90deg,  hsla(0, 0%, 100%, 0.9)  0%, hsla(0, 0%, 100%, 0.6) 100%) 100%   0%,
          linear-gradient(180deg, hsla(0, 0%, 100%, 0.6)  0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
          linear-gradient(360deg, hsla(0, 0%, 100%, 0.3)  0%, hsla(0, 0%, 100%, 0  ) 100%)   0% 100%;
    }
    &:after {
      content: '';
    }
  `,
  tw`before:absolute before:rounded-half before:bg-no-repeat before:bg-half
     before:-top-px before:-bottom-px before:-left-px before:-right-px
  `,
  tw`after:absolute after:rounded-half
     after:top-3/100 after:bottom-3/100 after:left-3/100 after:right-3/100
  `,
  darkMode ? tw`after:bg-accent-900` : tw`after:bg-accent-100`,
]);

const spinningEffect = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;
