import React, {Dispatch, SetStateAction} from 'react';
import tw, {css, styled} from 'twin.macro';
//import { RangePicker } from 'react-trip-date';
import RangeDatePicker from "./Calendar";
// import calendar style
import "assets/calendar-styles/calendar.scss";
import {dayjs, formatDate, ifCond, ifExistCall, isDayAfter, isDayBefore, isDayEqual, isDayRange} from 'utils';
import {EIcon, ETextAlign, Icon, Portal, TextBox} from 'components';
import {useStoreState} from "../../../store";

export interface ICalendar extends TThemed {
  disabledRange?: string[];
  /** Initial Start & End Dates */
  //selectedRange?: [dayjs.Dayjs, dayjs.Dayjs];
  initialStartDate?: dayjs.Dayjs | undefined;
  initialEndDate?: dayjs.Dayjs | undefined;
  months?: number;
  showToday?: boolean;
  locale?: string;
  /** To display input format (Day.js format) */
  dateFormat?: string;
  /** Initial Calendar base date(if start date not set) */
  initialDate?: dayjs.Dayjs;
  /** RangeDatePicker change event */
  onChange?: (start?: dayjs.Dayjs, end?: dayjs.Dayjs) => void;
  /** start day display this prop(optional) */
  startText?: string;
  /** end day display this prop(optional) */
  endText?: string;
  inputHandle?: React.RefObject<{onChange: Function, onBlur: Function, onClear: Function}>;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
const defaultProps  = {
  dateFormat: 'YYYY-MM-DD',
  portal: false,
  initialDate: dayjs(),
  showMonthCnt: 2,
  startText: '',
  endText: '',
};
enum EFieldType {
  START,
  END
}
interface State {
  start?: dayjs.Dayjs;
  end?: dayjs.Dayjs;
  hoverDate?: dayjs.Dayjs;
  startValue: string;
  endValue: string;
  mode?: EFieldType;
}

export const Calendar = React.forwardRef((props: ICalendar, ref) => {
  
  const { months=defaultProps.showMonthCnt, selectedRange=[], disabledRange=[], locale="en", showToday=false,
    dateFormat, direction, disabled, readOnly, initialDate, initialStartDate, initialEndDate,
    darkMode=false, demo=false, if:_if=true, ...rest
  } = props;
  const [state, setState, handleDateChange, handleMouseOver, handleCalendarText, handleCalendarClass ]: TCalendarHook = useCalendar(props);
  
  if ( !ifCond(_if) ) return null;
  return (
    <TWCalendar ref={ref} darkMode={darkMode} {...rest}>
      <RangeDatePicker
        //dateFormat={dateFormat}
        //initialStartDate={initialStartDate}
        //initialEndDate={initialEndDate}
        //initialDate={initialDate}
        base={state.start || initialDate}
        locale={locale}
        startDay={state.start}
        endDay={state.end}
        showMonthCnt={months}
        showToday={showToday}
        prevIcon={true}
        nextIcon={true}
        onChange={handleDateChange}
        onMouseOver={handleMouseOver}
        customDayText={handleCalendarText}
        customDayClass={handleCalendarClass}
      />
    </TWCalendar>
  );
});

const TWCalendar = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  //DayView
  css`
    .calendar {
      ${tw`font-sans`}
    }
    .calendar__list {
      grid-column-gap: 42px;
      ${tw`grid grid-flow-col`}
    }
    .calendar__container {
      ${tw`font-sans text-normal text-light-primary w-220p`}
    }
    .calendar__head--title {
      color: #181c20;
      letter-spacing: 0.47px;
      ${tw`text-normal`}
    }
    .calendar__body--table th {
      color: #727d85;
      ${tw`font-regular text-small h-17p mb-10p`}
    }
    .calendar__day {
      ${tw`bg-transparent align-middle border-0 h-30p w-30p p-0 text-light-primary`}
    }
    .calendar__day > div {
      ${tw`grid content-center items-center rounded-md overflow-hidden text-center w-full h-full`}
    }
    /*.calendar_day:not(.calendar_day-6)>div {
    
    }*/
    .calendar__day--start {
      ${tw`border-sky text-white bg-sky rounded-l-md`}
    }
    .calendar__day--today {
      ${tw`bg-inherit`}
    }
    .calendar__day--today > div {
      ${tw`border border-sky text-light-primary bg-white rounded-md`}
    }
    .calendar__day--end {
      ${tw`border-sky text-white bg-sky rounded-r-md`}
    }
    .calendar__day--range /*isDayRange(currentDate, startDay, endDay),*/ {
      ${tw`border-sky text-white bg-sky rounded-none`}
    }
    .calendar__day--selected /*this.isIncludeDay(date, selected),*/ {
    
    }
    .calendar__day--disabled {
    
    }
    .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range) {
      ${tw`bg-inherit`}
    }
    .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range) > div {
      ${tw`bg-accent-100 border border-sky text-light-primary rounded-md cursor-pointer`}
    }
    .calendar__day:hover:not(.calendar__day--disabled).calendar__day--start,
     .calendar__day:hover:not(.calendar__day--disabled).calendar__day--end,
     .calendar__day:hover:not(.calendar__day--disabled).calendar__day--range {
      ${tw`bg-sky`}
    }
    .calendar__day:hover:not(.calendar__day--disabled).calendar__day--range > div,
     .calendar__day:hover:not(.calendar__day--disabled).calendar__day--start > div,
     .calendar__day:hover:not(.calendar__day--disabled).calendar__day--end > div {
      ${tw`border border-sky bg-accent-100 text-light-primary rounded-md`}
    }
    .calendar__day--range + .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range):not(.calendar__day--end),
     .calendar__day--start + .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range):not(.calendar__day--end) {
      ${tw`border-sky text-white bg-sky rounded-r-md`}
    }
    .calendar__day--range + .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range):not(.calendar__day--end) > div,
     .calendar__day--start + .calendar__day:hover:not(.calendar__day--disabled):not(.calendar__day--range):not(.calendar__day--end) > div {
     
    }
  `,
  //buttons
  css`
    .calendar__head--button {
      outline: none;
      font-size: 0px;
    }
    .calendar__head--button svg {
      fill: unset;
    }
  `
]);

type TCalendarHook = [ State, Dispatch<SetStateAction<State>>, ...Array<(date: dayjs.Dayjs) => any>];
const useCalendar = (props: ICalendar, /*initialStartDate?, initialEndDate?*/ ): TCalendarHook  => {
  const { inputHandle, onChange, onClose,
    dateFormat, initialStartDate, initialEndDate
  } = props;
  const start = initialStartDate;
  const end = initialEndDate;

  const [state, setState] = React.useState<State>({
    start, end,
    startValue: formatDate(start, dateFormat),
    endValue: formatDate(end, dateFormat),
  });
  React.useImperativeHandle(inputHandle, () => ({
    onChange: (fieldType: EFieldType, value: string) => {
      const key = fieldType === EFieldType.START ? 'startValue' : 'endValue';
      setState({
        ...state,
        [key]: value,
      });
    },
    onBlur: (fieldType: EFieldType, value: string) => {
      const { start, end } = state;
      const parsedDate = dayjs(value, dateFormat);
      let startDate = start;
      let endDate = end;

      if (parsedDate.isValid() && dateFormat.length === value.length) {
        if (fieldType === EFieldType.END) {
          endDate = parsedDate;
        } else if (fieldType === EFieldType.START) {
          startDate = parsedDate;
        }
      }

      if (startDate && endDate) {
        if (isDayBefore(endDate, startDate) || isDayAfter(startDate, endDate)) {
          // Swapping Date
          let temp: dayjs.Dayjs;
          temp = startDate;
          startDate = endDate;
          endDate = temp;
        }
      }

      setState({
        ...state,
        start: startDate,
        end: endDate,
        startValue: formatDate(startDate, dateFormat),
        endValue: formatDate(endDate, dateFormat),
      });
    },
    onClear: (fieldType?: EFieldType) => {
      if (fieldType === EFieldType.START) {
        setState({
          ...state,
          start: undefined,
          startValue: '',
        });
      } else if (fieldType === EFieldType.END) {
        setState({
          ...state,
          end: undefined,
          endValue: '',
        });
      } else {
        setState({
          ...state,
          start: undefined,
          startValue: '',
          end: undefined,
          endValue: '',
        });
      }
    }
  }), [setState, state, dateFormat, inputHandle]);

  const handleDateChange = (date: dayjs.Dayjs) => {
    const { start, end } = state;
    let startDate: dayjs.Dayjs | undefined;
    let endDate: dayjs.Dayjs | undefined;

    startDate = start;
    endDate = end;

    if (!start) {
      startDate = date;
    } else {
      if (end) {
        startDate = date;
        endDate = undefined;
      } else {
        if (!isDayBefore(date, start)) {
          endDate = date;
        } else {
          startDate = date;
        }
      }
    }

    ifExistCall(onChange, startDate, endDate);

    setState((st) => {
      return {
        ...st,
        start: startDate,
        end: endDate,
        startValue: formatDate(startDate, dateFormat),
        endValue: formatDate(endDate, dateFormat),
      };
    });
  };
  const handleMouseOver = (date: dayjs.Dayjs) => {
    setState({
      ...state,
      hoverDate: date,
    });
  };
  const handleCalendarText = (date: dayjs.Dayjs) => {
    const { startText, endText, customDayText } = props;
    const { start, end } = state;
    if (isDayEqual(start, date)) return startText;
    if (isDayEqual(end, date)) return endText;
    ifExistCall(customDayText, date);
    return '';
  };
  const handleCalendarClass = (date: dayjs.Dayjs) => {
    const { customDayClass } = props;
    const { start, end, hoverDate } = state;
    if (start && !end && hoverDate) {
      if (isDayRange(date, start, hoverDate)) {
        return 'calendar__day--range';
      }
    }
    ifExistCall(customDayClass, date);
    return '';
  };
  React.useLayoutEffect(() => {
    if (state.start && state.end) {
      ifExistCall(onClose, state.start, state.end);
    }
  }, [state, onClose]);

  return [ state, setState, handleDateChange, handleMouseOver, handleCalendarText, handleCalendarClass ];
};

// function calendarMonthFormat(dateString: string) {
//   const d = dateString.match(/(\d{4,})\.(\d{1,})/);
//   const YYYY = d && d[1];
//   const MM = d && d[2];
//   return dayjs(`${YYYY}-${MM}-01`).format(MSG.DATE.CALENDAR.MONTH_HDR);
// }
