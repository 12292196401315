import React from 'react';
import tw, {css, styled} from 'twin.macro';
import InputMask from 'react-input-mask';
import {
  AscCloudpayments,
  Button,
  Buttons,
  EButtonKind,
  EditBox,
  EEditInput,
  EEditKind,
  EPanelCaptionKind, EPanelWidth,
  Line,
  Panel,
  Radio,
  RadioGroup,
  SpaceBox,
  TextBox
} from 'components';
import {FIELD, PLAN, PRODUCTS, PUBLIC_ID, SETTINGS} from 'const';
import {collectFieldErrors, debounceHandler, ifCond} from 'utils';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {IFieldErrors} from "../../../types";
import {createCryptogram} from "../../../utils/createCryptogram";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IBillingChangeMethod extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  onClearError?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

const initialValues = {
  [FIELD.CARD]: '',
  [FIELD.EXPIRES]: '',
  [FIELD.CVC]: '',
  [FIELD.HOLDER_NAME]: '',
}

export const BillingChangeMethod = React.forwardRef((props: IBillingChangeMethod, ref) => {
  const { onClose, onSubmit, raw=false, onClearError, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const methods = ["card", "paypal"];
  const MSG = useStoreState(s=>s.MSG);
  const sendInvoice = useStoreActions(a=>a.sendInvoice);
  const setMessages = useStoreActions(actions => actions.setMessages);
  const errors = useStoreState(state => state.fieldErrors);
  const bp = useMediaBreakpoints();
  const [method, setMethod] = React.useState(methods[0]);
  const handleClose = React.useCallback((e) => {
    onClearError('')
    if (onClose instanceof Function) onClose(e) }, [onClose]);
  
  const handleChangeMethod = React.useCallback((val, selected, name) => {
    //TODO: replace to MSG
    if (!!~name.search(/^billing-method-/) && method!==val) {
      setMethod(val);
    }
  },[method, setMethod]);
  const [values, setValues] = React.useState(initialValues);
  const handleSubmit = React.useCallback((e) => {
    
    const validationErrors = {}
    
    if (values[FIELD.CARD].replace(/' '/g, '').length < 16) {
      validationErrors[FIELD.CARD] = MSG.ERROR.CARD.REQ
    }
  
    if (values[FIELD.EXPIRES].length < 5) {
      validationErrors[FIELD.EXPIRES] = MSG.ERROR.EXPIRES.REQ
    }
  
    if (values[FIELD.CVC].length < 3) {
      validationErrors[FIELD.CVC] = MSG.ERROR.CVC.REQ
    }
  
    if (!values[FIELD.HOLDER_NAME]) {
      validationErrors[FIELD.HOLDER_NAME] = MSG.ERROR.HOLDER_NAME.REQ
    }
    
    if (!Object.keys(validationErrors).length) {
      const cryptogram = createCryptogram({
        cardNumber: values[FIELD.CARD],
        expDateMonth:  values[FIELD.EXPIRES].split('/')[0],
        expDateYear:  values[FIELD.EXPIRES].split('/')[1],
        cvv:  values[FIELD.CVC],
        name:  values[FIELD.HOLDER_NAME]
      })
      if (!cryptogram.success && cryptogram?.messages?.cardNumber) {
        validationErrors[FIELD.CARD] = MSG.ERROR.CARD.INCORRECT
      }
      if (!cryptogram.success && (cryptogram?.messages?.expDateMonth || cryptogram?.messages?.expDateYear)) {
        validationErrors[FIELD.EXPIRES] = MSG.ERROR.EXPIRES.INCORRECT
      }
      if (!cryptogram.success && cryptogram?.messages?.cvv) {
        validationErrors[FIELD.CVC] = MSG.ERROR.CVC.INCORRECT
      }
      if (!cryptogram.success && cryptogram?.messages?.name) {
        validationErrors[FIELD.HOLDER_NAME] = MSG.ERROR.HOLDER_NAME.INCORRECT
      }
    }
    
    if (Object.keys(validationErrors).length) {
      setMessages(collectFieldErrors(validationErrors))
    } else {
      const cryptogram = createCryptogram({
        cardNumber: values[FIELD.CARD],
        expDateMonth:  values[FIELD.EXPIRES].split('/')[0],
        expDateYear:  values[FIELD.EXPIRES].split('/')[1],
        cvv:  values[FIELD.CVC],
        name:  values[FIELD.HOLDER_NAME]
      })
      sendInvoice({item_name: PRODUCTS.VERIFICATION, quantity: 1, cryptogram: cryptogram.packet, cardholder_name: values[FIELD.HOLDER_NAME]})
      if (onSubmit instanceof Function) onSubmit(e);
    }
  }, [onSubmit, values, setValues]);
  const handleChange = React.useCallback((e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
    if (onClearError instanceof Function) {
      onClearError(fld)
    };
  }, [values, setValues]);
  const isCard = methods[0] === method, isPayPal = !isCard;

  useKeydown(handleSubmit, handleClose, values);

  if ( !ifCond(_if) ) return null;
  // height={method===methods[0] ? 355 : 373}
  return (
    <TWBillingChangeMethod ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.ACTION.CHANGE_PAYMENT_METHOD_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose}>
        <SpaceBox height={20} />
        <Line />
        {/*<SpaceBox height={30} />*/}
        {/*<RadioGroup name={MSG.UPGRADE_PLAN.BILLING_METHOD + "-" +Date.now()} as={TWPeriod} value={method} onChange={handleChangeMethod}>*/}
        {/*  <Radio value={methods[0]}><TextBox text={MSG.CREDIT_CARD}/></Radio>*/}
        {/*  <Radio value={methods[1]}><TextBox text={MSG.PAYPAL}/></Radio>*/}
        {/*</RadioGroup>*/}
        <SpaceBox height={30} />
        <Line if={isPayPal} />
        <SpaceBox if={isPayPal} height={30} />
        <TextBox if={isPayPal} text={MSG.UPGRADE_PLAN.PAYPAL_NOTE}/>
        <SpaceBox if={isPayPal} height={30} />
        <Line if={isPayPal} />
        <EditBox mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} if={isCard} caption={MSG.PAYMENT.CARD_NUMBER} kind={EEditKind.LG} input={EEditInput.TEXT} inline={false} fill={true} onChange={(e) => handleChange(e, FIELD.CARD)} errors={errors[FIELD.CARD]}/>
        <SpaceBox if={isCard} height={10} />
        <div tw="grid grid-flow-col col-gap-10p">
          <EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, '/', /\d/, /\d/,]} caption={MSG.PAYMENT.EXPIRES} kind={EEditKind.LG} input={EEditInput.TEXT} width={'auto'} onChange={(e) => handleChange(e, FIELD.EXPIRES)} errors={errors[FIELD.EXPIRES]}/>
          <EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, /\d/,]} caption={MSG.PAYMENT.CVC} kind={EEditKind.LG} input={EEditInput.TEXT} width={'auto'} onChange={(e) => handleChange(e, FIELD.CVC)} errors={errors[FIELD.CVC]}/>
          {/*<EditBox if={isCard} placeholderChar={'\u2000'} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} caption={MSG.PAYMENT.ZIP} kind={EEditKind.LG} input={EEditInput.TEXT} width={!bp.mobile ? 97 : 87}*/}
          {/*         onInput={debounceHandler((e) => {handleChange(e.target.value, null, FIELD.ZIP)}, SETTINGS.inputDelay)}/>*/}
        </div>
        <SpaceBox if={isCard} height={10} />
        <EditBox if={isCard} caption={MSG.PAYMENT.HOLDER_NAME} placeholderChar={'\u2000'} kind={EEditKind.LG} input={EEditInput.TEXT} inline={false} fill={true} onChange={(e) => handleChange(e, FIELD.HOLDER_NAME)} errors={errors[FIELD.HOLDER_NAME]}/>
        <SpaceBox height={20} />
        {/*<PanelFooter>*/}
          <Buttons>
            <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleClose}/>
            <Button caption={MSG.ACTIONS.SAVE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
          </Buttons>
        {/*</PanelFooter>*/}
      </Panel>
    </TWBillingChangeMethod>
  );
});

const TWBillingChangeMethod = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
const TWPeriod = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid col-gap-10p items-center content-center w-auto h-18p`,
  css`grid-template-columns: repeat(2, 116px);`
]);
