import React, {useRef} from "react";
import {useStoreState} from "../store";

export function useKeydown(onSubmit: Function, onClose: Function, value?: any) {
  const cond = useStoreState(state => state.screenshots.isBlocked);
  React.useEffect(() => {
    const handleEsc = async (event) => {
        if (event.code === "Escape") {
          await onClose(event);
        }
        if (event.code === "Enter") {
          if (!onSubmit) {
            await onClose(event);
            return null;
          }
          await onSubmit(event);
        }
      }
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [value, onClose, onSubmit, cond]);
}
