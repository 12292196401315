import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  LinkButton, PanelHeader, EEditInput, If, ETextColor, EPanelWidth
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {FIELD} from 'const';
import {useStoreState} from "../../../store";
import {TextareaBox} from "../../base/Field/TextateaBox";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface ITeamInviteUsers extends TThemed {
  link?: string;
  limit: number;
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  owner?: boolean;
  [prop: string]: any;
}

export const TeamInviteUsers = React.forwardRef((props: ITeamInviteUsers, ref) => {
  const MSG = useStoreState(s=>s.MSG);
  const { link,  owner=false, onPurchaseLicenses, onClose, onSubmit, raw=false, darkMode=false, demo=false, limit=0, if:_if=true, ...rest } = props;
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const [values, setValues] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [showOffer, setShowOffer] = React.useState(false);
  const [refLink, setRefLink] = React.useState(link);
  const bp = useMediaBreakpoints();
  const handleSubmit = React.useCallback((e) => {
    const emailRegex = /\b([a-z0-9._-]+@[a-z0-9.-]+)\b/gi;
    const emails = values.match(emailRegex)
    if (onSubmit instanceof Function) {
      if (emails) {
        if (emails.length <= limit) {
          onSubmit(e, emails)
        }
        else {
          setShowOffer(true)
        }
      } else {
        setErrors(['Please enter at least one correct email'])
      }
    };
  }, [onSubmit, values, setValues]);
  const handleChange = React.useCallback((e, fld) => {
    setErrors([])
    const value = e.target.value
    if (fld === FIELD.EMAIL) {
      setValues(value.replace(' ', '\n'))
    }
    if (fld === FIELD.LINK) {
      setRefLink(value)
    }
  }, [setValues, values]);

  useKeydown(handleSubmit, handleClose, values);

  if ( !ifCond(_if) ) return null;
  return (
    <TWTeamInviteUsers ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.ACTION.INVITE_USERS_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <EditBox caption={MSG.ACTION.INVITE_USERS_LINK} value={refLink} onBlur={(e) => handleChange(e, FIELD.LINK)} fill={true}/>
        <SpaceBox height={10} />
        <div tw="flex">
          <TextBox text={MSG.ACTION.INVITE_USERS_SEND_LINK} />
          <SpaceBox inline={true} width="stretch" />
          <LinkButton text={MSG.ACTION.INVITE_USERS_LINK_COPY}/>
        </div>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={30} />
        <PanelHeader kind={EPanelCaptionKind.SM} text={MSG.ACTION.INVITE_USERS_EMAIL_HDR}/>
        <SpaceBox height={20} />
        <TWInvitationList>
          <TextareaBox caption={MSG.ACTION.INVITE_USERS_ENTER}
                       errors={errors}
                   input={EEditInput.TEXT}
                       value={values}
                   onChange={(e) => {handleChange(e, FIELD.EMAIL);}}
                       height={200}
          />
        </TWInvitationList>
        <If if={showOffer}>
          <TextBox text={MSG.ACTION.LIMIT_EXCEEDED} color={ETextColor.ERROR}/>
          <LinkButton if={owner} text={MSG.ACTION.BUY_LICENSES} onClick={onPurchaseLicenses}/>
        </If>
        <SpaceBox height={20}/>
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_PRIMARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.SAVE} kind={EButtonKind.PRIMARY} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWTeamInviteUsers>
  );
});

const TWTeamInviteUsers = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);

const TWInvitationList = styled("div")(({ theme, darkMode, disabled }) => [
  tw`grid relative grid-cols-full grid-flow-row row-gap-10p w-full`,
  css``
]);
