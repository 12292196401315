/*global tw*/
import React from 'react';
import tw, { styled, css } from 'twin.macro';

export interface IIconButton {
  kind: EIconButtonKind;
  icon?: string;
  iconPos?: "left" | "right";
  outlined?: boolean;
  caption?: React.ReactNode;
  disabled?: boolean;
}

export enum EIconButtonKind {

}

export enum EIconButtonSize {

}

export const IconButton = (props: IIconButton) => {
  const { caption, disabled, outlined, kind, ...events } = props;
  const classNames = [`btn-${kind}`]
    .concat(outlined?'btn-outlined':'')
    .concat(disabled?'btn-disabled':'')
    .join(" ");
  return (
    <TWIconButton {...events} className={classNames}>
      {caption}
    </TWIconButton>
  );
};

const TWIconButton = styled("div")(({ theme, darkMode, disabled }) => [
  tw`align-middle`,
  css`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  `
]);