import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {EditBox, EEditInput, EEditKind, EIcon, EPanelCaptionKind, EPanelWidth, Panel, SpaceBox} from 'components';
import {FIELD} from 'const';
import {callIfExists, ifCond} from 'utils';
import {IFieldErrors} from 'types';
import {useStoreActions, useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";
import {useKeydownModal} from "../../../hooks/useKeydownModal";

export interface ICardProtect extends TThemed {
  slugs?: string[] | string;
  type?: ECardProtectType;
  onSubmit?: Function;
  onClose?: Function;
  errors?: IFieldErrors;
  onClearError?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}
export enum ECardProtectType {
  PROTECT, ACCESS
}

export const CardProtect = React.forwardRef((props: ICardProtect, ref) => {
  const { slugs=[], type=ECardProtectType.PROTECT, errors={}, onClearError, onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const slugList = React.useMemo(() => [].concat(slugs), [slugs]);
  const MSG = useStoreState(s=>s.MSG);
  const setIsBlocked = useStoreActions((state) => state.screenshots.setIsBlocked);
  const bp = useMediaBreakpoints();
  const [values, setValues] = React.useState({});
  console.log('values', values);
  const handleClearErrors = React.useCallback(async () => {
    await callIfExists(onClearError, FIELD.PWD);
  }, [onClearError]);
  const handleClose = React.useCallback(async (e) => {
    await handleClearErrors();
    callIfExists(onClose, e);
    setIsBlocked(false);
  }, [onClose]);
  const handleSubmit = React.useCallback(async (e) => {
    await handleClearErrors();
    callIfExists(onSubmit, e, { slugs: slugList, [FIELD.PWD]: values[FIELD.PWD] });
    setIsBlocked(false);
  }, [onSubmit, values, slugList]);
  const handleChange = (e, fld) => {
    const value = e.target.value;
    setValues({...values, [fld]: value});
  };
  const Props = {
    [ECardProtectType.PROTECT]: {
      caption: MSG.ACTION.PROTECT_PWD_PROTECT_HDR,
      pwd: MSG.ACTION.PROTECT_ENTER_A_PASSWORD,
      button: {
        caption: MSG.ACTIONS.LOCK,
        onClick: handleSubmit,
        padding: 20,
        width: 95
      },
    },
    [ECardProtectType.ACCESS]: {
      caption: MSG.ACTION.PROTECT_PWD_ACCESS_HDR,
      pwd: MSG.ACTION.PROTECT_ENTER_THE_PASSWORD,
      button: {
        caption: MSG.ACTIONS.UNLOCK,
        onClick: handleSubmit,
        padding: 20,
        width: 90
      },
    },
  };
  useKeydownModal(handleSubmit, handleClose, handleChange, values);
  if ( !ifCond(_if) ) return null;
  return (
    <TWCardProtect ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={Props[type].caption} width={bp.mobile ? EPanelWidth.MOB : EPanelWidth.SM} captionKind={EPanelCaptionKind.SM} icon={EIcon.Lock} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={20} />
        <EditBox caption={Props[type].pwd} kind={EEditKind.SM} errors={errors[FIELD.PWD]} onChange={(e) => handleChange(e, FIELD.PWD)}
                 input={EEditInput.PWD} showPwdBtn={true} button={Props[type].button} fill={true}
        />
        <SpaceBox height={20} />
      </Panel>
    </TWCardProtect>
  );
});

const TWCardProtect = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
