import React, {useState} from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  Icon, EIcon, ETextSize, ETextColor, EIconSize, TagLabel, ETagType, ETextAlign, ETagColor, If
} from 'components';
import {callIfExists, cardDateFormat, cssSize, ifCond, str} from 'utils';
import {PLAN} from 'const';
import ReactTooltip from "react-tooltip";

import LeftButton from 'assets/button/left-button.svg';
import RightButton from 'assets/button/right-button.svg';
import CloseButton from 'assets/button/close-button.svg';

import LeftButtonHover from 'assets/button/left-button-hover.svg';
import RightButtonHover from 'assets/button/right-button-hover.svg';
import CloseButtonHover from 'assets/button/close-button-hover.svg';
import {useStoreState} from "../../../store";
import {useKeydownSlug} from "../../../hooks/useKeydownSlug";

export interface ISlugFooter extends TThemed {
  onNext?: Function;
  onPrev?: Function;
  onClose?: Function;
  onTagClick?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const SlugFooter = React.forwardRef((props: ISlugFooter, ref) => {
  const { id, slug, caption=props.name, name, backHistory, description, created_date, preview, next, prev, bgcolor:bg, link:href,
    tags=[], file_size=0, file_extension="", mime_type="unknown", protected:locked=0, plan=PLAN.NONE.unique, index=1000,
    onClose, onNext, onPrev, onTagClick, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
  const ext = file_extension;
  const MSG = useStoreState(s=>s.MSG);
  const created = cardDateFormat(created_date, MSG.LANG_UNIQUE, MSG);

  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleNext = React.useCallback((e) => {
    if (onNext instanceof Function) onNext(e, next);
  }, [onNext, next]);
  const handlePrev = React.useCallback((e) => {
    if (onPrev instanceof Function) onPrev(e, prev);
  }, [onPrev, prev]);
  const handleTagClick = React.useCallback((e, cardTag) => {
    callIfExists(onTagClick, e, cardTag);
  }, [onTagClick]);

  useKeydownSlug(handlePrev, handleNext, backHistory, {next, prev});

  if ( !ifCond(_if) ) return null;
  if (!created || !caption ) return null;


  let leftButton;
  let rightButton;

  if (next != "" && next)
  {
    leftButton = <TWButton src={LeftButton} hoverSrc={LeftButtonHover} onClick={handleNext}></TWButton>;
  }else {
    leftButton = <div></div>
  }

  if (prev != "" && prev)
  {
    rightButton = <TWButton src={RightButton} hoverSrc={RightButtonHover} onClick={handlePrev}></TWButton>;
  }else {
    rightButton = <div></div>
  }
  
  return (
    <TWSlugFooter ref={ref} darkMode={darkMode} {...rest}>

      <TWInfoBlock>
        <TWCaption>
          {/* <Icon if={!!locked} icon={EIcon.LockGray} size={19} /> */}
          <TextBox text={caption} size={ETextSize.MEDIUM} color={ETextColor.PRIMARY} fillX={true} ellipsis={true} block={true} inline={false} index={index} darkMode={true}/>
        </TWCaption>
        <SpaceBox height={4} />
        <TWStamp>
          <TextBox text={created} size={ETextSize.SMALL} color={ETextColor.SECONDARY} fillX={true} ellipsis={true} block={true} inline={false} darkMode={true}/>
        </TWStamp>
        <SpaceBox height={5} />
      </TWInfoBlock>

      <TWTagLayout>
        <TWTagRibbon if={[PLAN.PRO.unique, PLAN.TEAM.unique].includes(plan)}>

            {tags.slice(0,10).map((tags, key) => {
              return (
                <TWTag key={key} onClick={(e)=>handleTagClick(e, tags)}>
                  <TagLabel color={tags.color} type={ETagType.LABEL} text={tags.name} hint={tags.name} id={tags.id} minmax={[60,80]} align={ETextAlign.CENTER}/>
                  { false && (key < 2 || tags.length > 3) && <div tw="inline-block relative w-5p"/> }
                </TWTag>
              );
            })}
        </TWTagRibbon>
      </TWTagLayout>

      <TWControls>
       
        <TWButtonLayout>
          <TWArrows>
            {leftButton}
            {rightButton}
          </TWArrows>
          <TWButton src={CloseButton} hoverSrc={CloseButtonHover} onClick={handleClose}></TWButton>
        </TWButtonLayout>
      </TWControls>
    </TWSlugFooter>
  );
});

const TWInfoBlock = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  tw`tablet:ml-20p`,
  tw`mobile:ml-15p mobile:mr-100p`,
  css`margin-left: 35px`
]);

const TWControls = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`
  background-color: #1d202a;
`
]);

const TWArrows = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  tw`mobile:hidden`,
  css`
      display: grid;
      grid-template-columns: 50px 50px 50px;
      grid-gap: 10px;
      @media (max-width: 767px) {
        display:none;
      }
`
]);

const TWButton = styled("div")(({ src, hoverSrc }) => [
  tw``,
  css`width: 50px;
      height: 50px;

      background-image: url(${src});
      background-position: center;
      background-repeat: no-repeat;

      &:hover {
        background-color: #2c3036;
        background-image: url(${hoverSrc});
        border: 1px solid #2c3036;
        border-radius: 90px;
      }
      `
]);

const TWButtonLayout = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css`display: grid;
      grid-template-columns: 100px 50px;
      grid-gap: 10px;
      float:right;
      margin-right: 30px;
      top: 10px;
      right: 0;
      position: absolute;
      @media (max-width: 767px) {
       top: 27px;
       grid-template-columns: 50px;
      }
      `
]);

const TWSlugFooter = styled("div")(({ theme, darkMode, disabled }) => [
  tw`w-full h-auto flex justify-between relative overflow-hidden`,
  tw`tablet:flex-col `,
  tw`mobile:flex-col `,
  css`
      //@media (max-width: 640px) {
      //
      //  flex-direction: column;
      //}
      background-color: #1d202a;
      padding-top: 15px;
      padding-bottom: 10px;
      //padding-right: 190px;
    `
]);

const TWCaption = styled("section")(() => [tw`relative grid grid-flow-col col-gap-10p overflow-hidden h-19p`,
  css`
    text-overflow: ellipsis;
    grid-template-columns: auto;
    grid-auto-columns: 1fr;
    &>a {overflow: hidden;}
  `
]);
const TWStamp = styled("section")(() => [tw`block relative h-17p capitalize`,]);
const TWTagLayout = styled("section")(() => [
  tw`relative left-0 bottom-0 w-auto flex justify-center`,
  tw`tablet:justify-start tablet:ml-15p`,
  tw`mobile:justify-start mobile:ml-10p`,
  css`
      margin-right: 180px;
      width: 100%;
      background-color: #1d202a;
`
]);
const TWTagRibbon = styled("div")(() => [ tw`relative grid grid-flow-col items-center content-center px-15/2p w-fit h-40p`,
  css`
    
    grid-auto-columns: auto;
    grid-column-gap: 5px;
  `
]);
const TWAddTagButton = styled("div")(({ theme, clickable }) => [ tw`inline-grid relative grid-cols-auto grid-flow-col col-gap-10p px-5/2p py-10p`, clickable && tw`cursor-pointer`,
  css`
    &:not(:hover)>.button-tag-add-hover {
      display: none !important;
    }
    &:hover>.button-tag-add-normal {
      display: none !important;
    }
    &:hover>.button-tag-add-text {
      color: ${theme.colors.sky} !important;
    }
  `
]);
const TWTag = styled("div")(() => [ tw`inline-block relative h-full cursor-pointer py-10p` ]);
const TWTagTooltip = styled("div")(({ theme, darkMode, disabled }) => [ tw`inline-grid grid-cols-5xa px-15/2p`, css`& label { width: auto; }` ]);
