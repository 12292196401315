import { range } from '@y0c/react-datepicker/lib/utils/ArrayUtil';
import { dayjs } from 'utils';
import * as React from 'react';
import CalendarContainer, { InheritProps as ContainerProps } from './CalendarContainer';

export interface Props extends ContainerProps {
  /** Calendar Initial Date Parameters */
  base: dayjs.Dayjs;
  /** Number of months to show at once */
  showMonthCnt: number;
}

export interface State {
  base: dayjs.Dayjs;
}

class Calendar extends React.Component<Props, State> {
  public static defaultProps = {
    base: dayjs(),
    showMonthCnt: 1,
    showToday: false,
    locale: 'en'
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      base: props.base,
    };
  }

  public setBase = (base: dayjs.Dayjs) => {
    this.setState({ base });
  };
  

  public render() {
    const { showMonthCnt, locale } = this.props;
    const { base } = this.state;
    const getLang = (lang) => {
      const ru = {
        name: 'ru',
        weekdays: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
        weekdaysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
        months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
      };
      if (lang === 'ru') {
        return ru
      }
      return lang
    }
    
    return (
      <div className="calendar">
        <div className="calendar__list">
          {range(showMonthCnt).map(idx => (
            <div className="calendar__item" key={idx}>
              <CalendarContainer
                {...this.props}
                locale={getLang(locale)}
                base={this.state.base}
                current={dayjs(base).add(idx, 'month')}
                prevIcon={idx === 0}
                nextIcon={idx === showMonthCnt! - 1}
                setBase={this.setBase}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Calendar;
