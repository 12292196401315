import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  EPanelCaptionKind,
  Panel,
  EditBox,
  TextBox,
  SpaceBox,
  EButtonKind,
  Buttons,
  Button,
  Line,
  ETextSize, EPanelWidth
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {useStoreState} from "../../../store";
import {useMediaBreakpoints} from "../../../hooks";
import {useKeydown} from "../../../hooks/useKeydown";

export interface IDeleteAvatar extends TThemed {
  onSubmit?: Function;
  onClose?: Function;
  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const DeleteAvatar = React.forwardRef((props: IDeleteAvatar, ref) => {
  const { onClose, onSubmit, raw=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const MSG = useStoreState(s=>s.MSG);
  const bp = useMediaBreakpoints();
  const handleClose = React.useCallback((e) => {
    if (onClose instanceof Function) onClose(e);
  }, [onClose]);
  const handleSubmit = React.useCallback((e) => {
    if (onSubmit instanceof Function) onSubmit(e);
  }, [onSubmit]);

  useKeydown(handleSubmit, handleClose);

  if ( !ifCond(_if) ) return null;
  return (
    <TWDeleteAvatar ref={ref} darkMode={darkMode} {...rest}>
      <Panel caption={MSG.CONFIRM.DELETE_AVATAR_HDR} width={bp.mobile ? EPanelWidth.MOB : 375} captionKind={EPanelCaptionKind.SM} onClose={handleClose} darkMode={darkMode}>
        <SpaceBox height={10} />
        <TextBox text={MSG.CONFIRM.DELETE_AVATAR_ASK} size={ETextSize.MEDIUM} inline={false} block={true}/>
        <SpaceBox height={20} />
        <Line />
        <SpaceBox height={20} />
        <Buttons>
          <Button caption={MSG.ACTIONS.CANCEL} kind={EButtonKind.OUTLINED_SECONDARY} onClick={handleClose}/>
          <Button caption={MSG.ACTIONS.DELETE} kind={EButtonKind.CRITICAL} onClick={handleSubmit}/>
        </Buttons>
      </Panel>
    </TWDeleteAvatar>
  );
});

const TWDeleteAvatar = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
