import React from 'react';
import tw, {css, styled} from 'twin.macro';
import {cssSize, debounce, ifCond} from 'utils';
import {EEditKind, EIcon, EIconSize, Icon, If, TextBox} from 'components';
import useOnclickOutside from 'react-cool-onclickoutside';
import {useInputValue, useDropDownPos, useStoreState} from 'hooks';

export interface IComboBox extends TThemed {
  caption?: string | number | null;
  value?: string | number | null;
  errors?: string[] | null;
  items?: IComboOption[];
  kind?: EComboKind;
  input?: EComboInput;
  fill?: boolean;
  inline?: boolean;
  onChange?: Function;
  onBlur?: Function;
  onPaste?: Function;
  onFocus?: Function;
  onKeyPress?: Function;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export interface IComboOption extends TThemed {
  text?: string;
  value: string | number | null;
}
export enum EComboKind {
  SM, LG
}
export enum EComboInput {
  SEARCH, TEXT, EMAIL, URL,
}
export const CComboInput = "SEARCH, TEXT, EMAIL, URL".split(", ")
  .map(t => t.toLowerCase());

export const ComboBox = React.forwardRef((props: IComboBox, _ref) => {
  
  const { caption, value:val, items=[], kind=EComboKind.LG, inline=true, fill=false, width, showPwdBtn, errors=[], onChange, onBlur, onPaste, onFocus, onKeyPress, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  
//**********MATCHING FUNCTIONS*************
  const match = React.useCallback((v, byText:boolean=false, checkOnly:boolean=false) => {
    
    const res = v == null ? [] : items.filter(it=>it[byText?"text":"value"]===v);
    return checkOnly ? res.length>0 : res;
  }, [items]);
  const includes = React.useCallback((v, checkOnly:boolean=false) => {
    const res = v == null ? [] : items.filter(it=>it.text.toLowerCase().indexOf((v||"").toLowerCase())==0);
    return checkOnly ? res.length>0 : res;
  }, [items]);

//**********STATE INIT*************
  
  const defSelected = match(val) as IComboOption[];
  // console.log({defSelected, val})
  const defItem = defSelected.length ? defSelected[0] : null;
  const {value: defValue, text: defText} = defItem || {value: null, text: ""};
  const [dataVisible, setDataVisible] = React.useState(false);
  const [selected, setSelected] = React.useState<IComboOption | null>(defItem);
  const [searchText, setSearchText] = React.useState(defText);
  const ref = React.useRef();
  const [hasValue, fire] = useInputValue(ref);

//**********SMALL STATE MANAGEMENT WRAPPERS*************
  const select = React.useCallback((target, item?) => {
    if (item) {
      if (selected!==item) setSelected(item);
      if (searchText!==item.text) setSearchText(item.text);
      target.value=item.text;
    } else {
      setSelected(null);
      setSearchText("");
      target.value = "";
    }
  }, [selected, setSelected, searchText, setSearchText]);
  const close = React.useCallback((...args) => {
    if (dataVisible) {(setDataVisible(false));}
  }, [dataVisible, setDataVisible]);
  const open = React.useCallback((...args) => {if (!dataVisible) {setDataVisible(true)}}, [dataVisible, setDataVisible]);
  const toggle = React.useCallback((...args) => {if (!dataVisible) open(); else handleBlur({target:ref.current});}, [dataVisible, open, close]);
  const changeText = React.useCallback((target, txt, ignoreStateChange:boolean=false) => {
    if (!ignoreStateChange && searchText!==txt) setSearchText(txt);
    target.value=txt || "";
    (fire as Function)();
  }, [searchText, setSearchText]);

//**********DROPDOWN REF & PARAMS*************
  const ddRef = useOnclickOutside(() => {
    changeText(ref.current,selected ? selected.text : "");
    close();
  });
  const ddPos = useDropDownPos(ref);

//**********HANDLERS*************
  const handleBlur = (e) => {
    const txt = e.target.value;
    const matched = match(txt, true) as IComboOption[];
    if (txt!="" && matched.length>0) {
      select(e.target, matched[0]);
      close();
    } else if (!txt) {
      select(e.target, null);
      close();
    }
    if (onBlur instanceof Function) onBlur(e, matched);
  };
  const handlePaste = (e) => {
    const txt = e.target.value;
    const matched = match(txt, true) as IComboOption[];
    if (matched.length>0) select(e.target, matched[0]);
    close();
    if (onPaste instanceof Function) onPaste(e, matched);
  };
  const handleFocus = (e) => {
    if (onFocus instanceof Function) onFocus(e);
    open();
  };
  const handleChevronClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };
  const handleChange = ((e) => {
    if (!e.target) return null;
    const txt = e.target.value; //e.target.value;
    let res = null;
    if (onChange instanceof Function) res = onChange({target: ref.current});
    if (res!==false) changeText(ref.current, txt);
  });
  const handleSelect = (e, item?) => {
    if (item) select(ref.current, item);
    close();
  };
  const handleKeyPress = (e) => {
    if (e.key==='Enter') {
      const txt = e.target.value;
      const matched = includes(txt) as IComboOption[];
      if (!!txt && matched.length>0) {
        select(e.target, matched[0]);
        close();
      } else if (!txt) {
        e.target.blur();
      }
      if (onKeyPress instanceof Function) onKeyPress(e, matched);
    }
  };
  const id = React.useMemo(() => "combobox-datalist-"+Date.now(), []);
  if ( !ifCond(_if) ) return null;
  return (

      <TWPlaceholder ref={ddRef} {...{kind,caption,errors,inline,fillX: fill,width, hasValue, dataVisible}}>
        <TWComboBox ref={ref} type={CComboInput[EComboInput.TEXT]}
                    {...{kind, caption, errors, darkMode, hasValue, dataVisible}}
                    placeholder={kind===EComboKind.SM && caption || undefined}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                    onInput={handleChange}
                    onFocus={handleFocus}
                    onKeyPress={handleKeyPress}
                    autoComplete="new-password"
                    defaultValue={defText}
                    list={id}
                    {...rest}
        />
        <TWIcon onClick={handleChevronClick}>
          <Icon size={10} icon={EIcon.ChevronGray} kind={kind}/>
        </TWIcon>
        <DataList if={dataVisible}
                  id={id}
                  position={ddPos}
                  search={searchText}
                  items={items} kind={kind}
                  onSelect={handleSelect}
                  onHover={(txt, ignoreStateChange) => changeText(ref.current, txt, ignoreStateChange)}
        />
      </TWPlaceholder>
  );
});
const TWPlaceholder = styled("label")(({ kind, fillX, inline, width, caption, hasValue, errors, dataVisible }) => [
  tw`relative outline-none cursor-text`,
  inline ? tw`inline-block` : tw`block`,
  fillX && tw`w-full`,
  width && css`${cssSize(width, "width")}`,
  kind === EComboKind.LG && errors.length>0 && tw`-mb-10p`,
  //before
  kind === EComboKind.LG ? tw`before:absolute after:inline-block` : tw`before:hidden after:hidden`,
  tw`before:absolute before:overflow-hidden before:text-light-secondary before:text-normal before:z-20 before:cursor-text`,
  css`
    &:before {
      transition: top var(--d-o, .3s), font-size var(--d-o, .3s), height var(--d-o, .3s);
      content: "${caption}";
      text-overflow: ellipsis;
    }
  `,
  tw`before:left-15p before:top-16p before:h-18p before:text-normal`,//no value
  tw`focus-within:before:top-8p focus-within:before:h-17p focus-within:before:text-small`,//focus
  hasValue && tw`before:top-8p before:h-17p before:text-small`, // value
  //after
  errors.length>0 && tw`after:relative after:bg-error after:text-left after:text-white after:font-sans after:font-semibold after:text-xsmall
    after:rounded-xs after:rounded-t-none after:px-15p after:pt-10p after:pb-px after:-top-10p after:w-full after:z-0`,
  errors.length>0 && css`
    &:after {
      content: "${errors.join('\n')}";
    }
  `
]);
const TWComboBox = styled("input")(({ theme, darkMode, disabled, kind, caption, hasValue, errors, dataVisible }) => [
    tw`relative outline-none border border-solid w-full placeholder-light-secondary z-10`,
    tw`font-sans text-light-primary text-normal pt-0`,
    hasValue ? tw`bg-accent-101 border-accent-101` : tw`bg-accent-100 border-accent-100`,
    errors.length>0 && tw`border-error`,
    errors.length>0 && (kind === EComboKind.SM && tw`text-error`),
    ...(kind === EComboKind.SM ?
      [
        tw`h-40p rounded-lg px-20p`, // Normal @ kind SM
      ] : [
        tw`h-50p rounded-xs pl-15p pr-34p`, //Normal @ kind LG
        !!dataVisible && tw`rounded-b-none bg-white border-gray-350 shadow-combobox`,
        hasValue && tw`pt-15p`,
      ]),
    //:hover
    errors.length<1 && !dataVisible && tw`hover:bg-accent-101 hover:border-accent-101`,
    errors.length>0 && !dataVisible && tw`hover:border-error`,
    //:focus
    tw`focus:bg-white focus:border-gray-350`,
    kind === EComboKind.LG && tw`focus:pt-15p`,
    errors.length>0 && tw`bg-white focus:border-error`,
    //:after error
    ...([]),
    css`
      /*--focus: 2px rgba(0, 94, 197, .3);*/
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: background .3s, border-color .3s;
    `,
    kind === EComboKind.SM && css`
    
    `
  ]
);

export interface IDataList extends TThemed {
  id: string;
  items?: IComboOption[];
  kind?: EComboKind;
  search?: string;
  onSelect: Function;
  onHover?: Function;
  children?: React.ReactNode;
  if?: boolean;
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}

export const DataList = React.forwardRef((props: IDataList, ref) => {
  const MSG = useStoreState(s=>s.MSG);
  const { id, items=[], search="", filter=false, kind=EComboKind.LG, onSelect, onHover, children, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const handleSelect = React.useCallback((e, option?) => {
    onSelect(e, option);
    e.stopPropagation();
    e.preventDefault();
  }, [onSelect]);
  const handleHover = React.useCallback((e, item, ignoreStateChange) => {
    if (onHover instanceof Function) onHover(item.text, ignoreStateChange);
  }, [onHover]);
  const currentItems = items.filter((item, i) => {
    if (!filter) return true
    if (!search) return true;
    return (item.text.toLowerCase()).includes((search || "").toLowerCase());
  }).map(((item, i) => {
    return (
      <TWOption key={i} kind={kind} value={item.value} onClick={(e) => handleSelect(e, item)}
                onMouseEnter={(e) => handleHover(e, item, true)}
                title={item.text}
      >
        <TextBox text={<TWOptionCaption>{item.text}</TWOptionCaption>} block={false} inline={false} fillX={true} fillY={true}/>
      </TWOption>
    );
  }));

  // console.log({currentItems, items})
  
  return (<>
    {children}
    <If if={_if}>
      <TWDataList id={id} ref={ref} darkMode={darkMode} kind={kind} {...rest}>
        <If if={currentItems.length>0} else={
          <TWNoOption >
            <TextBox text={<TWOptionCaption>{MSG.NO_DATA}</TWOptionCaption>} block={false} inline={false} fillX={true} fillY={true}/>
          </TWNoOption>}
        >
          {currentItems}
        </If>
      </TWDataList>
    </If>
  </>);
});

const TWDataList = styled("datalist")(({ theme, darkMode, disabled, kind, position, height, }) => [
  tw`absolute block z-30 opacity-100 bg-white border border-solid border-gray-350 shadow-combobox overflow-x-hidden overflow-y-auto h-auto`,
  position.dirUp ?
    tw`rounded-t-xs` :
    tw`rounded-b-xs`,
  css`
      ${cssSize(position.width, "width")}
      ${cssSize(position.height, "max-height")}
  `,
  css`text-overflow: ellipsis;`
]);
const TWOption = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`text-normal text-light-primary cursor-pointer w-full`,
  kind===EEditKind.SM ?
    tw`h-40p px-20p` :
    tw`h-50p px-15p`,
  tw`bg-white hover:bg-gray-200`,
  css``
]);
const TWNoOption = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`text-normal text-light-primary cursor-pointer w-full`,
  kind===EEditKind.SM ?
    tw`h-40p px-20p` :
    tw`h-50p px-15p`,
  tw`bg-white hover:bg-gray-200`,
  css``
]);
const TWOptionCaption = styled("span")(() => [tw`truncate`,css``]);

const TWIcon = styled("div")(({ theme, darkMode, disabled, kind }) => [
  tw`inline-grid absolute items-center cursor-pointer right-0 z-20 pl-10p pr-15p h-full`,
  kind===EEditKind.SM ? tw`h-40p` : tw`h-50p`,
  css`
  
  `
]);
