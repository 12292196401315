import React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
  Modal,
  Line,
  TextBox,
  SpaceBox,
  CardShare,
  HelpTranslate,
  BillingCancelSub,
  DeleteAccount,
  DeleteCard,
  DeleteUser,
  BillingChangeMethod,
  CardProtect,
  CardTags,
  ProfileChangeEmail,
  ProfileChangeLang,
  ProfileChangeName,
  ProfileChangePwd,
  TeamCustomDomain,
  TeamInviteUsers,
  TeamIPLimit,
  ComparePlansMobile,
  ComparePlans,
  UpgradePlan,
  ClearHistory,
  DeleteAvatar,
  SuccessfulPayment,
  ErrorPayment,
  TeamPurchaseLicenses,
  PurchaseLicenses,
  Donate,
  UnprotectCard,
  UnlockFile
} from 'components';
import { cssSize, ifCond, str } from 'utils';
import {store, useStoreActions, useStoreState} from 'store';

export interface IDialogs extends TThemed {

  if?: boolean | (() => boolean);
  raw?: boolean;
  demo?: boolean;
  [prop: string]: any;
}
export enum EDialogType {
  INFO = "Info", CONFIRM = "Confirm", ACTION = "Action"
}
export enum EDialogResult {
  SUBMIT = "submit", CLOSE = "close"
}
export interface IDialogResponse {
  result: EDialogResult;
  props?: any;
  data?: any;
}

export interface IDialogClose {
  result: EDialogResult;
  props?: any;
  data?: any;
}
//const onClose = () => store.getActions().dialogs.clearDialog();

export enum EDialogAction {
    CHANGE_PAYMENT_METHOD = "BillingChangeMethod",
    PROTECT_CARD = "CardProtect",
    SHOW_CARD_TAGS = "CardTags",
    UNLOCK_FILE= "UnlockFile",
    CLEAR_HISTORY = "ClearHistory",
    CHANGE_EMAIL = "ProfileChangeEmail",
    CHANGE_LANG = "ProfileChangeLang",
    CHANGE_USERNAME = "ProfileChangeName",
    CHANGE_PASSWORD = "ProfileChangePwd",
    CHANGE_TEAM_DOMAIN = "TeamCustomDomain",
    INVITE_TEAMMATES = "TeamInviteUsers",
    CHANGE_TEAM_IP_LIMIT = "TeamIPLimit",
    PURCHASE_LICENSES = "PurchaseLicenses",
    TEAM_PURCHASE_LICENSES = "TeamPurchaseLicenses",
    DELETE_AVATAR = "DeleteAvatar",
    DONATE = "Donate",
    UPGRADE_PLAN = "UpgradePlan",
    COMPARE_PLANS = "ComparePlans",
    COMPARE_PLANS_MOBILE = "ComparePlansMobile",
}
export enum EDialogConfirm {
  UNSUBSCRIBE = "BillingCancelSub",
  DELETE_CARD = "DeleteCard",
  DELETE_AVATAR = "DeleteAvatar",
  DELETE_USER = "DeleteUser",
  UNPROTECT_CARD = "UnprotectCard",
}
export enum EDialogInfo {
    PAYMENT_ERROR = "ErrorPayment",
    PAYMENT_SUCCESS = "SuccessfulPayment",
    SHARE_CARD = "CardShare",
    HELP_TRANSLATE = "HelpTranslate"
}
export type TDialog = EDialogAction | EDialogConfirm | EDialogInfo;
export const Dialogs = React.forwardRef((props: IDialogs, ref) => {
  const { raw=false, closeOnMisclick=false, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const dialogType = useStoreState(state => state.dialogs.type);
  const dialogComponentName = useStoreState(state => state.dialogs.subType);
  const dialogProps = useStoreState(state => state.dialogs.props);
  const dialogResolve = useStoreState(state => state.dialogs.resolve);
  const dialogReject = useStoreState(state => state.dialogs.reject);
  const fieldErrors = useStoreState(state => state.fieldErrors);
  const clearMessagesByField = useStoreActions(actions => actions.clearMessagesByField);
  const Info = { CardShare: CardShare, HelpTranslate: HelpTranslate, SuccessfulPayment, ErrorPayment };
  const Confirm = { BillingCancelSub, DeleteAccount, DeleteCard, DeleteUser, UnprotectCard };
  const Action = { BillingChangeMethod, CardProtect, CardTags, ProfileChangeEmail,
    ProfileChangeLang, ProfileChangeName, ProfileChangePwd, TeamCustomDomain, UnlockFile,
    TeamInviteUsers, TeamIPLimit, ComparePlansMobile, UpgradePlan, ClearHistory, DeleteAvatar, ComparePlans, TeamPurchaseLicenses, PurchaseLicenses, Donate
  };
  const ActionComponent = dialogType===EDialogType.ACTION ? Action[dialogComponentName] : null;
  const ConfirmComponent = dialogType===EDialogType.CONFIRM ? Confirm[dialogComponentName] : null;
  const InfoComponent = dialogType===EDialogType.INFO ? Info[dialogComponentName] : null;

  const handleClose = React.useCallback(async (e, values) => {
    await dialogReject({
      result: EDialogResult.CLOSE,
      props: dialogProps,
      data: values
    });
  }, [dialogReject, dialogProps]);
  const handleSubmit = React.useCallback(async (e, values) => {
    await dialogResolve({
      result: EDialogResult.SUBMIT,
      props: dialogProps,
      data: values
    });
  }, [dialogResolve, dialogProps]);

  if ( !ifCond(_if) ) return null;
  return (
    <Modal if={!!dialogComponentName} onClose={handleClose} closeOnMisclick={false}>
      {
        ActionComponent && <ActionComponent onClose={handleClose} onSubmit={handleSubmit} errors={fieldErrors} onClearError={clearMessagesByField} {...dialogProps} /> ||
        ConfirmComponent && <ConfirmComponent onClose={handleClose} onSubmit={handleSubmit} {...dialogProps} /> ||
        InfoComponent && <InfoComponent onClose={handleClose} {...dialogProps} />
      }
    </Modal>
  );
});

const TWDialogs = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
