import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox } from 'components';
import { cssSize, ifCond } from 'utils';

export interface ILine extends TThemed {
  vertical?: boolean; // false
  weight?: 1 | 2 | 3 | 4; // 1
  text?: string;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}
export enum ELineWeight {
  "sm" = 1, "md", "lg", "xl"
}

export const Line = (props: ILine) => {
  const { vertical=false, darkMode=false, if:_if=true, weight=1, text, ...rest } = props;
  //darkMode = false; because there are no lines at dark background, only at light
  const defaultedProps = {vertical, weight};
  if ( !ifCond(_if) ) return null;
  return (vertical
    ? <TWYLine {...defaultedProps} {...rest}/>
    : <TWXLine text={text} {...rest}>
        <TWHLine {...defaultedProps}/>
        {text && <TextBox color="secondary" size="medium" text={text} darkMode={false}/>}
        <TWHLine {...defaultedProps}/>
    </TWXLine>
  );
};

const TWXLine = styled.div(({text}) => [ tw`grid items-center clear-both w-full`, text ? tw`grid-cols-1a1 col-gap-10p` : tw`grid-cols-2`, ]);
const TWHLine = styled("div")(({ weight }) => [ tw`inline-block w-full border-0 leading-4 bg-gray-350`, css`${cssSize(weight, "height")}` ]);
const TWYLine = styled("div")(({ weight }) => [ tw`inline-block h-normal mx-1 -mb-2p bg-gray-350`, css`${cssSize(weight, "width")}` ]);