import {useCallback, useLayoutEffect, useState} from "react";

export function useInputValue(ref) {
  const [v, setV] = useState<string | null>(
    ref && ref.current ? ref.current.value : null
  );
  const handleChange = useCallback(() => {
    if (!ref.current) return;
    setV(ref.current.value);
  }, [ref]);
  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;
    handleChange();
    element.addEventListener("input", handleChange);
    element.addEventListener("change", handleChange);
    return () => {
      element.removeEventListener("input", handleChange);
      element.removeEventListener("change", handleChange);
    }
  }, [ref.current]);
  const fire = () => {fireInput(ref.current, "change")};
  return [v, fire];
}

function fireInput(target, evt:string) {
  // const valueSetter = Object.getOwnPropertyDescriptor(target, 'value').set;
  // const prototype = Object.getPrototypeOf(target);
  // const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
  // if (valueSetter && valueSetter !== prototypeValueSetter) {
  //   prototypeValueSetter.call(target, target.value);
  // } else {
  //   valueSetter.call(target, target.value);
  // }
  target.dispatchEvent(new Event(evt, {bubbles: true}));
}