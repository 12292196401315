import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { TextBox, SpaceBox, Line } from 'components';
import {cssSize, ifCond, injectPropsIntoChildren, str} from 'utils';
import ReactDOM from "react-dom";

export interface IPortal extends TThemed {
  element?: HTMLElement | HTMLElement[] | string; //element or id
  selector?: string;
  replaceContent?: boolean;
  children?: React.ReactNode;
  render?: React.ReactNode;
  if?: boolean | (() => boolean);
  demo?: boolean;
  [prop: string]: any;
}

export const Portal = React.forwardRef((props: IPortal, ref) => {
  const { children, element, selector, replaceContent=false, extractDomProps, render, darkMode=false, demo=false, if:_if=true, ...rest } = props;
  const els = !element ? document.querySelectorAll(selector) || []: (element+""===element ? [document.getElementById(element)] : [].concat(element as HTMLElement|HTMLElement[]));
  const config = {
    attributes: false,
    childList: true,
    subtree: false
  };
  return <>{
    Array.from(els).map((el,i) => {
      const [clean, setClean] = React.useState(!replaceContent);
      React.useLayoutEffect(() => {
        if (!clean) {
          el.innerHTML = '';
          setClean(true)
        }
      }, [clean, setClean]);
      const toRender = render ? render : children;
      if ( !ifCond(_if) || !el || !clean ) return null;
      return (el && ReactDOM.createPortal( toRender, el ));
    })
  }</>;
});

const TWPortal = styled("div")(({ theme, darkMode, disabled }) => [
  tw``,
  css``
]);
